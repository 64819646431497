import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'timeout-dialog',
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss']
})
export class TimeoutDialogComponent implements OnInit {

  message: string = ''
  showReconnect = true;
  // timer = 5;
  interval;
  timeLeft: number = 30;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if(this.showReconnect)
      this.message = "Your Session Will Expire after ( "+ (this.timeLeft) + " ) secondes"
    else
      this.message = "Your Session has been expired"

  }

  dismiss() {
    // this.pauseTimer();
    if(this.showReconnect)
      this.activeModal.dismiss('dissmissed');
    else
      this.activeModal.close('disconected');
  }

  confirm() {
    // this.pauseTimer();
    this.activeModal.close('logout');
  }

  close(){
    // this.pauseTimer();
    this.activeModal.close('disconected');
  }


  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.message = "Your Session Will Expire after ( "+ (this.timeLeft) + " ) secondes"
        this.timeLeft--;
      } else {
        this.message = "Your session has been expired"
        this.showReconnect = false
        // this.timeLeft = 60;
      }
    },1000)
  }

  // pauseTimer() {
  //   clearInterval(this.interval);
  // }
}
