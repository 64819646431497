<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">{{labelCreateOrModifyOrLoadArchivedDocument}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-horizontal-stepper linear #stepper>
  <mat-step [stepControl]="myFormGroup">
    <form [formGroup]="myFormGroup">
      <!--Title-->
      <ng-template matStepLabel>Doc informations</ng-template>
      <h3 class="mat-headline text-center">Please fill in the following information to {{labelModify}}  {{label}}</h3>
      <!--*****************************************New archived Document*******************************************-->
      <ng-container *ngIf="isArchivedDocument">
        <app-create-archived-document></app-create-archived-document>
      </ng-container>

      <!--*****************************************New Document****************************************************-->
      <ng-container *ngIf="!isArchivedDocument">
        <app-create-document></app-create-document>
      </ng-container>
      <div class="text-center footer">
        <button mat-stroked-button class="mr-3">Cancel</button>
        <button mat-flat-button matStepperNext color="primary">Next</button>
        <!--    &lt;!&ndash;      <button mat-flat-button matStepperNext color="primary" *ngIf="isArchivedChecked">Next2</button>&ndash;&gt;-->
        <!--    <button mat-flat-button matStepperNext color="primary"-->
        <!--            *ngIf="this.docType != 'FORM' && this.docType != 'INOR'"-->
        <!--            (click)="goForward(false)">Next-->
        <!--    </button>-->
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Doc type</ng-template>
      <h3 class="mat-headline text-center">Please select the type of the new document</h3>
      <div class="text-center">
        <mat-form-field>
          <mat-select placeholder="Type" formControlName="typeSelectCtrl" required>
            <mat-option value="FORM" [hidden]="this.docType != 'FORM'">FORM</mat-option>
            <mat-option value="INOR" [hidden]="this.docType != 'INOR'">INOR</mat-option>
            <mat-option value="UGFL" [hidden]="this.docType != 'UG'">UG for Licensee</mat-option>
            <mat-option value="UGIRA" [hidden]="this.docType != 'UG'">UGI for Reviewer-Approver</mat-option>
            <mat-option value="UGIA" [hidden]="this.docType != 'UG'">UGI for Admin</mat-option>
            <mat-option value="process" [hidden]="this.docType == 'UG'">Process</mat-option>
            <mat-option value="product" [hidden]="this.docType == 'UG'">Product</mat-option>
            <mat-option value="general_information" [hidden]="this.docType == 'UG'">General information</mat-option>
            <mat-option value="legacy">Legacy</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 100%">
        <div style="max-width: 450px; margin: 0 auto 2rem auto">
          <p>{{ typeDescriptionText }}</p>
        </div>
      </div>
    </form>
    <div class="text-center footer">
      <button mat-stroked-button matStepperPrevious class="mr-3">Previous</button>
      <button *ngIf="isArchivedDocument " mat-flat-button matStepperNext color="primary"
              (click)="saveDocument()">Next1
      </button>
      <button *ngIf="!isArchivedDocument " mat-flat-button matStepperNext color="primary">Next2</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup" label="step3" *ngIf="!isArchivedDocument">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Application date & approval</ng-template>
      <h3 class="mat-headline text-center">Please enter the Application date</h3>
      <div class="text-center">
        <mat-form-field class="mr-3">
          <input matInput placeholder="Applicable by" formControlName="applicableByCtrl" required
                 [(ngModel)]="docApplicableBy">
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Application date" formControlName="applicationDateCtrl"
                 required [(ngModel)]="docApplicationDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="text-center">
        <mat-form-field class="mr-3">
          <mat-select placeholder="Choose a reviewer"
                      [(ngModel)]="selectedReviewer"
                      formControlName="reviewerSelectCtrl"
                      required
          >
            <mat-option *ngFor="let reviewer of reviewers"
                        [value]="reviewer.id" [hidden]="selectedApprover == reviewer.id || idUser == reviewer.id ">
              {{ reviewer.first_name}} {{reviewer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Choose an approver"
                      [(ngModel)]="selectedApprover"
                      formControlName="approverSelectCtrl"
                      required>

            <mat-option *ngFor="let approver of approvers"
                        [value]="approver.id" [hidden]="selectedReviewer == approver.id || idUser == approver.id ">
              {{approver.first_name}} {{approver.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="text-center footer">
      <button mat-stroked-button matStepperPrevious class="mr-3">Previous</button>
      <button mat-flat-button matStepperNext (click)="saveDocument()" color="primary">Next</button>
      <button mat-flat-button matStepperNext (click)="sendToReview()" style="margin-left:2%" color="primary"
      >Send to review
      </button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirmation</ng-template>
    <ng-container *ngIf="commitSuccessful">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p>your new document has been created</p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">OK !</button>
      </div>
    </ng-container>
    <ng-container *ngIf="sendToReviewSuccessful">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p>your new document has been created and sent to reviwer</p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">OK !</button>
      </div>
    </ng-container>

    <ng-container *ngIf="commitFailed">
      <h3 class="mat-headline text-center">An error occurred!</h3>
      <div class="text-center">
        <p>your new document could not be created!</p>
        <mat-icon>mood_bad</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">Cancel</button>
      </div>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
