<ng-container [ngSwitch]="isEditEnable">
  <div *ngIf="!isEditEnable">
    <span (click)="edit()">{{ value }}</span>
  </div>

  <div *ngIf="isEditEnable">
    <div *ngIf="isHtmlContent">
      <ckeditor
        [editor]="Editor"
        [(ngModel)]="value"
        (change)="valueFormGroup.get('valueCtrl').setValue($event.editor.getData())"
      ></ckeditor>
    </div>

    <div *ngIf="!isHtmlContent">
      <form [formGroup]="valueFormGroup">
        <mat-form-field style="width: 90%">
          <input matInput formControlName="valueCtrl" [(ngModel)]="value" required>
        </mat-form-field>
      </form>
    </div>
    <div class="validate">
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button color="primary"  (click)="save()">Validate</button>
    </div>

  </div>
</ng-container>

