import { Tsli } from './tsli';
import { User } from './User';
import { Chapter } from './chapter';

export class Comment {
    idComment: number;
    commentator: User;
    comment: string;
    commentDate: Date;
    chapter: Chapter;
    tsli: Tsli;
    checked: boolean;
    is_revision_comment:string;
}
