import {Component, OnInit, OnDestroy, ViewChildren, QueryList} from '@angular/core';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {Tsli} from '../../../core/data/tsli';
import {Chapter} from '../../../core/data/chapter';
import {DetailedTsliConstants, Theme} from '../detailed-tsli.constants';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {AuthService} from 'src/app/core/auth.service';
import {InformationDialogComponent} from '../../../core/dialog/information-dialog/information-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DetailedTsliStore} from 'src/app/core/detailed-tsli-store.service';
import {Status} from 'src/app/core/enum/status.enum';
import {TsliRestService} from 'src/app/core/tsli-rest.service';
import {MatAccordion} from '@angular/material/expansion';
import {ConfigurationService} from 'src/app/core/configuration.service';
import {SpinnerDialogComponent} from '../../../core/dialog/spinner-dialog/spinner-dialog.component';
import {catchError, switchMap} from 'rxjs/operators';

@Component({
  selector: 'tsli-content',
  templateUrl: './tsli-content.component.html',
  styleUrls: ['./tsli-content.component.scss']
})
export class TsliContentComponent implements OnInit, OnDestroy {
  static readonly DEBUG: boolean = false;
  @ViewChildren('accordion') matAccordion: QueryList<MatAccordion>;
  loading = false;
  subscriptions: Subscription[] = [];
  public tsliSubscription: Subscription;
  public byItemSubscription: Subscription;
  public configSubscriptions = [];
  generalInfoChapters = [];
  processThemesChapters = [];
  ugiraThemesChapters = [];
  ugflThemesChapters = [];
  ugiaThemesChapters = [];

  checkView = false;

  themes: Theme[];
  tsli: Tsli;
  byItem: boolean;
  tsliDisclaimer = [];
  i = 1;
  modalRef: NgbModalRef;
  timeLeft: number = 125;

  constructor(public store: CurrentTsliStoreService,
              public rest: TsliRestService,
              public authService: AuthService,
              public modal: NgbModal,
              public config: ConfigurationService,
              public detailedTsli: DetailedTsliStore
  ) {
    if (TsliContentComponent.DEBUG) {
      console.log(`-- TsliContentComponent ctor`);
    }

    this.themes = DetailedTsliConstants.THEMES;
  }

  ngOnInit(): void {
    this.timeLeft = 125;
    this.checkView = true;

    //show dialog with success message for send to review
    if (this.detailedTsli.isSendToReview) {
      this.initializeInoformationDialog();
    }

    this.subscriptions.push(this.store.isByItem$.subscribe(value => this.byItem = value));

    this.subscriptions.push(
      this.store.tsli$
        .pipe(
          switchMap((tsli: Tsli) => this.handleTsli(tsli)),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );

  }

  handleTsli(tsli: Tsli): Observable<any> {
    this.initializeModal()

    console.log("Debug")
    console.log(this.modalRef)

    this.tsli = tsli;
    if (tsli != undefined && tsli.chapters != undefined) {

      console.log('tsli:', this.tsli);
      console.log('tsli chapters:', this.tsli.chapters);

      var regex = /&amp;token=\w*"/gi;
      let regUrl = /<a href="\/tsli\/by-reference\/(nt|rtli|tsli|tssu|form|inor|ug|)\/[a-zA-Z0-9\-]*">[ a-zA-Z0-9\-]*<\/a>/gi;

      this.rest.getPublishedDocuments().subscribe(resp => {
        this.modalRef.componentInstance.message = 'Please, wait while we are uploading content ...';
        let published = resp.tslis
        // console.log('published:', published);

        this.tsli.chapters.forEach(chapter => {
          chapter.body = chapter.body.replace(regex, '&amp;token=' + this.authService.getToken() + '\"');

          let founds = chapter.body.match(regUrl);
          let refs = [];
          if (founds != undefined) {
            founds.forEach(found => {
              let ref = found.replace(/<a href="\/tsli\/by-reference\/(nt|rtli|tsli|tssu|form|inor|ug|)\/[a-zA-Z0-9\-]*">/, '');
              refs.push(ref.replace(/<\/a>/, ''));
            });

            refs.forEach(ref => {
              let docAndRef = ref.split(' ');
              let publishedDocumentWithFileToDownload = published.filter(document => document.docType == docAndRef[0]
                && document.reference == docAndRef[1] && document.source_file_s3_url != undefined);

              if (publishedDocumentWithFileToDownload.length > 0) {
                let urlToReplace = '<a href="/tsli/by-reference/' + docAndRef[0] + '/' + docAndRef[1] + '">' + docAndRef[0] + ' ' + docAndRef[1] + '</a>';
                let newUrl = '';
                this.rest.getTsliByReference(docAndRef[0], docAndRef[1], false).subscribe(
                  response => {

                    if (response['msg'] == 'user_not_allowed') {
                      newUrl = '<a href="/tsli/Unauthorized/404">' + docAndRef[0] + ' ' + docAndRef[1] + '</a>';
                      chapter.body = chapter.body.replace(urlToReplace, newUrl);
                    } else {
                      newUrl = '<a href="' + response['url_download_bpd'] + '">' + docAndRef[0] + ' ' + docAndRef[1] + '</a>';
                      chapter.body = chapter.body.replace(urlToReplace, newUrl);
                    }
                  });
              }

              let publishedDocumentWithoutFileToDownload = published.filter(document => document.docType == docAndRef[0]
                && document.reference == docAndRef[1] && document.source_file_s3_url == null);

              if (publishedDocumentWithoutFileToDownload.length > 0) {
                let refUrl = '<a href="/tsli/by-reference/' + docAndRef[0] + '/' + docAndRef[1] + '">' + docAndRef[0].toUpperCase() + ' ' + docAndRef[1] + '</a>';
                chapter.body = chapter.body
                  .replace(refUrl, docAndRef[0] + ' ' + docAndRef[1]);
              }
            });
          }

        });

        this.modalRef.componentInstance.done = true;
        this.modal.dismissAll();
        this.modalRef.close()
      });
      this.generalInfoChapters = this.groupByRef(tsli.chapters, 'general_information');
      this.processThemesChapters = this.groupByRef(tsli.chapters, 'process');
      this.ugiraThemesChapters = this.groupByRef(tsli.chapters, 'ugira');
      this.ugiaThemesChapters = this.groupByRef(tsli.chapters, 'ugia');
      this.ugflThemesChapters = this.groupByRef(tsli.chapters, 'ugfl');

    }
    this.addDisclaimer();

    return of(null);
  }

  handleError(error: any): Observable<never> {
    console.error(error);
    return throwError(error);
  }

  initializeModal(): void {
    this.modalRef = this.modal.open(SpinnerDialogComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static'
    });
  }

  initializeInoformationDialog(): void {
    this.modal.open(InformationDialogComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
  }

  ngOnDestroy(): void {
    if (this.tsliSubscription !== undefined) {
      this.tsliSubscription.unsubscribe();
    }

    if (this.byItemSubscription !== undefined) {
      this.byItemSubscription.unsubscribe();
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.configSubscriptions.forEach(sub => sub.unsubscribe());
  }

  filterAndOrder(chapters: Chapter[], theme: string): Chapter[] {
    return chapters.filter(chap => chap.theme == theme && chap.type == 'product').sort((a: Chapter, b: Chapter) => a.index > b.index ? 1 : a.index === b.index ? 0 : -1);
  }

  orderBy(chapters: Chapter[], propName: string): Chapter[] {
    if (chapters === undefined) {
      return chapters;
    } else {
      return chapters.sort((a: Chapter, b: Chapter) => a[propName] > b[propName] ? 1 : a[propName] === b[propName] ? 0 : -1);
    }
  }

  filterBy(chapters: Chapter[], propName: string, proValue: any) {
    return chapters.filter(chapter => chapter[propName] == proValue);
  }

  groupByRef(chapters: Chapter[], type: string) {
    const typeChapters = this.filterBy(chapters, 'type', type);
    const result = [];
    const map = new Map();
    for (const chapter of typeChapters) {
      if (!map.has(chapter.tsliDocType + chapter.reference)) {
        map.set(chapter.tsliDocType + chapter.reference, true);    // set any value to Map
        result.push({
          reference: chapter.reference,
          title: chapter.title_tsli,
          theme: chapter.theme,
          docType: chapter.tsliDocType,
          revision: chapter.tsliRevision
        });
      }
    }
    return result;
  }

  closeAll() {
    this.matAccordion.forEach(e => e.closeAll());
  }

  openAll() {
    this.matAccordion.forEach(e => e.openAll());
  }

  addDisclaimer() {
    this.configSubscriptions.push(this.config.getConfigurationByName('tsli_disclaimer').subscribe(v => {
      this.tsliDisclaimer = v['configurations'];
      let testVar = document.getElementById('disclaimer');
      console.log('testvar :', testVar);
      document.getElementById('disclaimer').innerHTML = this.tsliDisclaimer[0].value;
    }));
  }

}
