<ng-container *ngIf="byItem">
  <div class="cartouche">
    <div class="row item">
      <p>DOCUMENT <b><i>ITEM</i></b></p>
    </div>
    <div class="row product-data">
      <div class="col" style="padding-left: 0px;">
        <div class="line-1">
          <span *ngIf="tsliItem.od != undefined">{{ tsliItem.od|fraction }}</span>
          <span *ngIf="tsliItem.wt != undefined" style="padding-left: 9px;">{{ tsliItem.wt }}</span>
          <span *ngIf="tsliItem.grade != undefined" style="padding-left: 9px;">{{ tsliItem.grade }}</span>
        </div>
        <div class="line-2">
          <span>{{ tsliItem.connection }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!byItem">
  <div class="cartouche">
    <div class="row" style="padding-top: 10px;">
      <div class="col-md-8">
        <span class="d-block badge" style="padding-left: 12px;text-align: left;">{{ "TITLE" }}</span>
        <span class="d-block info" style="padding-left: 12px;">{{ tsli?.title }}</span>
      </div>
      <div class="col-md-4">
        <span class="d-block badge">{{ tsli?.docType | uppercase }}</span>
        <span class="d-block text-center info">{{ tsli?.reference }}</span>
      </div>
    </div>
    <div class="row info-row ">
      <div class="col">
        <span class="d-block badge text-center">{{ "REVISION" }}</span>
        <span class="d-block text-center info" style="margin-top: 5px;">{{ tsli?.revision }}</span>
      </div>
      <div class="col">
        <span class="d-block badge">{{ "PUBLICATION" }}</span>
        <span class="d-block text-center info">{{ tsli?.publicationDate|date: 'dd MMM, yyyy' }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row info-row ">
      <div class="col">
        <span class="d-block badge">{{ "APPLICABLE FROM" }}</span>
        <span class="d-block text-center info">{{ tsli?.applicationDate|date: 'dd MMM, yyyy' }}</span>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="col">
        <span class="d-block badge">{{ "APPLICABLE BY" }}</span>
        <span class="d-block text-center info" style="padding-left: 5px;">{{ tsli?.applicableBy }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row info-row ">
      <div class="col">
        <span class="d-block badge">{{ "PREPARED BY" }}</span>
        <span class="d-block text-center info">{{ tsli?.author?.first_name }} {{ tsli?.author?.name }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row info-row ">
      <div class="col">
        <span class="d-block badge">{{ "REVIEWED BY" }}</span>
        <span class="d-block text-center info"
              style="padding-left: 5px;">{{ tsli?.reviewer?.first_name }} {{ tsli?.reviewer?.name }}</span>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="col">
        <span class="d-block badge">{{ "APPROVED BY" }}</span>
        <span class="d-block text-center info"
              style="padding-left: 5px;">{{ tsli?.approver?.first_name }} {{ tsli?.approver?.name }}</span>
      </div>
    </div>
  </div>
</ng-container>
