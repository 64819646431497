import { Component, OnInit } from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'assembly-data-header',
  templateUrl: './assembly-data-header.component.html',
  styleUrls: ['./assembly-data-header.component.scss']
})
export class AssemblyDataHeaderComponent implements OnInit {

  constructor(public basicProductDataStore: BasicProductDataStoreService) { }

  ngOnInit() {
  }

  switchSelectedTab(event) {
    this.basicProductDataStore.switchAssembyData(event.index);
  }
}
