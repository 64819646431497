import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentType} from '../../../core/enum/document-type.enum';
import {TsliMode} from '../../../core/enum/tsli-mode.enum';
import {User} from '../../../core/data/User';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TslisStoreService} from '../../../core/tslis-store.service';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {TsliRestService} from '../../../core/tsli-rest.service';
import {UserRestService} from '../../../core/user-rest.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigurationService} from '../../../core/configuration.service';
import {AuthService} from '../../../core/auth.service';
import {CommentsStoreService} from '../../../core/comments-store.service';
import {CommentsRestService} from '../../../core/comments-rest.service';
import {DatePipe} from '@angular/common';
import {Comment} from '../../../core/data/comment';
import {Tsli} from '../../../core/data/tsli';
import {forkJoin, Subscription} from 'rxjs';
import {ErrorBarComponent} from '../../error-bar/error-bar.component';
import {
  CheckApplicationDateDialogComponent
} from '../../../core/dialog/check-application-date-dialog/check-application-date-dialog.component';
import {Status} from '../../../core/enum/status.enum';
import {TsliResponse} from '../../../core/data/tsli-response';
import {Chapter} from '../../../core/data/chapter';

@Component({
  selector: 'app-modify-document',
  templateUrl: './modify-document.component.html',
  styleUrls: ['./modify-document.component.scss']
})
export class ModifyDocumentComponent implements OnInit, OnDestroy {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  isDisabledSourceFileZone: boolean;
  isDisabledDisplayFileZone: boolean;

  documentType: Map<string, string> = new Map<string, string>();
  documentTypeKeys: string[];
  documentTypeValues: string[];

  source_files: File[] = [];
  display_files: File[] = [];

  activatedTsliMode: TsliMode;

  docType: string;
  docTitle: string;
  docReference: string;
  docRevision: string;
  reviewers: User[];
  approvers: User[];
  reviewer: User;
  approver: User;

  typeDescriptionText: string;
  commitSuccessful = false;
  commitFailed = false;
  sendToReviewSuccessful = false;
  selectedReviewer;
  selectedApprover;
  docApplicableBy;
  docApplicationDate;
  tsliProcessDescription = '';
  tsliProductDescription = '';
  tsliGeneralInformationDescription = '';
  tsliLegacyDescription = '';
  docTypeForm = '';
  type;

  docTypeInor = '';

  chapterOneTitle = '';
  chapterOneBody = '';
  chapterOneNumber = '';
  chapterTwoTitle = '';
  chapterTwoBody = '';
  chapterTwoNumber = '';
  subscriptions = [];


  progress: { percentage: number } = {percentage: 0};
  inputComment: Comment;


  docSource_file_s3_url: string;
  reference: string;
  title: string;
  comment: string;
  idTsli: string;
  source_file_s3_url: string;
  display_file_s3_url: string;
  labelCreateOrModifyDocument = 'Create a new document';
  labelCreateOrModifyArchivedDocument = 'Create a new archived document';
  labelCreateOrModifyOrLoadArchivedDocument = this.labelCreateOrModifyDocument;
  label = '';
  labelModify = 'create';
  documentToCreate: Tsli;
  documentToModify: Tsli;
  documentToRevise: Tsli;
  tsli: Tsli;
  errorMsg = '';
  activatedDataReloading = true;
  isArchivedDocument = false;

  subscriptionActivatedTsliMode: Subscription;

  // Send to review document
  applicationDate: Date;
  modalRef: NgbModalRef;
  tsliType: string;

  public TYPE_DESCRIPTION_TEXT;
  idUser: string;

  isHTMLDoc = false;

  isTypeDisabled = false;
  idCurrentTsliForRevision: string;

  revisionNumberIsRequired = false;


  constructor(public fb: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public currentTsliStoreService: CurrentTsliStoreService,
              public rest: TsliRestService,
              public userRest: UserRestService,
              public snackBar: MatSnackBar,
              public configService: ConfigurationService,
              public authService: AuthService,
              public commentStore: CommentsStoreService,
              public commentsRestService: CommentsRestService,
              public datePipe: DatePipe,
              public modal: NgbModal) {
  }

  ngOnDestroy(): void {
    this.subscriptionActivatedTsliMode.unsubscribe();
  }

  ngOnInit(): void {
    //Step 1: firstFormGroup
    this.firstFormGroup = this.fb.group({
      docTypeCtrl: ['', []],
      referenceNumberCtrl: ['', []],
      titleCtrl: ['', []],
      revisionNumberCtrl: ['', []],
      dropZoneSourceFileCtrl: ['', []],
      dropZoneDisplayFileCtrl: ['', []],
      commentRevisionCtrl: ['', []]
    });

    // Step 2: secondFormGroup
    this.secondFormGroup = this.fb.group({
      typeSelectCtrl: ['', []]
    });

    // Step 3: thirdFormGroup
    this.thirdFormGroup = this.fb.group({
      applicationDateCtrl: ['', Validators.required],
      approverSelectCtrl: ['', Validators.required],
      reviewerSelectCtrl: ['', Validators.required],
      applicableByCtrl: ['', Validators.required]

    });

    setTimeout(() => {

      DocumentType.forEach((label, value) => {
        this.documentType.set(label, value);
      });
      this.documentTypeKeys = Array.from(this.documentType.values());
      this.documentTypeValues = Array.from(this.documentType.keys());

      // change descriptive text based on select value
      this.secondFormGroup.controls.typeSelectCtrl.valueChanges.subscribe((value: string) => {
        this.typeDescriptionText = this.TYPE_DESCRIPTION_TEXT[value];
      });

      // Edit document (with uploaded files)
      this.subscriptionActivatedTsliMode = this.currentTsliStoreService.activatedMode$
        .subscribe(value => {
          this.activatedTsliMode = value;
          if (value == TsliMode.simpleEditionMode) {
            this.commentsRestService.getAllComments(this.documentToModify.idTsli).subscribe((value: Comment[]) => {
              value.filter(comment => {
                if (comment.tsli.idTsli === this.documentToModify.idTsli) {
                  this.firstFormGroup.controls.commentRevisionCtrl.setValue(comment.comment);
                }
              });
            });

            this.firstFormGroup.controls.docTypeCtrl.setValue(this.documentToModify.docType);
            this.firstFormGroup.controls.referenceNumberCtrl.setValue(this.documentToModify.reference);
            this.firstFormGroup.controls.titleCtrl.setValue(this.documentToModify.title);
            this.firstFormGroup.controls.revisionNumberCtrl.setValue(this.documentToModify.revision);


            this.userRest.getUsers().subscribe((usersResponse: User[]) => {
              this.reviewers = usersResponse.filter(user => user.isReviewer);
            });
            this.userRest.getUsers().subscribe((usersResponse: User[]) => {
              this.approvers = usersResponse.filter(user => user.isApprover);
            });

            this.thirdFormGroup.controls.applicableByCtrl.setValue(this.documentToModify.applicableBy);
            this.thirdFormGroup.controls.applicationDateCtrl.setValue(this.documentToModify.applicationDate);
            this.thirdFormGroup.controls.reviewerSelectCtrl.setValue(this.documentToModify.reviewer.id);
            this.thirdFormGroup.controls.approverSelectCtrl.setValue(this.documentToModify.approver.id);

            this.idTsli = this.documentToModify.idTsli;

            this.firstFormGroup.controls.titleCtrl.disable();
            this.firstFormGroup.controls.docTypeCtrl.disable();
            this.firstFormGroup.controls.referenceNumberCtrl.disable();
            this.firstFormGroup.controls.revisionNumberCtrl.disable();
            this.firstFormGroup.controls.commentRevisionCtrl.disable();
            this.isTypeDisabled = true;


            // enable upload source file
            this.isDisabledSourceFileZone = false;

            const generatedSourceFile = new File([], this.documentToModify.source_file_s3_url);
            if (generatedSourceFile.name !== 'null' && generatedSourceFile.name !== '') {
              this.source_files.push(generatedSourceFile);
            }

            const generatedDisplayFile = new File([], this.documentToModify.display_file_s3_url);
            if (generatedDisplayFile.name !== 'null' && generatedDisplayFile.name !== '') {
              this.display_files.push(generatedDisplayFile);
            }

            this.isHTMLDoc = false; // this is not an HTML document
          }
        });
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getDynamicTSLICreation();
    });
  }

  private getDynamicTSLICreation() {
    // using forkJoin to get all values at a time to process them to
    this.subscriptions.push(
      forkJoin(
        [this.configService.getConfigurationByName('creation_process_description'),
          this.configService.getConfigurationByName('creation_general_information_description'),
          this.configService.getConfigurationByName('creation_product_description'),
          this.configService.getConfigurationByName('creation_form_description'),
          this.configService.getConfigurationByName('creation_inor_description'),
          this.configService.getConfigurationByName('creation_legacy_description'),
          this.configService.getConfigurationByName('creation_ugia_description'),
          this.configService.getConfigurationByName('creation_ugfl_description'),
          this.configService.getConfigurationByName('creation_ugira_description'),
          this.configService.getConfigurationByName("chapter_one_title"),
          this.configService.getConfigurationByName("chapter_one_body"),
          this.configService.getConfigurationByName("chapter_two_title"),
          this.configService.getConfigurationByName("chapter_two_body"),
          this.configService.getConfigurationByName("chapter_one_number"),
          this.configService.getConfigurationByName("chapter_two_number")]

      ).subscribe(([process, gi, product, form,
                     inor, legacy, ugia, ugfl, ugira
                     ,chapterOneTitle,chapterOneBody,chapterTwoTitle
                     ,chapterTwoBody,chapterOneNumber,chapterTwoNumber]) => {
        this.TYPE_DESCRIPTION_TEXT = {
          process: process['configurations'][0].value,
          general_information: gi['configurations'][0].value,
          product: product['configurations'][0].value,
          form: form['configurations'][0].value,
          inor: inor['configurations'][0].value,
          legacy: legacy['configurations'][0].value,
          ugia: ugia['configurations'][0].value,
          ugfl: ugfl['configurations'][0].value,
          ugira: ugira['configurations'][0].value
        };
        this.chapterOneTitle = chapterOneTitle['configurations'][0].value;
        this.chapterOneBody =  chapterOneBody['configurations'][0].value;
        this.chapterTwoTitle = chapterTwoTitle['configurations'][0].value;
        this.chapterTwoBody = chapterTwoBody['configurations'][0].value;
        this.chapterOneNumber = chapterOneNumber['configurations'][0].value;
        this.chapterTwoNumber = chapterTwoNumber['configurations'][0].value;

      }));
  }

  onSelect(event) {
    console.log(event);
    this.source_files.splice(this.source_files.indexOf(event), this.source_files.length);
    this.source_files.push(...event.addedFiles);

    this.firstFormGroup.controls.commentRevisionCtrl.enable();

    if (this.source_files.length > 0) {
      this.isDisabledDisplayFileZone = false;
    }

    this.isHTMLDoc = false;

    this.firstFormGroup.controls.commentRevisionCtrl.addValidators(Validators.required);
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();

  }

  onRemove(event) {
    this.source_files.splice(this.source_files.indexOf(event), 1);
    this.firstFormGroup.controls.dropZoneSourceFileCtrl.setValue('');
    this.display_files.splice(this.display_files.indexOf(event), 1);
    this.isDisabledDisplayFileZone = true;
    this.isHTMLDoc = true;

    this.firstFormGroup.controls.commentRevisionCtrl.setValue('');
    this.firstFormGroup.controls.commentRevisionCtrl.disable();
    this.firstFormGroup.controls.commentRevisionCtrl.clearValidators();
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();
  }

  onSelectDisplayFile(event) {
    console.log(event);
    this.display_files.splice(this.display_files.indexOf(event), this.display_files.length);
    this.display_files.push(...event.addedFiles);
  }

  onRemoveDisplayFile(event) {
    console.log(event);
    this.display_files.splice(this.display_files.indexOf(event), 1);

    this.firstFormGroup.controls.commentRevisionCtrl.setValue('');
    this.firstFormGroup.controls.commentRevisionCtrl.enable();
    this.firstFormGroup.controls.commentRevisionCtrl.addValidators(Validators.required);
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();
  }

  goForward() {
    this.secondFormGroup.controls.typeSelectCtrl.setValue(this.documentToModify.type.toLowerCase());
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  displayError(msg: string, statusCode: string, statusText: string) {
    this.snackBar.openFromComponent(ErrorBarComponent, {
      duration: 5000,
      data: {
        msg,
        statusCode,
        statusText
      }
    });
  }

  addRevisionComment(tsli: Tsli) {
    this.inputComment = new Comment();
    this.inputComment.commentDate = new Date();
    this.inputComment.tsli = tsli;
    this.inputComment.is_revision_comment = '1';
    this.authService.user.subscribe(user => {
      this.inputComment.commentator = user;
    });
    this.inputComment.comment = JSON.parse(JSON.stringify(this.firstFormGroup.controls.commentRevisionCtrl.value));
    if (this.inputComment.comment != '') {
      this.commentStore.addComment(this.inputComment);
    }
  }

  checkApplicationDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.applicationDate = new Date(this.thirdFormGroup.controls.applicationDateCtrl.value);
    if (this.applicationDate.getTime() < tomorrow.getTime()) {
      const modalRef: NgbModalRef = this.modal.open(CheckApplicationDateDialogComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalRef.componentInstance.applicationDate = this.datePipe.transform(this.applicationDate, 'dd/MM/yyyy');
      // modalRef.componentInstance.validationMode = this.activatedTsliMode == 'REVIEWING_MODE' || this.activatedTsliMode == 'APPROVING_MODE'
      return modalRef.result;
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

  checkDate() {
    const checkResult = this.checkApplicationDate();
    if (checkResult === undefined) {
      return this.commitDocument(true);
    }

    checkResult.then((userResponse) => {
      if (userResponse === 'continue') {
        this.commitDocument(true);
      }
    }).catch(err => console.error(err));
  }

  sendToReview() {
    if (this.thirdFormGroup.valid) {
      this.checkDate();
    }
  }

  saveDocument() {
    if (this.thirdFormGroup.valid) {
      this.commitDocument(false);
    }

  }

  /*
  * Commit document when click on next button in third form group or in second
  * for group for load archived document
  * */
  commitDocument(isSentToReview: boolean): void {
    const document: Tsli = new Tsli();
    document.docType = this.firstFormGroup.controls.docTypeCtrl.value.toLowerCase();
    document.title = this.firstFormGroup.controls.titleCtrl.value;
    document.reference = this.firstFormGroup.controls.referenceNumberCtrl.value;
    document.revision = this.firstFormGroup.controls.revisionNumberCtrl.value;
    document.type = this.secondFormGroup.controls.typeSelectCtrl.value.toLowerCase();
    document.applicationDate = this.thirdFormGroup.controls.applicationDateCtrl.value;
    document.reviewer = new User();
    document.reviewer.id = this.thirdFormGroup.controls.reviewerSelectCtrl.value;
    document.approver = new User();
    document.approver.id = this.thirdFormGroup.controls.approverSelectCtrl.value;
    document.applicableBy = this.thirdFormGroup.controls.applicableByCtrl.value;
    document.isLegacy = document.type.toUpperCase() === 'LEGACY';
    document.isArchivedDocument = false;
    document.status = this.documentToModify.status;
    this.authService.user.subscribe(user => {
      document.author = user;
    });

    if (this.activatedTsliMode === TsliMode.simpleEditionMode) {
      console.log('edit document', document);
      this.editDocument(document, isSentToReview); // in cas of Resume
    } else {
      console.log('create document', document);
      this.createNewDocument(document, isSentToReview); // in case of Resume => send to review
    }
  }

  createNewDocument(document: Tsli, isSentToReview: boolean) {
    this.tslisStore.createTsli(document).subscribe(
      (tsliResponse: TsliResponse) => {

        if (isSentToReview) {
          this.sendToReviewSuccessful = true;
          this.commitSuccessful = false;
        } else {
          this.sendToReviewSuccessful = false;
          this.commitSuccessful = true;
        }
        document.idTsli = tsliResponse.tsli.idTsli;

        // upload source and display file and add no chapter to add
        if (this.isHTMLDocument(document) && !this.isHTMLDoc) {
          // this.uploadSourceFile(document);
          // this.uploadDisplayFile(document);
          this.addRevisionComment(document);

          if (isSentToReview) {
            this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
              console.log(resp);
            });
          }
        } else {
          const chapters: Chapter[] = [];
          const chapterOne: Chapter = new Chapter();
          chapterOne.depth = 0;
          chapterOne.index = 0;
          chapterOne.title = this.chapterOneTitle;
          chapterOne.body = this.chapterOneBody;
          chapterOne.number = this.chapterOneNumber;
          chapterOne.titleStyle = null;
          chapters.push(chapterOne);
          const chapterTwo: Chapter = new Chapter();
          chapterTwo.depth = 0;
          chapterTwo.index = 1;
          chapterTwo.title = this.chapterTwoTitle;
          chapterTwo.body = this.chapterTwoBody;
          chapterTwo.number = this.chapterTwoNumber;
          chapterTwo.titleStyle = null;
          chapters.push(chapterTwo);
          this.currentTsliStoreService.addChaptersWhenTsliCreated(document, chapters);
        }

      },
      (error: any) => {
        this.displayError('An HTTP error occurred! ', error.status, error.statusText);
        this.commitFailed = true;
      });
  }

  editDocument(document: Tsli, isSentToReview: boolean) {
    document.idTsli = this.documentToModify.idTsli;

    console.log('isHTML:', this.isHTMLDoc);
    //Convert to HTML document: create chapters
    if (this.isHTMLDoc) {
      console.log('Convert to HTML document and add chapters');
      const chapters: Chapter[] = [];
      const chapterOne: Chapter = new Chapter();
      chapterOne.depth = 0;
      chapterOne.index = 0;
      chapterOne.title = this.chapterOneTitle;
      chapterOne.body = this.chapterOneBody;
      chapterOne.number = this.chapterOneNumber;
      chapterOne.titleStyle = null;
      chapters.push(chapterOne);
      const chapterTwo: Chapter = new Chapter();
      chapterTwo.depth = 0;
      chapterTwo.index = 1;
      chapterTwo.title = this.chapterTwoTitle;
      chapterTwo.body = this.chapterTwoBody;
      chapterTwo.number = this.chapterTwoNumber;
      chapterTwo.titleStyle = null;
      chapters.push(chapterTwo);
      this.currentTsliStoreService.addChaptersWhenTsliCreated(document, chapters);
    }

    this.rest.updateTsli(document, this.isHTMLDoc).subscribe(response => {
      console.log(response);
      if (isSentToReview) {
        this.sendToReviewSuccessful = true;
        this.commitSuccessful = false;
        console.log('send To Review Successful');
      } else {
        this.sendToReviewSuccessful = false;
        this.commitSuccessful = true;
        console.log('commit Successful');
      }

      if (this.commitSuccessful || this.sendToReviewSuccessful) {
        // Update or delete source file
        if (this.source_files.length > 0 && this.source_files[0].name !== this.documentToModify.source_file_s3_url) {
          document.source_file_s3_url = this.documentToModify.source_file_s3_url;
          this.uploadSourceFile(document);
        } else if (this.source_files.length === 0) {
          // Delete source file
          this.deleteSourceOrDisplayFile(document, true, false);
          console.log('delete source file successful => convert to HTML document');
        }

        // Update or delete display file
        if (this.display_files.length > 0 && this.display_files[0].name != this.documentToModify.display_file_s3_url) {
          document.display_file_s3_url = this.documentToModify.display_file_s3_url;
          this.uploadDisplayFile(document);
          console.log('update display file successful');
        } else if (this.display_files.length == 0) {
          // Delete display file
          this.deleteSourceOrDisplayFile(document, false, true);
          console.log('delete display file successful');
        }

        if (isSentToReview) {
          this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
            console.log(resp);
          });
        }

        // add comment
        this.addRevisionComment(document);
      }
    });
  }

  deleteSourceOrDisplayFile(document: Tsli, isSourceFile: boolean, isDisplayFile: boolean) {

    if (isSourceFile) {
      this.rest.update_source_file_url(document.idTsli, null).subscribe(response => {
        console.log(response);
        this.tslisStore.getAllDocuments().subscribe();
      });
    }
    if (isDisplayFile) {
      this.rest.update_display_file_url(document.idTsli, null).subscribe(response => {
        console.log(response);
        this.tslisStore.getAllDocuments().subscribe();
      });
    }

  }

  uploadSourceFile(document: Tsli) {
    if (this.source_files.length > 0) {
      this.rest.uploadFileWithPreSignedURL(this.source_files[0]).subscribe(source_files_resp => {
        console.log(source_files_resp);
        document.source_file_s3_url = source_files_resp.s3_filename;
        this.rest.update_source_file_url(document.idTsli, document.source_file_s3_url).subscribe(response => {
          console.log(response);
          this.tslisStore.getAllDocuments().subscribe();
        });
      });
    }
  }

  uploadDisplayFile(document: Tsli) {
    if (this.display_files.length > 0) {
      this.rest.uploadFileWithPreSignedURL(this.display_files[0]).subscribe(display_files_resp => {
        console.log(display_files_resp);
        document.display_file_s3_url = display_files_resp.s3_filename;
        this.rest.update_display_file_url(document.idTsli, document.display_file_s3_url).subscribe(response => {
          console.log(response);
          this.tslisStore.getAllDocuments().subscribe();
        });
      });
    }
  }
  isHTMLDocument(document: Tsli) {
    return this.tslisStore.isHTMLDocument(document);
  }

  getDocumentType(docType: string): boolean {
    return this.tslisStore.getDocumentType(docType);
  }


  // if (this.isArchivedDocument) {
  //   this.firstFormGroup.controls.dropZoneSourceFileCtrl.addValidators(Validators.required);
  //   this.firstFormGroup.controls.dropZoneSourceFileCtrl.updateValueAndValidity();
  //
  //   this.isDisabledSourceFileZone = false;
  //
  //   this.firstFormGroup.controls.commentRevisionCtrl.setValue('');
  //   this.firstFormGroup.controls.commentRevisionCtrl.disable();
  //   this.firstFormGroup.controls.commentRevisionCtrl.clearValidators();
  // }
  // if (this.isArchivedChecked) {
  //   this.firstFormGroup.controls.dropZoneSourceFileCtrl.addValidators(Validators.required);
  //   // this.firstFormGroup.controls.dropZoneSourceFileCtrl.clearValidators();
  //   this.firstFormGroup.controls.dropZoneSourceFileCtrl.updateValueAndValidity();
  // }

  //
  // if ((this.activatedTsliMode === TsliMode.creatingMode || this.activatedTsliMode === TsliMode.simpleEditionMode)) {
  //   if (this.isINORorFORMDocument(this.docType)) {
  //     this.firstFormGroup.controls.commentRevisionCtrl.setValue('');
  //     this.firstFormGroup.controls.commentRevisionCtrl.disable();
  //     this.firstFormGroup.controls.commentRevisionCtrl.clearValidators();
  //
  //     this.source_file_s3_url = null;
  //     this.display_file_s3_url = null;
  //     this.isHTMLDoc = true;
  //   } else {
  //
  //   }
  // }
}
