import {Component, EventEmitter, OnInit} from '@angular/core';
import {BasicProductDataStoreService} from '../../../core/basic-product-data-store.service';
import {BasicProductsDataConstants} from '../BasicProductsDataConstants';

@Component({
  selector: 'product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.scss']
})
export class ProductPickerComponent implements OnInit {
  FILTERS: string[] = [];
  readonly FILTERS_WIDTHS: string[] = BasicProductsDataConstants.FILTERS_WIDTHS;

  isEditionMode = false;
  selection: any = {};
  filtersOrder: {name:string, order:string} [] = [];
  activateSorting: boolean= true;
  // colors = ['#1f35de','#ffffff', '#f50707']


  constructor(public basicProductDataStore: BasicProductDataStoreService) {
    this.FILTERS = Array.of(...BasicProductsDataConstants.FILTERS);
    this.FILTERS.push('');
    this.FILTERS.push('');
    this.isEditionMode = this.basicProductDataStore.isEditionMode;
    this.basicProductDataStore.activatedDataReloading$.subscribe(value => this.activateSorting = value);
    this.basicProductDataStore.currentSelection$.subscribe((selection: any) => this.selection = selection);
    this.basicProductDataStore.order$.subscribe((order) => this.filtersOrder = order);
  }

  ngOnInit(): void {
    if(this.isEditionMode) {
      this.basicProductDataStore.loadFilter(BasicProductsDataConstants.CONNECTION, []);
    }
    console.log(this.FILTERS)

  }

  loadNextFilterOptions(filterName: string,
                        value: string): void {
    this.basicProductDataStore.setSelection(this.selection);

    if (filterName === BasicProductsDataConstants.CUSTOMER ) {
      return;
    }else if(value === undefined){
      let filterIndex: number = BasicProductsDataConstants.FILTERS.indexOf(filterName);
      ++filterIndex;
      for (let i = filterIndex; i < BasicProductsDataConstants.FILTERS.length; i++) {
        const key = BasicProductsDataConstants.FILTERS[i];
        this.selection[key]= undefined;
      }
      this.basicProductDataStore.clearFilters(filterIndex);
      return;
    }

    let filterIndex: number = BasicProductsDataConstants.FILTERS.indexOf(filterName);
    ++filterIndex;

    // get parents
    const keys = Object.keys(this.selection);
    const parents = [];
    keys.forEach(key => {
      if (this.selection[key] !== undefined) {
        parents.push({ name: key, value: this.selection[key]});
      }
    });

    this.basicProductDataStore.loadFilter(BasicProductsDataConstants.FILTERS[filterIndex], parents);
  }

  sort(index) {
    this.basicProductDataStore.changeSortOrder(index);
  }
}
