import {Component, EventEmitter} from '@angular/core';

import {BasicProductDataStoreService} from '../../../core/basic-product-data-store.service';
import {BasicProductsDataConstants} from '../BasicProductsDataConstants';
import {Product} from '../../../core/data/product';
import {TsliRestService} from 'src/app/core/tsli-rest.service';
import {Router} from '@angular/router';
import {DetailedTsliStore} from 'src/app/core/detailed-tsli-store.service';
import {ConfigurationService} from 'src/app/core/configuration.service';

@Component({
  selector: 'bpd-header',
  templateUrl: './bpd-header.component.html',
  styleUrls: ['./bpd-header.component.scss']
})
export class BpdHeaderComponent {
  readonly TITLE_BY_ITEM: string = 'Please create an item below and add to the list' +
    ' to display associated Basic Product Data.';
  readonly TITLE_BY_CONNECTION: string = 'Please use arrows below to sort the table';

  TITLE_DISCLAIMER: string = '';

  isEditionMode = false;
  isAddEnabled = false;
  isClearEnabled = false;
  isSaveEnabled = false;
  activatedPagination;
  checked: boolean = true;
  isOCTG: boolean = true;
  url_download_bpd: string;
  configSubscriptions = [];
  bpdLegacyDisclaimer = [];

  constructor(public basicProductDataStore: BasicProductDataStoreService, public tslirestservice: TsliRestService, public router: Router, public detailedTsliStore: DetailedTsliStore, public config: ConfigurationService,) {
    this.isEditionMode = this.basicProductDataStore.isEditionMode;
    this.basicProductDataStore.imperialOrSI$.subscribe(value => this.checked = value);
    this.basicProductDataStore.octgOrNonOctg$.subscribe(value => this.isOCTG = value);
    this.basicProductDataStore.activatedDataReloading$.subscribe(value => this.activatedPagination = value);

    this.basicProductDataStore.currentSelection$.subscribe((selection: any) => {
      let isComplete = true;
      for (const filter of BasicProductsDataConstants.FILTERS) {
        isComplete = isComplete && selection[filter] !== undefined;
      }
      this.isAddEnabled = isComplete;
    });

    this.basicProductDataStore.products$.subscribe((products: Product[]) => {
      this.isClearEnabled = products.length > 0;
    });

    if (!this.isEditionMode) {
      this.basicProductDataStore.fetchOverallCount();
      this.basicProductDataStore.fetchProductsPage();
    }
    // else {
    //   this.clearList();
    // }
    this.basicProductDataStore.fetchThreadCompoundHeaders();
  }

  ngOnInit(): void {
    this.addDisclaimer();
  }

  clearList(): void {
    this.basicProductDataStore.clearProducts();
    this.detailedTsliStore.clearProducts();
  }


  addToList(): void {
    this.basicProductDataStore.addProductFromSelection();
    this.basicProductDataStore.clearSelection();
  }

  saveList(): void {

  }

  downloadBpdByProduct() {
    var octg = this.isOCTG ? 'OCTG' : 'NON_OCTG';
    var imperialOrsI = this.basicProductDataStore.getImperialOrSI();
    //download bpd by item
    if (this.isEditionMode) {
      console.log('downloadBpdByProduct -> isEditionMode');
      this.basicProductDataStore.generateDataAssemblyByItemToCsv(imperialOrsI, octg);
      this.basicProductDataStore.generateDataThreadCompoundByItemToCsv(imperialOrsI, octg);
      this.basicProductDataStore.generateDataTdsByItemToCsv(octg);
    } else {
      console.log('downloadBpdByProduct -> all bpd');
      //download all bpd
      // this.basicProductDataStore.connection permit to get the connection used
      // and this.basicProductDataStore.getImperialOrSI() permit to get the unit used
      // (Imperial if true and SI if false)
      this.tslirestservice.downloadBpdByProduct(this.basicProductDataStore.connection, imperialOrsI, octg).subscribe(
        response => {
          console.log(response['url_download_bpd']);
          window.open(response['url_download_bpd'], '_blank');
        }
      );
    }
  }

  updateProducts(event: any): void {
    this.basicProductDataStore.setPageIndex(event.pageIndex);
    this.basicProductDataStore.setPageSize(event.pageSize);
    this.basicProductDataStore.fetchProductsPage();
    // this.basicProductDataStore.fetchSurfaceTreatmentPage();
  }

  switchImperialOrSI() {
    this.basicProductDataStore.switchImperialOrSI();
  }

  switchOctgNonOctg() {
    this.basicProductDataStore.switchOctgNonOctg();
  }

  ngOnDestroy(): void {
    if (this.router.routerState.snapshot.url != '/tsli/details') {
      this.clearList();
    }
    this.configSubscriptions.forEach(sub => sub.unsubscribe());
  }

  addDisclaimer() {
    this.configSubscriptions.push(this.config.getConfigurationByName('bpd_legacy_disclaimer').subscribe(v => {
      this.bpdLegacyDisclaimer = v['configurations'];
      this.TITLE_DISCLAIMER = this.bpdLegacyDisclaimer[0].value;
    }));
  }
}
