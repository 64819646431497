<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h1><span class="mat-h1" style="cursor: pointer" (click)="navigateToBasicProductData()">Basic Product Data</span>
        | <span class="mat-h1" style="font-weight: bold">Detailed document</span></h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button mat-stroked-button *ngIf="!currentUser.isLicenseLight" [ngClass]="{'selected': selectedOption == options[0]}" class="mr-3" (click)="switchOption(0)">{{ 'Search by product' }}</button>
      <button mat-stroked-button [ngClass]="{'selected': selectedOption == options[1]}" class="mr-3" (click)="switchOption(1)">{{ 'Search by reference' }}</button>
      <button mat-stroked-button *ngIf="!currentUser.isLicenseLight" [ngClass]="{'selected': selectedOption == options[2]}" class="mr-3" (click)="switchOption(2)">{{ 'Generate TSLI by item' }}</button>
      <button mat-stroked-button *ngIf="!currentUser.isLicenseLight" [ngClass]="{'selected': selectedOption == options[3]}" (click)="switchOption(3)">{{ 'Search by process' }}</button>
    </div>
    <ng-container [ngSwitch]="selectedOption">
      <ng-container *ngSwitchCase="options[0]">
        <div class="col text-right" *ngIf="connection != undefined && connection != ''" >
          <button (click)="downloadAll('connection', connection)" class="float-right" mat-stroked-button style="margin-right: 10px; margin-top: 10px;">{{"Download All"}}</button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="options[1]">
        <div class="col text-right">
          <button (click)="downloadAll('reference')"  class="float-right" mat-stroked-button style="margin-right: 10px; margin-top: 10px;">{{"Download All"}}</button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="options[3]">
        <div class="col text-right" *ngIf="theme != undefined && theme != ''">
          <button (click)="downloadAll('theme', theme)" class="float-right" mat-stroked-button style="margin-right: 10px; margin-top: 10px;">{{"Download All"}}</button>
        </div>
      </ng-container>
    </ng-container>

  </div>
  <div [ngSwitch]="selectedOption" class="row">
    <div *ngSwitchCase="options[0]" class="col">
      <detailed-tsli-by-connection *ngIf="connection == undefined || connection == ''"></detailed-tsli-by-connection>
      <detailed-tsli-by-connection-list *ngIf="connection != undefined && connection != ''"></detailed-tsli-by-connection-list>
    </div>
    <div *ngSwitchCase="options[1]" class="col">
      <detailed-tsli-by-reference></detailed-tsli-by-reference>
    </div>
    <div *ngSwitchCase="options[2]" class="col">
      <detailed-tsli-by-item></detailed-tsli-by-item>
    </div>
    <div *ngSwitchCase="options[3]" class="col">
      <detailed-tsli-by-process *ngIf="theme == undefined || theme == ''"></detailed-tsli-by-process>
      <detailed-tsli-by-process-list *ngIf="theme != undefined && theme != ''"></detailed-tsli-by-process-list>
    </div>
  </div>
</div>
