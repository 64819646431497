import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ApplicabilityItem} from 'src/app/core/data/applicability-item';
import {CurrentTsliStoreService} from '../../../../../core/current-tsli-store.service';
import {ApplicabilityService} from 'src/app/core/applicability.service';
import { TsliMode } from 'src/app/core/enum/tsli-mode.enum';

@Component({
  selector: 'applicability-item',
  templateUrl: './applicability-item.component.html',
  styleUrls: ['./applicability-item.component.scss']
})

export class ApplicabilityItemComponent implements OnInit {
  @Input() item: ApplicabilityItem;
  @Input() cols: number;
  @Input() parents: { name: string, value: string | number }[];
  @Input() chapterIndex: number;
  @Input() itemsOrder: any[];

  @Output() sendToParent = new EventEmitter<string>();
  @Output() callEnableValidateButton = new EventEmitter();
  @Output() callParentNotAllChildrenAreChecked = new EventEmitter();

  @ViewChildren('applicabilityItems') applicabilityItems: QueryList<ApplicabilityItemComponent>;

  isShowApplicabilityMode: boolean = false;

  _sendToParent(colsData:string) {
    this.sendToParent.emit(colsData);
  }

  eventFromChild(colsData:string) {
    // let checkChildren = true;
    console.log("hello ::",this.item )
    let checkedLength = this.item.children.filter(child=>child.checked).length;
    let indeterminatedLength = this.item.children.filter(child=>child.indeterminate || child.checked).length;
    this.item.checked =  checkedLength == this.item.children.length;
    this.item.allChildrenChecked = this.item.checked;
    this.item.indeterminate = !this.item.checked && indeterminatedLength > 0;

    if (this.cols < 7){
      this.sendToParent.emit(""+this.cols);
    }else return;

    // this.item.children.forEach(child => {
    //   if(child.checked != true){
    //     this.item.indeterminate = true;
    //     this.item.checked = false;
    //     checkChildren = false;
    //     return;
    //   }

    // });

    // if(this.cols > parseInt(colsData)){
    //   if(!checkChildren){
    //     this.item.indeterminate = true;
    //     this.item.checked = false;
    //     this.item.allChildrenChecked = false;
    //   }else{
    //     this.item.indeterminate = false;
    //     this.item.checked = true ;
    //     this.item.allChildrenChecked = true;
    //   }
    // }

}



  checkFutureChildren = false;
  uncheckFutureChildren = false;

  public hasChildren = false;

  constructor(public applicabilityRest: ApplicabilityService,
              public currentTsli: CurrentTsliStoreService) {
  }

  ngOnInit() {
    this.parents = JSON.parse(JSON.stringify(this.parents));
    this.parents.push({name: this.item.type, value: this.item.value});
    this.checkFutureChildren = this.item.checkFutureChildren;
    this.uncheckFutureChildren = this.item.uncheckFutureChildren;
    // if (this.cols < 7 && this.item.type.toUpperCase() == 'DESIGN OPTIONS'){
    // }else if (this.cols < 7 && this.parents[0].name == 'DESIGN OPTIONS'&& this.item.type.toUpperCase() == "WT"){
    // }
    this.currentTsli.activatedMode$.subscribe(value=>{
      this.isShowApplicabilityMode = value === TsliMode.reviewingMode;
    })
  }

  /**
   * Called by clicking on component,
   * or from ApplicabilityComponent for closing all items (forceClose)
   *
   * @param forceClose
   */
  collapse(forceClose: boolean = false): void {
    if (forceClose) {

        if (this.applicabilityItems !== undefined && this.item.children.length > 0) {
          this.item.isCollapsed = false;
          this.applicabilityItems.forEach(e => e.collapse(true));
        }

    } else {

      // if children are not loaded yet, load these from API
    if (!this.hasChildren) {
        //this.item.isCollapsed = true;
        this.checkFutureChildren = this.item.checkFutureChildren;
        this.uncheckFutureChildren = this.item.uncheckFutureChildren;

        if (this.item.type.toLowerCase() !== this.itemsOrder[this.itemsOrder.length - 1].name.toLowerCase()) {
          // lookup type of children nodes
          let childType;
          for (let i = 0; i < this.itemsOrder.length - 1; i++) {
            if (this.itemsOrder[i].name.toLowerCase() === this.item.type.toLowerCase()) {
              childType = this.itemsOrder[i + 1].name.toLowerCase();
              break;
            }
          }

          // get children nodes content from API
          this.applicabilityRest.getApplicability(
            childType,
            this.parents,
            this.currentTsli.getCurrentTsliId(),
            this.chapterIndex,
            this.item.allChildrenChecked,
            false).subscribe((result: any) => {
              this.item.children = result.items;

              this.hasChildren = true;
              if(result.theme != undefined && result.theme != "")
                this.currentTsli.setTheme(result.theme);

              // checkbox was checked without children loaded : erase all children data
              if (this.checkFutureChildren) {
                this.checkAll(this.item);
                this.item.children.forEach((child: ApplicabilityItem) => {child.checkFutureChildren = true});
              }

              // checkbox was unchecked without children loaded : erase all children data
              if (this.uncheckFutureChildren) {
                this.uncheckAll(this.item);
                this.item.children.forEach((child: ApplicabilityItem) => {child.uncheckFutureChildren = true});
              }
            this.item.isCollapsed = true;
            });
          }
          return;
      } else if (this.item.children.length > 0) {
        this.item.isCollapsed = !this.item.isCollapsed;
      }
    }
  }

  checkChildren(event): void {
    if (this.item.checked) {
      this.checkAll(this.item);
      this.item.checked = true;
      this.item.indeterminate = false;
      this.item.allChildrenChecked = true;
    } else {
      this.uncheckAll(this.item);
      this.item.checked = false;
      this.item.indeterminate = false ;
      this.item.allChildrenChecked = false;
      this.callParentNotAllChildrenAreChecked.emit();
    }
    this.callEnableValidateButton.emit();
    if(this.cols < 7) {
      this.sendToParent.emit(""+this.cols);
    }

  }

  checkAll(item: ApplicabilityItem): void {
    if (item.children === undefined || item.children.length === 0) {
      this.checkFutureChildren = true;
      this.uncheckFutureChildren = false;
      item.checkFutureChildren = true;
      item.uncheckFutureChildren = false;
      return;
    } else {
      item.children.forEach(child => {
        child.checked = true;
        child.indeterminate = false;
        child.allChildrenChecked = true;
        this.checkAll(child); // ICI
      });
      item.checked = true;
      item.allChildrenChecked = true;
      return;
    }
  }

  uncheckAll(item: ApplicabilityItem): void {


    if (item.children === undefined || item.children.length === 0) {
      this.checkFutureChildren = false;
      this.uncheckFutureChildren = true;
      item.checkFutureChildren = false;
      item.uncheckFutureChildren = true;
      return;
    } else {
      item.children.forEach(child => {
        child.checked = false;
        child.indeterminate = false;
        child.allChildrenChecked = false;
        this.uncheckAll(child); // ET LA
      });
      item.checked = false;
      item.indeterminate = false;
      item.allChildrenChecked = false;
      return;
    }
  }

  asParentCallEnableValidateButton(): void {
    this.callEnableValidateButton.emit();
  }

  asParentSetAllChildrenCheckedToFalse(): void {
    this.item.allChildrenChecked = false;
  }
}
