import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, empty, of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import {TsliResponse} from './data/tsli-response';
import {Tsli} from './data/tsli';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {Product} from './data/product';
import {ServicesNamesConstants} from './services-names.constants';
import {Router} from '@angular/router';
import {ActivityResponse} from './data/activity-response';
import {ActivityEvent} from './enum/activity-event.enum';
import {Status} from './enum/status.enum';

@Injectable({
  providedIn: 'root'
})
export class TsliRestService {
  static readonly DEBUG: boolean = true;

  readonly API_BASE_URL: string = environment.API_BASE_URL;
  // readonly API_BASE_LOCAL_URL: string = environment.API_BASE_LOCAL_URL;

  readonly TSLI: string = '/tsli';
  readonly TSLI_AS_PDF: string = '/tsli-as-pdf';
  readonly DOWNLOAD_FILE_CSV: string = '/download_file_csv';
  readonly DOWNLOAD_DISPLAY_AND_SOURCE_FILE: string = '/download_source_and_display_file';
  readonly APPLICABILITY: string = '/applicability';
  readonly UPLOAD_SOURCE_AND_DISPLAY_FILE: string = '/upload_source_and_display_file';

  // public readonly UPLOAD_SOURCE_AND_DISPLAY_FILE = '/upload_file';

  constructor(public http: HttpClient,
              public authService: AuthService,
              public router: Router) {
  }

  modifyAuthor(idTsli: number, idNewAuthor: number): Observable<string> {
    return this.http.get<string>(this.API_BASE_URL + this.TSLI + '/modify_date_or_author?author=' + idNewAuthor + '&idtsli=' + idTsli
      , this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE)).pipe(
      map((Response: any) => {
        console.log('-- createTsli completed');
        console.log(Response);
        return Response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));

  }

  downloadBpdByProduct(product: string, imperialOrsI: boolean, octg: string): any {
    var unit = imperialOrsI ? 'IMPERIAL' : 'SI';
    return this.http.get<any>(this.API_BASE_URL +
      this.DOWNLOAD_FILE_CSV +
      '?product=' + product +
      '&unit=' + unit +
      '&octg=' + octg
      , this.authService.getOptions()).pipe(
      map((response: any) => {
        return response;
      })
      , catchError((err) => {
        console.log(err);
        return err;
      }));
  }

  downloadDisplayAndSourceFile(s3_filename, referenceNumber = null, document_type = null , revision = null): any {
    return this.http.get<any>(this.API_BASE_URL +
      this.DOWNLOAD_DISPLAY_AND_SOURCE_FILE + '?filename=' + s3_filename + '&reference='
      + referenceNumber + '&document_type=' + document_type + (revision !== null ? '&revision=' + revision : '')
      , this.authService.getAuthorization()).pipe(
      map((response: any) => {
        return response;
      })
      , catchError((err) => {
        console.log(err);
        return err;
      }));
  }

  modifyDateValidation(idTsli: number, date: Date, reviewer: number, approval: number): Observable<string> {
    return this.http.get<string>(this.API_BASE_URL + this.TSLI + '/modify_date_or_author?date=' + date + '&idtsli=' + idTsli +
      '&reviewer=' + reviewer + '&approval=' + approval
      , this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE)).pipe(
      map((Response: any) => {
        console.log('-- createTsli completed');
        console.log(Response);
        return Response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));

  }

  createTsli(tsli: Tsli): Observable<TsliResponse> {
    return this.http.post<TsliResponse>(this.API_BASE_URL + this.TSLI  + '/save',
      tsli,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_CREATE)).pipe(
      map((tsliResponse: TsliResponse): TsliResponse => {
        console.log('-- createTsli completed');
        console.log(tsliResponse);
        return tsliResponse;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }


  createTsliNewRevision(tsli: Tsli): Observable<TsliResponse> {
    return this.http.post<TsliResponse>(this.API_BASE_URL + this.TSLI + '/createNewRevision',
      tsli,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_CREATE_NEW_REVISION)).pipe(
      map((tsliResponse: TsliResponse): TsliResponse => {
        console.log(tsliResponse);
        return tsliResponse;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }


  /**
   * Fetch all TSLIs from API
   *
   */
  getAllTslis(): Observable<TsliResponse> {
    console.log('get-all-documents');
    return this.http.get<TsliResponse>(this.API_BASE_URL + '/mytsli-documents-management/get-all-documents',
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_ALL)).pipe(
      map((response: TsliResponse): TsliResponse => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      })
    );
  }

  getPublishedDocuments(): Observable<TsliResponse> {
    console.log('get-published-documents');
    return this.http.get<TsliResponse>(this.API_BASE_URL + '/mytsli-documents-management/get-published-documents',
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_ALL)).pipe(
      map((response: TsliResponse): TsliResponse => {
        console.log('published_documents', response);
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      })
    );
  }

  getAllTslisByParam(param: string, value?: string | number): Observable<TsliResponse> {
    console.log('Search by -> ', param);

    let params = new HttpParams().set('paramby', param);
    if (value) {
      params = params.set('paramvalue', value);
    }

    console.log('params:', params);

    return this.http.get<TsliResponse>(this.API_BASE_URL + '/restrict-document-access/search_by_reference_or_process',
      {params: params, ...this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_BY_PARAM)}).pipe(
      map((response: TsliResponse): TsliResponse => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  getTsliByReference(docType: string, referenceNumber: string, isToView: boolean): Observable<TsliResponse> {

    // used to download source or display file inside document content
    if (!isToView) {
      console.log('to download');
      return this.http.get<TsliResponse>(this.API_BASE_URL + this.TSLI + '/reference?reference='
        + referenceNumber + '&documentType=' + docType,
        this.authService.getAuthorization()).pipe(
        map((tsliResponse: TsliResponse): TsliResponse => {
          console.log(tsliResponse);
          return tsliResponse;
        })
        , catchError((err) => {
          if (err.status == 404) {
            this.router.navigate(['tsli/Unauthorized/404']);
          }
          return empty();

        })
      );
    } else {
      return this.http.get<TsliResponse>(this.API_BASE_URL + this.TSLI + '/reference?reference='
        + referenceNumber + '&documentType=' + docType,
        this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_BY_REF)).pipe(
        map((tsliResponse: TsliResponse): TsliResponse => {
          tsliResponse.tsli.chapters.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          return tsliResponse;
        })
        , catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }

          if (err.status == 404) {
            this.router.navigate(['tsli/Unauthorized/404']);
          }
          return empty();

        }));
    }
  }

  getTsliById(tsliId: string): Observable<TsliResponse> {
    return this.http.get<TsliResponse>(this.API_BASE_URL + this.TSLI + '/id_tsli_ref?id_tsli_ref=' + tsliId,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_BY_REF)).pipe(
      map((tsliResponse: TsliResponse): TsliResponse => {
        tsliResponse.tsli.chapters.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        return tsliResponse;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  /**
   * Load generated Tsli for a given item
   *
   * @param p
   */
  getTsliByItem(p: Product): Observable<string> {
    if (TsliRestService.DEBUG) {
      console.log(`-- TsliRestService.getTsliByItem()`);
      console.log(`- p: `, p);
    }

    const payload: any[] = [];
    for (const prop in p) {
      if (prop !== 'equals') {
        payload.push({name: prop, value: p[prop]});
      }
    }

    return this.http.post<string>(this.API_BASE_URL + this.APPLICABILITY + "/get_chapters",
      {product: payload},
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_BY_PRODUCT)).pipe(
      map((response: string) => {
        if (TsliRestService.DEBUG) {
          console.log(`- response by item: `, response);
        }
        return response["requestId"];
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  recoverChapters(aws_request_id: string): Observable<TsliResponse> {
    return this.http.get<TsliResponse>(this.API_BASE_URL + this.APPLICABILITY + `/recover_chapters?aws_request_id=${aws_request_id}`,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_BY_PRODUCT)).pipe(
      map((response: TsliResponse): TsliResponse => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  /**
   * Load generated Tsli for a given item
   *
   * @param connection
   */
  getTsliByConnection(connection: string): Observable<TsliResponse> {
    console.log('Search by product -> getTsliByConnection:', connection);
    // const encoded = encodeURIComponent('VAM® 21');

    return this.http.get<TsliResponse>(this.API_BASE_URL + '/restrict-document-access/search_by_product/' + connection,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_GET_BY_CONNECTION)).pipe(
      map((response: TsliResponse) => {
        console.log('return value :', response['body']);
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  updateTsli(document: Tsli, isHTMLDocument: boolean): Observable<TsliResponse> {
    if (!isHTMLDocument) {
      console.log(`update no html document:`, document);
      return this.http.put<TsliResponse>(this.API_BASE_URL + this.TSLI + '/update_tsli?no_html=1',
        document,
        this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_NO_HTML_DOCUMENT)).pipe(
        map((tsliResponse: TsliResponse): TsliResponse => {
          console.log('update ' + document.docType + ' document done:', tsliResponse);
          // tsliResponse.tsli.chapters.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          return tsliResponse;
        })
        , catchError((err) => {
          if (err.status == 401) {
            console.log(`error:`, err);
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        }));
    } else {
      console.log('update html document:', document);
      return this.http.put<TsliResponse>(this.API_BASE_URL + this.TSLI + '/update_tsli',
        document,
        this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE)).pipe(
        map((tsliResponse: TsliResponse): TsliResponse => {
          console.log('update ' + document.docType + ' document done', document);
          tsliResponse.tsli.chapters.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          return tsliResponse;
        })
        , catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        }));
    }
  }

  updateTheme(theme: string, idTsli?: string, chaptersRefs?: string[]): Observable<any> {
    let body;
    if (idTsli !== undefined) {
      body = {updated_theme: theme, tsli: idTsli};
    } else {
      body = {updated_theme: theme, chapters: chaptersRefs};
    }
    return this.http.put<TsliResponse>(this.API_BASE_URL + this.TSLI + '/updated_theme', body,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_THEME)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  updateStatus(tsli: Tsli, status: string): Observable<any> {
    let body;
    if (tsli !== undefined) {
      body = {
        isRequestUpdateStatus: true,
        status: status,
        id: tsli.idTsli,
        approver: tsli.approver.id,
        reviewer: tsli.reviewer.id,
        reference: tsli.reference,
        revision: tsli.revision,
        oldStatus: tsli.status,
        author: tsli.author.id,
        eventDate: new Date(),
        docType: tsli.docType,
        title: tsli.title,
      };
      let event = '';
      let route = '';
      console.log('Update status: ', tsli.status, '=>', status);

      switch (status) {
        case Status.REVIEWING: {
          event = ActivityEvent.SENT_TO_REVIEW_AUTHOR;
          route = `tsli/by-id-reference/${tsli.idTsli}`;
          break;
        }
        case Status.APPROBATING: {
          event = ActivityEvent.VALIDATED_REVIEW_AUTHOR;
          route = `tsli/by-id-reference/${tsli.idTsli}`;
          break;
        }
        case Status.REFUSED: {
          if (tsli.status == Status.REVIEWING) {
            event = ActivityEvent.REFUSED_REVIEW;
            route = '/tsli/edit/drafts';
            break;
          } else if (tsli.status == Status.APPROBATING) {
            event = ActivityEvent.REFUSED_APPROVAL;
            route = '/tsli/edit/drafts';
            break;
          }
          break;
        }
        case Status.PUBLISHED: {
          event = ActivityEvent.APPROVED;
          route = '';
          break;
        }
        case Status.ARCHIVED: {
          event = ActivityEvent.ARCHIVED;
          route = '/tsli/edit/drafts';
          break;
        }
      }
      body['event'] = event;
      console.log(body + ' ' + ServicesNamesConstants.TSLI_UPDATE_STATUS);
      return this.http.put<TsliResponse>(this.API_BASE_URL + this.TSLI + "/requestUpdateStatus", body,
        this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_STATUS)).pipe(
        map((response: any): any => {
          const document = response['document'];
          if (document.tsli.source_file_s3_url != undefined) {
            console.log('go to draft list');
            route = '/tsli/edit/drafts';
          }
          this.router.navigate([route], {replaceUrl: true});
          return response;
        })
        , catchError((err) => {
          console.log('erreur de status');
          console.log(err);
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return err;
        }));
    }
  }

  getActivities(): Observable<ActivityResponse[]> {
    return this.http.get<ActivityResponse[]>(this.API_BASE_URL + '/mytsli-documents-management/get-activities',
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_ACTIVITIES)).pipe(
      map((activities: ActivityResponse[]): ActivityResponse[] => {
        return activities;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  deleteRevision(id_tsli, tsli_type) {
    return this.http.delete<any>(this.API_BASE_URL + this.TSLI + "/revision"
      + '?id_tsli=' + id_tsli + '&tsli_type=' + tsli_type,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_DELETE_REVISION)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  deleteTsli(ref, tsli_type, doc_type) {
    return this.http.delete<any>(this.API_BASE_URL + this.TSLI + "/revision"
      + '?tsli_ref=' + ref + '&tsli_type=' + tsli_type + '&doc_type=' + doc_type,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_DELETE_REVISION)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  downloadItemPdf(body) {
    body['user_mail'] = this.authService.getCurrentUserMail();
    if (this.authService.isAdmin()) {
      body['admin'] = true;
    }
    return this.http.post<any>(this.API_BASE_URL + this.TSLI_AS_PDF,
      body,
      this.authService.getAuthorization()).pipe(
      map((response: any): any => {
        console.log(response);
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  updateTsliSkillsMatrixUser(idTsli, idUser): Observable<any> {
    let body = {
      'idTsli': idTsli,
      'idUser': idUser,
    };

    return this.http.put<any>(this.API_BASE_URL + this.TSLI + '/update_skills_matrix',
      body, this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_SKILLS_MATRIX_USER)).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      })
    );
  }

  updateFirstEntry(firstEntry: string, idTsli?: string, chaptersRefs?: string[]): Observable<any> {
    let body;
    if (idTsli !== undefined) {
      body = {first_entry: firstEntry, tsli: idTsli};
    } else {
      body = {first_entry: firstEntry, chapters: chaptersRefs};
    }
    return this.http.put<TsliResponse>(this.API_BASE_URL + this.TSLI + '/first_entry', body,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_FIRST_ENTRY)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }


  updateIsVamFamily(idTsli, isVamFamily): Observable<any> {

    let body = {
      'idTsli': idTsli,
      'is_vam_family': isVamFamily,
    };
    return this.http.put<any>(this.API_BASE_URL + this.TSLI + '/update_is_vam_family',
      body, this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_SKILLS_MATRIX_USER)).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return of();
      })
    );
  }

  uploadFileWithPreSignedURL(file: File): Observable<any> {
    try {
      return this.getPresignedUrl(file).pipe(flatMap(resp => {
        return this.http.put<{ message: string, error: string, requestId: string }>(resp['url'], file,
          {headers: {'Content-Type': 'Multipart/form-data', 'Access-Control-Allow-Origin': '*'}});
      }, (r1) => r1)).pipe(map((response) => {
          console.log(response);
          return {url: 'string', error: 'string', requestId: response['requestId'], s3_filename: response['s3_filename']};
        })
        , catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        }));
    } catch (err) {
      console.log(err);
    }
  }

  getPresignedUrl(file: File) {
    return this.http.get<any>(
      this.API_BASE_URL + this.UPLOAD_SOURCE_AND_DISPLAY_FILE + '?file_name=' + file.name,
      this.authService.getAuthorization(ServicesNamesConstants.SOURCE_AND_DISPLAY_FILE_GET_SAVED)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));

  }

  update_source_file_url(idTsli, source_file_s3_url): Observable<any> {
    let body = {
      'idTsli': idTsli,
      'source_file_s3_url': source_file_s3_url,
    };
    body['headers'] = {
      'tsli-service-name': ServicesNamesConstants.DOC_UPDATE_SOURCE_FILE,
      'tsli-auth-token': this.authService.getToken()
    };
    return this.http.put<any>(this.API_BASE_URL + this.TSLI + '/update_source_file_url',
      body, this.authService.getAuthorization(ServicesNamesConstants.DOC_UPDATE_SOURCE_FILE)).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return of();
      })
    );
  }

  update_display_file_url(idTsli, display_file_s3_url): Observable<any> {

    let body = {
      'idTsli': idTsli,
      'display_file_s3_url': display_file_s3_url,
    };

    body['headers'] = {
      'tsli-service-name': ServicesNamesConstants.DOC_UPDATE_DISPLAY_FILE,
      'tsli-auth-token': this.authService.getToken()
    };
    return this.http.put<any>(this.API_BASE_URL + this.TSLI + '/update_display_file_url',
      body, this.authService.getAuthorization(ServicesNamesConstants.DOC_UPDATE_DISPLAY_FILE)).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return of();
      })
    );
  }

  updateIsLegacy(idTsli, isLegacy): Observable<any> {

    let body = {
      'idTsli': idTsli,
      'is_legacy': isLegacy,
    };

    return this.http.put<any>(this.API_BASE_URL + this.TSLI + '/update_is_legacy',
      body, this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_IS_LEGACY)).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return of();
      })
    );
  }

  isRevisionNumberExist(referenceNumber: string, documentType: string, revision: string): Observable<TsliResponse> {
    console.log('Appel !! ', referenceNumber, ' -- ', documentType, ' -- ', revision);
    return this.http.get<TsliResponse>(this.API_BASE_URL + this.TSLI + '/is_reference_exist?reference=' + referenceNumber + '&documentType=' + documentType + '&revision=' + revision,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_VALIDATE_REF)).pipe(
      map((tsliResponse: TsliResponse): TsliResponse => {
        console.log(tsliResponse);
        return tsliResponse;
      })
      , catchError((err) => {
        console.log('err');
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  isReferenceNumberUnique(referenceNumber: string, documentType: string): Observable<TsliResponse> {
    console.log('Appel !! ', referenceNumber, ' -- ', documentType);
    return this.http.get<TsliResponse>(this.API_BASE_URL + this.TSLI + '/is_reference_exist?reference=' + referenceNumber + '&documentType=' + documentType,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_VALIDATE_REF)).pipe(
      map((tsliResponse: TsliResponse): TsliResponse => {
        console.log(tsliResponse);
        return tsliResponse;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  isHTMLDocument(document: Tsli): boolean {
    return this.getDocumentType(document.docType) && document.source_file_s3_url != null;
  }

  getDocumentType(docType: string): boolean {
    return docType === ServicesNamesConstants.DOCUMENT_TYPE_FORM
      || docType === ServicesNamesConstants.DOCUMENT_TYPE_INOR
      || docType === ServicesNamesConstants.DOCUMENT_TYPE_UG;
  }

  updateRestrictedStatus(document: Tsli): Observable<any> {

    const payload = {is_restricted: document.is_restricted_document};

    return this.http.post<any>(this.API_BASE_URL + '/restrict-document-access/update_restricted_status/' + document.idTsli, payload,
      this.authService.getAuthorization(ServicesNamesConstants.TSLI_UPDATE_STATUS)).pipe(map((response: any): any => {
        return response;
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return of();
      })
    );
  }
}




