<div class="row">
    <h1><span class="mat-h1">Edit Notifications</span></h1>
</div>
<div class="row">
    <div class="col">
        <form [formGroup]="notifFormGroup">
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-select placeholder="Role" [(ngModel)]="role" matTooltip="{{descriptions[role]}}"
                        formControlName="roleCtrl" required>
                            <mat-option value="all">
                                {{"All"}}
                            </mat-option>
                            <mat-option value="admin">
                                {{"admin"}}
                            </mat-option>
                            <mat-option value="approver">
                                {{"Approver"}}
                            </mat-option>
                            <mat-option value="reviewer">
                                {{"Reviewer"}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Validity"
                            formControlName="validityDateCtrl" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-select placeholder="Licensee Number" formControlName="licenseeNumberCtrl" required
                            [(ngModel)]="licenseeNumber">
                            <mat-option value="all" >
                                {{"All"}}
                            </mat-option>
                            <mat-option *ngFor="let l of licensses" [value]="l" >
                                {{l}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                        <mat-checkbox class="example-margin" formControlName="permanentCtrl" (change)="checkPermanent($event.checked)">Permanent</mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field style="width: 90%">
                        <input matInput placeholder="Title" formControlName="titleCtrl" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div style="width: 950px;">
                <ckeditor  #chapterEditor formControlName="ctn" (change)="disableBtn($event)" [editor]="Editor" [config]="config" [(ngModel)]="dataSource.data"></ckeditor>
              </div>
            </div>

          <div class="row">
              <div class="col text-right">
                  <button *ngIf="editionMode || readMode" mat-flat-button color="primary"
                      (click)="cancel()" style="margin-right: 15px;">cancel</button>
                  <button *ngIf="editionMode" mat-flat-button color="primary"
                      (click)="updateNotification()" [disabled]="notifFormGroup.invalid">Validate</button>
                  <button formControlName="addBtn" name="addBtn" id="addBtn" *ngIf="!editionMode && !readMode" mat-flat-button color="primary"
                      (click)="addNotification()" [disabled]="notifFormGroup.invalid">Add</button>
              </div>
          </div>
        </form>
    </div>
    <div class="col">
        <div class="row">Published Notifications</div>
        <div class="row">
            <table mat-table [dataSource]="dataSource" style="width: 100%;">

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> Title </th>
                    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef> Role </th>
                    <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                </ng-container>

                <ng-container matColumnDef="validity">
                    <th mat-header-cell *matHeaderCellDef> Validity </th>
                    <td mat-cell *matCellDef="let element" >
                        <ng-container *ngIf="!element.permanent">
                            {{element.validity | date: 'dd MMM, yyyy'}}
                        </ng-container>
                        <ng-container *ngIf="element.permanent">
                            permanent
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="licenseeNumber">
                    <th mat-header-cell *matHeaderCellDef> Licensee Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.licenseeNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-right">
                            <button mat-icon-button (click)="show(element)">
                                <mat-icon>preview</mat-icon>
                            </button>
                            <button mat-icon-button (click)="edit(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="delete(element.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
