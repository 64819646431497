import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {Output, EventEmitter, ViewChildren, QueryList} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {CurrentTsliStoreService} from '../../../../core/current-tsli-store.service';
import {ApplicabilityItemComponent} from './applicability-item/applicability-item.component';
import {Tsli} from '../../../../core/data/tsli';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {CanComponentDeactivate} from 'src/app/core/guards/can-deactivate.guard';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NavigateDialogComponent} from 'src/app/core/dialog/navigate-dialog/navigate-dialog.component';
import {ApplicabilityService} from 'src/app/core/applicability.service';
import { TsliMode } from 'src/app/core/enum/tsli-mode.enum';
import { SpinnerDialogComponent } from 'src/app/core/dialog/spinner-dialog/spinner-dialog.component';
import { ConfigurationService } from 'src/app/core/configuration.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'applicability',
  templateUrl: './applicability.component.html',
  styleUrls: ['./applicability.component.scss']
})
export class ApplicabilityComponent implements OnInit, CanComponentDeactivate {
  readonly DEBUG_TSLI_REF: string = '001';

  formGroup: FormGroup;
  submit = false;
  activatedDataReloading = false;
  chapterIndex: any;
  parents: { name: string, value: string | number }[] = [];

  items: any[];
  isShowApplicabilityMode: boolean = false;
  savingRequestId = '';
  timeLeft: number = 360;
  interval;
  modalRef: NgbModalRef;
  savingDone = 'Saving Done';
  savingFailed = 'Saving Failed';


  readonly itemEntriesConst = [                 // default filters order values
    {name: 'Connection', order: 'desc'},
    {name: 'OD', order: 'desc'},
    {name: 'WT', order: 'desc'},
    {name: 'Grade', order: 'desc'},
    {name: 'Lubricant', order: 'desc'},
    {name: 'Product Type', order: 'desc'},
    {name: 'Design Options', order: 'desc'},
    {name: 'Customer', order: 'desc'}
  ];
  itemEntries: any[] = []; // ordered list of filters
  firstEntry: string;
  @Output() enableValidateButton = new EventEmitter();

  @ViewChildren('applicabilityItems') applicabilityItems: QueryList<ApplicabilityItemComponent>;

  constructor(public formBuilder: FormBuilder,
              public currentTsli: CurrentTsliStoreService,
              public route: ActivatedRoute,
              public modal: NgbModal,
              public applicabilityRest: ApplicabilityService,
              public configService: ConfigurationService,
              public datepipe: DatePipe) {
  }


  ngOnInit() {
    this.savingDone = this.configService.getConfigurationsValues().filter(x=>x.property == "applicability_save_success" )[0].value;
    this.savingFailed = this.configService.getConfigurationsValues().filter(x=>x.property == "applicability_save_failed" )[0].value;
    this.firstEntry = this.currentTsli.firstEntry; // will be undefined if Modify mode
    this.currentTsli.activatedMode$.subscribe(value=>{
      this.isShowApplicabilityMode = value == TsliMode.reviewingMode;
      this.submit = this.isShowApplicabilityMode;
    });

    this.formGroup = this.formBuilder.group({});

    this.route.paramMap.subscribe((params) => {
      this.chapterIndex = params.get('chapterRef');
      if(this.chapterIndex === 'undefined') {
        this.chapterIndex = undefined;
      }
    });

    this.setItemsOrder();
    this.startEditionTimer();
  }

  sort(index: number): void {
    let sortFunction = (a, b) => (b.name > a.name ? -1 : 1);
    if (this.itemEntries[index].name.toUpperCase() === 'OD') {
      sortFunction = (a, b) => (b.value > a.value ? -1 : 1);
    }

    if (this.itemEntries[index].order === 'desc') {
      sortFunction = (a, b) => (a.name > b.name ? -1 : 1);
      if (this.itemEntries[index].name.toUpperCase() === 'OD') {
        sortFunction = (a, b) => (a.value > b.value ? -1 : 1);
      }
      this.itemEntries[index].order = 'asc';
    } else {
      this.itemEntries[index].order = 'desc';
    }

    if (index === 0) {
      this.items.sort(sortFunction);
    } else {
      this.items.forEach(item => {
        this.subSort(item, index - 1, sortFunction);
      });
    }
  }

  subSort(item, index, sortFunction) {
    if (index === 0) {
      item.children.sort(sortFunction);
    } else {
      item.children.forEach(item => {
        this.subSort(item, index - 1, sortFunction);
      });
    }
  }

  callEnableValidateButton() {
    const data = this.isValid(this.items);
    this.enableValidateButton.emit({data});
  }

  isValid(items): boolean {
    if (items === undefined) {
      return true;
    }
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if (item.checked) {
        return false;
      }
      if (!this.isValid(item.children)) {
        return false;
      }
    }

    return true;
  }

  closeAll(): void {
    this.applicabilityItems.forEach(e => e.collapse(true));
  }

  /*
  * Build filters, using the default order and this.firstEntry
  *
  */
  setItemsOrder() {
    // when editing an existing applicability, loads its filter order first
    if (this.firstEntry === undefined) {
      let order = [];
      this.applicabilityRest.getApplicabilityOrder(this.currentTsli.getCurrentTsliId(),
         this.chapterIndex)
        .subscribe(res => {
          order = res;

          if (order === undefined || order.length === 0) {
            order = [{name: 'connection'}];
          }

          this.firstEntry = order[0].name;
          this.setItemsOrder(); // re-launch setItemsOrder()
        });
      return;
    }

    // build filters according to firstEntry
    if (this.firstEntry !== 'connection') {
      for (let index = 0; index < this.itemEntriesConst.length; index++) {
        if (this.itemEntriesConst[index].name.toLowerCase() === this.firstEntry.toLowerCase()) {
          this.itemEntries.push(this.itemEntriesConst[index]);
          this.itemEntries = this.itemEntries.concat(this.itemEntriesConst.slice(0, index));
          if (index < this.itemEntriesConst.length - 1) {
            this.itemEntries = this.itemEntries.concat(this.itemEntriesConst.slice(index + 1, this.itemEntriesConst.length));
          }
          break;
        }
      }
    } else {
      this.itemEntries = this.itemEntriesConst.slice(0);
    }

    // fetch available products data list according to firstEntry
    this.applicabilityRest.getApplicability(
      this.firstEntry,
      [],
      this.currentTsli.getCurrentTsliId(),
      this.chapterIndex).subscribe(result => {
        this.items = result.items;
        if(result.theme != undefined && result.theme != "")
          this.currentTsli.setTheme(result.theme);
      });
  }

  canDeactivate() {
    if (this.submit) {
      this.submit = false;
      return true;
    }

    //confirmation pop-up
    const modalRef = this.modal.open(NavigateDialogComponent, {size: 'lg', centered: true, backdrop:'static'});
    modalRef.componentInstance.title = 'Unsaved changes';
    modalRef.componentInstance.message_1 =
    'Are you sure you want to quit before saving Applicability changes';
    modalRef.componentInstance.message_2 =
    'All information associated to this Applicability will be lost.';

    return modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        return true;
      }
    }, (reason) => {
      return false;
    });
  }

  cancelApplicability() {
    this.currentTsli.openTsli(this.currentTsli.getCurrentTsliId());
  }

  validateApplicability() {
    this.stopTimer();
    this.modalRef = this.modal.open(SpinnerDialogComponent, { size: 'sm', centered: true, backdrop: 'static' });
    this.modalRef.dismissed.subscribe(resp=>{
      if (resp == 'error'){
        this.submit = true;
        this.currentTsli.RedirectToEditTsli(this.currentTsli.getCurrentTsliId());
      }
    })

    //US 191 BA0227-89: API gateway response in 30s, it is impossible to configure because it's fixed by AWS.
    //After 30s, the gateway api failed but the lambda continues to save the data. Solution solution implemented => put a timeout to close the component dialog
    //after this timeout, we considere that the save data has been done.


    //replace chapterIndex param with chaptersIndexes
    let chapters_ids = undefined;
    if(this.currentTsli.getTmpListCurrentChapters().length > 0) {
      chapters_ids = this.currentTsli.getTmpListCurrentChapters();
    }
    // if( this.chapterIndex != undefined)
    //   chapters_ids =  [this.chapterIndex];
    this.applicabilityRest.saveApplicability(
      this.currentTsli.getCurrentTsliId(),       // tsli id
      this.items,                                 // applicability
      this.itemEntries,                           // order
      chapters_ids).subscribe(resp => {
        this.savingRequestId = resp.requestId
        this.startTimer();
         // chapter id
    });
  }

  ngOnDestroy() {
    if(this.isShowApplicabilityMode){
      this.currentTsli.setActivatedMode(TsliMode.reviewingMode);
    }
    this.applicabilityRest.newApplicability = false;
    this.stopTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        if(this.timeLeft % 30 == 0){
          this.checkSavingResult();
        }
        this.timeLeft--;
      } else {
        this.stopTimer()
        this.modalRef .componentInstance.message = this.savingFailed.replace('{id}', this.savingRequestId).replace('{date}', this.datepipe.transform(new Date(), 'dd/MM/yyyy'))
        this.modalRef .componentInstance.done = false
        this.modalRef .componentInstance.showButton = true
        this.currentTsli.clearTmpListCurrentChapters();
      }
    },1000)
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  startEditionTimer(){
    this.interval = setInterval(()=>{
      console.log('=====> keep warm')
      this.applicabilityRest.keepLambdaWarm();
    }, 60000)
  }

  checkSavingResult(){
    this.applicabilityRest.getSavingResult(this.savingRequestId).subscribe((resp)=>{
      if (resp.result === 'done') {
        this.stopTimer()
        this.modalRef .componentInstance.message = this.savingDone
        this.modalRef .componentInstance.done = true
        this.currentTsli.addToListchaptersCurrent()
        setTimeout(()  => {
          this.modal.dismissAll();
          this.submit = true;
          this.currentTsli.RedirectToEditTsli(this.currentTsli.getCurrentTsliId());
        }, 5000);

      } else if (resp.result === 'wait'){
        console.log('wait 30seconds')
      }else{
        this.stopTimer()
        this.modalRef .componentInstance.message = this.savingFailed.replace('{id}', this.savingRequestId).replace('{date}', this.datepipe.transform(new Date(), 'dd/MM/yyyy'))
        this.modalRef .componentInstance.done = false
        this.modalRef .componentInstance.showButton = true
        this.currentTsli.clearTmpListCurrentChapters();
      }
    });

  }

}
