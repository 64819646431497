<div class="container-fluid mt-3" #tabs *hasRole="['admin', 'author', 'reviewer', 'approver']">
  <div class="row">
    <div class="col">
      <h1><span class="mat-h1">Basic Product Data  | </span><span class="mat-h1" style="font-weight: bold">Detailed document</span>
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-tab-group (selectedTabChange)="switchSelectedTab($event)" [selectedIndex]="selectedTabId">
        <mat-tab label="PUBLISHED DOCUMENT">
          <tslis tab="published"></tslis>
        </mat-tab>
        <mat-tab label="DRAFT DOCUMENT">
          <tslis tab="drafts"></tslis>
        </mat-tab>
        <mat-tab label="ARCHIVED DOCUMENT">
          <tslis tab="archived"></tslis>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
