<div class="container-fluid" style="min-width: 1500px; overflow-x: auto;
white-space: nowrap;">
  <div class="row">
    <div class="col">
      <bpd-header></bpd-header>
      <br>
    </div>
  </div>
  <br>
  <br>
  <br>

  <div class="row" [style.padding-right.px]="paddingRight">
    <div class="col" style="padding-right: 0px;">
      <product-picker></product-picker>
    </div>
    <div class="col" style="max-width: 35%;  min-width: 35%;" >
      <assembly-data-header></assembly-data-header>
    </div>
  </div>

  <!-- rows of products -->
  <div id="productsRows" class="row scrollable-content">
    <div class="col" style="padding-right: 0px;">
      <product-list></product-list>
    </div>
    <div class="col" style="max-width: 35%;  min-width: 35%;">
      <assembly-data-content></assembly-data-content>
    </div>
  </div>
</div>

