<table>
  <thead>
    <tr>
      <td>
        <div class="table-content"><span style="margin-top: 25px;">Table of Content</span></div>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div class="content">
          <div id="summary" class="summary">
            <div class="row chapters">
              <div class="col">
                <div class="entry" *ngFor="let entry of summary_entries;">
                  <div class="entry-chapter" [ngClass]="{'header1': entry.depth === 0, 'header2': entry.depth === 1, 'header3': entry.depth > 1  }" > {{entry.title}}</div>
                  <div class="entry-page">{{entry.page}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot><tr><td>
  </td></tr></tfoot>
</table>
