<div class="row">
  <h1><span class="mat-h1">Configure product list viewer</span></h1>
</div>
<div class="row" style="margin-left:20px;">
  <button mat-raised-button (click)="selectAll()">Select All</button>
</div>
<ng-container>
  <div class="row" style="margin-left:20px; margin-top: 20px;">
    <ng-container *ngFor="let connection of connectionsfilter">
      <mat-checkbox color="primary" class="col-md-3" style="justify-content:center;align-items:center;"
                    (click)="$event.stopPropagation()" (change)="$event ? rowsConnectionSelection.toggle(connection) : null"
                    [checked]="rowsConnectionSelection.isSelected(connection)" >
        {{connection}}
      </mat-checkbox>
    </ng-container>
  </div>
  <div class="row mt-2" style="margin-top: 50px;">
    <div class="col text-center">
      <button mat-raised-button (click)="commitProductsSelection()">Validate</button>
    </div>
  </div>
</ng-container>
