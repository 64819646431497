import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

/* Angular Material imports */
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import {MatExpansionModule} from '@angular/material/expansion';

/* TSLI imports starts here */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeaturesModule } from './features/features.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ConfirmDialogComponent } from './core/dialog/confirm-dialog/confirm-dialog.component';
import { NavigateDialogComponent } from './core/dialog/navigate-dialog/navigate-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { DownloadRequestDialogComponent } from './core/dialog/download-request-dialog/download-request-dialog.component';
import { SpinnerDialogComponent } from './core/dialog/spinner-dialog/spinner-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


// import { HeaderInterceptor } from './core/interceptors/header-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    SpinnerDialogComponent,
    NavigateDialogComponent,
    DownloadRequestDialogComponent,
  ],
  imports: [
    FeaturesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    NgbModule,

    CKEditorModule,

    /* Angular Material imports */
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatGridListModule,
    MatIconModule,
    MatBadgeModule,
    MatStepperModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSelectModule,
    MatInputModule,
    MatExpansionModule
  ],
  // providers: [
  //   { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  // ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
