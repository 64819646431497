<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Available files to download</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header  justify-content-center">
  <div class="row">
    <div class="col">
      <input matInput id="docTitle" [matTooltip]="docTitle" [(ngModel)]="docTitle" readonly
             style="width: 500px; text-align: center">
    </div>
    <div class="col">
      <input type="text" id="docRef" [matTooltip]="docReference" [(ngModel)]="docReference" readonly>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button mat-flat-button color="primary" style="margin-right: 10px" (click)="download('source_file')"
          [disabled]="is_exist_source_file_s3_url">Download source file
  </button>
  <button mat-flat-button color="primary" style="margin-right: 10px" (click)="download('display_file')"
          [disabled]="is_exist_display_file_s3_url">Download display file
  </button>
</div>
