import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {Tsli} from '../../../core/data/tsli';
import {Chapter} from 'src/app/core/data/chapter';
import {ChapterComponent} from '../chapter/chapter.component';
import {CreateApplicabilityStepperComponent} from '../chapter/create-applicability-stepper/create-applicability-stepper.component';
import {ConfirmDialogStatusComponent} from 'src/app/core/dialog/confirm-dialog-status/confirm-dialog-status.component';
import {Status} from '../../../core/enum/status.enum';
import {DetailedTsliStore} from 'src/app/core/detailed-tsli-store.service';
import {ModifyAuthorStepperComponent} from '../../modify-author-stepper/modify-author-stepper.component';
import {modifyDateValidationPopupComponent} from '../../modify-date-validation-popup/modify-date-validation-popup.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TsliMode} from 'src/app/core/enum/tsli-mode.enum';
import {ConfirmDialogAdminEditComponent} from 'src/app/core/dialog/confirm-dialog-admin-edit/confirm-dialog-admin-edit.component';
import {DownloadRequestDialogComponent} from 'src/app/core/dialog/download-request-dialog/download-request-dialog.component';
import {AuthService} from 'src/app/core/auth.service';
import {
  CheckApplicationDateDialogComponent
} from 'src/app/core/dialog/check-application-date-dialog/check-application-date-dialog.component';
import {Comment} from 'src/app/core/data/comment';
import {DatePipe} from '@angular/common';
import {CommentsStoreService} from 'src/app/core/comments-store.service';
import {SpinnerDialogComponent} from 'src/app/core/dialog/spinner-dialog/spinner-dialog.component';
import {UserRestService} from '../../../core/user-rest.service';
import {User} from '../../../core/data/User';

@Component({
  selector: 'chapters',
  templateUrl: './chapters-list.component.html',
  styleUrls: ['./chapters-list.component.scss']
})
export class ChaptersListComponent implements OnInit {
  tsli$: Observable<Tsli>;
  tsliSubscription: Subscription;
  @ViewChildren('chaptersElements') chapters: QueryList<ChapterComponent>;

  public checkAll: boolean = false;
  public indeterminate: boolean = false;
  public listchapters = [];
  public listbuttonsColor = [];
  public isSelecteditem = [];
  public saveMessage = 'All changes saved';
  public tsliHasApplicability = false;
  authors: User[];
  reviewers: User[];

  tsliStatus: string;
  tsliType: string;
  docType: string;
  tsliId: string;
  reference: string;
  revision: string;
  title: string;
  applicationDate: Date;
  applicabilityTheme: string;
  firstEntryApplicability: string;
  newApplicabilityEntry: string;
  hasNotThemeOrApplicability: boolean = false;
  refuseComment = new Comment();
  timeLeft: number = 125;
  interval;
  modalRef: NgbModalRef;
  document: Tsli;


  activatedTsliMode: TsliMode;
  subscriptionActivatedTsliMode: Subscription;

  readonly pendingSaveMessage = 'You have unsaved changes';
  readonly saveAllChangesMessage = 'All changes saved';

  constructor(public currentTsliStore: CurrentTsliStoreService,
              public modal: NgbModal,
              public detailedTsli: DetailedTsliStore,
              public router: Router,
              public route: ActivatedRoute,
              public auth: AuthService,
              public datePipe: DatePipe,
              public commentStore: CommentsStoreService,
              public userRest: UserRestService
  ) {
  }

  test(): boolean {
    //this.listbuttonsColor =listbuttonsColor.length > 0 && listbuttonsColor.indexOf(chapter.id_chapter) ! -1
    return true;
  }

  ngOnInit(): void {
    this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
    this.timeLeft = 125;

    this.listbuttonsColor = this.currentTsliStore.getListCurrentChapters();

    this.auth.user$.subscribe(value => {
      this.refuseComment.commentator = value;
    });
    this.subscriptionActivatedTsliMode = this.currentTsliStore.activatedMode$
      .subscribe(value => {
        this.activatedTsliMode = value;
      });

    this.tsli$ = this.currentTsliStore.tsli$;

    this.route.paramMap.subscribe(params => {
      if (params.get('ref') != this.currentTsliStore.getCurrentTsliId()) {
        if (this.router.url.includes('/chapters-list/edit/')) {
          this.currentTsliStore.setActivatedMode(TsliMode.simpleEditionMode);
        } else if (this.router.url.includes('/chapters-list/adminedit/')) {
          this.currentTsliStore.setActivatedMode(TsliMode.adminEditoionMode);
        } else if (this.router.url.includes('/chapters-list/review/')) {
          this.currentTsliStore.setActivatedMode(TsliMode.reviewingMode);
        } else if (this.router.url.includes('/chapters-list/approve/')) {
          this.currentTsliStore.setActivatedMode(TsliMode.approvingMode);
        } else if (this.router.url.includes('/chapters-list/applicabilityedit/')) {
          this.currentTsliStore.setActivatedMode(TsliMode.applicabilityEditionMode);
        } else if (this.router.url.includes('/chapters-list/themeedit/')) {
          this.currentTsliStore.setActivatedMode(TsliMode.themeEditionMode);
        }

        this.currentTsliStore.openTsli(params.get('ref'), true);
      }
    });

    this.tsliSubscription = this.tsli$.subscribe((value: Tsli): void => {
      if (value === undefined) {
        console.warn(`interrupting execution on undefined TSLI`);
        return;
      }


      this.document = value;
      this.tsliStatus = value.status;
      this.tsliType = value.type;
      this.docType = value.docType;
      this.tsliId = value.idTsli;
      this.reference = value.reference;
      this.revision = value.revision;
      this.title = value.title;
      this.applicationDate = new Date(value.applicationDate);

      this.refuseComment.tsli = value;
      this.refuseComment.comment = 'application date ' + this.datePipe.transform(this.applicationDate, 'dd/MM/yyyy') + ' is overdue from publication date';

      if (value.type === 'process' || value.type === 'ugia' || value.type === 'ugira' || value.type === 'ugfl') {
        this.applicabilityTheme = value.theme;
        this.tsliHasApplicability = value.applicability;
        this.firstEntryApplicability = value.applicabilityEntry;
        this.newApplicabilityEntry = value.newApplicabilityEntry;

        //we check theme and applicability on tsli
        if (!value.theme || !this.tsliHasApplicability) {
          this.hasNotThemeOrApplicability = true;
        }
      }

      //if type tsli is product then we check theme and applicability on chapters
      if (value.type === 'product') {
        value.chapters.forEach(element => {
          if (!element.theme || !element.applicability) {
            this.hasNotThemeOrApplicability = true;
          }
        });

      }

      // TODO: why is there this recursive subscription ?
      this.tsli$.subscribe((tsli: Tsli) => {
        let i = 0;
        tsli.chapters.forEach(chapter => {
          this.listchapters[i] = chapter.id_chapter;
          this.isSelecteditem[i] = false;
          i++;
        });

      });

      this.userRest.getAuthors(this.tsliId).subscribe((usersResponse: User[]) => {
        this.authors = usersResponse.filter(user => user.isAuthor);
        console.log('authors', this.authors);
      });

      this.userRest.getReviewers(this.tsliId).subscribe((usersResponse: User[]) => {
        this.reviewers = usersResponse.filter(user => user.isReviewer);
        console.log('reviewers', this.reviewers);
      });

      this.modalRef.componentInstance.message = 'Please, wait while we are uploading content ...';
      this.modalRef.componentInstance.done = true;

      setTimeout(() => {
        this.modal.dismissAll();
      }, 5000);
    });


  }

  ngOnDestroy(): void {
    // this.currentTsliStore.deactivateApplicabilityEditionMode();
    // this.currentTsliStore.disableValidationMode();
    // this.currentTsliStore.hideCommentsPanel();
    this.subscriptionActivatedTsliMode.unsubscribe();
    this.tsliSubscription.unsubscribe();
  }

  addChapter() {
    const newChapter: Chapter = new Chapter();
    newChapter.depth = this.currentTsliStore.getLastChapterDepth() >= 0 ? this.currentTsliStore.getLastChapterDepth() : 0;
    newChapter.index = this.currentTsliStore.getChaptersLength() >= 0 ? this.currentTsliStore.getChaptersLength() : 0;
    newChapter.title = `Chapter`;
    newChapter.body = 'Add your content here';
    newChapter.titleStyle = null;
    const lastChapterNumber = this.currentTsliStore.getLastChapterNumber();
    newChapter.number = (lastChapterNumber === '') ? '1' : lastChapterNumber;
    this.currentTsliStore.addChapter(newChapter);
  }

  moveChapter(event): void {
    let index: any;
    index = Number.parseInt(event.data, 10);

    if (event.type === 'left') {
      this.currentTsliStore.moveToLeft(index);
    } else if (event.type === 'right') {
      this.currentTsliStore.moveToRight(index);
    } else if (event.type === 'up') {
      index--;
      this.currentTsliStore.chaptersPermutation(index);
    } else {
      this.currentTsliStore.chaptersPermutation(index);
    }
  }

  collapseAll(): void {
    this.chapters.forEach(e => e.isCollapsed = true);
    setTimeout(() => {
      let tables = Array.from(document.getElementsByTagName('figure'));
      let images = tables.filter(element => element.classList.contains('image'));
      this.addImageResizedClass(images);
    }, 10000);
  }

  addImageResizedClass(images) {
    images.forEach(img => {
      if (!img.classList.contains('image_resized')) {
        img.style.width = img.firstElementChild.offsetWidth + 'px';
        img.classList.add('image_resized');
      }
    });
  }

  closeAll(): void {
    this.chapters.forEach(e => e.isCollapsed = false);
  }

  editChapter(chapter: ChapterComponent): void {
    this.saveMessage = this.saveAllChangesMessage;
    this.chapters.forEach(c => {
      if (c !== chapter) {
        c.isEditing = false;
        c.savingChapter = false;
        c.refreshBody();
      }
    });
  }

  setcheckitem(id) {
    this.isSelecteditem[id] = !this.isSelecteditem[id];
    if (this.isSelecteditem.indexOf(false) != -1) {
      this.checkAll = false;
      this.indeterminate = this.isSelecteditem.indexOf(true) != -1;
    } else {
      this.checkAll = true;
      this.indeterminate = false;
    }
  }

  modifyDateAndValidation(): void {
    const modalRef = this.modal.open(modifyDateValidationPopupComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.reviewers = this.reviewers;
    modalRef.componentInstance.idTsli = this.tsliId;
    modalRef.componentInstance.document = this.document;
  }

  modifyAuthor(): void {
    const modalRef = this.modal.open(ModifyAuthorStepperComponent, {
      size: 'lg', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.authors = this.authors;
    modalRef.componentInstance.idTsli = this.tsliId;
    modalRef.componentInstance.document = this.document;

  }

  checkAlltems() {
    this.checkAll = !this.checkAll;
    this.indeterminate = false;
    for (let i = 0; i < this.isSelecteditem.length; i++) {
      this.isSelecteditem[i] = this.checkAll;
    }
  }

  changedChapter(): void {
    this.saveMessage = this.pendingSaveMessage;
  }

  SaveAllApplicabilities(): void {
    this.currentTsliStore.addToTmpListCurrentChapters(this.listchapters.filter((v, i) => this.isSelecteditem[i]));
    // if (this.checkAll) {
    //   this.listchapters.forEach(c => this.currentTsliStore.listCurrentChapters(c));
    // } else {
    //   for (let i = 0; i < this.isSelecteditem.length; i++) {
    //     if (this.isSelecteditem[i]) {
    //       this.currentTsliStore.listCurrentChapters(this.listchapters[i])
    //     }
    //   }
    // }

    // const modalRef: NgbModalRef = this.modal.open(CreateApplicabilityStepperComponent, { size: 'lg', centered: true });
    // modalRef.componentInstance.chapterIndex = this.chapter.id_chapter;

  }

  savedChapter(): void {
    this.saveMessage = this.saveAllChangesMessage;
  }

  createModifyApplicability(multiple: boolean): void {
    const modalRef: NgbModalRef = this.modal.open(CreateApplicabilityStepperComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    if (multiple) {
      this.currentTsliStore.addToTmpListCurrentChapters(this.listchapters.filter((v, i) => this.isSelecteditem[i]));
      modalRef.componentInstance.multipleChapters = true;
    } else {
      modalRef.componentInstance.theme = this.applicabilityTheme;
      modalRef.componentInstance.hasApplicability = this.tsliHasApplicability;
      modalRef.componentInstance.firstEntryApplicability = this.firstEntryApplicability;
      modalRef.componentInstance.newFirstEntryApplicability = this.newApplicabilityEntry;
      //modalRef.componentInstance.firstEntryApplicability =
    }
    event.stopPropagation();
  }

  refuse() {
    // let tsli: BehaviorSubject<Tsli> = this.currentTsliStore.getTsli();
    this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
    this.timeLeft = 125;
    this.currentTsliStore.updateStatus(Status.REFUSED).subscribe(resp => {
      if (resp.msg != undefined && resp.msg == 'tsli status updated') {
        this.modalRef.componentInstance.message = 'Status Changed successfuly';
        this.modalRef.componentInstance.done = true;

        setTimeout(() => {
          this.modal.dismissAll();
        }, 5000);
      } else {
        this.modalRef.componentInstance.message = 'Failed to change Status';
        this.modalRef.componentInstance.done = false;

        setTimeout(() => {
          this.modal.dismissAll();
        }, 5000);
      }
    });
  }

  checkApplicationDate() {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (this.applicationDate.getTime() < tomorrow.getTime()) {
      const modalRef: NgbModalRef = this.modal.open(CheckApplicationDateDialogComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalRef.componentInstance.applicationDate = this.datePipe.transform(this.applicationDate, 'dd/MM/yyyy');
      modalRef.componentInstance.validationMode = this.activatedTsliMode == 'REVIEWING_MODE' || this.activatedTsliMode == 'APPROVING_MODE';
      return modalRef.result;
    }
  }

  sendToReview() {
    const checkResult = this.checkApplicationDate();
    if (checkResult == undefined) {
      return this.sendToReviewCallback();
    }

    checkResult.then((userResponse) => {
      if (userResponse === 'continue') {
        this.sendToReviewCallback();
      }
    }).catch(err => console.error(err));
  }

  sendToReviewCallback() {
    // let showDialogConfirm = false;
    let showDialogConfirm = this.hasNotThemeOrApplicability;

    if (showDialogConfirm) {
      const modalRef: NgbModalRef = this.modal.open(ConfirmDialogStatusComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalRef.componentInstance.tsliType = this.docType;
      modalRef.componentInstance.tsliType = this.tsliType;


      modalRef.result.then((userResponse) => {
        if (userResponse === 'confirmed') {
          this.updateStatus(Status.REVIEWING);
          this.detailedTsli.isSendToReview = true;
        }
      });
    } else {
      this.updateStatus(Status.REVIEWING);
      this.detailedTsli.isSendToReview = true;
    }
  }

  sendToApprove() {
    const checkResult = this.checkApplicationDate();
    if (checkResult == undefined) {
      this.updateStatus(Status.APPROBATING);
    }
    checkResult.then((userResponse) => {
      if (userResponse === 'reject') {
        this.addRefuseComment();
        this.refuse();
      }

      if (userResponse === 'continue') {
        this.updateStatus(Status.APPROBATING);
      }
    }).catch(err => console.error(err));
  }

  publish() {
    const checkResult = this.checkApplicationDate();
    if (checkResult == undefined) {
      this.updateStatus(Status.PUBLISHED);
    }
    checkResult.then((userResponse) => {
      if (userResponse === 'reject') {
        this.addRefuseComment();
        this.refuse();
      }

      if (userResponse === 'continue') {
        this.updateStatus(Status.PUBLISHED);
      }
    }).catch(err => console.error(err));
  }

  updateStatus(status: Status) {

    this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
    this.timeLeft = 125;
    this.currentTsliStore.updateStatus(status).subscribe(resp => {
      if (resp.msg != undefined && resp.msg == 'tsli status updated') {
        this.modalRef.componentInstance.message = 'Status Changed successfuly';
        this.modalRef.componentInstance.done = true;

        setTimeout(() => {
          this.modal.dismissAll();
        }, 5000);
      } else {
        this.modalRef.componentInstance.message = 'Failed to change Status';
        this.modalRef.componentInstance.done = false;

        setTimeout(() => {
          this.modal.dismissAll();
        }, 5000);
      }
    });
  }

  addRefuseComment() {

    this.refuseComment.commentDate = new Date();
    this.refuseComment.is_revision_comment = '0';
    let addedComment = JSON.parse(JSON.stringify(this.refuseComment));
    this.commentStore.addComment(addedComment);

  }

  showApplicability() {
    this.currentTsliStore.setFirstEntry(undefined); // clear firstEntry data to force reload from back-end
    // this.currentTsliStore.setActivatedMode(TsliMode.applicabilityReadingMode)
    this.router.navigate([`tsli/chapter/applicability/${undefined}`], {skipLocationChange: true});

  }

  validateAdminChanges() {
    const modalRef: NgbModalRef = this.modal.open(ConfirmDialogAdminEditComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.docType = this.docType;
    modalRef.componentInstance.reference = this.reference;
    modalRef.componentInstance.revision = this.revision;

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        this.currentTsliStore.updateTsli().then((hasNoError: boolean) => {
          if (hasNoError) {
            this.router.navigate([`tsli/by-id-reference/${this.tsliId}`]);
          }
        });
      }
    });


  }

  download(): void {

    let draft = (this.tsliStatus != Status.PUBLISHED);
    this.currentTsliStore.generateTsliPdfRequest({
      'id_tsli': this.tsliId,
      'name': this.docType.toUpperCase() + this.reference + ' - ' + this.title + ' - rev.' + this.revision
    }, draft).subscribe(resp => {
      if (resp.success != undefined && resp.success.message == 'pdf.generation.initialized') {
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_1;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_1.replace('<email>', this.auth.getCurrentUser()['email'])
          +
          modalRef.componentInstance.MSG_3;
      } else {
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_2;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_2;
      }
    });
  }

}
