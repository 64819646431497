import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {UserRestService} from 'src/app/core/user-rest.service';
import {forkJoin} from 'rxjs';
import {TslisStoreService} from 'src/app/core/tslis-store.service';
import {Tsli} from 'src/app/core/data/tsli';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe, Location} from '@angular/common';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {
  ConfirmDialogAdminLicenseLightComponent
} from 'src/app/core/dialog/confirm-dialog-admin-license-light/confirm-dialog-admin-license-light.component';
import {User} from 'src/app/core/data/User';
import {ApplicabilityService} from 'src/app/core/applicability.service';
import {TsliRestService} from '../../../core/tsli-rest.service';
import {TypeEnum} from 'src/app/core/enum/document-type.enum';

@Component({
  selector: 'app-connection-chooser',
  templateUrl: './user-license-light-rights.component.html',
  styleUrls: ['./user-license-light-rights.component.scss']
})
export class UserLicenseLightRightsComponent implements OnInit {
  dataSource = new MatTableDataSource<Tsli>([]);
  rowsSelection = new SelectionModel<Tsli>(true, []);
  cachedTslis: Tsli[] = [];
  searchedTslis = [];
  searchedExp = '';
  user: User = null;
  showSelected = false;

  displayedColumns: string[] = ['select', 'title', 'reference', 'revision', 'applicationDate', 'type', 'action'];

  connections = [];
  searchText = '';
  connectionsfilter = [];
  // connectionLogo = {
  //   'VAM TOP ®': 'vam_top.png',
  //   'VAM® 21': 'vam_21.png',
  //   'VAM® 21 HT': 'vam_21ht.png',
  //   'VAM® SLIJ-3': 'vam_slij_3.png',
  //   'VAM® SLIJ-3-NA': 'vam_slij_3.png'
  // };
  rowsConnectionSelection = new SelectionModel<string>(true, []);
  fileToDownload: string;

  constructor(public userService: UserRestService,
              public tslisService: TslisStoreService,
              public rest: TsliRestService,
              public currentTsliStore: CurrentTsliStoreService,
              public applicabilityRest: ApplicabilityService,
              public datePipe: DatePipe,
              public modal: NgbModal,
              public location: Location,
              public router: Router) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUpdatedUser();
    forkJoin([this.tslisService.getPublishedTslis(),
      this.userService.getUserLicenseLightRights(this.user.id)]
    ).subscribe(([published, selected]) => {
      if (this.user.isLicenseLight) {
        this.cachedTslis = published.tslis.filter(tsli => tsli.type !== TypeEnum.UGIA && tsli.type !== TypeEnum.UGIRA);
      } else {
        this.cachedTslis = published;
      }
      this.cachedTslis.filter(item => {
        if (selected['tslis'].includes(item.idTsli)) {
          this.rowsSelection.select(item);
        }
      });
      if (selected['tslis'].length > 0) {
        this.dataSource.data = this.cachedTslis;
      } else {
        this.dataSource.data = this.cachedTslis.slice(0, 15);
      }

    });

    forkJoin(
      [this.applicabilityRest.getItemValues({item: 'product', parents: [], fromFilter: false}),
        this.userService.getUserRights(this.user.id)]
    ).subscribe(([Response, data]) => {
      this.connections = Response.slice();
      this.connectionsfilter = this.connections;
      this.connectionsfilter.sort();

      this.user.product = data['products'];
      this.user.product.forEach(item => this.rowsConnectionSelection.select(item));
    });
  }

  searchInData() {
    // if (this.tab ==  'published')
    if (this.searchedExp == '') {
      this.searchedTslis = this.cachedTslis;
    } else {

      this.searchedTslis = this.cachedTslis.filter(t => {
        let safeSearchedExp = this.searchedExp.toLowerCase();
        let applicationDate = '';
        if (t.applicationDate != undefined) {
          applicationDate = this.datePipe.transform(t.applicationDate, 'dd/MM/yyyy');
        }
        return t.title.toLowerCase().includes(safeSearchedExp) ||
          t.reference.toLowerCase().includes(safeSearchedExp) ||
          (t.docType + ' - ' + t.reference).toLowerCase().includes(safeSearchedExp) ||
          t.type.toLowerCase().includes(safeSearchedExp) ||
          applicationDate.includes(safeSearchedExp);

      });
    }
    this.dataSource.data = this.searchedTslis;
  }

  view(document: Tsli): void {
    if (document.source_file_s3_url != undefined) {
      if (document.display_file_s3_url != null) {
        this.fileToDownload = document.display_file_s3_url;
      } else if (document.source_file_s3_url != null) {
        this.fileToDownload = document.source_file_s3_url;
      }
      console.log('reference:', document.reference);
      console.log('document type', document.docType);

      this.rest.downloadDisplayAndSourceFile(this.fileToDownload, document.reference, document.docType).subscribe(
        response => {
          window.open(response, '_self');
        });
    } else {
      this.currentTsliStore.setCurrentTsli(undefined);
      this.router.navigate([`/tsli/by-id-reference/${document.idTsli}`]);
    }
  }

  viewMore(): void {
    length = (this.cachedTslis.length - this.dataSource.data.length > 15) ? 15 : this.cachedTslis.length - this.dataSource.data.length;
    this.dataSource.data.push(...this.cachedTslis.slice(this.dataSource.data.length, this.dataSource.data.length + length));
    this.dataSource.data = this.dataSource.data.slice();
  }

  save() {
    const modalRef: NgbModalRef = this.modal.open(ConfirmDialogAdminLicenseLightComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.user = this.user.first_name + ' ' + this.user.name;

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        //save and go back to users
        let tslis = this.rowsSelection.selected;
        this.userService.updateLicenseLightRights({
          'id_user': this.user.id,
          'tslis': tslis
        })
          .subscribe((Response) => {
            this.location.back();
          });
      }
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.rowsSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.rowsSelection.clear() :
      this.dataSource.data.forEach(row => this.rowsSelection.select(row));
  }

  switchPartialLicenseLight() {
    this.user.isLicenseLight = !this.user.isLicenseLight;
    this.userService.updateUser(this.user).subscribe(resp => {
      console.log(resp);
    });
  }

  filterSelected() {
    this.showSelected = !this.showSelected;
    if (this.showSelected) {
      this.dataSource.data = this.cachedTslis.filter(row => this.rowsSelection.selected.includes(row));
    } else {
      this.dataSource.data = this.cachedTslis.slice(0, 15);
    }

  }

  filters() {
    this.connectionsfilter = [];
    this.connectionsfilter = this.connections.filter(s => {
      return s.indexOf(this.searchText.toUpperCase()) !== -1;
    });
  }

  commitProductsSelection() {
    let productsForRights = this.rowsConnectionSelection.selected.map(item => item);
    this.userService.updateRights({'is_partial': 1, 'id_user': this.user.id, 'products': productsForRights})
      .subscribe((Response) => {
        this.location.back();
      });

  }

}
