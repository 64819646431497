import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/configuration.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {
  video = undefined
  subscriptions = [];
  constructor(public config:ConfigurationService) { }


  ngOnInit() {
    this.subscriptions.push(this.config.getConfigurationByName("onboard_video").subscribe(v=>{
      this.video = v['configurations'][0].value
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
