<!-- <ng-container *ngIf='!showPages'> -->
<ng-container>
  
  <ng-container *ngIf='byItem'>
    <div class="page">
      <pdf-tds-data></pdf-tds-data>
    </div>
    <div class="page">
      <pdf-assembly-data></pdf-assembly-data>
    </div>
    <div class="page">
      <pdf-thread-compound></pdf-thread-compound>
    </div>
  </ng-container>
  <div *ngIf='withSummary' class="page">
    <pdf-summary></pdf-summary>
  </div>
  <pdf-chapters *ngIf="!type"></pdf-chapters>
  <pdf-tsli-list *ngIf="type"></pdf-tsli-list>
</ng-container>
<!-- <ng-container *ngIf='showPages'>
  <div class="l"></div>
</ng-container> -->