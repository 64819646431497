<div class="row" style="margin-left: 10px;">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput type="text" [(ngModel)]="searchedExp" (ngModelChange)="searchInData()">
    <button mat-button *ngIf="searchedExp" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchedExp=''; searchInData()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>

<table mat-table [dataSource]="dataSource">

  <!-- Select column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" class="checkbox-all"
                    [checked]="rowsSelection.hasValue() && isAllSelected()"
                    [indeterminate]="rowsSelection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                    (change)="$event ? rowsSelection.toggle(row) : null"
                    [checked]="rowsSelection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- connection-->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element" [matTooltip]="element.title" #tooltip="matTooltip">{{element.title}}</td>
  </ng-container>

  <!-- Reference column -->
  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef>Reference</th>
    <td mat-cell
        *matCellDef="let element">{{element.document_type | uppercase}}{{ " - " }}{{element.reference}}</td>
  </ng-container>

  <!-- Revision column -->
  <ng-container matColumnDef="revision">
    <th mat-header-cell *matHeaderCellDef>Revision</th>
    <td mat-cell *matCellDef="let element">{{element.revision}}</td>
  </ng-container>

  <!-- Application Date column -->
  <ng-container matColumnDef="applicationDate">
    <th mat-header-cell *matHeaderCellDef>Application Date</th>
    <td mat-cell *matCellDef="let element">{{ element.applicationDate | date :  "dd/MM/y" }}</td>
  </ng-container>

  <!-- Type column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">{{element.type}}</td>
  </ng-container>

  <!-- View Action column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button color="primary"
              (click)="view(element.document_type, element.reference)"
              [hidden]="element.source_file_s3_url != undefined ">{{ "View"  }}</button>
      <button mat-raised-button color="primary"
              (click)="downloadDisplayFile(element)"
              [hidden]="(element.source_file_s3_url == undefined)
              || element.isArchivedDocument">{{ "Download"  }}</button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="row mt-2">
  <div class="col text-center">
    <button mat-button (click)="viewMore()" [disabled]="dataSource.data.length == cachedTslis.length">VIEW MORE
    </button>
  </div>
</div>
<div class="row mt-2">
  <div class="col text-center">
    <button mat-stroked-button (click)="download()"
            [disabled]="rowsSelection.isEmpty()"
            style="margin-bottom: 10px;">Download
    </button>
  </div>
</div>
