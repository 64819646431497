// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  int: true,
  qual: false,
  demo: false,
  dev: false,
  API_BASE_LOCAL_URL: 'http://127.0.0.1:8000',
  API_BASE_URL: 'https://jwswumtb0h.execute-api.eu-west-1.amazonaws.com/int',
  URL_MYVAM: ['https://localhost/Appli/applications/espace/render/default.aspx',
              'https://localhost/Appli/applications/Publication/New%20Render/Default.aspx',
              'https://qual-myvam.vallourec.net/Appli/applications/espace/render/default.aspx',
              'https://qual-myvam.vallourec.net/Appli/applications/Publication/New%20Render/Default.aspx',
              'https://myvam.vamservices.com/Appli/applications/Publication/New%20Render/Default.aspx',
              'https://myvam.vamservices.com/Appli/applications/espace/render/default.aspx',
              'https://localhost/Appli/Applications/Publication/Search/Result/default.aspx?TypeSearch=Simple',
              'https://qual-myvam.vallourec.net/Appli/Applications/Publication/Search/Result/default.aspx?TypeSearch=Simple',
              'https://myvam.vamservices.com/Appli/Applications/Publication/Search/Result/default.aspx?TypeSearch=Simple'],
  users:  [
    {
      'login': 'ocaron_admin',
      'token': 'Y23NtkCT35heLFCg'
    },
    {
      'login': 'ocaron_ara1',
      'token': '3AEfxvE55euCrGty'
    },
    {
      'login': 'ocaron_reader1',
      'token': 'hQf3MUyXwaAjXWhz'
    },
    {
      'login': 'ocaron_ara2',
      'token': 'ULgnK5UZRVsx55hD'
    },
    {
      'login': 'ocaron_reader2',
      'token': 'XWV4gjNgwPKFweWC'
    },
    {
      'login': 'ocaron_R&D1',
      'token': 'kS6RaQLhQG7Fmq53'
    },
    {
      'login': 'ocaron_R&D2',
      'token': 'AxHXNv5JDEvbM8Qc'
    },
    {
      'login': 'ocaron_R&D3',
      'token': '9Y3BSsaJ5yGzhv2G'
    },
    {
      'login': 'aeg',
      'token': 'admin'
    },
    {
      'login': 'satrougout',
      'token': 'test'
    }
  ],
  URL_MYVAM_REDIRECT: "https://myvam.vamservices.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
