import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, empty } from 'rxjs';
import { DataPage } from './data/data-page';
import {map, catchError} from 'rxjs/operators';
import { ProductResponse } from './data/product-response';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { ServicesNamesConstants } from './services-names.constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public readonly API_PRODUCT_BASE_URL: string = environment.API_BASE_URL;
  public readonly PRODUCT: string = '/bpd_bulk/load-filter-data';
  public readonly API_BASE_LOCAL_URL: string = environment.API_BASE_LOCAL_URL;

  constructor(public http: HttpClient, public authService: AuthService, public router: Router) { }

  // search for nested data to call POO (drift_type, minwallthiknes,...)
  getNestedItems(product, octgOrNonOctg: boolean){
    console.log("octgOrNonOctg: ", octgOrNonOctg)
    return this.http.post<string[]>(
      this.API_PRODUCT_BASE_URL + this.PRODUCT,
      {product: product, octgOrNonOctg: octgOrNonOctg},
      this.authService.getAuthorization(ServicesNamesConstants.PRODUCT_GET_PRODUCTS)).pipe(
        map((response: any): any => {
          return response;
        })
        );
  }
  getAllItems(octgOrNonOctg: boolean, begin: number,
              size: number,
              params: {name: string, value: string | number}[],
              order?: {name: string, order: string}[]): Observable<DataPage> {

    if (order === undefined) {
      order = [{name: 'connection', order: 'asc'}];
    }

    return this.http.get<string[]>(
      this.API_PRODUCT_BASE_URL + this.PRODUCT,
      this.authService.getOptions(ServicesNamesConstants.PRODUCT_GET_PRODUCTS)).pipe(
        map((response: any): any => {
          const dataPage = new DataPage();
          dataPage.begin = begin;
          dataPage.size = size;
          dataPage.data = response;
          return dataPage;
        })
        ,
        catchError((err, caught) => {
          if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
          return empty();
        })
        );

  }

  getDrawingCustomerInformation(product){
    console.log({'itemToGetDrawingAndCustomerInformation': product})
    return this.http.get<string[]>(
      this.API_PRODUCT_BASE_URL + this.PRODUCT,
      this.authService.getOptions(ServicesNamesConstants.PRODUCT_GET_DRAWING_CUSTOMER_INFORMATION)).pipe(
        map((response: any): any => {
          console.log(response)
          return response;
        }),
        catchError((err, caught) => {
          if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
          return empty();
        })
        );
  }

  updateProductVisibility(body):Observable<any>{
    return this.http.put<any>(
      this.API_PRODUCT_BASE_URL + "/bpd_bulk/update-visible-products",
      {"products": body},
      this.authService.getAuthorization(ServicesNamesConstants.PRODUCT_UPDATE_PRODUCTS_VISIBILITY)).pipe(
      map((response: any): any => {
        console.log(response)
        return response;
      }),
      catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return empty();
      })
    );
  }
}
