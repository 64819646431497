import { Component, OnInit } from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'thread-compound-content',
  templateUrl: './thread-compound-content.component.html',
  styleUrls: ['./thread-compound-content.component.scss']
})
export class ThreadCompoundContentComponent implements OnInit {

  threadCompounds: any[] = [];
  isIMP = true;
  activatedDataReloading = true;


  constructor(public basicProductDataStore: BasicProductDataStoreService) { 
    this.basicProductDataStore.imperialOrSI$.subscribe(value => this.isIMP = value);
    this.basicProductDataStore.threadCompound$.subscribe(data => this.threadCompounds = data);
    this.basicProductDataStore.activatedDataReloading$.subscribe(value=> this.activatedDataReloading = value);
  }

  

  ngOnInit() {
  }

}
