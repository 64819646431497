import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {Tsli} from '../../../core/data/tsli';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'detailed-tsli-by-item-page',
  templateUrl: './tsli-by-item-page.component.html',
  styleUrls: ['./tsli-by-item-page.component.scss']
})
export class TsliByItemPageComponent implements OnInit, OnDestroy {
  public tsliSubscription: Subscription;

  constructor(public store: CurrentTsliStoreService,
              public detailedTsliStore: DetailedTsliStore,
              public router: Router) {

  }

  ngOnInit(): void {
    this.tsliSubscription = this.store.tsli$.subscribe((tsli: Tsli) => {
      if (tsli === undefined || tsli.chapters === undefined){
        // const product = localStorage.getItem('itemProduct');
        const product = sessionStorage.getItem('itemProduct');
        if (product !== undefined) {
          this.detailedTsliStore.setCurrentItem(JSON.parse(product));
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.tsliSubscription !== undefined) {
      this.tsliSubscription.unsubscribe();
    }
  }

  /**
   * Navigate to Basic Product Data,
   * behavior depends on displayed page:
   * - by item: navigate to item BPD page
   * - by connection: navigate to connection BPD page
   * - etc.
   *
   */
  navigateToBasicProductData(): void {
    this.router.navigate([`tsli/basic-product-data`]);
  }
}
