<div class="spinner" *ngIf="!activatedDataReloading">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="activatedDataReloading">
  <div class="tbl-row tbl-root"
 *ngFor="let threadCompound of threadCompounds" style="padding-left: 0;"
  [style.height.px]="40">
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{threadCompound.tcValid}}</p></div>
  <ng-container *ngIf="threadCompound.tcValid === 'Yes'">
  <div [ngSwitch]="isIMP" class="tbl-col br-lt-bt" style="flex: 1 1;"><p *ngSwitchCase="false" class="p-center"><ng-container *ngIf="threadCompound.minDopeVolSI != 0">{{threadCompound.minDopeVolSI}}</ng-container><ng-container *ngIf="threadCompound.minDopeVolSI == 0">{{'N/A'}}</ng-container></p><p *ngSwitchCase="true" class="p-center"><ng-container *ngIf="threadCompound.minDopeVolIMP != 0">{{threadCompound.minDopeVolIMP}}</ng-container><ng-container *ngIf="threadCompound.minDopeVolIMP == 0">{{'N/A'}}</ng-container></p></div>
  <div [ngSwitch]="isIMP" class="tbl-col br-lt-bt" style="flex: 1 1;"><p *ngSwitchCase="false" class="p-center"><ng-container *ngIf="threadCompound.maxDopeVolSI != 0">{{threadCompound.maxDopeVolSI}}</ng-container><ng-container *ngIf="threadCompound.maxDopeVolSI == 0">{{'N/A'}}</ng-container></p><p *ngSwitchCase="true" class="p-center"><ng-container *ngIf="threadCompound.maxDopeVolIMP != 0">{{threadCompound.maxDopeVolIMP}}</ng-container><ng-container *ngIf="threadCompound.maxDopeVolIMP == 0">{{'N/A'}}</ng-container></div>
  <div [ngSwitch]="isIMP" class="tbl-col br-lt-bt" style="flex: 1 1;"><p *ngSwitchCase="false" class="p-center"><ng-container *ngIf="threadCompound.minDopeQtySI != 0">{{threadCompound.minDopeQtySI}}</ng-container><ng-container *ngIf="threadCompound.minDopeQtySI == 0">{{'N/A'}}</ng-container></p><p *ngSwitchCase="true" class="p-center"><ng-container *ngIf="threadCompound.minDopeQtyIMP != 0">{{threadCompound.minDopeQtyIMP}}</ng-container><ng-container *ngIf="threadCompound.minDopeQtyIMP == 0">{{'N/A'}}</ng-container></p></div>
  <div [ngSwitch]="isIMP" class="tbl-col br-lt-bt" style="flex: 1 1;"><p *ngSwitchCase="false" class="p-center"><ng-container *ngIf="threadCompound.maxDopeQtySI != 0">{{threadCompound.maxDopeQtySI}}</ng-container><ng-container *ngIf="threadCompound.maxDopeQtySI == 0">{{'N/A'}}</ng-container></p><p *ngSwitchCase="true" class="p-center"><ng-container *ngIf="threadCompound.maxDopeQtyIMP != 0">{{threadCompound.maxDopeQtyIMP}}</ng-container><ng-container *ngIf="threadCompound.maxDopeQtyIMP == 0">{{'N/A'}}</ng-container></p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{threadCompound.ratioPinBox}}</p></div>
  <div class="tbl-col br-lt-bt br-rt" style="flex: 1 1;" [matTooltip]="threadCompound.comment" #tooltip="matTooltip" ><p class="p-comment p-center">{{threadCompound.comment}}</p></div>
</ng-container>
<ng-container *ngIf="threadCompound.tcValid != 'Yes' && threadCompound.tcValid != ''">
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{'N/A'}}</p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{'N/A'}}</p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{'N/A'}}</p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{'N/A'}}</p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center">{{'N/A'}}</p></div>
  <div class="tbl-col br-lt-bt br-rt" style="flex: 1 1;"><p class="p-comment p-center">{{'N/A'}}</p></div>
</ng-container>
<ng-container *ngIf="threadCompound.tcValid == ''">
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center"></p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center"></p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center"></p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center"></p></div>
  <div class="tbl-col br-lt-bt" style="flex: 1 1;"><p class="p-center"></p></div>
  <div class="tbl-col br-lt-bt br-rt" style="flex: 1 1;"><p class="p-comment p-center"></p></div>
</ng-container>
</div>

</ng-container>
