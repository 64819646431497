<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    <mat-icon color='warn'>warning</mat-icon>
    Warning, you are about to:
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-header  justify-content-center">
  <div class="row">
    <div class="col">
      <input matInput id="docTitle" [matTooltip]="docTitle" [(ngModel)]="docTitle" readonly
             style="width: 500px; text-align: center">
    </div>
    <div class="col">
      <input matInput id="docRef" [matTooltip]="docReference" [(ngModel)]="docReference" readonly>
    </div>
  </div>
</div>
<form [formGroup]="formGroup">
  <div class="modal-body">

    <mat-form-field style="width:100%; padding: 5px; margin-top: 20px" appearance="outline">
      <mat-label style="color: #03a9f4; font-size: 20px">Previous comments:</mat-label>
      <textarea id="myTextArea" matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                style="overflow: hidden;"
                formControlName="previousCommentsAreaName">
      </textarea>
    </mat-form-field>


    <mat-form-field style="width:100%; padding: 5px; margin-top: 20px" appearance="outline">
      <mat-label style="color: #03a9f4; font-size: 20px;">Reviewer / Approver comments:</mat-label>
      <textarea matInput id="newCommentsArea" formControlName="commentCtrl" required
                cdkTextareaAutosize
                #autosize1="cdkTextareaAutosize"
                style="overflow: hidden;">
      </textarea>
      <mat-error
        *ngIf="formGroup.controls.commentCtrl.invalid">{{ " Comment is required " }}</mat-error>
    </mat-form-field>
  </div>

  <div class="modal-footer justify-content-center">
    <button mat-flat-button (click)="refuseDoc()" color="primary" style="margin-right: 10px">Refuse</button>
    <button mat-flat-button (click)="sendToApproveDoc()" [disabled]="isToPublish == true && isSendToApprove == false"
            color="primary" style="margin-right: 10px">Send to Approve
    </button>
    <button mat-flat-button (click)="publishDoc()" [disabled]="isToPublish == false && isSendToApprove == true"
            color="primary">Publish
    </button>
  </div>
</form>
