<table>
    <thead>
<div class="header-space">&nbsp;</div>

    </thead>
    <tbody>

        <div class="row title">Thread-Compound </div>
        <div class="row tbl-thread">
            <div class="col-md-2 col-title"><span>Qualified Thread Compound</span></div>
            <div class="col col-title"><span>Thread Compound Validation</span></div>
            <div class="col col-title"><span>Min Dope Volume ({{unitVolume}})</span></div>
            <div class="col col-title"><span>Max Dope Volume ({{unitVolume}})</span></div>
            <div class="col col-title"><span>Min Dope Qty ({{unitQty}})</span></div>
            <div class="col col-title"><span>Max Dope Qty ({{unitQty}})</span></div>
            <div class="col col-title"><span>Ratio Pin Box</span></div>
            <div class="col col-title last"><span>Comment</span></div>
        
        </div>
        <div class="row" *ngFor="let threadCompound of threadCompounds">
            <div class="col-md-2 col-value"><span>{{threadCompound.name + ' ' +  getDensity(threadCompound.name)+' '+unit}}</span></div>
            <div class="col col-value"><span>{{threadCompound.tcValid}}</span></div>
            <ng-container *ngIf="threadCompound.tcValid === 'Yes'">
                <ng-container *ngIf="!isIMP">
                    <div class="col col-value"><span>{{threadCompound.minDopeVolSI}}</span></div>
                    <div class="col col-value"><span>{{threadCompound.maxDopeVolSI}}</span></div>
                    <div class="col col-value"><span>{{threadCompound.minDopeQtySI}}</span></div>
                    <div class="col col-value"><span>{{threadCompound.maxDopeQtySI}}</span></div>
                </ng-container>
                <ng-container *ngIf="isIMP">
                    <div class="col col-value"><span>{{threadCompound.minDopeVolIMP}}</span></div>
                    <div class="col col-value"><span>{{threadCompound.maxDopeVolIMP}}</span></div>
                    <div class="col col-value"><span>{{threadCompound.minDopeQtyIMP}}</span></div>
                    <div class="col col-value"><span>{{threadCompound.maxDopeQtyIMP}}</span></div>
                </ng-container>
                <div class="col col-value"><span>{{threadCompound.ratioPinBox}}</span></div>
                <div class="col col-value last" [matTooltip]="threadCompound.comment" #tooltip="matTooltip">
                    <span>{{threadCompound.comment}}</span></div>
            </ng-container>
            <ng-container *ngIf="threadCompound.tcValid != 'Yes'">
                <div class="col col-value"><span>{{'N/A'}}</span></div>
                <div class="col col-value"><span>{{'N/A'}}</span></div>
                <div class="col col-value"><span>{{'N/A'}}</span></div>
                <div class="col col-value"><span>{{'N/A'}}</span></div>
                <div class="col col-value"><span>{{'N/A'}}</span></div>
                <div class="col col-value last" ><span>{{'N/A'}}</span></div>
            </ng-container>
        
        </div>
    </tbody>

</table>
