import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';

import {TsliResponse} from '../../../core/data/tsli-response';
import {Tsli} from '../../../core/data/tsli';
import {ErrorBarComponent} from '../../error-bar/error-bar.component';
import {TslisStoreService} from '../../../core/tslis-store.service';
import {Status} from 'src/app/core/enum/status.enum';
import {AuthService} from 'src/app/core/auth.service';
import {User} from 'src/app/core/data/User';
import {map} from 'rxjs/operators';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {UserRestService} from 'src/app/core/user-rest.service';
import {TsliMode} from 'src/app/core/enum/tsli-mode.enum';
import {CreateArchivedDocumentComponent} from '../create-archived-document/create-archived-document.component';
import {CreateDocumentComponent} from '../create-document/create-document.component';

// import { userInfo } from 'os';

function validateZeroPadded() {
  const ZERO_PADDED_REGEXP = /^[0-9]+$/;
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isANumber = ZERO_PADDED_REGEXP.test(control.value);
    return isANumber ? null : {notANumber: true};
  };
}

@Component({
  selector: 'app-create-revision-popup',
  templateUrl: './create-revision-popup.component.html',
  styleUrls: ['./create-revision-popup.component.scss']
})
export class CreateRevisionPopupComponent implements OnInit {

  public reference: string;
  public docType: string;
  activatedDataReloading = true;
  commitFailed = false;
  isArchivedChecked: boolean;
  documentToRevise: Tsli;

  constructor(public formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public snackBar: MatSnackBar,
              public authService: AuthService,
              public currentTsliStore: CurrentTsliStoreService,
              public userRest: UserRestService,
              public modal: NgbModal,) {
  }

  ngOnInit(): void {
  }


  onCancel(): void {
    this.activeModal.dismiss();
  }

  onClose(): void {
    this.activeModal.close();
  }
  createRevisionToArchive() {
    const reviseDocument = this.modal.open(CreateArchivedDocumentComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    this.currentTsliStore.setActivatedMode(TsliMode.revisingMode);
    reviseDocument.componentInstance.documentToRevise = this.documentToRevise;

    this.onClose();
  }

  CreateRevision() {
    const reviseDocument = this.modal.open(CreateDocumentComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    this.currentTsliStore.setActivatedMode(TsliMode.revisingMode);
    reviseDocument.componentInstance.documentToRevise = this.documentToRevise;

    this.onClose();
  }
}
