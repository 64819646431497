<div class="modal-header">
  <h4 class="modal-title" id="modal-title"><mat-icon color='warn'>warning</mat-icon>  Change {{docType | uppercase}} status</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body" >
  <p><strong>  Are you sure to change <span class="text-primary">status</span> </strong></p>
  <p *ngIf="tsliType == 'product'">You have NOT completed applicability for all chapters, do you confirm Y/N.
  </p>
  <p *ngIf="tsliType == 'process'">You have NOT completed applicability for this {{docType | uppercase}}, do you confirm Y/N.
  </p>
</div>



<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="dismiss()">No</button>
  <button  mat-flat-button color='warn' (click)="confirm()">Yes</button>
</div>