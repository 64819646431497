<div class="container-fluid d-flex flex-column mt-3 mat-typography" style="height: 100%" *ngIf="auth.login$ | async">
  <div class="row">
    <div class="col-12">
      <h2 class="mat-headline">Hello {{ auth.firstName$ | async }} !</h2>
      <h3 class="mat-title">What do you want to do ?</h3>
      <button mat-stroked-button style="width: 100%" (click)="createDocument()">Create a Document</button>
      <button mat-stroked-button class="mt-3" style="width: 100%" (click)="createArchivedDocument()">Create Archived
        Document
      </button>
      <button mat-stroked-button class="mt-3" style="width: 100%" (click)="editDocument()">Edit a Document</button>
      <button mat-stroked-button (click)="validateDocument()" class="mt-3" style="width: 100%"
              [disabled]="!((auth.user$ | async)?.isReviewer || (auth.user$ | async)?.isApprover || (auth.user$ | async)?.isAdmin )">
        Validate a Document
      </button>
    </div>
  </div>
  <notifications-panel></notifications-panel>
  <div class="row mt-3">
    <div class="col">
      <h3 class="mat-title">Activity</h3>
      <!-- <p class="mat-small" style="text-align: right; text-decoration: underline">View all activity</p> -->
    </div>
  </div>

  <div class="row flex-fill" style="padding-bottom: 15px;">
    <div class="col d-flex flex-column">
      <div class="activity-content p-3">
        <p *ngFor="let activity of activities;" class="mat-body">Your
          <ng-container *ngIf="activity.filename != undefined ">
            <a href="#" (click)="download(activity.filename, activity.tsli.docType, activity.tsli.reference)">
              {{activity.tsli.docType | uppercase}} {{activity.tsli.reference}}</a> rev. {{ activity.tsli.revision }}
            <span>
            {{ activity.event }}
            </span>
          </ng-container>
          <ng-container *ngIf="activity.filename == undefined ">
            <a href="/tsli/by-reference/{{activity.tsli.docType}}/{{activity.tsli.reference}}">
              {{activity.tsli.docType | uppercase}} {{activity.tsli.reference}}</a> rev. {{ activity.tsli.revision }}
            <span>
            {{ activity.event }}
            </span>
          </ng-container>
        </p>
      </div>
    </div>
  </div>

</div>
