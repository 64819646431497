import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {BasicProductDataStoreService} from '../../../core/basic-product-data-store.service';
import {Product} from '../../../core/data/product';

@Component({
  selector: 'surface-treatment-content',
  templateUrl: './surface-treatment-content.component.html',
  styleUrls: ['./surface-treatment-content.component.scss']
})
export class SurfaceTreatementContentComponent {

  surfaceTreatments: any[] = [];
  activatedDataReloading = true;
  constructor(public basicProductDataStore: BasicProductDataStoreService) {
    this.basicProductDataStore.activatedDataReloading$.subscribe(value=> this.activatedDataReloading = value);
    this.basicProductDataStore.surfaceTreatments$.subscribe((st: any[]) => {

      /*

          Fetch surface treatments here

       */

      this.surfaceTreatments = st;

    });
    
  }
}
