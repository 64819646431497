import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fraction'
})
export class FractionPipe implements PipeTransform {
  public readonly mappingFractionDecimal: any[] = [
    {fraction: '1/16"', decimal: 0.0625},
    {fraction: '1/8"', decimal: 0.125},
    {fraction: '3/16"', decimal: 0.1875},
    {fraction: '1/4"', decimal: 0.25},
    {fraction: '5/16"', decimal: 0.3125},
    {fraction: '3/8"', decimal: 0.375},
    {fraction: '7/16"', decimal: 0.4375},
    {fraction: '1/2"', decimal: 0.5},
    {fraction: '9/16"', decimal: 0.5625},
    {fraction: '5/8"', decimal: 0.625},
    {fraction: '11/16"', decimal: 0.6875},
    {fraction: '3/4"', decimal: 0.75},
    {fraction: '13/16"', decimal: 0.8125},
    {fraction: '7/8"', decimal: 0.875},
    {fraction: '15/16"', decimal: 0.9375},
  ];

  transform(value: number): any {
    const decimal = value - Math.floor(value);
    const fractionDecimal = this.mappingFractionDecimal.filter( frc => frc.decimal === decimal);
    return fractionDecimal.length > 0 ? Math.floor(value) + ' ' + fractionDecimal[0].fraction : this.addDecimal(value);
  }

  addDecimal(value){
    let strinValue = ""+value;
    if(strinValue.includes('.')){
      let decimalPart = strinValue.split('.')[1];

      for (let i = 0; i < 3-decimalPart.length; i++) {
        strinValue = strinValue + 0;
      }
      return strinValue + ' "';
    }
    else
    return value + '.000 "';
  }

}
