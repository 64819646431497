import { Component, OnInit } from '@angular/core';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { CommentsStoreService } from 'src/app/core/comments-store.service';
import { Comment } from 'src/app/core/data/comment';
import { AuthService } from 'src/app/core/auth.service';
import { Chapter } from 'src/app/core/data/chapter';
import { Subscription } from 'rxjs';


@Component({
  selector: 'general-comments',
  templateUrl: './general-comments.component.html',
  styleUrls: ['./general-comments.component.scss']
})
export class GeneralCommentsComponent implements OnInit {

  tsliComments = [];
  inputComment: Comment;
  activateClear = false;
  public subscription : Subscription;

  constructor(public currentTsliStore: CurrentTsliStoreService,
    public authService: AuthService,
     public commentsStore: CommentsStoreService) { }

  ngOnInit() {
    this.commentsStore.getAllComments(this.currentTsliStore.getCurrentTsliId())
    this.inputComment = new Comment();
    this.inputComment.comment = '';
    this.currentTsliStore.tsli$.subscribe(value=>{
      this.inputComment.tsli = value;
    });
    this.authService.user$.subscribe(value=>{
      this.inputComment.commentator = value;
    });
    this.subscription = this.commentsStore.comments$.subscribe(comments=>{
      this.activateClear = !(comments.length > 0);
      let filtered =  comments.filter(comment=> {return comment.tsli.idTsli == this.currentTsliStore.getCurrentTsliId() && comment.chapter.id_chapter == undefined });
      this.tsliComments = filtered;
      this.currentTsliStore.setHasComment(this.tsliComments.length > 0)
    })

  }

  clearAllComments(){
    this.commentsStore.clearComments(this.currentTsliStore.getCurrentTsliId(), undefined, false);
  }


  clearComments(){
    this.commentsStore.clearComments(this.currentTsliStore.getCurrentTsliId(), undefined, true);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
