import {Component, OnInit, Input} from '@angular/core';
import {BasicProductDataStoreService} from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'thread-compound-header',
  templateUrl: './thread-compound-header.component.html',
  styleUrls: ['./thread-compound-header.component.scss']
})


export class ThreadCompoundHeaderComponent implements OnInit {

  @Input()
  forPdf: boolean = false;
  unit = 'g/cm³';
  unitVolume = 'cm³';
  unitQty = 'g';
  threadCompounds = [];

  constructor(public basicProductDataStore: BasicProductDataStoreService) {
    this.basicProductDataStore.threadCompoundHeader$.subscribe(value => {
      this.threadCompounds = value;
    });
    this.basicProductDataStore.imperialOrSI$.subscribe(value => {
      if (value) {
        this.unit = 'oz/in³';
        this.unitVolume = 'in³';
        this.unitQty = 'oz';
      } else {
        this.unit = 'g/cm³';
        this.unitVolume = 'cm³';
        this.unitQty = 'g';
      }

    });
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    let element = document.getElementById('threadCompoundTab');
    if (element != null) {
      let elements = element.getElementsByTagName('mat-tab-header');
      elements[0].classList.add('mat-tab-header-pagination-controls-enabled');
    }
  }

  switchSelectedTab(event) {
    this.basicProductDataStore.switchThreadCompound(event.index);
  }

}
