<div id="header" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col">
      <h3 class="mat-subheading-2 mt-3" *ngIf="isEditionMode">{{TITLE_BY_ITEM}}</h3>
      <h3 class="mat-subheading-2 mt-3" *ngIf="!isEditionMode">{{TITLE_BY_CONNECTION}}</h3>
      <div id="disclaimer" class="mat-body mb-3 bpd-legacy-disclaimer" [innerHTML]="TITLE_DISCLAIMER"></div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="text-right" *ngIf="isEditionMode">
        <button mat-flat-button class="mr-1" color="primary" [disabled]="!isAddEnabled" (click)="addToList()">Add to
          list
        </button>
        <button mat-flat-button class="mr-1" color="primary" [disabled]="!isClearEnabled" (click)="clearList()">Clear
          list
        </button>
        <button mat-flat-button class="mr-1" color="primary" [disabled]="!isSaveEnabled" (click)="saveList()">Save
          list
        </button>
      </div>
    </div>
    <div class="col">
      <div class="text-right">
        <button mat-button class="mr-1" [matMenuTriggerFor]="menu" #t="matMenuTrigger">Units
          <mat-icon *ngIf="!t.menuOpen">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="t.menuOpen">keyboard_arrow_up</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <div class="pl-3 pr-3">
            SI
            <mat-slide-toggle [checked]="checked" color="primary" (change)="switchImperialOrSI()"
                              (click)="$event.stopPropagation()">Imperial
            </mat-slide-toggle>
          </div>
        </mat-menu>
<!--        <button mat-raised-button color="primary" [disabled]="!isClearEnabled" (click)="downloadBpdByProduct()">-->
<!--          Download all products-->
<!--        </button>-->
        <button mat-raised-button color="primary" [disabled]=true (click)="downloadBpdByProduct()">
          Download all products
        </button>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="row">
      <div class="col text-left">
        Select your grade type:
      </div>
    </div>
    <div class="col pl-3 pr-3">
      OCTG
      <mat-slide-toggle [checked]="!isOCTG" color="primary"
                        (change)="switchOctgNonOctg()" (click)="$event.stopPropagation()">Mechanical
      </mat-slide-toggle>
    </div>
    <div class="col text-right">
      <mat-paginator [disabled]="!activatedPagination"
                     *ngIf="!isEditionMode"
                     [length]="basicProductDataStore.productsOverallCount$ | async"
                     [pageSize]="basicProductDataStore.pageSize$ | async"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     [pageIndex]="basicProductDataStore.pageIndex$ | async"
                     (page)="updateProducts($event)"></mat-paginator>
    </div>
  </div>
</div>
