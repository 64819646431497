import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Tsli} from '../../core/data/tsli';
import {ErrorBarComponent} from '../error-bar/error-bar.component';
import {TslisStoreService} from '../../core/tslis-store.service';
import {TsliRestService} from '../../core/tsli-rest.service';
import {Status} from 'src/app/core/enum/status.enum';
import {AuthService} from 'src/app/core/auth.service';
import {User} from 'src/app/core/data/User';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {UserRestService} from 'src/app/core/user-rest.service';
import {TsliMode} from 'src/app/core/enum/tsli-mode.enum';
@Component({
  selector: 'app-modify-date-validation-popup',
  templateUrl: './modify-date-validation-popup.component.html',
  styleUrls: ['./modify-date-validation-popup.component.scss']
})
export class modifyDateValidationPopupComponent implements OnInit {

  thirdFormGroup: FormGroup;
  public reference: string;
  activatedDataReloading = true;
  typeDescriptionText: string;
  idTsli: number;
  idUser: string;
  errorMsg: string = '';
  commitSuccessful = false;
  reviewers: User[];
  approvers: User[];
  idCurrentTsliForRevision: string;
  commitFailed = false;
  selectedReviewer;
  selectedApprover;
  document: Tsli;
  public readonly TYPE_DESCRIPTION_TEXT: object = {
    process: 'Lorem process ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    product: 'Lorem product ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    general_information: 'Lorem gi ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
  };

  constructor(public formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public rest: TsliRestService,
              public snackBar: MatSnackBar,
              public authService: AuthService,
              public currentTsliStore: CurrentTsliStoreService,
              public userRest: UserRestService) {

  }

  ngOnInit(): void {

    this.thirdFormGroup = this.formBuilder.group({
      applicationDateCtrl: ['', Validators.required],
      approverSelectCtrl: ['', Validators.required],
      reviewerSelectCtrl: ['', Validators.required]
    });
    // console.log('date & validation idTsli:', this.idTsli);
    // this.userRest.getReviewers(this.idTsli).subscribe((usersResponse: User[]) => {
    //   this.reviewers = usersResponse.filter(user => user.isReviewer);
    // });
    this.userRest.getUsers().subscribe((usersResponse: User[]) => {
      this.approvers = usersResponse.filter(user => user.isApprover);
    });
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  onClose(): void {
    this.activeModal.close();
  }

  commitTsli(): void {
    this.activatedDataReloading = false;
    const tsli: Tsli = new Tsli();
    tsli.applicationDate = this.thirdFormGroup.controls.applicationDateCtrl.value;
    tsli.reviewer = new User();
    tsli.reviewer.id = this.thirdFormGroup.controls.reviewerSelectCtrl.value;
    tsli.approver = new User();
    tsli.approver.id = this.thirdFormGroup.controls.approverSelectCtrl.value;
    tsli.status = Status.WRITING;
    this.authService.user.subscribe(user => {
      tsli.author = user;
    });
    tsli.reference = this.reference;
    this.tslisStore.modifyDateValidation(this.idTsli, tsli.applicationDate, tsli.reviewer.id, tsli.approver.id).subscribe(
      (Reponse: string) => {
        this.commitSuccessful = true;
        this.idCurrentTsliForRevision = this.idTsli.toString();
        this.commitSuccessful = true;

        this.activatedDataReloading = true;
      },
      (error: any) => {

        console.log(error);
        this.displayError('An HTTP error occurred! ', error.status, error.statusText);
        this.errorMsg = error;
        this.commitFailed = true;
        this.activatedDataReloading = true;
      }
    );
  }

  onValid() {
    this.currentTsliStore.setActivatedMode(TsliMode.simpleEditionMode);
    this.currentTsliStore.openTsli(this.idCurrentTsliForRevision);
    this.activeModal.close();

  }

  displayError(msg: string,
               statusCode: string,
               statusText: string) {
    this.snackBar.openFromComponent(ErrorBarComponent, {
      duration: 5000,
      data: {
        msg,
        statusCode,
        statusText
      }
    });
  }

}
