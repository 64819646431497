import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Comment } from './data/comment';
import { Router } from '@angular/router';
import { CommentsRestService } from './comments-rest.service';
import { CurrentTsliStoreService } from './current-tsli-store.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentsStoreService {
  public  comments = new BehaviorSubject<Comment[]>([]);
  readonly  comments$ = this.comments.asObservable();

  constructor(public router: Router,
    public commentrest: CommentsRestService) { 
      
    }

    addComment(addComment: Comment) {
       this.commentrest.addComment(addComment).subscribe((response: any) => {
        this.comments.next(response);
      });
    }

    getAllComments(tsli){
      this.commentrest.getAllComments(tsli).subscribe(response=>{
        this.comments.next(response);
      })
    }

    clearComments(id_tsli, id_chapter, onlyTsli=true){
      //call rest service to delete comments then reload comments
    this.commentrest.deleteComments(id_tsli, id_chapter, onlyTsli).subscribe(resp=>{
      this.comments.next(resp);
    });
    //
    
    }

    updateComment(id_comment, value){
      this.commentrest.updateComment(id_comment, value).subscribe(resp=>{
        console.log(resp)
      })
    }
}
