import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

export interface ExampleExpression {
  value: string;
  name: string;
}

@Component({
  selector: 'app-mathjax',
  templateUrl: './mathjax.component.html',
  styleUrls: ['./mathjax.component.scss']
})
export class MathjaxComponent implements OnInit {
  @Output('insertMathExpression') insertMathExpression = new EventEmitter();
  @ViewChild('mathContent', { static: true }) mathContent: ElementRef;

  expression: string;
  expressionToParse: SafeHtml;
  mathJax: any;

  readonly examples: ExampleExpression[] = [
    {
      name: 'Greek letters',
      value: '$$\\alpha, \\beta, \\gamma, \\Gamma, \\pi, \\Pi, \\phi, \\varphi, \\mu, \\Phi, \\Delta, \\Sigma$$'
    },
    {
      name: 'Normal law',
      value: '$$f(x) = \\frac1{\\sigma \\sqrt{2\\pi}}\\operatorname e^{-\\frac12\\left(\\frac{x-\\mu}{\\sigma}\\right)^2}$$'
    },
    {
      name: 'Euler formula',
      value: '$$e^{ix} = \\cos x + i\\sin x,$$'
    },
    {
      name: 'Fourier transform',
      value: '$$\\hat{f}(\\xi) = \\int_{-\\infty}^{\\infty} f(x)\\ e^{-2\\pi i x \\xi}\\,dx,$$'
    },
    {
      name: 'Rendering equation',
      value: '$$L_{\\text{o}}(\\mathbf x,\\, \\omega_{\\text{o}},\\, \\lambda,\\, t) \\,=\\,' +
        ' L_e(\\mathbf x,\\, \\omega_{\\text{o}},\\, \\lambda,\\, t) \\ +\\, \\int_\\Omega f_r(\\m' +
        'athbf x,\\, \\omega_{\\text{i}},\\, \\omega_{\\text{o}},\\, \\lambda,\\, t)\\, L_{\\text{i}}' +
        '(\\mathbf x,\\, \\omega_{\\text{i}},\\, \\lambda,\\, t)\\, (\\omega_{\\text{i}}\\,\\cdot\\,' +
        '\\mathbf n)\\, \\operatorname d \\omega_{\\text{i}}$$'
    },
    {
      name: 'Ricker wavelet',
      value: '$$\\psi(t) = \\frac{2}{\\sqrt{3\\sigma}\\pi^{1/4}} \\left(1 - \\le' +
        'ft(\\frac{t}{\\sigma}\\right)^2 \\right) e^{-\\frac{t^2}{2\\sigma^2}}$$'
    }
  ];

  constructor(public activeModal: NgbActiveModal,
              public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-string-literal
    this.mathJax = window['MathJax'];
    this.mathJax.Hub.Config({
      showMathMenu: false,
      tex2jax: {inlineMath: [['$', '$']], displayMath: [['$$', '$$']]},
      jax: ['input/TeX', 'output/SVG'],
      SVG: {useFontCache: false, useGlobalCache: false}
    });
  }

  examplePicked(example: any): void {
    this.expression = example;
    this.expressionChanged();
  }

  expressionChanged(): void {
    var regex = /<script[^]*<\/script>/gi;
    var regexEvent = /on[^]*>/gi;
    var regexUnicode = /unicode[ ]*{[^]*}/gi;
    var regexHref = /<a[^]*>[^]*<\/a>/gi;
    console.log(this.expression)
    this.expression = this.expression.replace(regex, '')
    this.expression = this.expression.replace(regexEvent, '>')
    this.expression = this.expression.replace(regexUnicode, '')
    this.expression = this.expression.replace(regexHref, '')
    this.expressionToParse = this.sanitizer.bypassSecurityTrustHtml(this.expression);
    setTimeout(() => {
      this.mathJax.Hub.Queue(['Typeset', this.mathJax.Hub], 'mathContent');
    }, 200);
  }

  emitInsertMathExpression(): void {
    let src: string;
    let href: string;

    src = this.svgToBase64();
    href = '';
    this.insertMathExpression.emit({src, href});
    this.cancel();
  
  }

  openTexSyntax(): void {
    window.open('https://en.wikibooks.org/wiki/LaTeX/Mathematics#Symbols');
  }

  cancel(): void {
    this.activeModal.close();
  }

  svgToBase64() {
          var mjOut = document.getElementsByClassName('MathJax_SVG_Display').item(0).getElementsByTagName("svg")[0];
          mjOut.setAttribute("xmlns", "http://www.w3.org/2000/svg");
          mjOut.setAttribute('width', '768');
          let svg = mjOut.outerHTML;

         let src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svg)));
            
      return src;
  }
}
