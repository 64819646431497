import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'editvalue',
  templateUrl: './editvalue.component.html',
  styleUrls: ['./editvalue.component.scss']
})
export class EditvalueComponent implements OnInit {

  isEditEnable = false;
  oldValue;
  @Input() value;
  @Output('validate') validate = new EventEmitter();
  valueFormGroup: FormGroup;


  constructor(public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.valueFormGroup = this.formBuilder.group({
      valueCtrl: ['']
    });
  }

  edit(){
    this.oldValue = this.value;
    this.isEditEnable = true;
  }

  save(){
    this.validate.emit({ value: this.value });
    this.isEditEnable = false;
  }

  cancel(){
    this.value = this.oldValue;
    this.isEditEnable = false;
  }
}
