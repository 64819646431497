import { Component, OnInit } from '@angular/core';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import { Router } from '@angular/router';
import { ApplicabilityService } from 'src/app/core/applicability.service';


@Component({
  selector: 'detailed-tsli-by-connection',
  templateUrl: './tsli-by-connection.component.html',
  styleUrls: ['./tsli-by-connection.component.scss']
})
export class TsliByConnectionComponent implements OnInit {

  connections = ['VAM® 21',
    'VAM® 21 HT',
    'VAM TOP ®',
    'vam_top_hc',
    'vam_top_ht',
    'vam_httc',
    'vam_slij_II',
    'VAM® SLIJ-3',
    'vam_bolt',
    'vam_bolt_II',
    'vam_fjl',
    'vam_hp',
    'vam_dwc_c',
    'vam_dwc_c_plus',
    'vam_dwc_c_is',
    'vam_dwc_c_is_plus'
  ];
  connectionLogo = {
    'VAM TOP ®': 'vam_top.png',
    'VAM TOP ® HC': 'vam_top_hc.png',
    'VAM TOP ® HT': 'vam_top_ht.png',
    'VAM® 21': 'vam_21.png',
    'VAM® 21 HT': 'vam_21ht.png',
    'VAM® 21 HW': 'vam_21hw.png',
    'VAM® SLIJ-3': 'vam_slij_3.png',
    'VAM® SLIJ-3-NA': 'vam_slij_3.png',
    'VAM TOP ® FE': 'vam_top_fe.png',
    'VAM® SLIJ-II': 'vam_slij_II.png',
    'VAM® HTTC': 'vam_httc.png',
    'DINO VAM': 'dino_vam.png',
    // 'NEW VAM': '',
    'VAM® BOLT': 'vam_bolt.png',
    'VAM® BOLT II': 'vam_bolt_II.png',
    'VAM® EDGE SF': 'vam_edge_sf.png',
    'VAM® FJL': 'vam_fjl.png',
    'VAM® FPO': 'vam_fpo.png',
    'VAM® HP': 'vam_hp.png',
    'VAM® HTF-NR': 'vam_htf_nr.png',
    'VAM® LOX': 'vam_lox.png',
    'VAM® MUST': 'vam_must.png',
    'VAM® SG': 'vam_sg.png',
    'VAM® SPRINT-FJ': 'vam_sprint_fj.png',
  }

  constructor(public store: DetailedTsliStore,
    public applicabilityRest:ApplicabilityService,
    public router: Router) { }

  ngOnInit() {
    this.connections = [];
    this.applicabilityRest.getItemValues({item: 'product', parents: [], fromFilter: true })
    .subscribe(resp => {
      this.connections = resp.slice();
      this.connections = this.connections.sort();
    });

  }

  showTSLI(connection): void {
    this.router.navigate([`/tsli/by-connection/${connection}`])
  }
}
