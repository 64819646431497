<div class="row">
  <div class="col">
    Please select the process of your choice below
   </div>
</div>
<div class="row cards">
  <div class="col-md-3 theme-card" *ngFor="let theme of themes">
    <mat-card class="card" (click)="navigate(theme.key)">
      <img mat-card-image src="assets/img/process/{{images[theme.key]}}" alt="Placeholder image">
      <mat-card-content>
        <p>
          {{theme.process}}
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
