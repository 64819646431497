import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, combineLatest} from 'rxjs';
import {Tsli} from './data/tsli';
import {Chapter} from './data/chapter';
import {Router} from '@angular/router';
import {TsliRestService} from './tsli-rest.service';
import {TsliResponse} from './data/tsli-response';
import {Status} from './enum/status.enum';
import {AuthService} from './auth.service';
import {TsliMode} from './enum/tsli-mode.enum';
import {ConfigurationService} from './configuration.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentTsliStoreService {

  public readonly tsli = new BehaviorSubject<Tsli>(new Tsli());
  public readonly isByItem = new BehaviorSubject<boolean>(false);
  public readonly summaryPageCount = new BehaviorSubject<number>(0);
  public readonly contentPageCount = new BehaviorSubject<number>(0);
  public readonly chaptesSummary = new BehaviorSubject<Map<string, number>>(new Map());
  readonly tsli$ = this.tsli.asObservable();
  readonly isByItem$ = this.isByItem.asObservable();
  readonly summaryPageCount$ = this.summaryPageCount.asObservable();
  readonly contentPageCount$ = this.contentPageCount.asObservable();
  readonly chaptesSummary$ = this.chaptesSummary.asObservable();


  public readonly activatedMode = new BehaviorSubject<TsliMode>(undefined);
  readonly activatedMode$ = this.activatedMode.asObservable();

  //mode : string;
  public listchaptersCurrent: string[] = [];
  public tmpListchaptersCurrent: string[] = [];


  firstEntry: string;
  theme: string;

  selectedChaptersInfos: { idChapter: string, selected: boolean }[] = [];

  selectedChapters = {};

  mrHelp = '';
  subscriptions = [];
  editionRoutes = {
    'SIMPLE_EDITION_MODE': 'tsli/chapters-list/edit/',
    'ADMIN_EDITION_MODE': 'tsli/chapters-list/adminedit/',
    'REVIEWING_MODE': 'tsli/chapters-list/review/',
    'APPROVING_MODE': 'tsli/chapters-list/approve/',
    'APPLICABILITY_EDITION_MODE': 'tsli/chapters-list/applicabilityedit/',
    'THEME_EDITION_MODE': 'tsli/chapters-list/themeedit/'
  };

  constructor(public router: Router,
              public rest: TsliRestService,
              public authService: AuthService,
              public config: ConfigurationService) {

    /*
    this.subscriptions.push(this.config.configurations$.subscribe(cf=> {
      this.mrHelp = cf.filter(c=>c.property == "mr_help")[0].value;
    }));
    */

  }

  addToListchaptersCurrent() { // chapters
    this.listchaptersCurrent.push(...this.tmpListchaptersCurrent);
    this.tsli.value.chapters.filter(c => this.tmpListchaptersCurrent.includes(c.id_chapter)).forEach(c => c.applicability = true);
    this.tsli.next(this.tsli.getValue());
    this.clearTmpListCurrentChapters();
  }

  addToTmpListCurrentChapters(chapter: string[]) { // chapters
    this.tmpListchaptersCurrent.push(...chapter);
  }

  clearlistCurrentChapters() {
    this.listchaptersCurrent = [];
  }

  clearTmpListCurrentChapters() {
    this.tmpListchaptersCurrent = [];
  }


  getListCurrentChapters(): any { // chapters
    return this.listchaptersCurrent;
  }

  getTmpListCurrentChapters(): any { // chapters
    return this.tmpListchaptersCurrent;
  }

  addChapter(addedChapter: Chapter) {
    let tsliTemporary = new Tsli();
    tsliTemporary = JSON.parse(JSON.stringify(this.tsli.getValue()));
    tsliTemporary.chapters.push(addedChapter);
    if (this.activatedMode.getValue() != TsliMode.adminEditoionMode) {
      this.rest.updateTsli(tsliTemporary, true).subscribe((response: TsliResponse) => {
        this.tsli.next(response.tsli);
      });
    } else {
      this.tsli.next(tsliTemporary);
    }
  }

  addChaptersWhenTsliCreated(document: Tsli, chapters: Chapter[]) {
    document.chapters = chapters;
    document = JSON.parse(JSON.stringify(document));
    // if (this.rest.isHTMLDocument(document)) {
    //   this.rest.updateHTMLDocument(document, false).subscribe((response: TsliResponse) => {
    //     this.tsli.next(response.tsli);
    //   });
    // } else {
    this.rest.updateTsli(document, true).subscribe((response: TsliResponse) => {
      this.tsli.next(response.tsli);
    });
    // }
  }

  removeChapter(removedChapter: Chapter) {
    let tsliTemporary = new Tsli();
    tsliTemporary = JSON.parse(JSON.stringify(this.tsli.getValue()));

    if (this.tsli.getValue().chapters.indexOf(removedChapter) !== -1) {
      var chapterIndex = removedChapter.index + 1;
      if (chapterIndex < this.tsli.getValue().chapters.length) {
        if (this.tsli.getValue().chapters[chapterIndex].depth > removedChapter.depth) {
          tsliTemporary.chapters[chapterIndex].depth--;
          tsliTemporary.chapters[chapterIndex].index--;
          for (let i = chapterIndex + 1; i < this.tsli.getValue().chapters.length; i++) {
            if (this.tsli.getValue().chapters[chapterIndex].index == chapterIndex && this.tsli.getValue().chapters[i].depth - 1 > this.tsli.getValue().chapters[chapterIndex].depth) {
              tsliTemporary.chapters[i].depth--;
            }
            if (this.tsli.getValue().chapters[i].index > removedChapter.index) {
              tsliTemporary.chapters[i].index--;
            }
          }
        } else {
          tsliTemporary.chapters[chapterIndex].index--;
          for (let i = chapterIndex + 1; i < this.tsli.getValue().chapters.length; i++) {
            tsliTemporary.chapters[i].index--;
          }
        }
      }
      ;

      tsliTemporary.chapters.splice(this.tsli.getValue().chapters.indexOf(removedChapter), 1);
      if (this.activatedMode.getValue() != TsliMode.adminEditoionMode) {
        this.rest.updateTsli(tsliTemporary, true).subscribe((response: TsliResponse) => {
          this.tsli.next(response.tsli);
        });
      } else {
        this.tsli.next(tsliTemporary);
      }
    } else {
      console.warn('cannot remove unknown Chapter');
    }
  }

  openTsli(idTsli: string, replaceUrl = false) {
    //this.mode = action;
    combineLatest(this.rest.getTsliById(idTsli), this.rest.getAllTslis(), (tsliResp, published) => ({tsliResp, published}))
      .subscribe(resp => {
        let published = resp.published.tslis.filter(tsli => tsli.status == Status.PUBLISHED);
        this.tsli.next(this.processChapters(resp.tsliResp.tsli, published));
        resp.tsliResp.tsli.chapters.forEach(chapter => {
          this.selectedChaptersInfos.push({
            idChapter: chapter.id_chapter,
            selected: false
          });
        });
        this.isByItem.next(false);

        if (this.editionRoutes[this.activatedMode.getValue()] != undefined) {
          this.router.navigate([this.editionRoutes[this.activatedMode.getValue()] + idTsli], {replaceUrl: replaceUrl});
        }
      });
  }

  RedirectToEditTsli(idTsli: string) {
    // this.router.navigate([`tsli/chapters-list/edit/${idTsli}`])
    this.openTsli(idTsli);
  }

  openTsliForPdf(idTsli: string) {
    combineLatest(this.rest.getTsliById(idTsli), this.rest.getAllTslis(), (tsliResp, published) => ({tsliResp, published}))
      .subscribe(resp => {
        let published = resp.published.tslis.filter(tsli => tsli.status == Status.PUBLISHED);
        this.tsli.next(this.processChapters(resp.tsliResp.tsli, published));
        resp.tsliResp.tsli.chapters.forEach(chapter => {
          this.selectedChaptersInfos.push({
            idChapter: chapter.id_chapter,
            selected: false
          });
        });
        this.isByItem.next(false);
      });
  }

  processChapters(tsli: Tsli, published) {
    var regex = /token=\w*"/gi;
    // var paraReg = /(\?)token=\w*"/gi;
    let reg = /<a href="\/tsli\/by-reference\/(nt|rtli|tsli|tssu|form|inor|ug|)\/[a-zA-Z0-9\-]*">[ a-zA-Z0-9\-]*<\/a>/gi;
    let regexMrHelp = /<a href=".+?">\w*\s*[mM][rR]\s*\.*\s*[hH]+[eE]+[lL]+[pP]+\s*\w*<\/a>/gi;

    tsli.chapters.forEach(chapter => {
      chapter.body = chapter.body.replace(regex, 'token=' + this.authService.getToken() + '\"');
      let founds = chapter.body.match(reg);
      let refs = [];
      if (founds != undefined) {
        founds.forEach(found => {
          let ref = found.replace(/<a href="\/tsli\/by-reference\/(nt|rtli|tsli|tssu|form|inor|ug|)\/[a-zA-Z0-9\-]*">/, '');
          refs.push(ref.replace(/<\/a>/, ''));
        });
      }
      refs.forEach(ref => {
        let docAndRef = ref.split(' ');
        if (published.filter(tsli => tsli.docType == docAndRef[0].toLowerCase() && tsli.reference == docAndRef[1]).length === 0) {
          let refUrl = '<a href="/tsli/by-reference/' + docAndRef[0].toLowerCase() + '/' + docAndRef[1] + '">' + docAndRef[0] + ' ' + docAndRef[1] + '</a>';
          chapter.body = chapter.body
            .replace(refUrl, docAndRef[0] + ' ' + docAndRef[1]);
        }
      });
      /*find Mr. Help in chapters
      let foundsMrHelp = chapter.body.match(regexMrHelp);
      if (foundsMrHelp != undefined) {
        foundsMrHelp.forEach(foundsMrHelp => {
          let mrHelpUrl = '<a href="' + this.mrHelp + '">Mr. Help</a>';
          console.log(mrHelpUrl);
          //chapter.body = chapter.body.replace(regexMrHelp, mrHelpUrl);
        });
      }*/

    });
    return tsli;
  }

  viewTsli(docType: string, reference: string) {
    combineLatest(this.rest.getTsliByReference(docType, reference, true), this.rest.getAllTslis(), (tsliResp, published) => ({
      tsliResp,
      published
    }))
      .subscribe(resp => {
        let published = resp.published.tslis.filter(tsli => tsli.status == Status.PUBLISHED);
        this.tsli.next(this.processChapters(resp.tsliResp.tsli, published));
        this.isByItem.next(false);

      });
  }

  getTsliById(tsliId: string) {
    combineLatest(this.rest.getTsliById(tsliId), this.rest.getAllTslis(), (tsliResp, published) => ({tsliResp, published}))
      .subscribe(resp => {
        let published = resp.published.tslis.filter(tsli => tsli.status == Status.PUBLISHED);
        this.tsli.next(this.processChapters(resp.tsliResp.tsli, published));
        this.isByItem.next(false);

      });

    // this.rest.getTsliById(tsliId).subscribe((response: TsliResponse) => {
    //   this.tsli.next(response.tsli);
    //   this.isByItem.next(false);
    // });
  }


  saveChapter(chapterToSave: Chapter): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const savedChapter: Chapter = this.tsli.getValue().chapters.find((item: Chapter) => item.index === chapterToSave.index);
      savedChapter.title = chapterToSave.title;
      savedChapter.body = chapterToSave.body;
      savedChapter.index = chapterToSave.index;
      savedChapter.depth = chapterToSave.depth;
      savedChapter.titleStyle = chapterToSave.titleStyle;
      this.rest.updateTsli(this.tsli.getValue(), true).subscribe((response: TsliResponse) => {
        //  this.tsli.next(response.tsli);
        resolve(true);
      });
    });
  }

  updateTsli(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.rest.updateTsli(this.tsli.getValue(), true).subscribe((response: TsliResponse) => {
        resolve(true);
      });
    });
  }

  getChaptersLength(): number {
    if (this.tsli.getValue().chapters.length > 0) {
      return this.tsli.getValue().chapters.length;
    } else {
      return 0;
    }
  }

  getLastChapterDepth(): number {
    if (this.tsli.getValue().chapters.length > 0) {
      return this.tsli.getValue().chapters[this.tsli.getValue().chapters.length - 1].depth;
    } else {
      return -1;
    }

  }

  getLastChapterNumber(): string {
    if (this.tsli.getValue().chapters.length > 0) {
      return this.tsli.getValue().chapters[this.tsli.getValue().chapters.length - 1].number;
    } else {
      return '';
    }

  }

  /*
      Swap Chapter at chapterIndex
      with next chapter
      depth is not taken into account
   */
  chaptersPermutation(chapterIndex: number): void {
    let tsliTemporary: Tsli;
    tsliTemporary = JSON.parse(JSON.stringify(this.tsli.getValue()));
    const nextChapter: Chapter = tsliTemporary.chapters[chapterIndex + 1];
    const currentChapterDepth = tsliTemporary.chapters[chapterIndex].depth;
    const nextChapterDepth = nextChapter.depth;
    tsliTemporary.chapters[chapterIndex + 1] = tsliTemporary.chapters[chapterIndex];
    tsliTemporary.chapters[chapterIndex] = nextChapter;
    tsliTemporary.chapters[chapterIndex + 1].index++;
    tsliTemporary.chapters[chapterIndex].index--;
    tsliTemporary.chapters[chapterIndex + 1].depth = nextChapterDepth;
    tsliTemporary.chapters[chapterIndex].depth = currentChapterDepth;

    // emit changes to View
    if (this.activatedMode.getValue() != TsliMode.adminEditoionMode) {
      this.rest.updateTsli(tsliTemporary, true).subscribe((response: TsliResponse) => {
        this.tsli.next(response.tsli);

        //permuter applicability
      });
    } else {
      this.tsli.next(tsliTemporary);
    }
  }

  moveToLeft(chapterIndex: number) {
    let tsliTemporary: Tsli;
    tsliTemporary = JSON.parse(JSON.stringify(this.tsli.getValue()));
    tsliTemporary.chapters[chapterIndex].depth--;

    for (let i = chapterIndex + 1; i < this.tsli.getValue().chapters.length; i++) {
      if (tsliTemporary.chapters[i].depth - 1 > tsliTemporary.chapters[chapterIndex].depth) {
        this.tsli.getValue().chapters[i].depth--;
      }
    }
    if (this.activatedMode.getValue() != TsliMode.adminEditoionMode) {
      this.rest.updateTsli(tsliTemporary, true).subscribe((response: TsliResponse) => {
        this.tsli.next(response.tsli);
      });
    } else {
      this.tsli.next(tsliTemporary);
    }
  }

  checkSuccessorsLeft(chapterIndex: number) {
    for (let i = chapterIndex + 1; i < this.tsli.getValue().chapters.length; i++) {
      if (this.tsli.getValue().chapters[i].depth - 1 > this.tsli.getValue().chapters[chapterIndex].depth) {
        this.tsli.getValue().chapters[i].depth--;
      } else {
        return;
      }
    }
    return;
  }

  moveToRight(chapterIndex: number) {
    let tsliTemporary: Tsli;
    tsliTemporary = tsliTemporary = JSON.parse(JSON.stringify(this.tsli.getValue()));
    tsliTemporary.chapters[chapterIndex].depth++;
    if (this.activatedMode.getValue() != TsliMode.adminEditoionMode) {
      this.rest.updateTsli(tsliTemporary, true).subscribe((response: TsliResponse) => {
        this.tsli.next(response.tsli);
      });
    } else {
      this.tsli.next(tsliTemporary);
    }
  }

  addChapterApplicability(chapterIndex: number,
                          applicability: string) {
    const localChapter = this.tsli.getValue().chapters.find((chapter) => {
      return chapter.id_chapter + '' === chapterIndex + '';
    });

    this.tsli.getValue().chapters[localChapter.index].applicability = true;
    this.saveChapter(this.tsli.getValue().chapters[localChapter.index]).then((result) => {
      this.tsli.getValue().chapters[localChapter.index].applicabilityEntry = applicability;
      this.router.navigate([this.editionRoutes[this.activatedMode.getValue()] + this.tsli.getValue().idTsli]);
    });
  }


  addTsliApplicability() {
    this.tsli.getValue().applicabilityEntry = this.tsli.getValue().reference;
    this.rest.updateTsli(this.tsli.getValue(), true).subscribe((response: TsliResponse) => {
      this.tsli.next(response.tsli);
    });

  }

  setTheme(value: string) {
    this.theme = value;
  }

  getTheme(): string {
    return this.theme;
  }

  setFirstEntry(value: string) {
    this.firstEntry = value;
  }

  getCurrentTsliRef() {
    return this.tsli.getValue().reference;
  }

  getCurrentTsliAproverId() {
    return this.tsli.getValue().approver.id;
  }

  getCurrentTsliAuthorId() {
    return this.tsli.getValue().author.id;
  }

  getCurrentTsliId() {
    if (this.tsli.getValue() == undefined) {
      return undefined;
    }
    return this.tsli.getValue().idTsli;
  }

  getCurrentDocument() {
    if (this.tsli.getValue() == undefined) {
      return undefined;
    }
    return this.tsli.getValue();
  }

  setHasComment(value) {
    this.tsli.getValue().comment = value;
  }

  // setCurrentTsli(t: Tsli): void {
  //   this.tsli.next(t);
  // }

  setCurrentTsli(t: Tsli | undefined): void {
    if (!t) {
      t = new Tsli();  // or any other default state
    }
    this.tsli.next(t);
  }

  setCurrentTsliByItem(isByItem: boolean) {
    this.isByItem.next(isByItem);
  }

  allChaptersHasThemes(): Observable<boolean> {
    let allHasTheme = true;
    this.tsli.value.chapters.forEach(chapter => {
      if (this.listchaptersCurrent.includes(chapter.id_chapter)
        && chapter.theme == undefined) {
        allHasTheme = false;
      }
    });

    return of(allHasTheme);
  }


  allChaptersHasApplicability(): Observable<boolean> {
    let allHasApplicability = true;
    this.tsli.value.chapters.forEach(chapter => {
      if (this.listchaptersCurrent.includes(chapter.id_chapter)
        && !chapter.applicability) {
        allHasApplicability = false;
      }
    });

    return of(allHasApplicability);
  }

  oneChaptersHasThemes(): Observable<boolean> {
    let oneHasTheme = false;
    this.tsli.value.chapters.forEach(chapter => {
      if (this.listchaptersCurrent.includes(chapter.id_chapter)
        && chapter.theme != undefined) {
        oneHasTheme = true;
      }
    });

    return of(oneHasTheme);
  }


  oneChaptersHasApplicability(): Observable<boolean> {
    let allHasApplicability = false;
    this.tsli.value.chapters.forEach(chapter => {
      if (this.listchaptersCurrent.includes(chapter.id_chapter)
        && chapter.applicability) {
        allHasApplicability = true;
      }
    });

    return of(allHasApplicability);
  }


  updateTheme(theme: string, chaptersRefs: string[]) {
    if (chaptersRefs == undefined) {
      this.rest.updateTheme(theme, this.tsli.value.idTsli, chaptersRefs).subscribe(resp => {
        if (resp.msg == 'themes updated') {
          this.tsli.value.theme = theme;
        }
      });
    } else {
      this.rest.updateTheme(theme, undefined, chaptersRefs).subscribe(resp => {
        if (resp.msg == 'themes updated') {
          this.tsli.value.chapters.filter(c => chaptersRefs.includes(c.id_chapter)).forEach(c => c.theme = theme);
        }
      });
    }
  }

  getProcessTsliTheme() {
    return this.tsli.value.theme;
  }

  updateStatus(status: string) {
    return this.rest.updateStatus(this.tsli.value, status).pipe(map(res => res),
      catchError(err => {
        console.log('caught mapping error and rethrowing', err);
        return err;
      }));
  }

  getTsli() {
    return this.tsli;
  }

  setActivatedMode(mode: TsliMode) {
    this.activatedMode.next(mode);
  }

  setSummaryPageCount(count: number) {
    this.summaryPageCount.next(count);
  }

  setContentPageCount(count: number) {
    this.contentPageCount.next(count);

  }

  setChaptesSummary(summary) {
    this.chaptesSummary.next(summary);
  }

  generateTsliPdfRequest(idTsli, draft = false, status = undefined) {
    let body = {'id_tsli': idTsli};
    if (draft) {
      body['draft'] = true;
    }
    if (status != undefined) {
      body['status'] = status;
    }
    return this.rest.downloadItemPdf(body);
  }

  generateTsliListPdfRequest(refList) {
    let body = {'id_tsli_list': refList};
    return this.rest.downloadItemPdf(body);

  }

  generatePdfSourceFile(fileName) {
    let body = {'fileName': fileName};
    return this.rest.downloadItemPdf(body);

  }

  generateItemsPdfRequest(products, siOrImp, octgOrNonOctg): Observable<any> {
    let body = {'products': products, 'siOrImp': siOrImp, 'octgOrNonOctg': octgOrNonOctg};
    return this.rest.downloadItemPdf(body);

  }

  saveNewFirstEntry(firstEntry, chapters = undefined) {
    if (chapters != undefined) {
      this.rest.updateFirstEntry(firstEntry, undefined, chapters).subscribe(v => {
      });
    } else {
      this.rest.updateFirstEntry(firstEntry, this.tsli.value.idTsli, undefined).subscribe(v => {
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
