import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Theme, DetailedTsliConstants } from '../../detailed-tsli/detailed-tsli.constants';
import { Tsli } from 'src/app/core/data/tsli';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { TsliRestService } from 'src/app/core/tsli-rest.service';
import { Chapter } from 'src/app/core/data/chapter';
import { max } from 'rxjs/operators';

@Component({
  selector: 'pdf-chapters',
  templateUrl: './pdf-chapters.component.html',
  styleUrls: ['./pdf-chapters.component.scss']
})
export class PdfChaptersComponent implements OnInit {

  static readonly DEBUG: boolean = false;

  public tsliSubscription: Subscription;
  public byItemSubscription: Subscription;

  themes: Theme[] = [];
  tsli: Tsli;
  byItem: boolean;
  chaptersToSplitIndexes = [];
  chaptersAfterPagination = [];
  checkView = false;
  pageCount = 2;
  titles = new Map();
  th_div = true;
  table_break_inside = false;
  withSummary = ' ';

  chapters = new BehaviorSubject<Chapter[]>([]);
  chapters$ = this.chapters.asObservable();

  constructor(public store: CurrentTsliStoreService,
    public rest: TsliRestService,
    public ref: ChangeDetectorRef
  ) {

    this.themes.push({ key: 'gnrl_info', name: 'General Information', process: 'General Information' })
    this.themes.push(...DetailedTsliConstants.THEMES);
  }

  ngOnInit(): void {

    /* chapters are loaded from pdf-content */

    this.th_div = true;
    this.table_break_inside = JSON.parse(sessionStorage.getItem('tbl_brk_in'));
    this.tsliSubscription = this.store.tsli$.subscribe((tsli: Tsli) => {

      // add some dummy content
      if (tsli == null || tsli.chapters === undefined) {
        tsli = new Tsli();
        tsli.chapters = [];
      }

      this.tsli = tsli;
      this.chapters.next(this.tsli.chapters);
      this.checkView = true;
    });

    this.byItemSubscription = this.store.isByItem$.subscribe(value => {
      this.byItem = value;
      // if(this.byItem){
      //   this.appendTitle('1 .General Information', 0);
      // }
    });
    if (JSON.parse(sessionStorage.getItem('summary')) != undefined)
      this.withSummary = ' ';
    else
      this.withSummary = '[.]'


  }

  ngOnDestroy(): void {
    if (this.tsliSubscription !== undefined) {
      this.tsliSubscription.unsubscribe();
    }

    if (this.byItemSubscription !== undefined) {
      this.byItemSubscription.unsubscribe();
    }
  }

  orderBy(chapters: Chapter[], propName: string): Chapter[] {
    if (chapters === undefined) {
      return chapters;
    } else {
      return chapters.sort((a: Chapter, b: Chapter) => a[propName] > b[propName] ? 1 : a[propName] === b[propName] ? 0 : -1);
    }
  }

  doesThemeExist(chapters: Chapter[], themeKey: string): boolean {
    const foundChapter: Chapter = chapters.find((c: Chapter) => c.theme === themeKey);
    return foundChapter !== undefined;
  }

  filterBy(chapters: Chapter[], propName: string, proValue: any) {
    if (chapters == undefined) return [];
    return chapters.filter(chapter => chapter[propName] == proValue);
  }

  filterByThemeAndType(chapters: Chapter[], theme: string, type: string) {
    if (chapters == undefined) return [];
    return chapters.filter(chapter => chapter.theme == theme && chapter.type == type)
      .sort((a: Chapter, b: Chapter) => a.index > b.index ? 1 : a.index === b.index ? 0 : -1);

  }

  groupByRef(chapters: Chapter[], type: string) {
    const typeChapters = this.filterBy(chapters, "type", type);
    const result = [];
    const map = new Map();
    for (const chapter of typeChapters) {
      if (!map.has(chapter.tsliDocType + chapter.reference)) {
        map.set(chapter.tsliDocType + chapter.reference, true);    // set any value to Map
        result.push({
          reference: chapter.reference,
          title: chapter.title_tsli,
          theme: chapter.theme,
          docType: chapter.tsliDocType,
          revision: chapter.tsliRevision
        });
      }
    }
    return result;
  }

  appendTitle(title, depth) {
    this.titles.set(title, { title: title, page: 0, depth: depth })
    let values = Array.from(this.titles.values());
    localStorage.setItem('titles', JSON.stringify(values));
    // console.log(values)
    return title;
  }

  replaceArrow(b) {
    return b.replace('→', '&#8594;');
  }

  ngAfterViewChecked() {
    if (this.byItem) {
      /* prepare Summary titles with themes and their chapters */
      let summary = []
      for (let i = 0; i < this.themes.length; i++) {
        summary.push({ title: (i + 1) + this.withSummary + this.themes[i].name, page: 0, depth: 0 })
        let tchapters = this.filterByThemeAndType(this.tsli.chapters, this.themes[i].key, 'product');
        for (let j = 0; j < tchapters.length; j++) {
          summary.push({ title: (i + 1) + ' .' + tchapters[j].number + this.withSummary + tchapters[j].title, page: 0, depth: tchapters[j].depth + 1 })
        }

      }
      localStorage.setItem('titles', JSON.stringify(summary));

    }

    if (this.checkView) {
      setTimeout(() => {

        // tables with a width > A4 whith and images page break
        let tables = Array.from(document.getElementsByTagName("figure")) 
        let initialTop = document.getElementById('contentWithoutHeaders').offsetTop;


        if (tables != undefined && tables.length > 0) {
          let images = tables.filter(element => element.classList.contains('image'));
          this.addImageResizedClass(images);
          tables = tables.filter(element => element.classList.contains('table'));
          if (tables != undefined && tables.length > 0) {
            for (let t = 0; t < tables.length; t++) {
              if (tables[t].getElementsByTagName("table")[0].offsetWidth > 1100) {
                tables[t].getElementsByTagName("table")[0].classList.add('overwith-table');
              }
              // tables[t].getElementsByTagName("table")[0].classList.add('page-break-table');
              this.splitVerticallyMergedCells(tables[t])
              this.splitHeader(tables[t])
              this.resizeTableImages(tables[t]);
            

              this.setTdBackgroundColor(tables[t].getElementsByTagName("table")[0])

            }
          }

         
          this.th_div = false;
        }
      }, 20000);
      this.checkView = false;
    }

  }

  // browser print make a white background for <td> and <th> so we need to make a default style to <td> and <th>
  setTdBackgroundColor(table) {
    let cells = Array.from(table.getElementsByTagName("td"))
    cells = cells.concat(Array.from(table.getElementsByTagName("th")))
    cells.forEach((cell: HTMLTableElement) => {
      if (!cell.style.cssText.includes('background-color')) {
        cell.style.cssText = cell.style.cssText + ' background-color: unset !important;'
      }
      else {
        // if important
        let backgroundIndex = cell.style.cssText.indexOf('background-color');
        backgroundIndex = cell.style.cssText.indexOf(';', backgroundIndex);

        if (cell.style.cssText.charAt(backgroundIndex - 10) != '!')
          cell.style.cssText = cell.style.cssText.slice(0, backgroundIndex) + ' !important' + cell.style.cssText.slice(backgroundIndex)
      }
    });


  }

  splitVerticallyMergedCells(table) {
    /* remove rowspan from cells and replace it with new cells with no top or bottom border */
    /* if a cell contains a specific style (color, colspan,...) the new cells must have the same style*/
    let tr = Array.from(table.getElementsByTagName('tr')).filter((r: any) => {
      return r.querySelectorAll('td[rowspan]').length > 0
    })
    tr = tr.concat(Array.from(table.getElementsByTagName('tr')).filter((r: any) => {
      return r.querySelectorAll('th[rowspan]').length > 0
    }))
    if (tr.length) {
      tr.forEach((t: HTMLTableRowElement) => {
        let addedCells = 0;
        Array.from(t.children).forEach((c, i) => {
        let nextTrColspan = 0;
          if (c.hasAttribute('rowspan')) {
            let rowspanValue: number = +c.getAttribute('rowspan')
            c.removeAttribute('rowspan')
            c.classList.add('no-border-bottom')

            /** get next table row tr */
            let nextTr = t.nextElementSibling

            /** create a new td or th */
            let type = 'td'
            if(c.localName == 'th') type = 'th'

            for (let index = 0; index < rowspanValue - 1; index++) {
              nextTrColspan = 0;
              let nextTrchildren = Array.from(nextTr.children);
              for(let j=0; j+nextTrColspan < i + addedCells && j+nextTrColspan < nextTrchildren.length; j ++){
                if(nextTrchildren[j].hasAttribute('colspan')){
                  nextTrColspan = nextTrColspan + +nextTrchildren[j].getAttribute('colspan')
                }
              }
              let cell = document.createElement(type)
              for (let a = 0; a < c.attributes.length; a++) {
                cell.setAttribute(c.attributes[a].name, c.attributes[a].value)
              }
              cell.classList.add(...['no-border-top', 'no-border-bottom']);

              if(i+1 >= t.children.length)
                nextTr.appendChild(cell);
              else {
                if(addedCells > 0 && nextTrColspan == 0) 
                  nextTr.insertBefore(cell, nextTr.children[i+addedCells-1])
                else if(addedCells == 0 && nextTrColspan > 0)
                  nextTr.insertBefore(cell, nextTr.children[i-nextTrColspan + 1])
                else
                  nextTr.insertBefore(cell, nextTr.children[i+addedCells-nextTrColspan])

              }
              nextTr = nextTr.nextElementSibling
            }
          }
          if(c.hasAttribute('colspan')){
            addedCells = addedCells + +c.getAttribute('colspan')
          }
        })
      })
    }
  }

  

  /*replace thead with a simple <tr> */
  splitHeader(table) {
    let thead = table.getElementsByTagName('thead');
    let tbody = table.getElementsByTagName('tbody')[0];
    if (thead.length) {
      let old_header = thead[0]
      let headers = []
      let tr_list = Array.from<any>(old_header.getElementsByTagName('tr'))
      let tbody_first_child = tbody.firstElementChild
      tr_list.forEach(tr => {
        let new_tr = document.createElement('tr');
        new_tr.innerHTML = tr.innerHTML;
        // new_tr.innerHTML = tr.innerHTML.replace('<th ', '<td ').replace('<th>', '<td>').replace('</th>', '</td>')
        // console.log(new_thead.outerHTML)
        tbody_first_child.parentNode.insertBefore(new_tr, tbody_first_child);
      });

      // headers.forEach(h => {
      //   old_header.parentNode.insertBefore(h, old_header);
      // });
      old_header.remove()

    }

  }


  /** resize images to make table fit with A4 page */
  resizeTableImages(table){
    let tableRows = Array.from(table.getElementsByTagName('tr'))
    .filter((r:any)=>r.querySelectorAll('figure.image').length > 0);
    tableRows.forEach((row:any)=> {
      let sumSize = 0;
      Array.from(row.children).forEach(c => {
        sumSize = sumSize + this.imgWithMaxSize(c);
      });
      if(sumSize > 1000){
        Array.from(row.children).forEach(c => {
          this.resizeImages(c, sumSize);
        });
        
      }
      
    });
  }

  mark_table(table, empty=false){
    let color1 = 'black'
    let color2 = 'color: black;'
    if(empty){
      color1 = 'white'
      color2 = 'color: white;'
    }
   
    const elem = document.createElement('span');
      elem.innerText = 'mytsliTable';
      elem.style.fontSize = "3px";
      elem.style.color = color1
      table.parentNode.insertBefore(elem, table);
      let tbody_first_td = table.getElementsByTagName('tbody')[0].children.item(0).children.item(0);
      tbody_first_td.innerHTML = '<p style="font-size:3px; height: 3px; margin-bottom:10px;'+color2+'">mytsliBody</p>' + tbody_first_td.innerHTML

    

  }
  
  /* get the large image of a cell */
  imgWithMaxSize(e){
    if(!e.querySelectorAll('figure.image').length)
    return e.offsetWidth
    let imagesSize = Array.from(e.querySelectorAll('figure.image')).map((i: any)=> {
      if(i.classList.contains('image_resized')) return i.style.width.replace('px', '');
      else{
        i.style.width = i.firstElementChild.offsetWidth + 'px'
        i.classList.add('image_resized')
        return i.offsetWidth
      };
    });
    return Math.max(...imagesSize);
    
  }

  /* change an image size */
  resizeImages(e, maxSize){
    let images = Array.from(e.querySelectorAll('figure.image'));
    images.forEach((i:any)=>{
      i.style.width = Math.floor(i.style.width.replace('px', '') * 1000/maxSize) + 'px';
    })
    
  }

  /* add resized class to all images */
  addImageResizedClass(images){
    images.forEach(img => {
      if(!img.classList.contains('image_resized')){
        img.style.width = img.firstElementChild.offsetWidth + 'px'
        img.classList.add('image_resized')
      };
    });
  }
}
