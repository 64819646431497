<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Select right for {{componentUser.name + ' ' + componentUser.first_name}} </h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ">
  <div class="row">
    <h3 class="mat-headline text-center" style="margin-left:2% ;">Please pick up connections for this user</h3>
  </div>
  <div class="row">
    <mat-form-field class="example-full-width" style="margin-left:2% ;">
      <mat-label>Filter by :</mat-label>
      <input matInput placeholder="filter ..... " [(ngModel)]="searchText" (blur)="filters()">
    </mat-form-field>
  </div>
  <div style="overflow-y: auto; max-height:400px;">
    <div class="row">
      <ng-container *ngFor="let connection of connectionsfilter">
        <mat-checkbox color="primary" class="col-md-3" style="justify-content:center;align-items:center;"
          (click)="$event.stopPropagation()" (change)="$event ? rowsSelection.toggle(connection) : null"
          [checked]="rowsSelection.isSelected(connection)">
          {{connection}}
        </mat-checkbox>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer text-center">
  <button mat-flat-button color='primary' (click)="commitProductsSelection()">Validate</button>
</div>