import {Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicabilityService} from "../../core/applicability.service";
import {ConfigurationService} from "../../core/configuration.service";


@Component({
  selector: 'app-poc-ns',
  templateUrl: './poc-ns.component.html',
  styleUrls: ['./poc-ns.component.scss']
})
export class PocNsComponent implements OnInit {
  readonly API_BASE_URL: string = environment.API_BASE_URL;

  public product: any;

  public isButtonDisabled: boolean;

  public lubricant: any = "standard thread compound";
  public lubricants: any;

  subscriptions = [];

  public isLoadingExcel: boolean;

  text_area_one = '';
  text_area_two = '';


  constructor(private route: ActivatedRoute,
              private applicabilityRest: ApplicabilityService,
              public router: Router,
              public config: ConfigurationService,
  ) { }

  ngOnInit(): void {
    this.isLoadingExcel = false;

    this.route.paramMap.subscribe((params) => {
      this.product = params.get('connection');
      if(this.product === 'undefined') {
        this.product = undefined;
      }

    });

    this.subscriptions.push(this.config.getConfigurationByName("text_area_bpd_download_title").subscribe(v=>{
      this.text_area_one = v['configurations'][0].value;
    }));

    this.subscriptions.push(this.config.getConfigurationByName("text_area_bpd_download_disclaimer").subscribe(v=>{
      this.text_area_two = v['configurations'][0].value;
    }));

    this.loadLubricants(this.product)

    this.isButtonDisabled = false;
  }

  onLubricantSelectionChange(event: any) {
    if(event.value){
      this.isButtonDisabled = !(this.product && this.lubricant);
    }
    else {
      this.isButtonDisabled = true
    }
  }

  download_S3_pdf(): void {
      this.isLoadingExcel = true;
    this.applicabilityRest.downloadPOCNS({}).subscribe(
      (response) => {
        this.handleResponse(response , true);
        this.isLoadingExcel = false;
      }
    )  }

  loadLubricants(product: string): void {
    this.applicabilityRest.getLubricant(product)
      .subscribe(resp => {
          this.lubricants = resp;
        console.log("voici le isButtonDisabled ",this.isButtonDisabled);

      });
    this.isButtonDisabled = !(this.product && this.lubricant);
  }

  downloadCatalog(): void {
    this.isLoadingExcel = true;
    this.isButtonDisabled = true;

    this.applicabilityRest.downloadPOCNS({product: this.product, lubricant: this.lubricant}).subscribe(
      (response) => {
        this.handleResponse(response, false);
        this.isLoadingExcel = false;
        this.isButtonDisabled = false;
      }
    )
  }

  handleResponse(response: any , isPdf: boolean){
    if (response.err) {
      return;
    }


    const a = document.createElement("a");
    document.body.appendChild(a);
    if (response) {
      const blob = new Blob(
        [Uint8Array.from(atob(response), (c) => c.charCodeAt(0))],
        { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
      );
      a.href = window.URL.createObjectURL(blob);

      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear().toString();
      if(isPdf){
        a.download = `MyTSLI USER GUIDE BULK BASIC PRODUCT DATA EXCTRACTION.pdf`;
      }
      else{
        a.download = `Basic Product Data - ${this.product}-${this.lubricant}-${day}-${month}-${year}.xlsx`;
      }
      a.click();
    }
  }
}
