import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {DocumentType} from '../../../core/enum/document-type.enum';
import {TsliMode} from '../../../core/enum/tsli-mode.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TslisStoreService} from '../../../core/tslis-store.service';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {TsliRestService} from '../../../core/tsli-rest.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigurationService} from '../../../core/configuration.service';
import {AuthService} from '../../../core/auth.service';
import {CommentsStoreService} from '../../../core/comments-store.service';
import {Comment} from '../../../core/data/comment';
import {Tsli} from '../../../core/data/tsli';
import {Status} from '../../../core/enum/status.enum';
import {TsliResponse} from '../../../core/data/tsli-response';
import {ErrorBarComponent} from '../../error-bar/error-bar.component';
import {forkJoin, Observable, of, Subscription, timer} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MatStepper} from '@angular/material/stepper';
import {ServicesNamesConstants} from '../../../core/services-names.constants';
import {User} from '../../../core/data/User';
import {CommentsRestService} from '../../../core/comments-rest.service';

@Component({
  selector: 'app-create-archived-document',
  templateUrl: './create-archived-document.component.html',
  styleUrls: ['./create-archived-document.component.scss']
})
export class CreateArchivedDocumentComponent implements OnInit, OnDestroy {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  docType: string;
  docTitle: string;
  docReference: string;
  docRevision: string;
  revision: string;
  typeDescriptionText = '';
  commitSuccessful = false;
  commitFailed = false;
  tsliProcessDescription = '';
  tsliProductDescription = '';
  tsliGeneralInformationDescription = '';
  tsliLegacyDescription = '';
  docTypeForm = '';
  type;
  docTypeInor = '';
  chapterOneTitle = '';
  chapterOneBody = '';
  chapterOneNumber = '';
  chapterTwoTitle = '';
  chapterTwoBody = '';
  chapterTwoNumber = '';
  subscriptions = [];
  progress: { percentage: number } = {percentage: 0};
  inputComment: Comment;
  reference: string;
  title: string;
  comment: string;
  idTsli: string;
  source_file_s3_url: string;
  display_file_s3_url: string;
  documentToRevise: Tsli;
  tsli: Tsli;
  errorMsg = '';
  activatedDataReloading = true;
  isArchivedDocument = false;
  activatedTsliMode: TsliMode;
  tsliType: string;
  public TYPE_DESCRIPTION_TEXT;
  isTypeDisabled: boolean;

  isDocTypeDisabled = false;
  idCurrentTsliForRevision: string;
  revisionNumberIsRequired = false;
  documentType: Map<string, string> = new Map<string, string>();
  documentTypeKeys: string[];
  documentTypeValues: string[];
  source_files: File[] = [];
  display_files: File[] = [];
  subscriptionActivatedTsliMode: Subscription;
  isDisabledDisplayFileZone: boolean;
  isSourceFileRequired: boolean;


  constructor(public fb: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public currentTsliStoreService: CurrentTsliStoreService,
              public rest: TsliRestService,
              public snackBar: MatSnackBar,
              public configService: ConfigurationService,
              public authService: AuthService,
              public commentStore: CommentsStoreService,
              public commentsRestService: CommentsRestService,) {
  }

  ngOnDestroy(): void {
    this.subscriptionActivatedTsliMode.unsubscribe();
  }

  ngOnInit(): void {

    this.firstFormGroup = this.fb.group({
      docTypeCtrl: ['', [Validators.required], [this.checkIfRevisionNumberExists()]],
      referenceNumberCtrl: ['', [Validators.required], [this.checkIfRevisionNumberExists()], {updateOn: 'blur'}],
      titleCtrl: ['', Validators.required],
      revisionNumberCtrl: ['', [Validators.required, Validators.pattern('[0-9]+')], [this.checkIfRevisionNumberExists()], {updateOn: 'blur'}],
      applicationDateCtrl: ['', []],
      dropZoneSourceFileCtrl: ['', [Validators.required]],
      dropZoneDisplayFileCtrl: ['', []],
      commentRevisionCtrl: ['', []]
    });

    //Step 1: firstFormGroup
    DocumentType.forEach((label, value) => {
      this.documentType.set(label, value);
    });
    this.documentTypeKeys = Array.from(this.documentType.values());
    this.documentTypeValues = Array.from(this.documentType.keys());

    this.firstFormGroup.controls.commentRevisionCtrl.disable();
    this.isTypeDisabled = false;

    this.secondFormGroup = this.fb.group({
      typeSelectCtrl: ['', Validators.required]
    });

    this.secondFormGroup.controls.typeSelectCtrl.valueChanges.subscribe((value: string) => {
      this.typeDescriptionText = this.TYPE_DESCRIPTION_TEXT[value];
    });

    this.isDisabledDisplayFileZone = true;
    this.isSourceFileRequired = true;

    this.title = 'Create a new archived document';

    setTimeout(() => {
      // Create new revision (with uploaded files)
      this.subscriptionActivatedTsliMode = this.currentTsliStoreService.activatedMode$
        .subscribe(value => {
          this.activatedTsliMode = value;
          if (value == TsliMode.revisingMode) {
            this.title = 'Create a new revision to archive';

            this.firstFormGroup = this.fb.group({
              docTypeCtrl: ['', []],
              referenceNumberCtrl: ['', []],
              titleCtrl: ['', []],
              revisionNumberCtrl: ['', [Validators.required, Validators.pattern('[0-9]+')], [this.checkIfRevisionNumberExists()], {updateOn: 'blur'}],
              dropZoneSourceFileCtrl: ['', [Validators.required]],
              dropZoneDisplayFileCtrl: ['', []],
              commentRevisionCtrl: ['', []]
            });


            this.docTitle = this.documentToRevise.title;
            this.docReference = this.documentToRevise.reference;
            this.docType = this.documentToRevise.docType.toLowerCase();
            this.revision = this.documentToRevise.revision;
            this.type = this.documentToRevise.type;

            this.firstFormGroup.controls.docTypeCtrl.setValue(this.documentToRevise.docType.toLowerCase());
            this.firstFormGroup.controls.referenceNumberCtrl.setValue(this.documentToRevise.reference);
            this.firstFormGroup.controls.titleCtrl.setValue(this.documentToRevise.title);
            this.firstFormGroup.controls.revisionNumberCtrl.setValue('');

            this.firstFormGroup.controls.titleCtrl.disable();
            this.isDocTypeDisabled = true;
            this.firstFormGroup.controls.referenceNumberCtrl.disable();

            // enable upload display file
            this.isDisabledDisplayFileZone = false;
            // create archived revision from  HTML document
            this.isSourceFileRequired = true;
            this.firstFormGroup.controls.dropZoneSourceFileCtrl.setErrors(Validators.required);
            this.firstFormGroup.controls.dropZoneSourceFileCtrl.updateValueAndValidity();

          }
        });
    });

  }

  ngAfterViewInit(): void {
    this.getDynamicTSLICreation();
  }

  onDocumentTypeChange() {
    this.docType = this.firstFormGroup.controls.docTypeCtrl.value;
    this.docReference = this.firstFormGroup.controls.referenceNumberCtrl.value;
    this.docRevision = this.firstFormGroup.controls.revisionNumberCtrl.value;
  }

  onSelectSourceFile(event) {
    console.log(event);
    this.source_files.splice(this.source_files.indexOf(event), this.source_files.length);
    this.source_files.push(...event.addedFiles);
    this.isDisabledDisplayFileZone = false;
    this.firstFormGroup.controls.commentRevisionCtrl.enable();
    this.firstFormGroup.controls.commentRevisionCtrl.addValidators(Validators.required);
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();
  }

  onRemoveSourceFile(event) {
    this.source_files.splice(this.source_files.indexOf(event), 1);
    this.firstFormGroup.controls.dropZoneSourceFileCtrl.setValue('');
    this.display_files.splice(this.display_files.indexOf(event), 1);

    this.isDisabledDisplayFileZone = true;
    this.firstFormGroup.controls.dropZoneSourceFileCtrl.addValidators(Validators.required);
    this.firstFormGroup.controls.dropZoneSourceFileCtrl.updateValueAndValidity();

    this.firstFormGroup.controls.commentRevisionCtrl.setValue('');
    this.firstFormGroup.controls.commentRevisionCtrl.disable();
    this.firstFormGroup.controls.commentRevisionCtrl.clearValidators();
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();
  }

  onSelectDisplayFile(event) {
    console.log(event);
    this.display_files.splice(this.display_files.indexOf(event), this.display_files.length);
    this.display_files.push(...event.addedFiles);
  }

  onRemoveDisplayFile(event) {
    console.log(event);
    this.display_files.splice(this.display_files.indexOf(event), 1);
  }

  checkIfRevisionNumberExists(): ValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      this.docReference = this.firstFormGroup.controls.referenceNumberCtrl.value;
      this.docRevision = this.firstFormGroup.controls.revisionNumberCtrl.value;
      console.log('ref+doctype:', this.docType + '/' + this.docReference + '/' + this.docRevision);

      return this.documentValidatorWithRevisionNumber();
    };
  }

  documentValidatorWithRevisionNumber(): Observable<ValidationErrors | null> {
    return timer(20).pipe(
      switchMap(() => {
        this.docReference = this.firstFormGroup.controls.referenceNumberCtrl.value;
        this.docRevision = this.firstFormGroup.controls.revisionNumberCtrl.value;

        console.log('ref+doctype:', this.docType + '/' + this.docReference + '/' + this.docRevision);

        // doctype && reference => get title
        if (this.docReference !== '' && this.docType !== '' && this.docReference !== undefined && this.docType !== undefined) {
          if (this.docRevision == '' || this.docRevision == undefined) {
            return this.rest.isReferenceNumberUnique(this.docReference, this.docType).pipe(
              map((value) => {
                if (value.response != 'reference_number.not.found') {
                  console.log('set title');
                  this.firstFormGroup.controls.titleCtrl.setValue(value['tsli']['title']);
                  return null;
                }
              }),
              catchError(() => null)
            );
          } else {
            // put revision
            console.log('put revision');
            return this.rest.isRevisionNumberExist(this.docReference, this.docType, this.docRevision).pipe(
              map((value) => {
                //if document exist => published document and search document => revise

                if (value.response == 'reference_number.not.found') {
                  if (value.tsli != undefined && value['tsli']['revision'] < this.docRevision) {
                    this.revision = value['tsli']['revision'];
                    return {revisionNumberInvalidReviseMode: true};
                  }
                  if(value.tsli != undefined && value['tsli']['revision'] > this.docRevision){
                    this.firstFormGroup.controls.referenceNumberCtrl.setErrors(null);
                    this.firstFormGroup.controls.docTypeCtrl.setErrors(null);
                    this.firstFormGroup.controls.revisionNumberCtrl.setErrors(null);
                    this.firstFormGroup.controls.titleCtrl.setValue(value['tsli']['title']);
                    return null;
                  }
                  this.firstFormGroup.controls.referenceNumberCtrl.setErrors(null);
                  this.firstFormGroup.controls.docTypeCtrl.setErrors(null);
                  this.firstFormGroup.controls.revisionNumberCtrl.setErrors(null);
                  this.firstFormGroup.controls.titleCtrl.setValue('');
                  return null

                } else {
                  if (this.documentToRevise != undefined) {
                    if (value['tsli']['revision'] <= this.docRevision) {
                      this.revision = value['tsli']['revision'];
                      return {revisionNumberInvalidReviseMode: true};
                    }
                  } else if (value['tsli']['revision'] == this.docRevision) {
                    return {documentExists: true};
                  } else if (value['tsli']['revision'] < this.docRevision) {
                    this.revision = value['tsli']['revision'];
                    return {revisionNumberInvalidReviseMode: true};
                  }
                }
              }),
              catchError(() => null)
            );
          }
        } else {
          return of(null);
        }
      })
    );
  }

  getDocumentType(docType: string): boolean {
    return this.tslisStore.getDocumentType(docType);
  }

  goForward(stepper: MatStepper) {
    if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_FORM) {
      this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_FORM);
      this.isTypeDisabled = true;
    } else if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_INOR) {
      this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_INOR);
      this.isTypeDisabled = true;
    } else {
      this.isTypeDisabled = false;
      this.secondFormGroup.controls.typeSelectCtrl.setValue('');
    }
    this.secondFormGroup.controls.typeSelectCtrl.updateValueAndValidity();
  }

  private getDynamicTSLICreation() {
    // using forkJoin to get all values at a time to process them to
    this.subscriptions.push(
      forkJoin(
        [this.configService.getConfigurationByName('creation_process_description'),
          this.configService.getConfigurationByName('creation_general_information_description'),
          this.configService.getConfigurationByName('creation_product_description'),
          this.configService.getConfigurationByName('creation_form_description'),
          this.configService.getConfigurationByName('creation_inor_description'),
          this.configService.getConfigurationByName('creation_legacy_description'),
          this.configService.getConfigurationByName('creation_ugia_description'),
          this.configService.getConfigurationByName('creation_ugfl_description'),
          this.configService.getConfigurationByName('creation_ugira_description')]
      ).subscribe(([process, gi, product, form,
                     inor, legacy,ugia,ugfl,ugira]) => {
        this.TYPE_DESCRIPTION_TEXT = {
          process: process['configurations'][0].value,
          general_information: gi['configurations'][0].value,
          product: product['configurations'][0].value,
          form: form['configurations'][0].value,
          inor: inor['configurations'][0].value,
          legacy: legacy['configurations'][0].value,
          ugia: ugia['configurations'][0].value,
          ugfl: ugfl['configurations'][0].value,
          ugira: ugira['configurations'][0].value
        };
      }));
  }

  saveDocument(): void {
    if (this.secondFormGroup.valid) {
      const document: Tsli = new Tsli();
      document.docType = this.firstFormGroup.controls.docTypeCtrl.value.toLowerCase();
      document.title = this.firstFormGroup.controls.titleCtrl.value;
      document.reference = this.firstFormGroup.controls.referenceNumberCtrl.value;
      document.revision = this.firstFormGroup.controls.revisionNumberCtrl.value;
      document.type = this.secondFormGroup.controls.typeSelectCtrl.value.toLowerCase();
      document.applicationDate = new Date(this.firstFormGroup.controls.applicationDateCtrl.value);
      document.reviewer = new User();
      document.approver = new User();
      document.isLegacy = document.type.toUpperCase() === 'LEGACY';
      document.isArchivedDocument = true;
      document.status = Status.ARCHIVED;
      this.authService.user.subscribe(user => {
        document.author = user;
      });

      if (this.activatedTsliMode === TsliMode.revisingMode) {
        console.log('create new revision', document);
        this.CreateNewRevision(document);
      } else {
        console.log('create new document', document);
        this.createNewDocument(document);
      }
    }
  }

  createNewDocument(document: Tsli) {
    this.tslisStore.createTsli(document).subscribe(
      (tsliResponse: TsliResponse) => {
        this.commitSuccessful = true;
        document.idTsli = tsliResponse.tsli.idTsli;
        // upload source and display file and no chapter to add
        this.uploadSourceFile(document);
        this.uploadDisplayFile(document);
        this.addRevisionComment(document);
      },
      (error: any) => {
        this.displayError('An HTTP error occurred! ', error.status, error.statusText);
        this.commitFailed = true;
      });
  }

  CreateNewRevision(document: Tsli) {
    this.tslisStore.createNewRevision(document).subscribe(
      (tsliResponse: TsliResponse) => {
        if (tsliResponse.tsli == null) {
          this.displayError('An HTTP error occurred! ', tsliResponse.error, tsliResponse.error);
          this.errorMsg = tsliResponse.error ? tsliResponse.error : 'TSLI IS NULL';
          this.commitFailed = true;
        } else {
          this.idCurrentTsliForRevision = tsliResponse.tsli.idTsli;
          document.idTsli = tsliResponse.tsli.idTsli;
          this.commitSuccessful = true;
          this.uploadSourceFile(document);
          this.uploadDisplayFile(document);
          this.addRevisionComment(document);
        }
      }
    );
  }

  uploadSourceFile(document: Tsli) {
    if (this.source_files.length > 0) {
      this.rest.uploadFileWithPreSignedURL(this.source_files[0]).subscribe(source_files_resp => {
        console.log(source_files_resp);
        document.source_file_s3_url = source_files_resp.s3_filename;
        this.rest.update_source_file_url(document.idTsli, document.source_file_s3_url).subscribe(response => {
          console.log(response);
          this.tslisStore.getAllDocuments().subscribe();
        });
      });
    }
  }

  uploadDisplayFile(document: Tsli) {
    if (this.display_files.length > 0) {
      this.rest.uploadFileWithPreSignedURL(this.display_files[0]).subscribe(display_files_resp => {
        console.log(display_files_resp);
        document.display_file_s3_url = display_files_resp.s3_filename;
        this.rest.update_display_file_url(document.idTsli, document.display_file_s3_url).subscribe(response => {
          console.log(response);
          this.tslisStore.getAllDocuments().subscribe();
        });
      });
    }
  }

  displayError(msg: string, statusCode: string, statusText: string) {
    this.snackBar.openFromComponent(ErrorBarComponent, {
      duration: 5000,
      data: {
        msg,
        statusCode,
        statusText
      }
    });
  }

  addRevisionComment(tsli: Tsli) {
    this.inputComment = new Comment();
    this.inputComment.commentDate = new Date();
    this.inputComment.tsli = tsli;
    this.inputComment.is_revision_comment = '1';
    this.authService.user.subscribe(user => {
      this.inputComment.commentator = user;
    });
    this.inputComment.comment = JSON.parse(JSON.stringify(this.firstFormGroup.controls.commentRevisionCtrl.value));
    this.commentStore.addComment(this.inputComment);

  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  onClose(): void {
    this.activeModal.close();
  }

}
