import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Configuration } from './data/configuration';
import { ServicesNamesConstants } from './services-names.constants';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public readonly PATH: string = environment.API_BASE_URL + '/configuration';
  public readonly configurations = new BehaviorSubject<Configuration[]>([]);
  readonly configurations$: Observable<Configuration[]> = this.configurations.asObservable();
  public configurationsList = [];

  constructor(public http: HttpClient,
    public authService: AuthService,
    public router: Router) {
  }

  addConfiguration(configuration:Configuration): Observable<any> {
    return this.http.post<any>(this.PATH,
      configuration,
      this.authService.getAuthorization(ServicesNamesConstants.CONFIGURATION_SAVE_CONFIGURATION)).pipe(
      map((response: any): any => {
        this.configurations.next(response["configurations"])
        return response;
      })
      , catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return of();
      }));
  }

  updateConfiguration(configuration:Configuration): Observable<any> {
    return this.http.put<any>(this.PATH,
      configuration,
      this.authService.getAuthorization(ServicesNamesConstants.CONFIGURATION_SAVE_CONFIGURATION)).pipe(
      map((response: any): any => {
        this.configurations.next(response["configurations"])
        return response;
      })
      , catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return of();
      }));
  }

  deleteConfiguration(idConfiguration): Observable<any> {
    return this.http.delete<any>(this.PATH + "?id_configuration=" + idConfiguration ,
      this.authService.getAuthorization(ServicesNamesConstants.CONFIGURATION_DELETE_CONFIGURATION)).pipe(
      map((response: any): any => {
        return response;
      })
    , catchError((err, caught) => {
      if(err.status === 401) { this.router.navigate([`tsli/Unauthorized`]); }
      return of();
    }));

  }

  getConfigurationByName(property_name): Observable<any> {
    return this.http.get<any>(this.PATH + '?by_name=' + property_name,
      this.authService.getAuthorization(ServicesNamesConstants.CONFIGURATION_GET_CONFIGURATION)).pipe(
      map(response => {
        this.configurations.next(response["configurations"]);
         return response;
      })
    , catchError((err, caught) => {
      if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
      return of<any>();
    }));
  }


  getAllConfigurations(): Observable<any> {
    return this.http.get<any>(this.PATH,
      this.authService.getAuthorization(ServicesNamesConstants.CONFIGURATION_GET_CONFIGURATIONS)).pipe(
      map(response => {
        this.configurationsList = response["configurations"]
        let modalTimerGlobal = this.configurationsList.filter(v=>v['property']=='modal_timer_global')[0]
        let modalTimerLaunch = this.configurationsList.filter(v=>v['property']=='modal_timer_launch')[0]

        this.authService.setModalTimers(modalTimerGlobal.value, modalTimerLaunch.value)
         return response;
      })
    , catchError((err, caught) => {
      if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
      return of<any>();
    }));
  }
  getConfigurationsValues(){
    return this.configurationsList;
  }

  loadAll(){
    this.http.get<any>(this.PATH,
      this.authService.getAuthorization(ServicesNamesConstants.CONFIGURATION_GET_CONFIGURATIONS)).subscribe(resp =>{
        this.configurations.next(resp["configurations"])
      });
  }
}
