import {Component, OnInit, ViewChild, NgZone, Input} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import {CommentsStoreService} from 'src/app/core/comments-store.service';
import {Comment} from 'src/app/core/data/comment';

@Component({
  selector: 'comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss']
})
export class CommentInputComponent implements OnInit {

  @ViewChild('autosize', {static: true}) autosize: CdkTextareaAutosize;

  constructor(public _ngZone: NgZone, public commentStore: CommentsStoreService) {
  }

  @Input()
  comment: Comment;


  ngOnInit() {
    if (this.comment == undefined) this.comment = new Comment();
  }


  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  addComment() {

    this.comment.commentDate = new Date();
    this.comment.is_revision_comment = '0';
    let addedComment = JSON.parse(JSON.stringify(this.comment));
    this.commentStore.addComment(addedComment);
    this.comment.comment = '';
  }

}
