import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {Roles} from '../enum/role.enum';
import {AuthService} from '../auth.service';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  @Input('hasRole')
  checkRoles: Roles | Roles[];
  public subscription: Subscription;

  constructor(public vcr: ViewContainerRef,
              public tpl: TemplateRef<any>,
              public authService: AuthService) {}

  ngOnInit() {
    this.authService
      .hasRole(this.checkRoles)
      .subscribe(hasRoles => {
        this.vcr.clear();
        if (hasRoles) {
          this.vcr.createEmbeddedView(this.tpl);
        }
      });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
    this.vcr.clear();
  }
}
