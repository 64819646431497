import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { User } from 'src/app/core/data/User';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pdf-user',
  templateUrl: './pdf-user.component.html',
  styleUrls: ['./pdf-user.component.scss']
})
export class PdfUserComponent implements OnInit {
  currentUser: User;
  userInfo: String;

  constructor(public auth:AuthService, public datePipe: DatePipe) {
    this.currentUser = auth.getCurrentUser();

   }

  ngOnInit() {
    this.userInfo = this.currentUser.companyName + ' ' + this.currentUser.login + ' ' + this.currentUser.licenseeNumber + ' ' + this.datePipe.transform(new Date(),"dd-MM-yyyy");
  }

}
