
    <mat-spinner *ngIf="message == ''" class="text-center modal-transparent" style="margin-left: 35%;"> </mat-spinner>
    <div class="row" *ngIf="message != ''">
        <div class="col result">
            <span [ngClass]="{'done' :done , 'failed': !done}">{{message}}</span>
        </div>
    </div>
    <div class="row"*ngIf= "showButton" class="text-center" style="margin-bottom: 15px; " >
         <button mat-stroked-button color='basic' (click)="dismiss()">Copy and Close</button>
    </div>
