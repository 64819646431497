<div class="modal-header">
  <h4 class="modal-title" style="color:green;" id="modal-title">Sent to review successfully</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>The TSLI was sent for review to {{reviewer.first_name}} {{reviewer.name}}</p>
</div>
<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="close()">Close</button>
</div>
