import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitNumberFormat'
})
export class UnitNumberFormatPipe implements PipeTransform {

  transform(value: number | string, imperialOrSI?: boolean): any {
    if (typeof value === 'string'){
      return value;
    }
    if (value === 0 || value === undefined) return '';
    if (!imperialOrSI) return value.toLocaleString('fr-FR');
    return value.toLocaleString('us-US');
  }

}
