import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';

import {Tsli} from './data/tsli';
import {TsliRestService} from './tsli-rest.service';
import {map} from 'rxjs/operators';
import {TsliResponse} from './data/tsli-response';
import {Status} from './enum/status.enum';
import {ConfigurationService} from './configuration.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TslisStoreService {

  public readonly tslis = new BehaviorSubject<Tsli[]>([]);
  readonly tslis$ = this.tslis.asObservable();
  public readonly drafts = new BehaviorSubject<Tsli[]>([]);
  readonly drafts$ = this.drafts.asObservable();
  public readonly published = new BehaviorSubject<Tsli[]>([]);
  readonly published$ = this.published.asObservable();
  public readonly archived = new BehaviorSubject<Tsli[]>([]);
  readonly archived$ = this.archived.asObservable();
  public selectedTab = new BehaviorSubject<number>(1);
  readonly selectedTab$ = this.selectedTab.asObservable();
  public subscription: Subscription;
  subscriptions = [];
  public TYPE_DESCRIPTION_TEXT;

  constructor(public rest: TsliRestService,
              public configService: ConfigurationService,
              public authService: AuthService) {
     this.subscription = this.rest.getAllTslis().subscribe(
       (response: TsliResponse) => this.tslis.next(response.tslis)
     );
  }


  modifyDateValidation(idTsli: number, date: Date, reviewer: number, approval: number): Observable<string> {
    return this.rest.modifyDateValidation(idTsli, date, reviewer, approval).pipe(
      map((response: string) => {
        return response;
      })
    );
  }

  modifyAuthor(idTsli: number, idNewAuthor: number): Observable<string> {
    return this.rest.modifyAuthor(idTsli, idNewAuthor).pipe(
      map((response: string) => {
        return response;
      })
    );
  }

  createTsli(addedTsli: Tsli): Observable<TsliResponse> {
    return this.rest.createTsli(addedTsli).pipe(
      map((response: TsliResponse) => {

        this.tslis.next([...this.tslis.getValue(), addedTsli]);
        return response;
      })
    );
  }

  createNewRevision(tsliNewRevision: Tsli): Observable<TsliResponse> {
    return this.rest.createTsliNewRevision(tsliNewRevision).pipe(
      map((response: TsliResponse) => {

        this.getAllDocuments().subscribe();

        // this.tslis.next([...this.tslis.getValue(), tsliNewRevision]);
        return response;
      })
    );
  }

  /**
   * Fetch all TSLIs from API
   *
   */
  getAllDocuments(): Observable<Tsli[]> {
    this.rest.getAllTslis().subscribe((response: TsliResponse) => {
      this.tslis.next(response["tslis"]);
      // filter results based status
      const drafts = response.tslis.filter(tsli => tsli.status === Status.WRITING || tsli.status === Status.REVIEWING
        || tsli.status === Status.APPROBATING || tsli.status === Status.REFUSED);
      const published = response.tslis.filter(tsli => tsli.status === Status.PUBLISHED);
      const archived = response.tslis.filter(tsli => tsli.status === Status.ARCHIVED);

      // a published TSLI can have an in-progress draft ?
      published.forEach(tsli => {
        if (tsli.draftStatus != undefined) {
          tsli.draftInProgress = true;
        }
      });

      this.drafts.next(drafts);
      this.published.next(published);
      this.archived.next(archived);
    });

    return this.tslis$;
  }

  getPublishedTslis(): Observable<any> {
    return this.rest.getPublishedDocuments()
  }


  getAllTslisByParam(param: string, value: any = undefined) {
    this.rest.getAllTslisByParam(param, value).subscribe(
      (response: TsliResponse) => this.tslis.next(response.tslis)
    );
    return this.tslis$;
  }

  setSelectedTab(index) {
    this.selectedTab.next(index);
  }

  deleteRevision(id_tsli, tsli_type) {
    this.rest.deleteRevision(id_tsli, tsli_type).subscribe(resp => {
      if (resp.msg == 'success') {
        this.subscription = this.getAllDocuments().subscribe();
      }
    });
  }

  deleteTsli(ref, tsli_type, doc_type) {
    this.rest.deleteTsli(ref, tsli_type, doc_type).subscribe(resp => {
      if (resp.msg == 'success') {
        this.subscription = this.getAllDocuments().subscribe();
      }
    });
  }

  isHTMLDocument(document: Tsli): boolean {
    return this.rest.isHTMLDocument(document);
  }

  getDocumentType(doctype: string): boolean {
    return this.rest.getDocumentType(doctype);
  }

  public getDynamicTSLICreation() {
    // using forkJoin to get all values at a time to process them to
    this.subscriptions.push(
      forkJoin(
        [this.configService.getConfigurationByName('creation_process_description'),
          this.configService.getConfigurationByName('creation_general_information_description'),
          this.configService.getConfigurationByName('creation_product_description'),
          this.configService.getConfigurationByName('creation_form_description'),
          this.configService.getConfigurationByName('creation_inor_description'),
          this.configService.getConfigurationByName('creation_legacy_description'),
          this.configService.getConfigurationByName('creation_ugia_description'),
          this.configService.getConfigurationByName('creation_ugfl_description'),
          this.configService.getConfigurationByName('creation_ugira_description')]
      ).subscribe(([process, gi, product, form,
                     inor, legacy, ugia, ugfl, ugira]) => {
        this.TYPE_DESCRIPTION_TEXT = {
          process: process['configurations'][0].value,
          general_information: gi['configurations'][0].value,
          product: product['configurations'][0].value,
          form: form['configurations'][0].value,
          inor: inor['configurations'][0].value,
          legacy: legacy['configurations'][0].value,
          ugia: ugia['configurations'][0].value,
          ugfl: ugfl['configurations'][0].value,
          ugira: ugira['configurations'][0].value
        };
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
