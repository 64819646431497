<div style="margin-bottom: 10px;" class="text-center">
  <mat-accordion *ngIf="chapter.comment">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{chapter.number}} {{chapter.title}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="comments-content">
        <comment *ngFor="let comment of comments;" [comment]="comment"></comment>
      </div>

      <mat-divider></mat-divider>
      <comment-input [comment]="inputComment"></comment-input>
      <div class="text-center">
        <button mat-raised-button color="primary" [disabled]="comments.length == 0"  
                  (click)="clearComments()" >{{" Clear Comments"}}</button>
             
      </div>
    </mat-expansion-panel>

    
  </mat-accordion>
</div>