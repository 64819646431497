<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Archive {{docType | uppercase}}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p><strong>you are about to archive this <span class="text-primary">{{docType | uppercase}} {{reference}} rev. {{revision}}</span>, it
      will no longer be visible by the licensee </strong></p>
  <p>
    <span class="text-danger">This operation can not be undone.</span>
  </p>
</div>
<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="dismiss()">Cancel</button>
  <button mat-flat-button color='warn' (click)="confirm()">Ok</button>
</div>