import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {DocumentType} from '../../../core/enum/document-type.enum';
import {forkJoin, Observable, of, timer} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {TsliMode} from '../../../core/enum/tsli-mode.enum';
import {Tsli} from '../../../core/data/tsli';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TslisStoreService} from '../../../core/tslis-store.service';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {TsliRestService} from '../../../core/tsli-rest.service';
import {UserRestService} from '../../../core/user-rest.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigurationService} from '../../../core/configuration.service';
import {AuthService} from '../../../core/auth.service';
import {CommentsStoreService} from '../../../core/comments-store.service';
import {CommentsRestService} from '../../../core/comments-rest.service';
import {DatePipe} from '@angular/common';
import {User} from '../../../core/data/User';
import {Comment} from '../../../core/data/comment';
import {Status} from '../../../core/enum/status.enum';
import {TsliResponse} from '../../../core/data/tsli-response';
import {Chapter} from '../../../core/data/chapter';
import {ErrorBarComponent} from '../../error-bar/error-bar.component';
import {
  CheckApplicationDateDialogComponent
} from '../../../core/dialog/check-application-date-dialog/check-application-date-dialog.component';
import {ServicesNamesConstants} from '../../../core/services-names.constants';
import {MatStepper} from '@angular/material/stepper';

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.scss']
})
export class CreateDocumentComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  reviewers: User[];
  approvers: User[];
  reviewer: User;
  approver: User;

  docType: string;
  docTitle: string;
  docReference: string;
  docRevision: string;

  typeDescriptionText = '';
  commitSuccessful = false;
  commitFailed = false;
  sendToReviewSuccessful = false;
  selectedReviewer;
  selectedApprover;
  docApplicableBy;
  docApplicationDate;
  tsliProcessDescription = '';
  tsliProductDescription = '';
  tsliGeneralInformationDescription = '';
  tsliFormDescription = '';
  tsliInorDescription = '';
  tsliLegacyDescription = '';
  tsliUgiaDescription = '';
  tsliUgflDescription = '';
  tsliUgiraDescription = '';
  type;

  docTypeInor = '';

  chapterOneTitle = '';
  chapterOneBody = '';
  chapterOneNumber = '';
  chapterTwoTitle = '';
  chapterTwoBody = '';
  chapterTwoNumber = '';
  subscriptions = [];


  progress: { percentage: number } = {percentage: 0};
  inputComment: Comment;


  docSource_file_s3_url: string;
  reference: string;
  title: string;
  comment: string;
  idTsli: string;
  source_file_s3_url: string;
  display_file_s3_url: string;
  documentToCreate: Tsli;
  documentToModify: Tsli;
  documentToRevise: Tsli;
  tsli: Tsli;
  errorMsg = '';
  activatedDataReloading = true;
  isArchivedDocument = false;

  activatedTsliMode: TsliMode;
  subscriptionActivatedTsliMode;


  // Send to review document
  applicationDate: Date;
  modalRef: NgbModalRef;
  tsliType: string;

  public TYPE_DESCRIPTION_TEXT;
  idUser: string;

  // Used to activate/deactivate Send to Review Button in case of HTML/No HTML document (step 3: third form group)
  isHTMLDoc = true;

  isTypeDisabled: boolean;
  isDocTypeDisabled = false;
  idCurrentTsliForRevision: string;
  revisionNumberIsRequired = false;
  documentType: Map<string, string> = new Map<string, string>();
  documentTypeKeys: string[];
  documentTypeValues: string[];
  isDisabledSourceFileZone: boolean;
  isDisabledDisplayFileZone: boolean;

  source_files: File[] = [];
  display_files: File[] = [];
  revision: string;


  constructor(private fb: FormBuilder,
              public formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public currentTsliStoreService: CurrentTsliStoreService,
              public rest: TsliRestService,
              public userRest: UserRestService,
              public snackBar: MatSnackBar,
              public configService: ConfigurationService,
              public authService: AuthService,
              public commentStore: CommentsStoreService,
              public commentsRestService: CommentsRestService,
              public datePipe: DatePipe,
              public modal: NgbModal) {
  }

  ngOnInit(): void {
    this.userRest.getUsers().subscribe((usersResponse: User[]) => {
      this.reviewers = usersResponse.filter(user => user.isReviewer);
    });
    this.userRest.getUsers().subscribe((usersResponse: User[]) => {
      this.approvers = usersResponse.filter(user => user.isApprover);
    });

    this.firstFormGroup = this.fb.group({
      docTypeCtrl: ['', [Validators.required], [this.checkIfRevisionNumberExists()]],
      referenceNumberCtrl: ['', [Validators.required], [this.checkIfRevisionNumberExists()], {updateOn: 'blur'}],
      titleCtrl: ['', Validators.required],
      revisionNumberCtrl: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      dropZoneSourceFileCtrl: ['', []],
      dropZoneDisplayFileCtrl: ['', []],
      commentRevisionCtrl: ['', []]
    });

    this.secondFormGroup = this.fb.group({
      typeSelectCtrl: ['', Validators.required]
    });

    // Step 3: thirdFormGroup
    this.thirdFormGroup = this.fb.group({
      applicationDateCtrl: ['', Validators.required],
      approverSelectCtrl: ['', Validators.required],
      reviewerSelectCtrl: ['', Validators.required],
      applicableByCtrl: ['', Validators.required]
    });


    this.title = 'Create a new document';

    setTimeout(() => {
      //Step 1: firstFormGroup
      DocumentType.forEach((label, value) => {
        this.documentType.set(label, value);
      });
      this.documentTypeKeys = Array.from(this.documentType.values());
      this.documentTypeValues = Array.from(this.documentType.keys());

      this.firstFormGroup.controls.commentRevisionCtrl.disable();

      this.isDisabledSourceFileZone = true;
      this.isDisabledDisplayFileZone = true;
      this.isTypeDisabled = false;

      this.secondFormGroup.controls.typeSelectCtrl.valueChanges.subscribe((value: string) => {
        this.typeDescriptionText = this.TYPE_DESCRIPTION_TEXT[value];
      });

      // Create new revision (with uploaded files)
      this.subscriptionActivatedTsliMode = this.currentTsliStoreService.activatedMode$
        .subscribe(value => {
          this.activatedTsliMode = value;
          if (value == TsliMode.revisingMode) {

            this.title = 'Create a new revision';

            this.firstFormGroup = this.fb.group({
              docTypeCtrl: ['', []],
              referenceNumberCtrl: ['', []],
              titleCtrl: ['', []],
              revisionNumberCtrl: ['', []],
              dropZoneSourceFileCtrl: ['', []],
              dropZoneDisplayFileCtrl: ['', []],
              commentRevisionCtrl: ['', []]
            });

            // firstFormGroup
            this.docTitle = this.documentToRevise.title;
            this.docReference = this.documentToRevise.reference;
            this.docType = this.documentToRevise.docType.toLowerCase();
            this.revision = this.documentToRevise.revision;


            this.firstFormGroup.controls.docTypeCtrl.setValue(this.documentToRevise.docType.toLowerCase());
            this.firstFormGroup.controls.referenceNumberCtrl.setValue(this.documentToRevise.reference);
            this.firstFormGroup.controls.titleCtrl.setValue(this.documentToRevise.title);
            this.firstFormGroup.controls.revisionNumberCtrl.setValue(this.documentToRevise.revision + 1);

            this.firstFormGroup.controls.titleCtrl.disable();
            this.isDocTypeDisabled = true;
            this.firstFormGroup.controls.referenceNumberCtrl.disable();
            this.firstFormGroup.controls.revisionNumberCtrl.disable();

            // enable upload display file
            this.isDisabledSourceFileZone = !this.getDocumentType(this.docType);
            this.isDisabledDisplayFileZone = true;

          }
        });
    });
  }

  onDocumentTypeChange($event) {
    this.docType = this.firstFormGroup.controls.docTypeCtrl.value;
    this.docReference = this.firstFormGroup.controls.referenceNumberCtrl.value;
    this.docRevision = this.firstFormGroup.controls.revisionNumberCtrl.value;
    this.isDisabledSourceFileZone = !this.getDocumentType(this.docType);

    if (this.isDisabledSourceFileZone) {
      this.onRemove($event);
    }
    console.log('doctype:', this.docType);
  }

  onSelect(event) {
    console.log(event);
    this.source_files.splice(this.source_files.indexOf(event), this.source_files.length);
    this.source_files.push(...event.addedFiles);

    this.firstFormGroup.controls.commentRevisionCtrl.enable();

    if (this.source_files.length > 0) {
      this.isDisabledDisplayFileZone = false;
    }

    //
    this.isHTMLDoc = false;

    this.firstFormGroup.controls.commentRevisionCtrl.addValidators(Validators.required);
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();
  }

  onRemove(event) {
    this.source_files.splice(this.source_files.indexOf(event), 1);
    this.firstFormGroup.controls.dropZoneSourceFileCtrl.setValue('');
    this.display_files.splice(this.display_files.indexOf(event), 1);
    this.isDisabledDisplayFileZone = true;

    this.firstFormGroup.controls.commentRevisionCtrl.setValue('');
    this.firstFormGroup.controls.commentRevisionCtrl.disable();
    this.firstFormGroup.controls.commentRevisionCtrl.clearValidators();
    this.firstFormGroup.controls.commentRevisionCtrl.updateValueAndValidity();

    this.isHTMLDoc = true;
  }

  onSelectDisplayFile(event) {
    console.log(event);
    this.display_files.splice(this.display_files.indexOf(event), this.display_files.length);
    this.display_files.push(...event.addedFiles);
  }

  onRemoveDisplayFile(event) {
    console.log(event);
    this.display_files.splice(this.display_files.indexOf(event), 1);
  }

  checkIfRevisionNumberExists(): ValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      return this.documentValidatorWithRevisionNumber();
    };
  }

  documentValidatorWithRevisionNumber(): Observable<ValidationErrors | null> {
    return timer(20).pipe(
      switchMap(() => {
        this.docReference = this.firstFormGroup.controls.referenceNumberCtrl.value;
        console.log('ref+doctype:', this.docType + '/' + this.docReference);
        if (this.docReference !== '' && this.docType !== '' && this.docReference !== undefined && this.docType !== undefined) {
          return this.rest.isReferenceNumberUnique(this.docReference, this.docType).pipe(
            map((value) => {
              if (value.response == 'reference_number.not.found') {
                this.firstFormGroup.controls.referenceNumberCtrl.setErrors(null);
                this.firstFormGroup.controls.docTypeCtrl.setErrors(null);
                return null;
              } else {
                return {documentExists: true};
              }
            }),
            catchError(() => null)
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getDocumentType(docType: string): boolean {
    return this.tslisStore.getDocumentType(docType);
  }

  goForward(stepper: MatStepper) {
    if (stepper.selectedIndex == 1) {
      if (this.activatedTsliMode == TsliMode.revisingMode) {
        // secondFormGroup
        this.secondFormGroup.controls.typeSelectCtrl.setValue(this.documentToRevise.type);
        this.isTypeDisabled = true;
      } else {
        if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_FORM) {
          this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_FORM);
          this.isTypeDisabled = true;
        } else if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_INOR) {
          this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_INOR);
          this.isTypeDisabled = true;
        } else {
          this.isTypeDisabled = false;
          this.secondFormGroup.controls.typeSelectCtrl.setValue('');
        }
        this.secondFormGroup.controls.typeSelectCtrl.updateValueAndValidity();
      }
    }
  }

  ngAfterViewInit(): void {
    this.getDynamicTSLICreation();
  }

  private getDynamicTSLICreation() {
    // using forkJoin to get all values at a time to process them to
    this.subscriptions.push(
      forkJoin(
        [this.configService.getConfigurationByName('creation_process_description'),
          this.configService.getConfigurationByName('creation_general_information_description'),
          this.configService.getConfigurationByName('creation_product_description'),
          this.configService.getConfigurationByName('creation_form_description'),
          this.configService.getConfigurationByName('creation_inor_description'),
          this.configService.getConfigurationByName('creation_legacy_description'),
          this.configService.getConfigurationByName('creation_ugia_description'),
          this.configService.getConfigurationByName('creation_ugfl_description'),
          this.configService.getConfigurationByName('creation_ugira_description'),
          this.configService.getConfigurationByName('chapter_one_title'),
          this.configService.getConfigurationByName('chapter_one_body'),
          this.configService.getConfigurationByName('chapter_two_title'),
          this.configService.getConfigurationByName('chapter_two_body'),
          this.configService.getConfigurationByName('chapter_one_number'),
          this.configService.getConfigurationByName('chapter_two_number')]
      ).subscribe(([process, gi, product, form,
                     inor, legacy, ugia, ugfl, ugira
                     , chapterOneTitle, chapterOneBody, chapterTwoTitle
                     , chapterTwoBody, chapterOneNumber, chapterTwoNumber]) => {
        this.tsliProductDescription = product['configurations'][0].value;
        this.tsliGeneralInformationDescription = gi['configurations'][0].value;
        this.tsliProcessDescription = process['configurations'][0].value;
        this.tsliFormDescription = form['configurations'][0].value;
        this.tsliInorDescription = inor['configurations'][0].value;
        this.tsliLegacyDescription = legacy['configurations'][0].value;
        this.tsliUgiaDescription = ugia['configurations'][0].value;
        this.tsliUgflDescription = ugfl['configurations'][0].value;
        this.tsliUgiraDescription = ugira['configurations'][0].value;

        this.TYPE_DESCRIPTION_TEXT = {
          process: this.tsliProcessDescription,
          product: this.tsliProductDescription,
          general_information: this.tsliGeneralInformationDescription,
          form: this.tsliFormDescription,
          inor: this.tsliInorDescription,
          legacy: this.tsliLegacyDescription,
          ugia: this.tsliUgiaDescription,
          ugfl: this.tsliUgflDescription,
          ugira: this.tsliUgiraDescription
        };
        this.chapterOneTitle = chapterOneTitle['configurations'][0].value;
        this.chapterOneBody = chapterOneBody['configurations'][0].value;
        this.chapterTwoTitle = chapterTwoTitle['configurations'][0].value;
        this.chapterTwoBody = chapterTwoBody['configurations'][0].value;
        this.chapterOneNumber = chapterOneNumber['configurations'][0].value;
        this.chapterTwoNumber = chapterTwoNumber['configurations'][0].value;

      }));
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  checkApplicationDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.applicationDate = new Date(this.thirdFormGroup.controls.applicationDateCtrl.value);
    if (this.applicationDate.getTime() < tomorrow.getTime()) {
      const modalRef: NgbModalRef = this.modal.open(CheckApplicationDateDialogComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalRef.componentInstance.applicationDate = this.datePipe.transform(this.applicationDate, 'dd/MM/yyyy');
      // modalRef.componentInstance.validationMode = this.activatedTsliMode == 'REVIEWING_MODE' || this.activatedTsliMode == 'APPROVING_MODE'
      return modalRef.result;
    }
  }

  checkDate() {
    const checkResult = this.checkApplicationDate();
    if (checkResult === undefined) {
      return this.commitDocument(true);
    }

    checkResult.then((userResponse) => {
      if (userResponse === 'continue') {
        this.commitDocument(true);
      }
    }).catch(err => console.error(err));
  }

  sendToReview() {
    if (this.thirdFormGroup.valid) {
      this.checkDate();
    }
  }

  saveDocument() {
    if (this.thirdFormGroup.valid) {
      this.commitDocument(false);
    }
  }

  commitDocument(isSentToReview: boolean): void {
    const document: Tsli = new Tsli();
    document.docType = this.firstFormGroup.controls.docTypeCtrl.value.toLowerCase();
    document.title = this.firstFormGroup.controls.titleCtrl.value;
    document.reference = this.firstFormGroup.controls.referenceNumberCtrl.value;
    document.revision = this.firstFormGroup.controls.revisionNumberCtrl.value;
    document.type = this.secondFormGroup.controls.typeSelectCtrl.value.toLowerCase();
    document.applicationDate = this.thirdFormGroup.controls.applicationDateCtrl.value;
    document.reviewer = new User();
    document.reviewer.id = this.thirdFormGroup.controls.reviewerSelectCtrl.value;
    document.approver = new User();
    document.approver.id = this.thirdFormGroup.controls.approverSelectCtrl.value;
    document.applicableBy = this.thirdFormGroup.controls.applicableByCtrl.value;
    document.isLegacy = document.type.toUpperCase() === 'LEGACY';
    document.isArchivedDocument = false;
    document.status = Status.WRITING;
    this.authService.user.subscribe(user => {
      document.author = user;
    });

    if (this.activatedTsliMode === TsliMode.revisingMode) {
      console.log('create new revision', document);
      this.CreateNewRevision(document, isSentToReview);
    } else if (this.activatedTsliMode === TsliMode.simpleEditionMode && this.getDocumentType(this.docType)) {
      console.log('edit document', document);
      this.editDocument(document, isSentToReview);
    } else {
      console.log('create new document', document);
      document.is_restricted_document = false;
      this.createNewDocument(document, isSentToReview);
    }
  }

  isHTMLDocument(document: Tsli) {
    return this.tslisStore.isHTMLDocument(document);
  }

  displayError(msg: string, statusCode: string, statusText: string) {
    this.snackBar.openFromComponent(ErrorBarComponent, {
      duration: 5000,
      data: {
        msg,
        statusCode,
        statusText
      }
    });
  }

  addRevisionComment(tsli: Tsli) {
    this.inputComment = new Comment();
    this.inputComment.commentDate = new Date();
    this.inputComment.tsli = tsli;
    this.inputComment.is_revision_comment = '1';
    this.authService.user.subscribe(user => {
      this.inputComment.commentator = user;
    });
    this.inputComment.comment = JSON.parse(JSON.stringify(this.firstFormGroup.controls.commentRevisionCtrl.value));
    this.commentStore.addComment(this.inputComment);

  }

  createNewDocument(document: Tsli, isSentToReview: boolean) {
    this.tslisStore.createTsli(document).subscribe(
      (tsliResponse: TsliResponse) => {

        if (isSentToReview) {
          this.sendToReviewSuccessful = true;
          this.commitSuccessful = false;
        } else {
          this.sendToReviewSuccessful = false;
          this.commitSuccessful = true;
        }
        document.idTsli = tsliResponse.tsli.idTsli;

        // upload source and display file and no chapter to create
        if (!this.isHTMLDoc) {
          this.uploadSourceFile(document, isSentToReview);
          this.uploadDisplayFile(document, isSentToReview);
          this.addRevisionComment(document);
        } else {
          const chapters: Chapter[] = [];
          const chapterOne: Chapter = new Chapter();
          chapterOne.depth = 0;
          chapterOne.index = 0;
          chapterOne.title = this.chapterOneTitle;
          chapterOne.body = this.chapterOneBody;
          chapterOne.number = this.chapterOneNumber;
          chapterOne.titleStyle = null;
          chapters.push(chapterOne);
          const chapterTwo: Chapter = new Chapter();
          chapterTwo.depth = 0;
          chapterTwo.index = 1;
          chapterTwo.title = this.chapterTwoTitle;
          chapterTwo.body = this.chapterTwoBody;
          chapterTwo.number = this.chapterTwoNumber;
          chapterTwo.titleStyle = null;
          chapters.push(chapterTwo);
          this.currentTsliStoreService.addChaptersWhenTsliCreated(document, chapters);
        }
      },
      (error: any) => {
        this.displayError('An HTTP error occurred! ', error.status, error.statusText);
        this.commitFailed = true;
      });
  }

  editDocument(document: Tsli, isSentToReview: boolean) {
    document.idTsli = this.documentToModify.idTsli;

    this.rest.updateTsli(document, this.isHTMLDoc).subscribe(response => {
      console.log(response);
      if (isSentToReview) {
        this.sendToReviewSuccessful = true;
        this.commitSuccessful = false;
      } else {
        this.sendToReviewSuccessful = false;
        this.commitSuccessful = true;
      }
    });
  }

  CreateNewRevision(document: Tsli, isSentToReview: boolean) {
    this.tslisStore.createNewRevision(document).subscribe(
      (tsliResponse: TsliResponse) => {
        if (tsliResponse.tsli == null) {
          this.displayError('An HTTP error occurred! ', tsliResponse.error, tsliResponse.error);
          this.errorMsg = tsliResponse.error ? tsliResponse.error : 'TSLI IS NULL';
          this.commitFailed = true;
        } else {
          this.idCurrentTsliForRevision = tsliResponse.tsli.idTsli;
          document.idTsli = tsliResponse.tsli.idTsli;
          if (isSentToReview) {
            this.sendToReviewSuccessful = true;
            this.commitSuccessful = false;
          } else {
            this.sendToReviewSuccessful = false;
            this.commitSuccessful = true;
          }

          // upload source and display file and no chapter to add
          if (!this.isHTMLDoc) {
            this.uploadSourceFile(document, isSentToReview);
            this.uploadDisplayFile(document, isSentToReview);
            this.addRevisionComment(document);
          } else if (tsliResponse.tsli.chapters.length == 0) { // new revision: convert document with sf to HTML => create new chapters
            const chapters: Chapter[] = [];
            const chapterOne: Chapter = new Chapter();
            chapterOne.depth = 0;
            chapterOne.index = 0;
            chapterOne.title = this.chapterOneTitle;
            chapterOne.body = this.chapterOneBody;
            chapterOne.number = this.chapterOneNumber;
            chapterOne.titleStyle = null;
            chapters.push(chapterOne);
            const chapterTwo: Chapter = new Chapter();
            chapterTwo.depth = 0;
            chapterTwo.index = 1;
            chapterTwo.title = this.chapterTwoTitle;
            chapterTwo.body = this.chapterTwoBody;
            chapterTwo.number = this.chapterTwoNumber;
            chapterTwo.titleStyle = null;
            chapters.push(chapterTwo);
            this.currentTsliStoreService.addChaptersWhenTsliCreated(document, chapters);
          }
        }
      }
    );
  }

  uploadSourceFile(document: Tsli, isSentToReview: boolean): boolean {
    if (this.source_files.length > 0) {
      this.rest.uploadFileWithPreSignedURL(this.source_files[0]).subscribe(source_files_resp => {
        console.log(source_files_resp);
        document.source_file_s3_url = source_files_resp.s3_filename;
        this.rest.update_source_file_url(document.idTsli, document.source_file_s3_url).subscribe(response => {
          console.log(response);
          if (isSentToReview) {
            this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
              console.log(resp);
            });
          }
          this.tslisStore.getAllDocuments().subscribe();
        });
      });

      return true;
    }
  }

  uploadDisplayFile(document: Tsli, isSentToReview: boolean) {
    if (this.display_files.length > 0) {
      this.rest.uploadFileWithPreSignedURL(this.display_files[0]).subscribe(display_files_resp => {
        console.log(display_files_resp);
        document.display_file_s3_url = display_files_resp.s3_filename;
        this.rest.update_display_file_url(document.idTsli, document.display_file_s3_url).subscribe(response => {
          console.log(response);
          if (isSentToReview) {
            this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
              console.log(resp);
            });
          }
          this.tslisStore.getAllDocuments().subscribe();
        });
      });
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

}
