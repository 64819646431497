import { Component, OnInit } from '@angular/core';
import {User} from "../../../core/data/User";
import {UserRestService} from "../../../core/user-rest.service";
import {ApplicabilityService} from "../../../core/applicability.service";
import {Location} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {SelectionModel} from "@angular/cdk/collections";
import {forkJoin} from "rxjs";
import {ProductService} from "../../../core/product.service";

@Component({
  selector: 'app-product-list-management',
  templateUrl: './product-list-management.component.html',
  styleUrls: ['./product-list-management.component.scss']
})
export class ProductListManagementComponent implements OnInit {

  user: User = null;
  connectionsfilter = [];
  rowsConnectionSelection = new SelectionModel<string>(true, []);
  isSelected: boolean = false

  constructor(public userService: UserRestService,
              public productService: ProductService,
              public applicabilityService: ApplicabilityService,
              public modal: NgbModal,
              public location: Location,
              public router: Router) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUpdatedUser();
    forkJoin(
      [this.applicabilityService.getVisibleProducts()]
    ).subscribe(response => {
      for (const key in response[0]){
        this.connectionsfilter.push(key)
      }
      this.connectionsfilter.sort();
      this.connectionsfilter.forEach(item => {
        if(response[0][item])
          this.rowsConnectionSelection.select(item)
      });
    });
  }

  selectAll() {
    this.rowsConnectionSelection.clear();
    console.log(this.isSelected)
    if (!this.isSelected){
      this.connectionsfilter.forEach(connection => this.rowsConnectionSelection.select(connection));
      this.isSelected = true
    }
    else {
      this.isSelected = false
    }
  }

  commitProductsSelection(){
    console.log(this.rowsConnectionSelection)
    let productsForRights = {}
    for (const connection of this.connectionsfilter){
      productsForRights[connection] = this.rowsConnectionSelection.isSelected(connection)
    }

    this.productService.updateProductVisibility(productsForRights)
      .subscribe((Response) => {
        this.location.back();
      });
  }
}
