<div class="tbl-row">
  <mat-tab-group (selectedTabChange)="switchSelectedTab($event)" class="assemblyTab">
    <mat-tab label="Field End" style="min-width: 50%;">
      <assembly-data-header-grid></assembly-data-header-grid>

    </mat-tab>
    <mat-tab label="Mill End" style="min-width: 50%;">
        <assembly-data-header-grid></assembly-data-header-grid>
    </mat-tab>

  </mat-tab-group>
</div>
