<div class="mt-1 item">
  <div class="d-flex justify-content-between pt-2 pl-3 pr-2 pb-1" (click)="collapse()" [ngClass]="{'collapsed': item.isCollapsed, 'uncollapsed': !item.isCollapsed}">
      <div class="checkbox-content">
          <mat-checkbox class="mr-1" [disabled]="isShowApplicabilityMode"
                        color="primary"
                        [indeterminate]="item.indeterminate"
                        [(ngModel)]="item.checked"
                        (change)="checkChildren($event)"
                        (click)="$event.stopPropagation()"
                        ></mat-checkbox>
                        <ng-container *ngIf="item.type.toUpperCase() == 'CONNECTION'">{{item.value.toUpperCase().includes('SPECIFIC_REQUIREMENT')? item.value.toUpperCase().replace('-SPECIFIC_REQUIREMENT', ''):item.value}} <strong [hidden] = "!item.value.toUpperCase().includes('SPECIFIC_REQUIREMENT')" style="color:crimson">{{" Specific"}}</strong></ng-container>
                          <ng-container *ngIf="item.type.toUpperCase() != 'OD' && item.type.toUpperCase() != 'CONNECTION'">
                            {{item.value}} 
                            <strong *ngIf = "item.customer_information && isSpecific != '' && item.drawing" 
                          [matTooltip]="'customer information : '+item.customer_information  + '' +
                          '   Drawing: '+ item.drawing "
                          [matTooltipClass]="matTooltip" style="flex: 2 2; color:crimson"
                            >
                              {{"Cust. & Drawing"}}</strong>
                            </ng-container>

                          <ng-container *ngIf="item.type.toUpperCase() === 'OD'">{{item.value | fraction}}</ng-container>
                      
      </div>
      <div>
        <mat-icon *ngIf="item.isCollapsed">expand_less</mat-icon>
        <mat-icon *ngIf="!hasChildren && !item.isCollapsed || !item.isCollapsed">expand_more</mat-icon>
      </div>
  </div>

  <div [hidden]="!item.isCollapsed">
      <div class="d-flex" *ngFor='let child of item.children'>
        <applicability-item
          #applicabilityItems
          [item]="child" [cols]="cols-1"
          [parents]="parents"
          [chapterIndex]="chapterIndex"
          [itemsOrder] = itemsOrder
          (sendToParent) = 'eventFromChild($event)'
          (callEnableValidateButton) = 'asParentCallEnableValidateButton()'
          (callParentNotAllChildrenAreChecked) = 'asParentSetAllChildrenCheckedToFalse()'
          style="flex: 1; margin-left: 12.5vw;"></applicability-item>
      </div>
  </div>
</div>
