import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailedTsliStore } from '../../detailed-tsli-store.service';
import { CurrentTsliStoreService } from '../../current-tsli-store.service';
import { User } from '../../data/User';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, 
    public detailedTsli: DetailedTsliStore,
    public store: CurrentTsliStoreService) { }
    reviewer: User;
  ngOnInit() {
    this.store.tsli$.subscribe(tsli=>{
      console.log(tsli);
      this.reviewer = tsli.reviewer;
    });
  }

  close(): void {
    this.activeModal.close('confirmed')
  }

  ngOnDestroy(){
    this.detailedTsli.isSendToReview = false;
  }

}
