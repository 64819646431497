import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitNumberFormat'
})
export class UnitNumberFormatPipe implements PipeTransform {

  transform(value: number, imperialOrSI?: boolean): any {
    if (value == 0) return '';
    if(!imperialOrSI) return value.toLocaleString('fr-FR');
    return value.toLocaleString('us-US');
  }

}
