import {Component, OnInit} from '@angular/core';
import {TslisStoreService} from 'src/app/core/tslis-store.service';
import {Tsli} from 'src/app/core/data/tsli';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {Status} from 'src/app/core/enum/status.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TsliRestService} from '../../../../core/tsli-rest.service';


@Component({
  selector: 'tsli-hisory',
  templateUrl: './tsli-hisory.component.html',
  styleUrls: ['./tsli-hisory.component.scss']
})
export class TsliHistoryComponent implements OnInit {
  tslis: Tsli[];
  reference: string;
  docType: string;
  revision: number;
  reader: boolean;
  reviwerOrApprover: boolean;
  fileToDownload: string;

  constructor(public tslisStore: TslisStoreService,
              public activeModal: NgbActiveModal,
              public router: Router,
              public modal: NgbModal,
              public rest: TsliRestService) {
  }

  ngOnInit() {
    if (this.reader) {
      this.tslisStore.tslis$.subscribe(res => this.tslis =
        res.filter(tsli => tsli.reference === this.reference && this.docType == tsli.docType && tsli.status == Status.ARCHIVED)
          .sort((a, b) => {
            return Number.parseInt(b.revision) - Number.parseInt(a.revision);
          }).slice(0, 2));
    } else if (this.reviwerOrApprover) {
      this.tslisStore.tslis$.subscribe(res => this.tslis =
        res.filter(tsli => tsli.reference === this.reference && this.docType == tsli.docType && tsli.status == Status.ARCHIVED)
          .sort((a, b) => {
            return Number.parseInt(b.revision) - Number.parseInt(a.revision);
          }).slice(0, 5));
    } else {
      this.tslisStore.tslis$.subscribe(res => this.tslis = res.filter(tsli => tsli.reference === this.reference
        && this.docType == tsli.docType && tsli.status == Status.ARCHIVED)
        .sort((a, b) => {
          return Number.parseInt(b.revision) - Number.parseInt(a.revision);
        }));
    }
  }

  dismiss() {
    this.activeModal.dismiss('dissmissed');
  }

  viewRevision(document: Tsli) {
    if (document.isArchivedDocument || document.source_file_s3_url != undefined) {
      if (document.display_file_s3_url != null) {
        this.fileToDownload = document.display_file_s3_url;
      } else {
        this.fileToDownload = document.source_file_s3_url;
      }

      this.rest.downloadDisplayAndSourceFile(this.fileToDownload, document.reference, document.docType).subscribe(
        response => {
          window.open(response, '_self');
        });

    } else {
      this.router.navigate([`/tsli/by-id-reference/${document.idTsli}`]);
      this.activeModal.close('confirmed');
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
}
