import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './../auth.service';
import { TsliRestService } from '../tsli-rest.service';
import { map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService,
                    public route: ActivatedRoute,
              public tsliRest: TsliRestService, public router: Router) {


  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):Observable<boolean> {
      return this.route.queryParamMap.pipe(map((paramsMap)=>{
        if (paramsMap.keys.indexOf('token') !== -1) {
          if(this.authService.getToken() != paramsMap.get('token')){
            return this.authService.checkLogIn(paramsMap.get('token'));
          }
        }
        return true;
      }));
      
}

}
