import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Product} from './data/product';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BasicProductDataRestService {
  public readonly API_PRODUCT_BASE_URL: string = environment.API_BASE_URL;
  readonly API_BASE_LOCAL_URL: string = environment.API_BASE_LOCAL_URL;
  public readonly filterMapping: Record<string, { type: string }> = {
    'VAM® 21': { type: 'Pipe Field End' },
    'VAM® 21 HT': { type: 'Pipe Field End' },
    'VAM TOP ®': { type: 'Both' },
    'VAM TOP ® HC': { type: 'Both' },
    'VAM TOP ® HT': { type: 'Pipe Mill End' },
    'VAM TOP ® FE': { type: 'Both' },
    'VAM® HP': { type: 'Both' },
    'VAM® HP-II': { type: 'Both' },
    'VAM® LOX': { type: 'Both' },
    'DINO VAM': { type: 'Pipe Field End' },
    'BIG OMEGA ®': { type: 'Both' },
    'BIG OMEGA ® IS': { type: 'Both' },
    'VAM® HTTC': { type: 'Mr Help' },
    'VAM® SPRINT-TC': { type: 'Mr Help' },
    'VAM® SLIJ-II': { type: 'Pipe Field End' },
    'VAM® SLIJ-3': { type: 'Pipe Field End' },
    'VAM® SPRINT-SF': { type: 'Pipe Field End' },
    'VAM® SFC': { type: 'Pipe Field End' },
    'VAM® EDGE SF': { type: 'Pipe Field End' },
    'VAM® EDGE SF+': { type: 'Pipe Field End' },
    'VAM® SG': { type: 'Pipe Field End' },
    'VAM® HTF-NR': { type: 'Pipe Field End' },
    'VAM® BOLT-II': { type: 'Pipe Field End' },
    'VAM® FJL': { type: 'Pipe Field End' },
    'VAM® SPRINT-FJ': { type: 'Pipe Field End' },
    'VAM® MUST': { type: 'Both' },
    'VAM® EPIC': { type: 'Pipe Field End' },
    'VAM® HTF-NT': { type: 'Pipe Field End' },
    'VAM® HTT': { type: 'Both' }
  };

  filterResponse(response: any): any {
    response.body.products.forEach((product: any) => {
      const connectionType = this.filterMapping[product.connection]?.type;
      if (connectionType) {
        response.body.assembly = response.body.assembly.map((fld: any) => {
          if (connectionType === 'Pipe Field End' && fld.side === 'Mill') {
            return { id: fld.id, side: fld.side };
          } else if (connectionType === 'Pipe Mill End' && fld.side === 'Field') {
            return { id: fld.id, side: fld.side };
          } else if (connectionType === 'Mr Help') {
            const newFld: any = {
              ...fld
            };
            for (const key in fld) {
              if (fld.hasOwnProperty(key)) {
                if (key !== 'id' && key !== 'side') {
                  newFld[key] = 'Please contact Mr Help';
                }
              }
            }
            return newFld;
          }
          return fld;
        });
      }
    });
    return response;
  }

  constructor(public http: HttpClient, public authService: AuthService, public router: Router) {
  }

  getBasicProductData(product: Product | Product[]): Observable<any> {
    let body: any = {product: product};
    body["product"]["connection"] = body["product"]["product"]

    if (Array.isArray(product)) {
      body = {products: product};
    }

    return this.http.post(
      this.API_PRODUCT_BASE_URL + '/bpd_bulk/get-bpd-by-item',
      body,
      this.authService.getAuthorization('service')).pipe(
      map(resp => {
        const productType = body.product.productType;
        if (productType === 'Accessories') {
          return this.filterResponse(resp);
        }
        else {
          return resp;
        }
      }));
  }

  getThreadCompoundHeaders(): Observable<any[]> {
    return this.http.get<any>(
      this.API_PRODUCT_BASE_URL + '/bpd_bulk/get-thread-compound-headers',
      this.authService.getAuthorization('service')).pipe(
      map((response: any): any => {
        return response;
      })
    );
  }
}
