<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">{{ docType | uppercase }} - {{reference}} history</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="container">

  <div class="row scrollable-content">
    <div class="row tsli-row" *ngFor="let tsli of tslis">
      <div class="col-md-10">
        <p class="mat-body">rev.<span class="mat-body-strong">{{tsli.revision}}</span> - {{tsli.title}}</p>

      </div>
      <div class="col-md-2">
          <button mat-raised-button class="float-right" color="primary" style="margin-right: 20px;"
          (click)="viewRevision(tsli)">{{tsli.isArchivedDocument || tsli.source_file_s3_url != undefined ? "Download" : "View" }}</button>
      </div>
    </div>

  </div>
</div>
