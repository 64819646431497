import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Notification } from 'src/app/core/data/Notification';
import { NotificationService } from 'src/app/core/notification.service';
import { UserRestService } from 'src/app/core/user-rest.service';

import * as CKEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'admin-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @ViewChild('chapterEditor') myEditor: CKEditorComponent;
  notifFormGroup: FormGroup;
  dataSource = new MatTableDataSource<Notification>([]);
  readMode = false;
  selectedId;
  editionMode = false;
  licenseeNumber;
  role;
  licensses = [];
  descriptions = {
    'all': 'All Roles are allowed to see the notification',
    'admin': 'Only Admins are allowed to see the notification',
    'approver': 'Admins and Approvers are allowed to see the notification',
    'reviewer': 'Admins, Approvers and Reviewers are allowed to see the notification'
  };

  Editor = CKEditor;
  displayedColumns: string[] = ["title", "role", "validity", "licenseeNumber", "action"];

  public config = {
    placeholder: 'Type the content here!',
  };

  public isDisabled = false;

  constructor(
    public formBuilder: FormBuilder,
    public store: NotificationService,
    public userStore: UserRestService
  ) { }

  ngOnInit() {
    this.notifFormGroup = this.formBuilder.group({
      roleCtrl: ['', [Validators.required]],
      validityDateCtrl: ['', Validators.required],
      titleCtrl: ['', Validators.required],
      licenseeNumberCtrl: ['', Validators.required],
      permanentCtrl: [''],
      addBtn: ['', Validators.required],
      ctn: ['']
    });

    this.store.getAllNotitifications().subscribe(v => {
      this.dataSource.data = v["notifications"];
    });

    this.store.notifications$.subscribe(v => {
      this.dataSource.data = v;
    });

    this.userStore.getLicensseeNumbers().subscribe(v => this.licensses = v);

    // Listen to the editor's ready event
    this.myEditor.ready.subscribe(() => {
      // Now the editor is fully initialized
    });
  }

  addNotification() {
    let notification = new Notification();
    notification.title = this.notifFormGroup.controls.titleCtrl.value;
    notification.content = this.myEditor.editorInstance.getData();
    notification.role = this.notifFormGroup.controls.roleCtrl.value;
    let date = new Date(this.notifFormGroup.controls.validityDateCtrl.value);
    notification.validity = date.toString();
    notification.licenseeNumber = this.notifFormGroup.controls.licenseeNumberCtrl.value;
    notification.permanent = this.notifFormGroup.controls.permanentCtrl.value;
    this.store.addNotification(notification).subscribe(v => {
      this.dataSource.data = v["notifications"];
    });
    this.clearValues();
  }

  updateNotification() {
    let notification = new Notification();
    notification.id = this.selectedId;
    notification.title = this.notifFormGroup.controls.titleCtrl.value;
    notification.content = this.myEditor.editorInstance.getData();
    notification.role = this.notifFormGroup.controls.roleCtrl.value;
    let date = new Date(this.notifFormGroup.controls.validityDateCtrl.value);
    notification.validity = date.toString();
    notification.licenseeNumber = this.notifFormGroup.controls.licenseeNumberCtrl.value;
    notification.permanent = this.notifFormGroup.controls.permanentCtrl.value;

    this.store.updateNotification(notification).subscribe(v => {
      this.dataSource.data = v["notifications"];
    });
    this.editionMode = false;
    this.clearValues();
  }

  edit(notification: Notification) {
    this.selectedId = notification.id;
    this.setValues(notification);
    this.editionMode = true;
    this.readMode = false;
    this.notifFormGroup.enable();
    this.myEditor.editorInstance.isReadOnly = false;
  }

  show(notification: Notification) {
    this.setValues(notification);
    this.readMode = true;
    this.editionMode = false;
    this.notifFormGroup.disable();
    this.myEditor.editorInstance.isReadOnly = true;
  }

  delete(idNotif) {
    if (this.selectedId === idNotif) {
      this.clearValues();
    }
    this.readMode = false;
    this.editionMode = false;
    this.notifFormGroup.enable();

    this.store.deleteNotification(idNotif).subscribe(v => {
      this.dataSource.data = v["notifications"];
    });
  }

  setValues(notification: Notification) {
    this.notifFormGroup.controls.titleCtrl.setValue(notification.title);
    this.myEditor.ready.subscribe(() => {
      this.myEditor.editorInstance.setData(notification.content);
    });
    this.notifFormGroup.controls.roleCtrl.setValue(notification.role);
    let date = new Date(notification.validity);
    this.notifFormGroup.controls.validityDateCtrl.setValue(date.toISOString());
    this.notifFormGroup.controls.licenseeNumberCtrl.setValue(notification.licenseeNumber);
    this.notifFormGroup.controls.permanentCtrl.setValue(notification.permanent);
  }

  clearValues() {
    this.notifFormGroup.controls.titleCtrl.setValue('');
    this.notifFormGroup.controls.roleCtrl.setValue('');
    this.notifFormGroup.controls.validityDateCtrl.setValue('');
    this.notifFormGroup.controls.licenseeNumberCtrl.setValue('');
    this.notifFormGroup.controls.permanentCtrl.setValue('');
    this.myEditor.ready.subscribe(() => {
      this.myEditor.editorInstance.setData('');
    });
  }

  disableBtn(c) {
    if (this.myEditor.editorInstance.getData().length > 0) {
      this.notifFormGroup.controls.addBtn.disable();
    } else {
      this.notifFormGroup.controls.addBtn.enable();
    }
  }

  cancel() {
    this.editionMode = false;
    this.readMode = false;
    this.clearValues();
    this.notifFormGroup.enable();
    this.myEditor.editorInstance.isReadOnly = false;
  }

  checkPermanent(c) {
    console.log(c);
    c ? this.notifFormGroup.controls.validityDateCtrl.disable() : this.notifFormGroup.controls.validityDateCtrl.enable();
  }
}
