import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import { ApplicabilityService } from 'src/app/core/applicability.service';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from 'src/app/core/data/User';
import { UserRestService } from 'src/app/core/user-rest.service';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-connection-chooser',
  templateUrl: './user-partial-rights.component.html',
  styleUrls: ['./user-partial-rights.component.scss']
})
export class UserPartialRightsComponent implements OnInit {
  connections = [];
  searchText = '';
  connectionsfilter = [];
  connectionLogo = {'VAM TOP ®': 'vam_top.png','VAM® 21': 'vam_21.png', 'VAM® 21 HT':'vam_21ht.png', 'VAM® SLIJ-3':'vam_slij_3.png','VAM® SLIJ-3-NA':'vam_slij_3.png'}
  rowsSelection = new SelectionModel<string>(true, []);

  componentUser: User;

  constructor(public activeModal: NgbActiveModal,
              public applicabilityRest: ApplicabilityService,
              public userService: UserRestService,
              public router: Router) {
  }

  ngOnInit(): void {
    forkJoin(
      this.applicabilityRest.getItemValues({item: 'product', parents: [], fromFilter: false }),
      this.userService.getUserRights(this.componentUser.id)
    ).subscribe(([Response, data]) => {
        this.connections = Response.slice();
        this.connectionsfilter = this.connections;
        this.connectionsfilter.sort();

        this.componentUser.product = data['products'];
        this.componentUser.product.forEach(item => this.rowsSelection.select(item));
        });
  }

  onClose(): void {
    this.activeModal.dismiss('cross clicked');
  }

  filters(){
    this.connectionsfilter = [];
    this.connectionsfilter = this.connections.filter(s => {return s.indexOf(this.searchText) !== -1});
  }

  commitProductsSelection(){
    let productsForRights = this.rowsSelection.selected.map(item => item);
    this.userService.updateRights({'is_partial':1,'id_user':this.componentUser.id,'products':productsForRights})
    .subscribe((Response) => {
      this.activeModal.dismiss('cross clicked');
    });

  }

    // Clearing All Selections
  clearSelection() {
    this.connectionsfilter = this.connections;
  }

  showBpdOfConnection(connection: string) {
    this.activeModal.dismiss('cross clicked');
    //this.router.navigate([`tsli/basic-product-data/${connection}`]);
  }
}
