import {Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {TsliRestService} from '../../core/tsli-rest.service';
import {AuthService} from '../../core/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BpdStartChooserComponent} from '../basic-product-data/modals/bpd-start-chooser/bpd-start-chooser.component';
import {DetailedTsliStore} from 'src/app/core/detailed-tsli-store.service';
import {Status} from 'src/app/core/enum/status.enum';
import {ConfigurationService} from 'src/app/core/configuration.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  token = '';
  url = '';
  tsliCardHeight = 530;
  notifications = [];
  bpdDescription = '';
  techSpecsDescription = '';
  subscriptions = [];

  constructor(public route: ActivatedRoute,
              public tsliRest: TsliRestService,
              public modal: NgbModal,
              public router: Router,
              public auth: AuthService,
              public cdRef: ChangeDetectorRef,
              public detailedTSLIStore: DetailedTsliStore,
              public config: ConfigurationService) {
  }

  ngOnInit(): void {
    // if (environment.production){
    //   if (!environment.URL_MYVAM.includes(document.referrer)){
    //     this.router.navigate(['tsli/Unauthorized']);
    //   }
    // }

    this.tsliRest.getActivities().subscribe(activities => {
      this.notifications = JSON.parse(JSON.stringify(activities));
      this.notifications.forEach(act => {
        if (act.newStatus == Status.PUBLISHED) {
          act.event = 'revised/published';
        }
      });
      this.notifications = this.notifications.slice(0, 3);
    });

    this.config.getAllConfigurations().subscribe();

    this.subscriptions.push(this.config.getConfigurationByName('bpd_description').subscribe(v => {
      this.bpdDescription = v["configurations"]["value"]
    }));
    this.subscriptions.push(this.config.getConfigurationByName('tech_spec_description').subscribe(v => {
      this.techSpecsDescription = v["configurations"]["value"]
    }));
  }

  navigateToBasicProductData(): void {
    this.modal.open(BpdStartChooserComponent, {size: 'lg', centered: true, backdrop: 'static'});
  }

  navigateToDetailedTsli(): void {
    this.detailedTSLIStore.setSelectedOption('');
    this.router.navigate(['tsli/details']);
  }

  ngAfterViewChecked() {
    let element = document.getElementById('tsliCard');
    if (element != null) {
      this.tsliCardHeight = element.clientHeight;
    }
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  download(filename: string, docType: string, reference: string) {
    this.tsliRest.downloadDisplayAndSourceFile(filename, docType, reference).subscribe(
      response => {
        window.open(response, '_self');
      });
  }
}
