import { ConfigurationService } from './../../../core/configuration.service';
import {Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {TslisStoreService} from 'src/app/core/tslis-store.service';
import {Tsli} from 'src/app/core/data/tsli';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Status } from 'src/app/core/enum/status.enum';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-tsli-links-list',
  templateUrl: './tsli-links-list.component.html',
  styleUrls: ['./tsli-links-list.component.scss']
})
export class TsliLinksListComponent implements OnInit , OnDestroy{
  @Output('insertLink') insertLink = new EventEmitter();

  tslis: Tsli[];
  mr_help = [];
  subscriptions = [];

  constructor(public tslisStore: TslisStoreService,
              public activeModal: NgbActiveModal,
              public auth: AuthService,
              public router: Router,
              public config: ConfigurationService) {
  }

  ngOnInit() {
    this.tslisStore.tslis$.subscribe(res => this.tslis = res.filter(tsli=> tsli.status == Status.PUBLISHED));
    this.subscriptions.push(this.config.getConfigurationByName("mr_help").subscribe(v=>{
      this.mr_help = v['configurations']
    }));
  }

  insertSelectedLink(selectedTslis: any[]) {


    //This is the Mr Help
    if (selectedTslis[0].value === undefined) {
      //let href = this.mr_help[0].value;
      //this.insertLink.emit({data:  "Mr. Help" , href: href})
    }
    //normal insert of a TSLI as a link
    else{

      let href = "/tsli/by-reference/" + selectedTslis[0].value.docType.toLowerCase() + "/" + selectedTslis[0].value.reference;
      this.insertLink.emit({data:  selectedTslis[0].value.docType + " " + selectedTslis[0].value.reference, href: href})
    }
    this.activeModal.close();
  }

  handleSelection(event) {
    if (event.option.selected) {
      event.source.deselectAll();
      event.option._setSelected(true);
    }
  }

  cancel(): void {
    this.activeModal.dismiss('close.clicked')
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
