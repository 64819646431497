import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigurationService} from 'src/app/core/configuration.service';
import {Location} from '@angular/common';
import {DetailedTsliStore} from '../../core/detailed-tsli-store.service';

@Component({
  selector: 'app-page-unauthorized',
  templateUrl: './page-unauthorized.component.html',
  styleUrls: ['./page-unauthorized.component.scss']
})
export class PageUnauthorizedComponent implements OnInit {


  errorCode = '401';
  errorMessage = 'WAS UNAUTHORIZED';
  page = 'THE PAGE';

  constructor(public router: Router,
              public config: ConfigurationService,
              public detailedTsli: DetailedTsliStore) {
  }

  ngOnInit() {

    if (this.router.url.includes('404')) {
      this.errorCode = 'Access Denied';
      this.page = '';

      this.config.getConfigurationByName('vfm_tsli_access').subscribe(cf => {
        //console.log(cf);
        this.errorMessage = cf['configurations'][0].value;
      });
    }


  }

  // goBack() {
  //
  //   const product = sessionStorage.getItem('itemProduct');
  //   console.log('product back: ', product);
  //   if (product != undefined) {
  //     /* get TSLI applicable on the product (general information, process and products) */
  //     this.detailedTsli.setCurrentItem(JSON.parse(product));
  //     this.router.navigate([`tsli/by-item`]);
  //   } else {
  //     this._location.back();
  //   }
  // }

}
