import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Tsli} from '../../../core/data/tsli';
import {TsliRestService} from '../../../core/tsli-rest.service';


@Component({
  selector: 'app-download-source-or-display-file-popup',
  templateUrl: './download-source-or-display-file-popup.component.html',
  styleUrls: ['./download-source-or-display-file-popup.component.scss']
})
export class DownloadSourceOrDisplayFilePopupComponent implements OnInit {

  document: Tsli;
  docTitle: string;
  docReference: string;
  docType: string;
  docRevision: string;
  is_exist_source_file_s3_url: boolean = true;
  is_exist_display_file_s3_url: boolean = true;
  fileToDownload: string;

  constructor(public activeModal: NgbActiveModal, public rest: TsliRestService) {
  }

  ngOnInit(): void {
    this.docTitle = this.document.title;
    this.docRevision = this.document.revision;
    this.docReference = this.document.docType.toUpperCase() + '-' + this.document.reference;
    this.is_exist_source_file_s3_url = this.document.source_file_s3_url == null;
    this.is_exist_display_file_s3_url = this.document.display_file_s3_url == null;
  }

  download(source_or_display_file: string): void {
    if (source_or_display_file == 'source_file') {
      this.fileToDownload = this.document.source_file_s3_url;
    }
    if (source_or_display_file == 'display_file') {
      this.fileToDownload = this.document.display_file_s3_url;
    }

    this.rest.downloadDisplayAndSourceFile(this.fileToDownload, this.document.reference, this.document.docType, this.docRevision).subscribe(
      response => {
        console.log(response);
        window.open(response, '_self');
      });
    this.onClose();
  }

  onClose(): void {
    this.activeModal.close();
  }

}
