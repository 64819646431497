import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Product} from './data/product';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BasicProductDataRestService {
  public readonly API_PRODUCT_BASE_URL: string = environment.API_BASE_URL;
  readonly API_BASE_LOCAL_URL: string = environment.API_BASE_LOCAL_URL;

  constructor(public http: HttpClient, public authService: AuthService, public router: Router) {
  }

  getBasicProductData(product: Product | Product[]): Observable<any> {
    let body: any = {product: product};
    body["product"]["connection"] = body["product"]["product"]

    if (Array.isArray(product)) {
      body = {products: product};
    }

    return this.http.post(
      this.API_PRODUCT_BASE_URL + '/bpd_bulk/get-bpd-by-item',
      body,
      this.authService.getAuthorization('service')).pipe(
      map(resp => {

        console.log('result bpd from back:', resp);

        return resp;
      }));
  }

  getThreadCompoundHeaders(): Observable<any[]> {
    return this.http.get<any>(
      this.API_PRODUCT_BASE_URL + '/bpd_bulk/get-thread-compound-headers',
      this.authService.getAuthorization('service')).pipe(
      map((response: any): any => {
        console.log('threadCompounds service:', response);
        return response;
      })
    );
  }
}
