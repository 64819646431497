<div class="header-space">&nbsp;</div>

<div class="row title">Assembly Data</div>
<div class="row fields-ml">
    <div class="col ends">FIELD END</div>
</div>
<div class="row fields-ml">
    <assembly-data-header-grid [forPdf]="true"></assembly-data-header-grid>
</div>
<div class="row">
        <pdf-assembly-content [assemblyData]= "fieldEnds" style="width: 100%;"></pdf-assembly-content>
</div>
<div class="row fields-ml">
    <div class="col ends">MILL END</div>
</div>
<div class="row fields-ml">
    <assembly-data-header-grid [forPdf]="true"></assembly-data-header-grid>
</div>
<div class="row ">
        <pdf-assembly-content [assemblyData]= "millEnds" style="width: 100%;"></pdf-assembly-content>
</div>
