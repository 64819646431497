import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Tsli } from 'src/app/core/data/tsli';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth.service';
import { ConfigurationService } from 'src/app/core/configuration.service';

@Component({
  selector: 'pdf-tsli-description',
  templateUrl: './tsli-description.component.html',
  styleUrls: ['./tsli-description.component.scss']
})
export class TsliDescriptionComponent implements OnInit , OnDestroy {
  tsli:Tsli;
  disclaimer = '';
  subscriptions = [];
  constructor(public config: ConfigurationService) { }

  ngOnInit() {
    this.subscriptions.push(this.config.getConfigurationByName("tsli_disclaimer").subscribe(v=>{
      this.disclaimer = v['configurations'][0].value;
    }));
    }
  ngOnDestroy() {
      this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
