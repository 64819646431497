import {Component, OnInit} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {User} from 'src/app/core/data/User';
import {SelectionModel} from '@angular/cdk/collections';
import {UserRestService} from 'src/app/core/user-rest.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialogComponent} from 'src/app/core/dialog/confirm-dialog/confirm-dialog.component';
import {Roles} from 'src/app/core/enum/role.enum';
import {UserPartialRightsComponent} from '../user-partial-rights/user-partial-rights.component';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NavigateDialogComponent} from '../../../core/dialog/navigate-dialog/navigate-dialog.component';
import {Tsli} from '../../../core/data/tsli';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {forkJoin} from 'rxjs';
import {TsliRestService} from '../../../core/tsli-rest.service';
import {SpinnerDialogComponent} from '../../../core/dialog/spinner-dialog/spinner-dialog.component';
import {ConfigurationService} from '../../../core/configuration.service';


@Component({
  selector: 'users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit {

  email = '';
  dataSource = new MatTableDataSource<User>([]);
  cachedUsers = [];
  searchedUsers = [];
  searchedExp = '';
  displayedColumns = ['select', 'name', 'numLicensee', 'company', 'email', 'profil', 'profil_rights', 'AccessVAMfamlyMembers'];
  currentSort: Sort;
  rowsSelection = new SelectionModel<User>(true, []);
  hasIdTsli: boolean;
  document: Tsli;
  modalRef: NgbModalRef;
  showSelected = false;
  subscriptions = [];
  restricted_title = '';


  constructor(public userService: UserRestService,
              public modal: NgbModal,
              public router: Router,
              public route: ActivatedRoute,
              public currentTsliStore: CurrentTsliStoreService,
              public tsliRest: TsliRestService,
              public config: ConfigurationService
  ) {
  }

  ngOnInit() {
    this.dataSource.data = this.cachedUsers.slice(0, 100);
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('idTsli')) {
        this.hasIdTsli = params.has('idTsli');
        // this.store.viewTsli(params.get('docType'), params.get('ref'));
        if (this.hasIdTsli) {
          this.displayedColumns = ['select', 'name', 'numLicensee', 'company', 'email', 'profil'];
        }
        this.document = this.currentTsliStore.getCurrentDocument();
      }
    });

    if (this.hasIdTsli) {
      forkJoin([this.userService.getRestrictedUsersForDocument(this.document.idTsli),
        this.userService.getUsersToRestrict()]
      ).subscribe(([selected, users]) => {
        console.log(users);
        this.cachedUsers = users;
        this.searchedUsers = users;

        if (selected.length > 0) {
          selected.forEach(s => {
            let user = this.cachedUsers.find(item => s.id == item.id);
            if (user) {
              this.rowsSelection.select(user);
            }
          });
        }
        this.searchInData();
        this.dataSource.data = this.cachedUsers.slice(0, 15);
      });
      this.subscriptions.push(this.config.getConfigurationByName('restricted_title').subscribe(v => {
        this.restricted_title = v['configurations'][0].value;
      }));
    } else {
      this.userService.getUsers().subscribe(users => {
        this.cachedUsers = users;
        this.searchedUsers = users;
        // for (let i = 0; i < 30; i++) {
        //   this.cachedUsers.push({id: i, name: 'test'+i, first_name: 'test'+i, isAdmin: true})

        // }
        this.searchInData();
        // this.dataSource.data = this.cachedUsers.slice(0, 100);
      });
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.rowsSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.rowsSelection.clear() :
      this.dataSource.data.forEach(row => this.rowsSelection.select(row));
  }

  updateUserRole(user, role): void {
    user.isAdmin = role == 'admin';
    user.isApprover = role == 'approver' || role == 'admin';
    user.isReviewer = role == 'reviewer' || role == 'partialreviewer' || role == 'approver' || role == 'admin';
    user.isAuthor = user.isReviewer;
    user.isReader = role == 'reader' || role == 'partialreader';
    user.isPartial = role == 'partialreviewer' || role == 'partialreader';
    user.activated = role != 'deactivated';
    user.isLicenseLight = false;
    this.userService.updateUser(user).subscribe(resp => {
      console.log(resp);
    });
  }

  viewMore(): void {
    this.dataSource.data.push(...this.searchedUsers.slice(this.dataSource.data.length, this.dataSource.data.length + 100));
    this.dataSource.data = this.dataSource.data.slice();
  }

  deleteSelection(): void {
    const modalRef = this.modal.open(ConfirmDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.componentName = 'selected users';
    modalRef.componentInstance.componentType = 'USERS';

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        //call delete service

        this.userService.deleteUsers(this.rowsSelection.selected).subscribe(resp => {
          this.cachedUsers = resp;

          if (this.currentSort != undefined) {
            this.sortData(this.currentSort);
          } else {
            this.searchedUsers = this.cachedUsers.slice(0, this.cachedUsers.length);
            this.dataSource.data = this.searchedUsers.slice(0, this.dataSource.data.length);
          }
          this.rowsSelection.clear();
          this.searchedExp = '';
          console.log(resp);
        });
      }
    }, () => {
    });
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = this.searchedUsers.slice(0, this.dataSource.data.length);
      return;
    }
    this.currentSort = sort;
    this.cachedUsers = this.cachedUsers.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name + a.first_name, b.name + b.first_name, isAsc);
        case 'profil':
          return this.compare(this.getHigherRole(a), this.getHigherRole(b), isAsc);
        case 'numLicensee':
          return this.compare(a.licenseeNumber, b.licenseeNumber, isAsc);
        case 'company':
          return this.compare(a.companyName, b.companyName, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        default:
          return 0;
      }
    });
    this.searchedUsers = this.cachedUsers.slice(0, this.cachedUsers.length);
    this.dataSource.data = this.searchedUsers.slice(0, this.dataSource.data.length);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getHigherRole(user: User) {
    if (!user.isAdmin && !user.isApprover && !user.isReader && !user.isReviewer && !user.isReviewer && !user.isReader) {
      return 'deactivated';
    }
    if (!user.activated) {
      return 'deactivated';
    }
    if (user.isAdmin) {
      return Roles.ADMIN;
    }
    if (user.isApprover) {
      return Roles.APPROVER;
    }
    if (user.isReader && user.isPartial) {
      return Roles.PARTIAL_READER;
    }
    if (user.isReviewer && user.isPartial) {
      return Roles.PARTIAL_REVIEWER;
    }
    if (user.isReviewer) {
      return Roles.REVIEWER;
    }
    if (user.isReader) {
      return Roles.READER;
    }
  }

  getRole(user) {
    if (!user.is_admin && !user.is_approver && !user.is_reader && !user.is_reviewer) {
      return 'deactivated';
    }
    if (!user.activated) {
      return 'deactivated';
    }
    if (user.is_admin) {
      return Roles.ADMIN;
    }
    if (user.is_approver) {
      return Roles.APPROVER;
    }
    if (user.is_reader && user.is_partial) {
      return Roles.PARTIAL_READER;
    }
    if (user.is_reviewer && user.is_partial) {
      return Roles.PARTIAL_REVIEWER;
    }
    if (user.is_reviewer) {
      return Roles.REVIEWER;
    }
    if (user.is_reader) {
      return Roles.READER;
    }
  }


  showconnectionsRights(user): void {
    const modalRef = this.modal.open(UserPartialRightsComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.componentUser = user;
  }

  showLicenseLightRights(user): void {
    this.userService.setUpdatedUser(user);
    this.router.navigate([`/tsli/admin/licenseLight`]);

  }

  searchInData() {
    if (this.searchedExp == '') {
      this.searchedUsers = this.cachedUsers.slice(0, this.cachedUsers.length);
      //this.dataSource.data = this.cachedUsers.slice(0, this.dataSource.data.length);
    } else {
      this.searchedUsers = this.cachedUsers.filter(u => {
        let safeSearchedExp = '';
        let licenseeNumberStr = '';
        let company = '';
        let safeEmail = '';
        let safeProfil = '';
        safeSearchedExp = this.searchedExp.toLowerCase();
        safeProfil = '' + this.getHigherRole(u);
        safeEmail = '' + u.email;

        if (this.hasIdTsli) {
          licenseeNumberStr = String(u.licensee_number);
          company = '' + u.company_name;
        } else {
          licenseeNumberStr = String(u.licenseeNumber);
          company = '' + u.companyName;
        }
        return u.name.toLowerCase().includes(safeSearchedExp) ||
          u.first_name.toLowerCase().includes(safeSearchedExp) ||
          licenseeNumberStr.toLowerCase().includes(safeSearchedExp) ||
          company.toLowerCase().includes(safeSearchedExp) ||
          safeProfil.toLowerCase().includes(safeSearchedExp) ||
          safeEmail.toLowerCase().includes(safeSearchedExp);
      });
    }
    this.dataSource.data = this.searchedUsers.slice(0, 100);
  }

  updateUserMail(user, email): void {
    if (email.value == null) {
      return;
    }
    user.email = email.value;
    this.userService.updateUserMail(user).subscribe(resp => {
      console.log('updateUserMail:', resp);
    });
  }


  updateIsVamFamily(isVamFamily, idUser) {
    let value = isVamFamily ? 1 : 0;
    this.userService.updateIsVamFamily(value, idUser).subscribe(resp => {
      console.log(resp);
    });
  }

  validateRestrictedAccess() {
    this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
    this.userService.addOrUpdateRestrictedUsers(+this.document.idTsli, this.rowsSelection.selected).subscribe(
      response => {
        if (response.detail != undefined && response.detail == 'Restricted users successfully updated.') {
          this.modalRef.componentInstance.message = response.detail;
          this.modalRef.componentInstance.done = true;
          this.router.navigate(['/tsli/edit/drafts']);
          setTimeout(() => {
            this.modal.dismissAll();
          }, 5000);
        }
      },
      error => {
        console.error('Error updating restricted users:', error);
      }
    );
  }

  cancel() {
    // this.modalRef = this.modal.open(ConfirmDialogAdminLicenseLightComponent, {
    //   size: 'lg',
    //   centered: true,
    //   backdrop: 'static'
    // });
    //
    // this.modalRef.result.then((userResponse) => {
    //   if (userResponse === 'confirmed') {
    //     //don't save and go back to document list
    //     let tslis = this.rowsSelection.selected;
    //     this.userService.updateLicenseLightRights({
    //       'is_partial_light': this.user.isLicenseLight ? 1 : 0,
    //       'id_user': this.user.id,
    //       'tslis': tslis
    //     })
    //       .subscribe((Response) => {
    //         this.location.back();
    //       });
    //   }
    // });

    const modalRef = this.modal.open(NavigateDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.title = 'Unsaved changes';
    modalRef.componentInstance.message_1 =
      'Are you sure you want to quit before saving changes';
    modalRef.componentInstance.message_2 =
      'All information associated to this Restricted access will be lost.';

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        this.router.navigate([`tsli/edit/drafts`]);
      }
    }, () => {
    });
  }

  updateRestrictedStatus(document: Tsli) {
    this.config.getConfigurationByName('cancel_vam_family_property_for_document').subscribe(cf => {

      if (document.is_vam_family) {
        //confirmation pop-up
        const modal = this.modal.open(NavigateDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
        modal.componentInstance.title = 'Unsaved changes';
        modal.componentInstance.message_1 = cf['configurations'][0].value;
        modal.componentInstance.message_2 = '';

        return modal.result.then((userResponse) => {
          console.log('userResponse:', userResponse);
          if (userResponse === 'confirmed') {
            this.tsliRest.updateRestrictedStatus(this.document).subscribe(
              response => {
                if (response.message != undefined && response.message == 'Restricted access status successfully updated') {
                  this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
                  this.modalRef.componentInstance.message = response.message;
                  this.modalRef.componentInstance.done = true;
                  setTimeout(() => {
                    this.modal.dismissAll();
                  }, 5000);
                }
              },
              error => {
                console.error('Error updating restricted users:', error);
              }
            );
            return true;
          }
        }, (reason) => {
          document.is_restricted_document = false;
          return false;
        });
      } else {
        this.tsliRest.updateRestrictedStatus(this.document).subscribe(
          response => {
            if (response.message != undefined && response.message == 'Restricted access status successfully updated') {
              this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
              this.modalRef.componentInstance.message = response.message;
              this.modalRef.componentInstance.done = true;
              setTimeout(() => {
                this.modal.dismissAll();
              }, 5000);
            }
          },
          error => {
            console.error('Error updating restricted users:', error);
          }
        );
      }
    });
  }

  filterSelected() {
    this.showSelected = !this.showSelected;
    if (this.showSelected) {
      this.dataSource.data = this.cachedUsers.filter(row => this.rowsSelection.selected.includes(row));
    } else {
      this.dataSource.data = this.cachedUsers.slice(0, 15);
    }

  }
}
