export class BasicProductsDataConstants {
  // -- COMMON BASIC PRODUCT DATA
  static readonly CONNECTION: string = 'Connection';
  static readonly OD: string = 'OD';
  static readonly WT: string = 'WT';
  static readonly GRADE: string = 'Grade';
  static readonly LUBRICANT: string = 'Lubricant';
  static readonly PRODUCT_TYPE: string = 'Product Type';
  static readonly DESIGN_OPTIONS: string = 'Design Options';
  static readonly CUSTOMER: string = 'Customer';

  static readonly FILTERS: string[] = [
    BasicProductsDataConstants.CONNECTION,
    BasicProductsDataConstants.OD,
    BasicProductsDataConstants.WT,
    BasicProductsDataConstants.GRADE,
    BasicProductsDataConstants.LUBRICANT,
    BasicProductsDataConstants.PRODUCT_TYPE,
    BasicProductsDataConstants.DESIGN_OPTIONS,
    BasicProductsDataConstants.CUSTOMER
  ];

  static readonly FILTERS_WIDTHS: string[] = [
    '15', '5', '9', '11', '15', '9', '9', '9','10','5'
  ];

  static readonly DEFAULT_PAGE_SIZE: number = 10;

  // -- SURFACE TREATMENT
  static readonly ENDS: object[] = [
    {key: 'ASM', value: 'As machined'},
    {key: 'CBB', value: 'Ceramic Bead Blasting'},
    {key: 'SB', value: 'Alumina (Al2O3) sand blasting'},
    {key: 'Zn', value: 'Zinc Phosphate'},
    {key: 'SB+Zn', value: 'Alumina (Al2O3) sand blasting + Zn Phosphate'},
    {key: 'Mn', value: 'Mn Phosphate'},
    {key: 'SB+Mn', value: 'Alumina (Al2O3) sand blasting + Mn Phosphate'},
    {key: 'Cu', value: 'Copper Plating'}
  ];

  static readonly FM_ENDS: object[] = [
    {key: 'ASM', value: 'As machined'},
    {key: 'CBB', value: 'Ceramic Bead Blasting'},
    {key: 'SB', value: 'Alumina (Al2O3) sand Blasting'},
    {key: 'Zn', value: 'Zinc Phosphate'},
    {key: 'SB+Zn', value: 'Alumina (Al2O3) sand Blasting + Zn Phosphate'},
    {key: 'Mn', value: 'Mn Phosphate'},
    {key: 'SB+Mn', value: 'Alumina (Al2O3) sand Blasting + Mn Phosphate'},
    {key: 'Cu', value: 'Copper Plating'},
    {key: 'SB+Cu', value: 'Alumina (Al2O3) sand Blasting + Copper Plating'},
    {key: 'CBB+Cu', value: 'Ceramic Bead Blasting + Copper Plating'},
    {key: 'TER', value: 'TERNARY ALLOY'},
    {key: 'PIO', value: 'PIOTEC PLATING'}
  ];

  //thread compound

  static readonly SI_DENSITY = {
    "API Modified": 1.89,
    "Seal Guard TM": 1.26,
    "Seal Guard ECF": 1.28,
    "Run-n-Seal ECF": 1.32,
    "4010 NM": 1.25,
    "3010 NM Special": 1.28,
    "2010 NM Ultra": 1.28,
    "2000 NM": 1.22,
    "PTC": 1.09,
    "Tifora PG": 0.91,
    "Mercasol 633 SR": 1.1,
    "OCR 325 AG": 1.2,
    "72733": 1.9,
    "TF-15": 1.21,
    "HPHT TM": 1.36,
    "TOPCO Green-Seal® II Thermal": 1.12,
    "FlexLube 101":1.13,
    "TF-15 ARCTIC":1.19
  }

  static readonly IMP_DENSITY = {
    "API Modified": 1.09,
    "Seal Guard TM": 0.72,
    "Seal Guard ECF": 0.73,
    "Run-n-Seal ECF": 0.76,
    "4010 NM": 0.72,
    "3010 NM Special": 0.74,
    "2010 NM Ultra": 0.74,
    "2000 NM": 0.71,
    "PTC": 0.63,
    "Tifora PG": 0.52,
    "Mercasol 633 SR": 0.58,
    "OCR 325 AG": 0.69,
    "72733": 1.09,
    "TF-15": 0.69,
    "HPHT TM": 0.78,
    "TOPCO Green-Seal® II Thermal": 0,
    "FlexLube 101": 0.65,
    "TF-15 ARCTIC": 0.69
  }
}
