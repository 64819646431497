import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DetailedTsliConstants } from '../detailed-tsli.constants';

@Component({
  selector: 'detailed-tsli-by-process',
  templateUrl: './tsli-by-process.component.html',
  styleUrls: ['./tsli-by-process.component.scss']
})
export class TsliByProcessComponent implements OnInit {
 
  themes = [];
  images = {'designAndEngineering':'1-Design-&-Engineering-ter_VAM_2.jpg', 
            'endSizing':'2-End-sizing & Stress relieve.jpg',
            'threading':'3-Threading.jpg', 
            'gaugingAndInspection':'4-Gauging & Inspection.jpg',
            'surfaceTreatment':'5-Surface treatment.jpg',
            'lubricant':'6-Lubricant.jpg',
            'makeUp':'7-Assembly.JPG',
            'markingProtectionAndPackaging':'8-Marking, Protection, Packaging, Storage.jpg'};
  constructor(
    public router: Router) { }

  ngOnInit() {
    this.themes = DetailedTsliConstants.THEMES;
  }

  navigate(theme) {
    this.router.navigate([`/tsli/by-process/${theme}`])
  }
}
