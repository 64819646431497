 <ng-container *ngIf="tsli">
   <div id="contentWithoutHeaders" class="content-without-headers">
      <!-- by item chapters -->
      <ng-container *ngIf="byItem">
          <!-- add General Information -->
          <h1 class="mat-h1 gras" id="gnrl_info" style="text-decoration: underline">1{{withSummary}}General Information</h1>
          <div class="mb-3">
            <div *ngFor="let c of groupByRef(tsli.chapters, 'general_information')">
              <div>
                <div class="mat-body"><a class="tsli-link"
                    href="tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{c.docType | uppercase}}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a>
                </div>
              </div>
            </div>
          </div>
  
          <ng-container *ngFor="let t of themes; let i = index;">
            <div *ngIf="t.key != 'gnrl_info'">
              <h1 class="mat-h1 gras" id="{{t.key}}" style="text-decoration: underline">{{(i+1) + withSummary + t.name}}</h1>
              <!-- add Process chapters -->
              <div class="mb-3">
                <ng-container *ngFor="let c of groupByRef(tsli.chapters, 'process')">
                  <div *ngIf="c.theme === t.key">
                    <div class="mat-body"><a class="tsli-link"
                        href="tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{c.docType | uppercase}}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a>
                    </div>
                  </div>
                </ng-container>
              </div>
  
              <!-- add Product chapters -->
              <ng-container *ngFor="let c of filterByThemeAndType(tsli.chapters, t.key, 'product')">
                  <h3 class="mat-h3 header-size" id="{{t.key}}-{{c.index}}"
                  [ngClass]="{'title1': c.depth === 0, 'title2': c.depth === 1, 'title3': c.depth > 1 }"><span [style.background-color]="c.titleStyle">{{(i+1) + ' .' + c.number + withSummary + c.title}}</span></h3>
                  <div class="mat-body mb-3 chap-content" [innerHTML]="replaceArrow(c.body) | safeHtml"></div>
              </ng-container>
  
            </div>
          </ng-container>
        </ng-container>
  
        <!-- by reference chapters -->
        <ng-container *ngIf="!byItem">
  
          <ng-container *ngFor="let c of (chapters$|async); let i = index;">
            <!-- <pdf-tsli-infos *ngIf="chaptersToSplitIndexes.includes(i)"></pdf-tsli-infos>         -->
            <h3  id="{{'chapter'}}-{{i}}"
            [ngClass]="{'title1': c.depth === 1, 'title2': c.depth === 2, 'title3': c.depth > 2 }">
            <strong [style.background-color]="c.titleStyle">{{appendTitle(c.number + withSummary + c.title, c.depth)}}</strong></h3>
            <div class="mat-body mb-3 chap-content" [innerHTML]="replaceArrow(c.body) | safeHtml" style="overflow: auto;"></div>
          </ng-container>
  
        </ng-container>
   </div>
</ng-container>