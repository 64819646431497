<div class="mb-3 mat-elevation-z4 chapter" [style.margin-left.px]="5 + chapter.depth * 80">
  <div class="d-flex flex-row justify-content-between" [ngClass]="{'mb-3': isCollapsed}">
    <!-- title and arrow icon when not editing -->
    <!-- <div *ngIf="!isEditing" class="chapter-title" (click)="collapse()"> -->
    <div (click)="collapse()">
      <mat-icon class="arrow-icon" [ngClass]="{'extra-offset': isEditing}" *ngIf="!isCollapsed">keyboard_arrow_right
      </mat-icon>
      <mat-icon class="arrow-icon" [ngClass]="{'extra-offset': isEditing}" *ngIf="isCollapsed">keyboard_arrow_down
      </mat-icon>
    </div>

    <div class="chapter-title" *ngIf="!isEditing" (click)="collapse()" style="flex: 1">
      {{chapter.number}} <span [style.background-color]="chapter.titleStyle">{{ chapter.title }}</span>
    </div>

    <div class="chapter-title ml-2 mr-3" *ngIf="isEditing">
      {{chapter.number}}
    </div>

    <!-- title and arrow icon when editing -->
    <div class="chapter-title" *ngIf="isEditing" style="flex: 1;  padding-right: 10px">
      <form [formGroup]="titleFormGroup">
        <!-- <mat-form-field style="width: 100%"> -->
        <input matInput formControlName="titleCtrl" required (input)="onChapterChanged()" style="width: 100%;"
               [class]="selectedTitleStyle"
               [(ngModel)]="chapter.title">
        <!-- </mat-form-field> -->
      </form>
    </div>

    <!-- space filler -->
    <!-- <div class="spacer" (click)="collapse()"></div> -->

    <!-- icons -->
    <div>
      <mat-icon class="rotating" *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' && savingChapter">autorenew
      </mat-icon>
      <!-- <button mat-icon-button (click)="edit()" [ngSwitch]="switch_expression"><img *ngIf="isEditing" src="assets/img/editing.png" /><mat-icon *ngIf="!isEditing">edit</mat-icon></button> -->
      <ng-container *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'ADMIN_EDITION_MODE'">
        <button mat-icon-button (click)="edit()" [ngSwitch]="isEditing">
          <img *ngSwitchCase="true" src="assets/img/editing.png"/>
          <mat-icon *ngSwitchCase="false">edit</mat-icon>
        </button>
        <div class="dropdown">
          <button (click)="switchTitleStyle()" class="dropbtn" [disabled]='!isEditing'>
            <img src="assets/img/icons/alpha-h-box.png">
          </button>
          <div [id]="dropDownID" class="dropdown-content">
            <div style="display: block;">
              <div class="colors">
                <div class="col clr" (click)="switchTitleStyle('#ffffff')">
                  <mat-icon *ngIf="chapter.titleStyle == '#ffffff'"> done</mat-icon>
                </div>
                <div class="col clr gr" (click)="switchTitleStyle('#62f962')">
                  <mat-icon *ngIf="chapter.titleStyle == '#62f962'"> done</mat-icon>
                </div>
                <div class="col clr bl" (click)="switchTitleStyle('#72ccfd')">
                  <mat-icon *ngIf="chapter.titleStyle == '#72ccfd'"> done</mat-icon>
                </div>
                <div class="col clr rd" (click)="switchTitleStyle('#fc7899')">
                  <mat-icon *ngIf="chapter.titleStyle == '#fc7899'"> done</mat-icon>
                </div>
                <div class="col clr ye" (click)="switchTitleStyle('#fdfd77')">
                  <mat-icon *ngIf="chapter.titleStyle == '#fdfd77'"> done</mat-icon>
                </div>
                <div class="col clr or" (click)="switchTitleStyle('#fabe4e')">
                  <mat-icon *ngIf="chapter.titleStyle == '#fabe4e'"> done</mat-icon>
                </div>
              </div>
            </div>

            <div class="row"></div>
            <!-- <div style="display: inline-block;"><div class="clr">no</div> <div class="clr gr">gr</div> <div class="clr bl">bl</div></div>
            <div><span class="clr rd">re</span> <span class="clr ye">ye</span> <span class="clr or">or</span></div>             -->
          </div>
        </div>
        <button mat-icon-button (click)="deleteChapter($event)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="goLeft($event)" [disabled]='canNotBeMovedToLeft()'>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button (click)="goDown($event)" [disabled]='canNotBeMovedDown()'>
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button mat-icon-button (click)="goUp($event)" [disabled]='canNotBeMovedUp() '>
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button mat-icon-button (click)="goRight($event)" [disabled]='canNotBeMovedToRight()'>
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </ng-container>

      <button mat-icon-button *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE'" (click)="saveChapter()"
              [disabled]="!chapterHasChanged">
        <mat-icon>save</mat-icon>
      </button>

      <ng-container *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'ADMIN_EDITION_MODE'">
        <button mat-icon-button (click)="linkChapter($event)" [disabled]="!isEditing || !isCollapsed">
          <mat-icon>link</mat-icon>
        </button>
        <button mat-icon-button (click)="mathEdit($event)" [disabled]="!isEditing || !isCollapsed">
          <mat-icon>functions</mat-icon>
        </button>
      </ng-container>

      <button mat-raised-button [color]="colorinput?'accent':'primary'" class="ml-2"
              *ngIf="tsli.type === 'product'
              && (activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'APPLICABILITY_EDITION_MODE'
              || activatedTsliMode == 'THEME_EDITION_MODE')"
              (click)="createModifyApplicability($event)" [disabled]="tsli.isLegacy">
        {{ activatedTsliMode == 'THEME_EDITION_MODE' ? "Edit Theme" : chapter.applicability === false ? "Add applicability" : "Modify applicability" }}</button>

      <button mat-raised-button [color]="'primary'" class="ml-2"
              *ngIf="tsli.status === status.REVIEWING && chapter.applicability && activatedTsliMode == 'REVIEWING_MODE'"
              (click)="showApplicability()">
        {{ "Show Applicability" }}</button>

      <button mat-raised-button color="primary" class="ml-2"
              *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'REVIEWING_MODE' || activatedTsliMode == 'APPROVING_MODE' || activatedTsliMode == 'THEME_EDITION_MODE'"
              (click)="chapter.comment = true;">{{ "Add Comment"}}</button>
    </div>
  </div>
  <div *ngIf="isCollapsed && isEditing">
    <ckeditor #chapterEditor [editor]="Editor"
              [config]="{simpleUpload: {uploadUrl: {url: uploadImgURL, headers:{'Authorization': 'Bearer ' + this.token}}}}"
              [(ngModel)]="chapter.body" (change)="onChapterChanged()" (ready)="onReady()"></ckeditor>
  </div>
  <div *ngIf="isCollapsed && !isEditing" [innerHTML]="securedBody" style="overflow: auto;">
  </div>
</div>
