import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {CurrentTsliStoreService} from '../../core/current-tsli-store.service';
import {TsliRestService} from '../../core/tsli-rest.service';
import {Comment} from '../../core/data/comment';
import {CommentsRestService} from '../../core/comments-rest.service';
import {CommentsStoreService} from '../../core/comments-store.service';
import {Tsli} from '../../core/data/tsli';
import {Status} from '../../core/enum/status.enum';
import {AuthService} from '../../core/auth.service';
import {TslisStoreService} from '../../core/tslis-store.service';
import {
  CheckApplicationDateDialogComponent
} from '../../core/dialog/check-application-date-dialog/check-application-date-dialog.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {
  @ViewChild('autosize', {static: true}) autosize: CdkTextareaAutosize;
  @ViewChild('autosize1', {static: true}) autosize1: CdkTextareaAutosize;

  @Input()
  commentInput: Comment;

  documentToReviewOrApprove: Tsli;
  formGroup: FormGroup;
  docTitle: string;
  docReference: string;
  reference: string;
  title: string;
  docType: string;
  comment: string;
  idTsli: string;
  isToPublish: boolean = false;
  isSendToApprove: boolean = false;
  applicationDate: Date;


  public docComments: Comment[] = [];

  constructor(public formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public currentTsliStore: CurrentTsliStoreService,
              public service: TsliRestService,
              public commentsRestService: CommentsRestService,
              public commentStore: CommentsStoreService,
              public authService: AuthService,
              public tslisStore: TslisStoreService,
              public datePipe: DatePipe,
              public modal: NgbModal) {


  }


  ngOnInit(): void {

    this.applicationDate = new Date(this.documentToReviewOrApprove.applicationDate);
    this.docTitle = this.documentToReviewOrApprove.title;
    this.docReference = this.documentToReviewOrApprove.docType.toUpperCase() + '-' + this.documentToReviewOrApprove.reference;

    this.formGroup = this.formBuilder.group({
      commentCtrl: ['', [Validators.required]],
      previousCommentsAreaName: ['', []],
    });


    setTimeout(() => {
      this.commentsRestService.getAllComments(this.documentToReviewOrApprove.idTsli).subscribe((value: Comment[]) => {
        this.docComments = value.filter(comment => {
          return comment.is_revision_comment == '1' && comment.tsli.idTsli === this.documentToReviewOrApprove.idTsli;
        });
        this.comment = '';
        this.docComments.forEach((comment: Comment) => {
          let commentatorProfil = '';
          if (comment.commentator.isApprover) {
            commentatorProfil = 'A';
          } else if (comment.commentator.isReviewer) {
            commentatorProfil = 'R';
          }

          this.comment += '- ' + commentatorProfil + ' - ' + comment.commentator.first_name + ' ' + comment.commentator.name + ': ' + comment.comment + '\n';
        });
        this.formGroup.controls.previousCommentsAreaName.setValue(this.comment);
        this.formGroup.controls.previousCommentsAreaName.disable();
      });
    });
  }


  refuseDoc(): void {
    if (this.formGroup.controls.commentCtrl.value !== '') {
      this.addRevisionComment();
      this.service.updateStatus(this.documentToReviewOrApprove, Status.REFUSED).subscribe(resp => {
        console.log(resp);
        this.tslisStore.getAllDocuments().subscribe();
      });
      this.onClose();
    }
  }

  sendToApproveDoc() {
    if (this.formGroup.controls.commentCtrl.value !== '') {
      const checkResult = this.checkApplicationDate();
      if (checkResult == undefined) {
        return this.sendToApproveDocCallback();
      }

      checkResult.then((userResponse) => {
        if (userResponse === 'continue') {
          this.sendToApproveDocCallback();
        }
      }).catch(err => console.error(err));
    }
  }

  sendToApproveDocCallback(): void {
    this.addRevisionComment();
    this.onClose();
  }

  publishDoc() {
    if (this.formGroup.controls.commentCtrl.value !== '') {
      const checkResult = this.checkApplicationDate();
      if (checkResult == undefined) {
        return this.publishDocCallback();
      }

      checkResult.then((userResponse) => {
        if (userResponse === 'continue') {
          this.publishDocCallback();
        }
      }).catch(err => console.error(err));
    }
  }

  publishDocCallback(): void {

    this.addRevisionComment();
    this.onClose();

  }

  addRevisionComment() {
    this.commentInput = new Comment();
    this.commentInput.is_revision_comment = '1';
    this.commentInput.commentDate = new Date();
    this.commentInput.tsli = this.documentToReviewOrApprove;

    this.commentInput.comment = JSON.parse(JSON.stringify(this.formGroup.controls.commentCtrl.value));

    this.authService.user.subscribe(user => {
      this.commentInput.commentator = user;
    });
    this.commentStore.addComment(this.commentInput);

  }

  onClose(): void {
    this.activeModal.close('confirmed');
  }

  checkApplicationDate() {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (this.applicationDate.getTime() < tomorrow.getTime()) {
      const modalRef: NgbModalRef = this.modal.open(CheckApplicationDateDialogComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalRef.componentInstance.applicationDate = this.datePipe.transform(this.applicationDate, 'dd/MM/yyyy');
      // modalRef.componentInstance.validationMode = this.activatedTsliMode == 'REVIEWING_MODE' || this.activatedTsliMode == 'APPROVING_MODE'
      return modalRef.result;
    }
  }
}
