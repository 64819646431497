import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import {BasicProductDataStoreService} from '../../core/basic-product-data-store.service';

@Component({
  selector: 'app-basic-product-data',
  templateUrl: './basic-product-data.component.html',
  styleUrls: ['./basic-product-data.component.scss']
})
export class BasicProductDataComponent implements OnInit {

  currentUser;
  constructor(public route: ActivatedRoute,
              public productsStore: BasicProductDataStoreService,
              public detailedTSLIStore: DetailedTsliStore,
              public auth: AuthService,
              public router: Router) {
    this.route.paramMap.subscribe((params) => {
      this.productsStore.connection = params.get('connection');
      if (this.productsStore.connection !== undefined && this.productsStore.connection !== null) {
        this.productsStore.isEditionMode = false;
      } else {
        this.productsStore.isEditionMode = true;
      }
    });
  }

  ngOnInit() {
    this.auth.Authentication();
    this.currentUser = this.auth.getCurrentUser();
  }

  switchSelectedTab(event) {
    this.productsStore.switchBasicDataTab(event.index);
  }

  navigateToTsliByItemOrByReference(){
    this.detailedTSLIStore.setSelectedOption("item");
    if(this.currentUser.isLicenseLight){
      this.detailedTSLIStore.setSelectedOption("");
    }
    this.router.navigate(['tsli/details']);
  }
}
