import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {DetailedTsliConstants, DetailedTsliProperty} from '../detailed-tsli.constants';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Product} from '../../../core/data/product';
import {DetailedTsliStore} from '../../../core/detailed-tsli-store.service';
import {BasicProductDataStoreService} from '../../../core/basic-product-data-store.service';
import {ApplicabilityFilter} from '../../../core/applicability.service';
import {Router} from '@angular/router';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {Subscription} from 'rxjs';
import {NgbModalRef, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DownloadRequestDialogComponent} from 'src/app/core/dialog/download-request-dialog/download-request-dialog.component';
import {AuthService} from 'src/app/core/auth.service';
import {ConfigurationService} from 'src/app/core/configuration.service';


@Component({
  selector: 'detailed-tsli-by-item',
  templateUrl: './tsli-by-item.component.html',
  styleUrls: ['./tsli-by-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TsliByItemComponent implements OnInit, OnDestroy {
  public productsSubscription: Subscription;
  public configSubscribtion: Subscription;
  checked: boolean = true;
  displayedColumns: string[] = ['select', ...DetailedTsliConstants.PROPERTIES.map<string>((p) => p.key), 'delete', 'view'];
  dataSource = new MatTableDataSource<Product>([]);
  rowsSelection = new SelectionModel<Product>(true, []);
  isOCTG: boolean = true;
  filterSelection: Product = new Product(); // holds current selection
  warningDisplay = '';

  /**
   * TSLI by item rowsSelection screen
   *
   */
  constructor(public store: DetailedTsliStore,
              public tsliStore: CurrentTsliStoreService,
              public bpdStore: BasicProductDataStoreService,
              public modal: NgbModal,
              public router: Router,
              public authService: AuthService,
              public config: ConfigurationService) {
    this.bpdStore.octgOrNonOctg$.subscribe(value => this.isOCTG = value);

  }

  ngOnInit(): void {
    this.productsSubscription = this.store.products$.subscribe((items: Product[]) => {
      this.dataSource.data = items;
      this.dataSource.data.forEach(produit => {
        produit.connection_specific = produit.connection.split('-SPECIFIC').length == 2 ? produit.connection.split('-SPECIFIC')[0] : '';
      });

    });
    this.dynamicWarning();
    this.store.getFilterValues([], this.isOCTG, true);
    this.bpdStore.imperialOrSI$.subscribe(value => this.checked = value);
    // this.bpdStore.fetchThreadCompoundHeaders();


  }

  ngOnDestroy(): void {
    if (this.productsSubscription !== undefined) {
      this.productsSubscription.unsubscribe();
    }
    if (this.configSubscribtion !== undefined) {
      this.configSubscribtion.unsubscribe();
    }
  }

  /**
   * Add a value to the product selection
   *
   * @param filterKey
   * @param value
   *
   */
  setFilter(filterKey: string, value: string | number): void {
    // if(filterKey == 'wt' && (''+value).includes('#')){
    //   value = (''+value).split("(")[1].replace('")', '');
    // }
    this.filterSelection[filterKey] = value;
    // erase all values after key
    let erase = false;
    for (const property of DetailedTsliConstants.PROPERTIES) {
      if (!erase) {
        if (property.key === filterKey) {
          erase = true;
        }
      } else {
        delete this.filterSelection[property.key];
        this.store.clearFilterValues(property.key);
      }
    }

    // updates filter values, with an API call
    const filters: ApplicabilityFilter[] = [];
    for (const prop in this.filterSelection) {
      if (prop === 'equals') {
        continue;
      }

      const filter: ApplicabilityFilter = new ApplicabilityFilter();
      filter.name = prop;
      filter.value = this.filterSelection[prop].toString();
      filters.push(filter);
    }
    this.store.getFilterValues(filters, this.isOCTG, true);

  }

  /**
   * Clear product filter
   *
   */
  clearFilter(): void {
    this.filterSelection = new Product();
  }

  /**
   * Return "label" or "label: value " for a given filter name
   *
   */
  getFilterLabel(filterKey: string): string {
    const label: string = DetailedTsliConstants.PROPERTIES.find((p: DetailedTsliProperty) => p.key === filterKey).name;
    let processedLabel: string = label;
    if (this.filterSelection[filterKey] !== undefined) {
      processedLabel = `${this.filterSelection[filterKey]}`;
    }
    return processedLabel;
  }

  /**
   * Check if current product filter is incomplete
   *
   */
  isFilterSelectionIncomplete(): boolean {
    let isIncomplete = false;
    DetailedTsliConstants.PROPERTIES.forEach((p: DetailedTsliProperty) => {
        if (this.filterSelection[p.key] === undefined) {
          isIncomplete = true;
        }
      }
    );
    return isIncomplete;
  }

  /**
   * Add current filter product to products list
   *
   */
  addToList(): void {
    this.bpdStore.addProductOfTsliItem(Object.assign(new Product(), this.filterSelection)).subscribe(p => {
      this.store.addProduct(Object.assign(new Product(), this.filterSelection));
      this.clearFilter();
    });
  }

  /**
   * Remove product row from products list
   *
   */
  removeFromList(product: Product): void {
    this.bpdStore.removeProduct(Object.assign(new Product(), product));
    this.store.removeProduct(Object.assign(new Product(), product));
  }

  /**
   *  Whether the number of selected elements matches the total number of rows.
   *
   */
  isAllSelected(): boolean {
    const numSelected = this.rowsSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear rowsSelection.
   *
   */
  masterToggle(): void {
    this.isAllSelected() ?
      this.rowsSelection.clear() :
      this.dataSource.data.forEach(row => this.rowsSelection.select(row));
  }


  /**
   * View a row
   *
   */
  view(element: Product): void {
    this.tsliStore.setCurrentTsli(undefined);
    // console.log('current tsli in store :', this.tsliStore.getCurrentTsliId());
    // this.store.setCurrentItem(element);
    sessionStorage.setItem('itemProduct', JSON.stringify({
      'od': element.od,
      'wt': element.wt.toString().includes('#') ? element.wt : element.weight + '# (' + element.wt + '")',
      'grade': element.grade,
      'lubricant': element.lubricant,
      'connection': element.connection,
      'productType': element.productType,
      'option': element.option,
      'customer': element.customer,
      'connection_specific': element.connection_specific
    }));
    console.log('redirect to item ');
    this.router.navigate([`tsli/by-item`]);
  }

  /**
   * Download selected rows
   *
   */
  download(): void {
    let prd: Product[] = [];

    this.rowsSelection.selected.forEach(p => {
      console.log(p)
      prd.push(
        Object.assign(new Product(), {
          'od': p.od,
          'wt': p.wt.toString().includes('#') ? p.wt : p.weight + '# (' + p.wt + '")',
          'grade': p.grade,
          'lubricant': p.lubricant,
          'connection': p.connection,
          'productType': p.productType,
          'option': p.option,
          'customer': p.customer,
          'connection_specific': p.connection_specific
        })
      );
    });

    this.tsliStore.generateItemsPdfRequest(prd, this.checked, this.isOCTG).subscribe(resp => {
      if (resp.success != undefined && resp.success.message == 'pdf.generation.initialized') {
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_1;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_1.replace('<email>', this.authService.getCurrentUser()['email'])
          + modalRef.componentInstance.MSG_3;
      } else {
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static'
        });
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_2;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_2;
      }
    });
  }

  clearList() {
    this.store.clearProducts();
    this.bpdStore.clearProducts();
  }

  switchImperialOrSI() {
    this.bpdStore.switchImperialOrSI();
  }

  switchOctgNonOctg() {
    this.bpdStore.switchOctgNonOctg();
    for (const property of DetailedTsliConstants.PROPERTIES) {
      delete this.filterSelection[property.key];
      this.store.clearFilterValues(property.key);
    }
    this.store.getFilterValues([], this.isOCTG, true);
  }

  dynamicWarning() {
    this.configSubscribtion = this.config.getConfigurationByName('tsli_by_item_warning').subscribe(v => {
      this.warningDisplay = v['configurations'][0].value;
    });
  }

}
