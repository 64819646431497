import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from 'src/app/core/notification.service';

@Component({
  selector: 'notifications-panel',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(public store: NotificationService, public router: Router) {
  }

  @ViewChild('notifCarousel', {static: true}) carousel: NgbCarousel;

  notifications = [];

  ngOnInit() {
    this.store.getAllNotitifications().subscribe(v => {
      this.notifications = v["notifications"];
    });
  }

  moveNext() {
    this.carousel.next();
  }

  getPrev() {
    this.carousel.prev();
  }

  viewNotifications() {
    this.router.navigate([`tsli/notifications/0`]);
  }
}
