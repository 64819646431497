import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Product} from './data/product';
import {ApplicabilityFilter, ApplicabilityService} from './applicability.service';
import {DetailedTsliConstants} from '../features/detailed-tsli/detailed-tsli.constants';
import {Tsli} from './data/tsli';
import {TsliRestService} from './tsli-rest.service';
import {CurrentTsliStoreService} from './current-tsli-store.service';
import {TsliResponse} from './data/tsli-response';
import {AuthService} from './auth.service';
import {User} from './data/User';

@Injectable({
  providedIn: 'root'
})
export class DetailedTsliStore implements OnDestroy {
  public static readonly DEBUG = false;

  public readonly products = new BehaviorSubject<Product[]>([]);
  public readonly filterValues = new BehaviorSubject<any>({});     // values in drop-down boxes when choosing an item
  public readonly currentItem = new BehaviorSubject<Product>(new Product());
  public readonly selectedOption = new BehaviorSubject<string>('');
  public readonly tslis = new BehaviorSubject<Tsli[]>([]);
  public readonly searchParam = new BehaviorSubject<string>('');


  readonly products$ = this.products.asObservable();
  readonly filterValues$ = this.filterValues.asObservable();
  readonly currentItem$ = this.currentItem.asObservable();
  readonly selectedOption$ = this.selectedOption.asObservable();
  readonly tslis$ = this.tslis.asObservable();
  readonly searchParam$ = this.searchParam.asObservable();

  isSendToReview: boolean = false;

  currentUser: User;
  interval;
  timeLeft: number = 125;

  constructor(public applicabilityService: ApplicabilityService,
              public tsliService: TsliRestService,
              public tsliStore: CurrentTsliStoreService,
              public authService: AuthService) {

  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  addProduct(p: Product): void {
    this.products.next([...this.products.getValue(), p]);
  }

  clearProducts(): void {
    this.products.next([]);
  }

  removeProduct(toRemove: Product): void {
    const i: number = this.products.getValue().findIndex((p: Product) => {
      return p.connection === toRemove.connection
        && p.od === toRemove.od
        && p.wt === toRemove.wt
        && p.grade === toRemove.grade
        && p.lubricant === toRemove.lubricant
        && p.option === toRemove.option
        && p.customer === toRemove.customer
        && p.productType === toRemove.productType;
    });

    if (i === -1) {
      console.warn('Trying to remove an unknown product');
      console.warn(toRemove);
      console.warn(this.products.getValue());
      return;
    } else {
      const updatedProducts: Product[] = this.products.getValue();
      updatedProducts.splice(i, 1);
      this.products.next(updatedProducts);
    }
  }

  /**
   * Get allowed values for a given filter
   *
   * e.g.  for a filter
   *            {name: "od", value: "4.5"}
   *       will return
   *            ["0.273", "0.473"]
   *       which are values for wt.
   *
   * @param filters
   *
   * @param isOCTG
   * @param fromFilter
   */
  getFilterValues(filters: ApplicabilityFilter[], isOCTG, fromFilter: boolean): void {
    let nextFilterKey: string = this.getNextFilterKey(filters);

    if (nextFilterKey == "productType") {
      nextFilterKey = "product_type"
    } else if (nextFilterKey == "option") {
      nextFilterKey = "option_name"
    }

    filters.forEach(item => {
      item["name"] = item["name"] == "productType" ? 'product_type' : item["name"]
      item["name"] = item["name"] == "option" ? 'option_name' : item["name"]
    })

    this.applicabilityService.getApplicabilityByFilters(filters, nextFilterKey, isOCTG, fromFilter).subscribe((values: (string | number)[]) => {
      const newFilterValues: any = Object.assign({}, this.filterValues.getValue());

      if (nextFilterKey == "option_name") {
        newFilterValues["option"] = values.map(item => item["value"])
        newFilterValues["drawing"] = values.map(item => item["drawing"])
        newFilterValues["customer_information"] = values.map(item => item["customer_information"])
      } else if (nextFilterKey == "product_type") {
        newFilterValues["productType"] = values
      } else if (nextFilterKey !== 'od') {
        newFilterValues[nextFilterKey] = values.sort();
      } else {
        newFilterValues[nextFilterKey] = values;
      }
      this.filterValues.next(newFilterValues);
    });
  }

  /**
   * Clear list of values for a given filter
   *
   * @param filterKey
   *
   */
  clearFilterValues(filterKey: string): void {
    const newFilterValues: any = this.filterValues.getValue();
    delete newFilterValues[filterKey];
    this.filterValues.next(Object.assign({}, newFilterValues));
  }

  /**
   * Returns the next filter name in the following order:
   * od, wt, grade, connection, lubricant, product type, option, final user
   *
   */
  public getNextFilterKey(filters: ApplicabilityFilter[]): string {
    if (DetailedTsliStore.DEBUG) {
      console.log(`-- DetailedTsliStore.getNextFilterKey()`);
      console.log(`- filters:`, filters);
    }

    if (filters.length === 0) {
      if (DetailedTsliStore.DEBUG) {
        console.log(`- returned value at property 0: ${DetailedTsliConstants.PROPERTIES[0].key}`);
      }

      return DetailedTsliConstants.PROPERTIES[0].key;
    }

    for (const property of DetailedTsliConstants.PROPERTIES) {
      const f: ApplicabilityFilter = filters.find((v: ApplicabilityFilter) => v.name === property.key);
      if (f === undefined) {
        if (DetailedTsliStore.DEBUG) {
          console.log(`-- DetailedTsliStore.getNextFilterKey()`);
          console.log(`returned value: ${property.key}`);
        }

        return property.key;
      }
    }

    return undefined;
  }

  public save_chapters(tsli): void {
    if (tsli == undefined) {
      tsli = new Tsli();
      tsli = [];
    } else {
      tsli = this.processChapters(tsli);
    }
    //set index and depth
    DetailedTsliConstants.THEMES.forEach(theme => {
      let themechaptersIndex = 0;
      //chapters by theme
      let themeChapters = tsli.chapters.filter(chapter => chapter.theme == theme.key && chapter.type == 'product');

      let refs = new Map();
      themeChapters.forEach(chapter => refs.set(chapter.tsliDocType + chapter.reference, {
        docType: chapter.tsliDocType,
        reference: chapter.reference
      }));

      refs.forEach((value, key) => {
        // chapters by reference && doctype
        let refChapters = themeChapters.filter(chapter => chapter.reference == value.reference && chapter.tsliDocType == value.docType)
          .sort((a, b) => {
            return (a.index - b.index) != 0 ? a.index - b.index : a.depth - b.depth;
          });

        //set chapters index and depths
        refChapters[0].index = 0;
        refChapters[0].depth = 0;
        for (let j = 1; j < refChapters.length; j++) {
          refChapters[j].index = refChapters[j - 1].index + 1;
          refChapters[j].depth = 0;
          for (let k = j - 1; k > -1; k--) {
            if (refChapters[j].number.startsWith(refChapters[k].number)) {
              refChapters[j].depth = refChapters[k].depth + 1;
              break;
            }
          }

        }

        //set chapters index according to theme
        for (let i = 0; i < refChapters.length; i++) {
          refChapters[i].index = themechaptersIndex + i;
        }
        themechaptersIndex = themechaptersIndex + refChapters.length;
      });
    });

    //set chapter numbers
    DetailedTsliConstants.THEMES.forEach(theme => {
      let themeChapters = tsli.chapters.filter(chapter => chapter.theme === theme.key && chapter.type === 'product')
        .sort((a, b) => {
          return a.index - b.index;
        });
      themeChapters.forEach(chptr => {

        if (chptr.index == 0) {
          chptr.number = '1';
        } else {
          if (chptr.depth == 0) {
            const result = themeChapters
              .filter(chapter => chapter.depth == 0 && chapter.index < chptr.index);
            chptr.number = (result.length + 1) + '';
          } else {
            const supposedParents = themeChapters
              .filter(chapter => chapter.depth == chptr.depth - 1 && chapter.index < chptr.index);
            const realParent = supposedParents[supposedParents.length - 1];
            const result = themeChapters
              .filter(chapter => chapter.depth == chptr.depth
                && chapter.index < chptr.index
                && chapter.index > realParent.index);

            chptr.number = realParent.number + '.' + (result.length + 1);
          }
        }
      });
    });

    console.log(tsli)

    this.tsliStore.setCurrentTsli(tsli);
    this.tsliStore.setCurrentTsliByItem(true);
  }

  checkSavingResult(requestId: string) {
    this.applicabilityService.getSavingResult(requestId).subscribe(resp => {
      if (resp.result === 'done') {
        clearInterval(this.interval);
        this.tsliService.recoverChapters(requestId).subscribe(response => {
          this.save_chapters(response.tsli);
        });
      } else if (resp.result === 'wait') {
        console.log('wait 10 seconds');
      } else {
        clearInterval(this.interval);
      }
    });
  }

  public setCurrentItem(p: Product): void {
    this.currentItem.next(p);
    this.tsliService.getTsliByItem(p).subscribe((requestId: string) => {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.checkSavingResult(requestId);
      }, 5000);
    });
  }

  loadTslisByConnection(connection: string) {
    this.currentUser = this.authService.getCurrentUser();
    this.tslis.next([]);
    let result = [];
    this.tsliService.getTsliByConnection(connection)
      .subscribe((resp: TsliResponse) => {
        result = result.concat(resp.tslis.filter(tsli => tsli.type === 'general_information'));
        result = result.concat(resp.tslis.filter(tsli => tsli.type === 'product'));
        result = result.concat(resp.tslis.filter(tsli => tsli.type === 'process'));
        result = result.concat(resp.tslis.filter(tsli => tsli.type === 'ugfl'));
        result = result.concat(resp.tslis.filter(tsli => tsli.type === 'ugira'));

        if (this.currentUser.isAdmin) {
          result = result.concat(resp.tslis.filter(tsli => tsli.type === 'ugia'));
        }

        this.tslis.next(result);
      });
  }

  setSelectedOption(option) {
    this.selectedOption.next(option);
  }

  setSearchParam(docType: string, reference: string) {
    this.searchParam.next(docType + ' - ' + reference);
  }

  loadTslisByParam(param, value = undefined) {
    this.tslis.next([]);
    this.tsliService.getAllTslisByParam(param, value).subscribe(resp => {
      this.tslis.next(resp.tslis);
    });
  }

  processChapters(tsli: Tsli) {
    var regex = /token=\w*"/gi;
    let reg = /<a href="\/tsli\/by-reference\/(nt|rtli|tsli|tssu|form|inor|ug|)\/[a-zA-Z0-9\-]*">[ a-zA-Z0-9\-]*<\/a>/gi;

    tsli.chapters.forEach(chapter => {
      chapter.body = chapter.body.replace(regex, 'token=' + this.authService.getToken() + '\"');
      let founds = chapter.body.match(reg);
      let refs = [];
      if (founds != undefined) {
        founds.forEach(found => {
          let ref = found.replace(/<a href="\/tsli\/by-reference\/(nt|rtli|tsli|tssu|form|inor|ug|)\/[a-zA-Z0-9\-]*">/, '');
          refs.push(ref.replace(/<\/a>/, ''));
        });
      }
    });
    return tsli;
  }


  generateTsliListPdf(type, value) {
    let body = {'type': type};
    if (value != undefined) {
      body['value'] = value;
    }

    return this.tsliService.downloadItemPdf(body);
  }

}
