import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'check-application-date-dialog',
  templateUrl: './check-application-date-dialog.component.html',
  styleUrls: ['./check-application-date-dialog.component.scss']
})
export class CheckApplicationDateDialogComponent implements OnInit {

  applicationDate: string = ''
  validationMode: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  dismiss() {
    this.activeModal.dismiss('dissmissed');
  }

  continue() {
    this.activeModal.close('continue');
  }


  reject() {
    this.activeModal.close('reject');
  }

}
