import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './features/home/home.component';
import {PageNotFoundComponent} from './features/page-not-found/page-not-found.component';
import {TsliComponent} from './features/tsli/tsli.component';
import {ChaptersListComponent} from './features/tsli/chapters-list/chapters-list.component';
import {ApplicabilityComponent} from './features/tsli/chapter/applicability/applicability.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { BasicProductDataComponent } from './features/basic-product-data/basic-product-data.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PageUnauthorizedComponent } from './features/page-unauthorized/page-unauthorized.component';
import {DetailedTsliComponent} from './features/detailed-tsli/detailed-tsli.component';
import {TsliByItemPageComponent} from './features/detailed-tsli/tsli-by-item-page/tsli-by-item-page.component';
import {TsliByReferencePageComponent } from './features/detailed-tsli/tsli-by-reference-page/tsli-by-reference-page.component';
import { RoleGuard } from './core/guards/role.guard';
import { UsersManagementComponent } from './features/admin/users-management/users-management.component';
import { PdfContentComponent } from './features/pdf/pdf-content/pdf-content.component';
import { PdfUserComponent } from './features/pdf/pdf-user/pdf-user.component';
import { VideoComponent } from './features/home/video/video.component';
import { PdfSummaryComponent } from './features/pdf/pdf-summary/pdf-summary.component';
import { NotificationComponent } from './features/admin/notification/notification.component';
import { NotificationDetailsComponent } from './features/notifications/notification-details/notification-details.component';
import { ConfigurationComponent } from './features/admin/configuration/configuration.component';
import { UserLicenseLightRightsComponent } from './features/admin/user-license-light-rights/user-license-light-rights.component';
import { ProductListManagementComponent } from "./features/admin/product-list-management/product-list-management.component";
import { UserPartialRightsComponent} from "./features/admin/user-partial-rights/user-partial-rights.component";
import {PocNsComponent} from "./features/poc-ns/poc-ns.component";


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard]  },
  { path: 'tsli/edit/drafts', component: TsliComponent , canActivate: [AuthGuard, RoleGuard] },
  { path: 'tsli/chapters-list/edit/:ref', component: ChaptersListComponent , canActivate: [AuthGuard, RoleGuard ] },
  { path: 'tsli/chapters-list/adminedit/:ref', component: ChaptersListComponent , canActivate: [AuthGuard, RoleGuard ] },
  { path: 'tsli/chapters-list/review/:ref', component: ChaptersListComponent , canActivate: [AuthGuard, RoleGuard ] },
  { path: 'tsli/chapters-list/approve/:ref', component: ChaptersListComponent , canActivate: [AuthGuard, RoleGuard ] },
  { path: 'tsli/chapters-list/applicabilityedit/:ref', component: ChaptersListComponent , canActivate: [AuthGuard, RoleGuard ] },
  { path: 'tsli/chapters-list/themeedit/:ref', component: ChaptersListComponent , canActivate: [AuthGuard, RoleGuard ] },

  { path: 'tsli/chapter/applicability/:chapterRef',
    component: ApplicabilityComponent,
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [CanDeactivateGuard]},
  { path: 'tsli/poc-ns/:connection', component: PocNsComponent, canActivate: [AuthGuard] },
  { path: 'tsli/basic-product-data', component: BasicProductDataComponent , canActivate: [AuthGuard] },
  { path: 'tsli/Unauthorized', component: PageUnauthorizedComponent},
  { path: 'tsli/Unauthorized/404', component: PageUnauthorizedComponent},
  { path: 'tsli/details', component: DetailedTsliComponent},
  { path: 'tsli/by-item', component: TsliByItemPageComponent},
  { path: 'tsli/by-reference/:docType/:ref', component: TsliByReferencePageComponent},
  { path: 'tsli/by-id-reference/:idTsli', component: TsliByReferencePageComponent},
  { path: 'tsli/by-process/:theme', component: DetailedTsliComponent},
  { path: 'tsli/by-connection/:connection', component: DetailedTsliComponent},
  { path: 'tsli/admin/users', component: UsersManagementComponent, canActivate: [AuthGuard, RoleGuard]},
  { path: 'tsli/admin/users/:idTsli', component: UsersManagementComponent, canActivate: [AuthGuard, RoleGuard] },
  { path: 'tsli/admin/licenseLight', component: UserLicenseLightRightsComponent, canActivate: [AuthGuard, RoleGuard]},
  { path: 'tsli/admin/config', component: ConfigurationComponent, canActivate: [AuthGuard, RoleGuard]},
  { path: 'tsli/admin/product-list-management', component: ProductListManagementComponent, canActivate: [AuthGuard, RoleGuard]},
  { path: 'tsli/admin/user-partial-rights', component: UserPartialRightsComponent, canActivate: [AuthGuard, RoleGuard]},
  { path: 'tsli/admin/notifications', component: NotificationComponent, canActivate: [AuthGuard, RoleGuard]},
  { path: 'tsli/notifications/:notif', component: NotificationDetailsComponent },
  { path: 'tsli/as-pdf/:id_tsli', component: PdfContentComponent },
  { path: 'tsli/by-item/as-pdf', component: PdfContentComponent },
  { path: 'tsli/pdf-list/:type', component: PdfContentComponent },
  { path: 'tsli/pdf-list/:type/:value', component: PdfContentComponent },
  { path: 'tsli/pdf-watermark', component: PdfUserComponent },
  { path: 'tsli/pdf-summary', component: PdfSummaryComponent },
  { path: 'tsli/pdf-pages', component: PdfContentComponent },
  { path: 'tsli/video', component: VideoComponent},
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

