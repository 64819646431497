<div class="row product-row" *ngFor="let p of productsRow; let i = index;"
     (mouseenter)="p.hover = true" (mouseleave)="p.hover = false"
     [style.height.px]="(optionsLengths[i]>1 && view=='tds')? optionsLengths[i]*30 : 40">
  <div class="product-col text-center" style="width: 15%;">
    <ng-container *ngIf="p.product.specific_requirement === 'Yes'">
      <div class="connection pt-3"
           [matTooltip]="p.product.connection.toUpperCase().replace('-SPECIFIC_REQUIREMENT', '').replace('-'+p.product.isolated,'')+(p.product.isolated === 'Regular'?'':'-'+p.product.isolated)">
        {{p.product.connection.toUpperCase().replace("-"+p.product.isolated,"").replace('-SPECIFIC_REQUIREMENT', '')}}{{p.product.isolated === "Regular"?"":"-"+p.product.isolated}}</div>
      <div class="specific"><strong *ngIf="p.product.connection.toUpperCase().includes('SPECIFIC_REQUIREMENT') || p.product.specific_requirement === 'Yes'"
                                    style="color:crimson">{{" Specific"}}</strong></div>
    </ng-container>
    <ng-container *ngIf="p.product.specific_requirement === 'No'">
      <p [matTooltip]="p.product.connection.toUpperCase().replace('-'+p.product.isolated,'')+(p.product.isolated === 'Regular'?'':'-'+p.product.isolated)">
        {{p.product.connection.toUpperCase().replace("-"+p.product.isolated,"")}}{{p.product.isolated === "Regular"?"":"-"+p.product.isolated}}
      </p>
    </ng-container>
  </div>

  <div class="product-col text-center" style="width: 5%;">
    <p>{{p.product.od | fraction}}</p>
  </div>
  <div class="product-col text-center" style="width: 9%;">
    <p>{{p.product.weight| number : '1.2-2'}}# ({{p.product.wt}}")</p>
  </div>
  <div class="product-col text-center" style="width: 11%;">
    <p>{{p.product.grade}}</p>
  </div>
  <div class="product-col text-center" style="width: 15%;">
    <p>{{p.product.lubricant}}</p>
  </div>
  <div class="product-col text-center" style="width: 9%;">
    <p>{{p.product.product_type}}</p>
  </div>
  <div class="product-col text-center" style="width: 9%;">
    <p>{{p.product.option}}</p>
  </div>
  <div class="product-col text-center" style="width: 9%;">
    <p>{{p.product.customer}}</p>
  </div>
  <div class="product-col text-center" style="width: 10%;">
    <p *ngIf="p.product.connection.split('-SPECIFIC').length >= 2 || p.product.specific_requirement === 'Yes'"
       [matTooltip]="splitData(p.product.customer_information, 'CUSTOMER INFORMATION')  + '   '+ splitData(p.product.drawing, 'Drawing') "
       [matTooltipClass]="matTooltipDrawing" style="flex: 2 2; font-weight: bold; color:crimson">
      {{ "Cust & Drawing"}}</p>
  </div>
  <div class="product-col text-center" style="width: 5%;">
    <button mat-icon-button *ngIf="p.hover && isEditionMode"
            (click)="removeProduct(p)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
