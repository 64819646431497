import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {Tsli} from 'src/app/core/data/tsli';
import {DetailedTsliConstants} from '../detailed-tsli.constants';
import {Chapter} from 'src/app/core/data/chapter';
import {Subscription} from 'rxjs';

@Component({
  selector: 'sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})
export class SidebarNavComponent implements OnInit, OnDestroy {
  public tsliSubscription: Subscription;
  public byItemSubscription: Subscription;

  themes = DetailedTsliConstants.THEMES;
  tsli: Tsli;
  byItem: boolean = false;
  chaptersByItem = {
    designAndEngineering: [],
    endSizing: [],
    threading: [],
    gaugingAndInspection: [],
    surfaceTreatment: [],
    lubricant: [],
    makeUp: [],
    markingProtectionAndPackaging: []
  };

  constructor(public store: CurrentTsliStoreService) {
  }

  ngOnInit(): void {
    this.tsliSubscription = this.store.tsli$.subscribe((tsli: Tsli) => {
      this.tsli = tsli;
      if (tsli != null && tsli.chapters !== undefined) {
        this.themes.forEach(theme => {
          this.chaptersByItem[theme.key] = tsli.chapters.filter(chapter => chapter.theme === theme.key && chapter.type === 'product').sort((a, b) => a.index - b.index);
        });
      }
    });

    this.byItemSubscription = this.store.isByItem$.subscribe(value => this.byItem = value);
  }

  ngOnDestroy(): void {
    if (this.tsliSubscription !== undefined) {
      this.tsliSubscription.unsubscribe();
    }

    if (this.byItemSubscription !== undefined) {
      this.byItemSubscription.unsubscribe();
    }
  }

  navigateToChapter(index, theme?) {
    if (theme !== undefined) {
      let targetElt = document.getElementById(theme.key + '-' + index);
      if (targetElt !== undefined && index !== undefined && index != 0) {
        targetElt.scrollIntoView();
        //targetElt.parentElement.parentElement.scrollIntoView();
      } else {
        targetElt = document.getElementById(theme.name);
        if(targetElt !== undefined) targetElt.scrollIntoView();
      }
    } else {
      document.getElementById('chapter-' + index).scrollIntoView();
    }
  }

  filterBy(chapters: Chapter[],
           propName: string,
           value: any,
           operation: string = 'equals'): Chapter[] {
    if (operation === 'equals') {
      return chapters.filter(chapter => chapter[propName] == value);
    }
    if (operation === 'startsWith') {
      return chapters.filter(chapter => chapter[propName].startsWith(value));
    }
    return chapters;
  }
}
