<div class="spinner" *ngIf="!activatedDataReloading">
    <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="activatedDataReloading">
    <div class="tbl-col" style="flex: 9 9">
        <div class="tbl-row " style="height: 40px; min-width: 50%;"  *ngFor="let row of assemblyData;">
            <ng-container *ngIf="assemblyDataValues =='ASSEMBLYVALUES'">

                    <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                        <p
                          *ngSwitchCase="false" class="p-center"
                        >{{row.minMakeUpTorque|unitNumberFormat: imperialOrSI}}</p>
                        <p *ngSwitchCase="true" class="p-center">{{row.minMakeUpTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                        <p *ngSwitchCase="false" class="p-center">{{row.optMakeUpTorque|unitNumberFormat: imperialOrSI}}</p>
                        <p *ngSwitchCase="true" class="p-center">{{row.optMakeUpTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                        <p *ngSwitchCase="false" class="p-center">{{row.maxMakeUpTorque|unitNumberFormat: imperialOrSI}}</p>
                        <p *ngSwitchCase="true" class="p-center">{{row.maxMakeUpTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                        <p *ngSwitchCase="false" class="p-center">{{row.minShoulderingTorque|unitNumberFormat: imperialOrSI}}</p>
                        <p *ngSwitchCase="true" class="p-center">{{row.minShoulderingTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl b-right" style="width: 20%;">
                        <p *ngSwitchCase="false" class="p-center">{{row.maxShoulderingTorque|unitNumberFormat: imperialOrSI}}</p>
                        <p *ngSwitchCase="true" class="p-center">{{row.maxShoulderingTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                    </div>
            </ng-container>
            <ng-container *ngIf="assemblyDataValues =='OTHERASSEMBLY'">
                    <div class="tbl-col b-bl" style="width: 20%;">
                        <p class="p-center">{{row.minDeltaTurns|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div class="tbl-col b-bl" style="width: 20%;">
                        <p class="p-center">{{row.maxDeltaTurns|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div class="tbl-col b-bl" style="width: 20%;">
                        <p class="p-center">{{row.minShoulderSlope|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div class="tbl-col b-bl" style="width: 20%;">
                        <p class="p-center">{{row.maxShoulderSlope|unitNumberFormat: imperialOrSI}}</p>
                    </div>
                    <div class="tbl-col b-bl b-right" [matTooltip]="row.jaws" #tooltip="matTooltip"
                        style=" width: 20%;">
                        <p class="p-jaw">{{row.jaws}}</p>
                    </div>
            </ng-container>
            <ng-container *ngIf="assemblyDataValues =='MAXVALUES'">
                <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                    <p *ngSwitchCase="false" class="p-center">{{row.maxTorqueSealability|unitNumberFormat: imperialOrSI}}</p>
                    <p *ngSwitchCase="true" class="p-center">{{row.maxTorqueSealability_lbft|unitNumberFormat: imperialOrSI}}</p>
                </div>
                <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                    <p *ngSwitchCase="false" class="p-center">{{row.mtv|unitNumberFormat: imperialOrSI}}</p>
                    <p *ngSwitchCase="true" class="p-center">{{row.mtv_lbft|unitNumberFormat: imperialOrSI}}</p>
                </div>
                <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl" style="width: 20%;">
                    <p *ngSwitchCase="false" class="p-center">{{row.maxLinerTorque|unitNumberFormat: imperialOrSI}}</p>
                    <p *ngSwitchCase="true" class="p-center">{{row.maxLinerTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                </div>
                <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl " style="width: 20%;">
                    <p *ngSwitchCase="false" class="p-center">{{row.maxAllowedTorque|unitNumberFormat: imperialOrSI}}</p>
                    <p *ngSwitchCase="true" class="p-center">{{row.maxAllowedTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                </div>
                <div [ngSwitch]="imperialOrSI" class="tbl-col b-bl b-right" style="width: 20%;">
                    <p *ngSwitchCase="false" class="p-center">{{row.shaleOperatingTorque|unitNumberFormat: imperialOrSI}}</p>
                    <p *ngSwitchCase="true" class="p-center">{{row.shaleOperatingTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
