export enum DocumentTypeEnum {
  FORM = 'FORM',
  INOR = 'INOR',
  UG = 'UG',
  TSLI = 'TSLI',
  NT = 'NT',
  TSSU = 'TSSU',
  RTLI = 'RTLI'
}

export const DocumentType = new Map<string, string>([
  [DocumentTypeEnum.FORM, 'form'],
  [DocumentTypeEnum.INOR, 'inor'],
  [DocumentTypeEnum.UG, 'ug'],
  [DocumentTypeEnum.TSLI, 'tsli'],
  [DocumentTypeEnum.NT, 'nt'],
  [DocumentTypeEnum.TSSU, 'tssu'],
  [DocumentTypeEnum.RTLI, 'rtli']
]);


export enum TypeEnum {
  FORM = 'form',
  INOR = 'inor',
  UGFL = 'ugfl',
  UGIRA = 'ugira',
  UGIA = 'ugia',
  PROCESS = 'process',
  PRODUCT = 'product',
  GI = 'general_information',
  LEGACY = 'legacy'
}

export const UserGuideType = new Map<string, string>([
  [TypeEnum.UGIRA, 'ugira'],
  [TypeEnum.UGIA, 'ugia'],
  [TypeEnum.UGFL, 'ugfl'],
]);

