    <mat-form-field style="width:100%; padding: 5px;" appearance="outline">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput 
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                style="overflow: hidden;"
                [(ngModel)]="comment.comment" ></textarea>
    <button mat-button  matSuffix mat-icon-button [disabled] = "comment.comment == ''"  (click)="addComment()">
        <mat-icon [class.icon-active]="comment.comment != ''" style=" font-size: 25px;">send</mat-icon>
    </button>
        
    </mat-form-field>
