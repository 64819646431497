import { Component, OnInit } from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'pdf-thread-compound',
  templateUrl: './pdf-thread-compound.component.html',
  styleUrls: ['./pdf-thread-compound.component.scss']
})
export class PdfThreadCompoundComponent implements OnInit {

  threadCompounds = [];
  threadCompoundsHeader = [];
  isIMP = true;
  activatedDataReloading = true;
  unit = "oz/in³";
  unitVolume = "in³";
  unitQty = "oz";
  constructor(public store: BasicProductDataStoreService) {
  }

  ngOnInit() {
    this.store.threadCompoundHeader$.subscribe(threadCompoundsheader => this.threadCompoundsHeader = threadCompoundsheader);

    this.store.threadCompound$.subscribe(threadcompounds=> {
      this.threadCompounds = threadcompounds
      console.log(this.threadCompounds);

    });
    this.store.imperialOrSI$.subscribe(imperialOrSI => {
      this.isIMP = imperialOrSI;
      if(imperialOrSI){ 
        this.unit = "oz/in³";
        this.unitVolume = "in³" ;
        this.unitQty = "oz" ;
      } 
      else{ 
        this.unit="g/cm³";
        this.unitVolume = "cm³" ;
        this.unitQty = "g" ;
      }
    });

  }

  getDensity(threadName){
    if(this.threadCompoundsHeader.length > 0){
      let threadHeader = this.threadCompoundsHeader.filter(thread => thread.name == threadName);
      if(threadHeader.length > 0)
        return threadHeader[0].density;
    }else return '';
  }
}
