<div class="row">
    <h1><span class="mat-h1">Configuration</span></h1>
</div>
<div class="row">
    <table mat-table [dataSource]="dataSource" >
        <!-- Groupe -->
        <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef>Group</th>
            <td mat-cell *matCellDef="let element">{{element.group}}</td>
        </ng-container>

        <!-- Property -->
        <ng-container matColumnDef="property">
            <th mat-header-cell *matHeaderCellDef>Property</th>
            <td mat-cell *matCellDef="let element">{{element.property}}</td>
        </ng-container>

         <!-- Value -->
         <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <mat-header-cell *matHeaderCellDef> value </mat-header-cell>
            <td mat-cell *matCellDef="let element">
                <editvalue [value]=stripHtmlTags(element.value) (validate)="updateValue(element, $event)"></editvalue>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
