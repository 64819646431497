import { Component, OnInit } from '@angular/core';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';

@Component({
  selector: 'detailed-tsli-by-reference',
  templateUrl: './tsli-by-reference.component.html',
  styleUrls: ['./tsli-by-reference.component.scss']
})
export class TsliByReferenceComponent implements OnInit {
  constructor(public detailledTslisStore: DetailedTsliStore) { }

  ngOnInit() {
    this.detailledTslisStore.loadTslisByParam('reference');
  }
}

