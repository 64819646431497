import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ApplicabilityService } from 'src/app/core/applicability.service';

@Component({
  selector: 'app-connection-chooser',
  templateUrl: './connection-chooser.component.html',
  styleUrls: ['./connection-chooser.component.scss']
})
export class ConnectionChooserComponent implements OnInit {
  connections = [];
  connectionLogo = {
    'VAM TOP ®': 'vam_top.png',
    'VAM TOP ® HC': 'vam_top_hc.png',
    'VAM TOP ® HT': 'vam_top_ht.png',
    'VAM® 21': 'vam_21.png',
    'VAM® 21 HT': 'vam_21ht.png',
    'VAM® 21 HW': 'vam_21hw.png',
    'VAM® SLIJ-3': 'vam_slij_3.png',
    'VAM® SLIJ-3-NA': 'vam_slij_3.png',
    'VAM TOP ® FE': 'vam_top_fe.png',
    'VAM® SLIJ-II': 'vam_slij_II.png',
    'VAM® HTTC': 'vam_httc.png',
    'DINO VAM': 'dino_vam.png',
    // 'NEW VAM': '',
    'VAM® BOLT': 'vam_bolt.png',
    'VAM® BOLT II': 'vam_bolt_II.png',
    'VAM® EDGE SF': 'vam_edge_sf.png',
    'VAM® FJL': 'vam_fjl.png',
    'VAM® FPO': 'vam_fpo.png',
    'VAM® HP': 'vam_hp.png',
    'VAM® HTF-NR': 'vam_htf_nr.png',
    'VAM® LOX': 'vam_lox.png',
    'VAM® MUST': 'vam_must.png',
    'VAM® SG': 'vam_sg.png',
    'VAM® SPRINT-FJ': 'vam_sprint_fj.png',
    'VAM® SPRINT-SF': 'vam_sprint_sf.png'
  }

  constructor(public activeModal: NgbActiveModal,
    public applicabilityRest: ApplicabilityService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.applicabilityRest.getProduct()
      .subscribe(resp => {
        this.connections = resp;
      });
  }

  onClose(): void {
    this.activeModal.dismiss('cross clicked');
  }

  showBpdOfConnection(connection: string) {
    this.activeModal.dismiss('cross clicked');
    this.router.navigate([`tsli/poc-ns/${connection}`]);
  }
}
