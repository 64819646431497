<div class="container-fluid chapters">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col-7">
      <h3 class="mt-3">{{(tsli$ | async)?.docType | uppercase}}{{'-'}}{{ (tsli$ | async)?.reference }} {{'rev.'}}
        {{ (tsli$ | async)?.revision }} {{' :  '}}
        {{ (tsli$ | async)?.title }}</h3>
    </div>
    <div class="col pt-2 text-right">
      <ng-container *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE'">
        <button mat-flat-button class="mt-3 mr-3" color="primary"
                (click)="modifyAuthor()">{{ "Modify author" }}</button>
        <button mat-flat-button class="mt-3 mr-3" color="primary"
                (click)="modifyDateAndValidation()">{{ "Date & validation" }}</button>
      </ng-container>
      ·
      <ng-container *ngIf="(tsli$ | async).type === 'process'
      || (tsli$ | async).type  === 'ugfl'
      || (tsli$ | async).type  === 'ugia'
      || (tsli$ | async).type  === 'ugira'">
        <ng-container
          *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'APPLICABILITY_EDITION_MODE' || activatedTsliMode == 'THEME_EDITION_MODE'">
          <button mat-flat-button class="mt-3 mr-3" color="primary" *ngIf=" !tsliHasApplicability "
                  (click)="createModifyApplicability(false)">{{ activatedTsliMode == 'THEME_EDITION_MODE' ? "Edit Theme" : "Add applicability" }}</button>
          <button mat-flat-button class="mt-3 mr-3" color="primary" *ngIf=" tsliHasApplicability "
                  (click)="createModifyApplicability(false)">{{ activatedTsliMode == 'THEME_EDITION_MODE' ? "Edit Theme" : "Modify applicability" }}</button>
        </ng-container>
        <button mat-flat-button class="mt-3 mr-3" color="primary"
                *ngIf=" activatedTsliMode == 'REVIEWING_MODE' && tsliHasApplicability "
                (click)="showApplicability()">{{ "Show applicability" }}</button>
      </ng-container>

      <button mat-flat-button class="mt-3 mr-3" color="primary"
              *ngIf="(activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'APPLICABILITY_EDITION_MODE' || activatedTsliMode == 'THEME_EDITION_MODE') && (tsli$ | async).type === 'product'"
              (click)="createModifyApplicability(true)"
              [disabled]=" !checkAll == false ? !checkAll :!indeterminate">{{ activatedTsliMode == 'THEME_EDITION_MODE' ? "Multiple Theme" : "Multiple applicability" }}</button>
      <button mat-flat-button class="mt-3 mr-3" color="primary" disabled>{{ "Visualize" }}</button>
      <button mat-flat-button class="mt-3 mr-3" color="primary" disabled>{{ "Delete" }}</button>
      <button *ngIf="tsliStatus != 'published'"
              (click)="download()" mat-flat-button class="mt-3 mr-3" color="primary">{{ "Download" }}</button>

    </div>
  </div>
  <div class="row" style="margin-top: 10px; margin-right: 0px;">
    <div class="col-1 pt-2">
      <div class="row text-center pl-5 ">
        <mat-checkbox
          *ngIf="(tsli$ | async).type === 'product'
          && (activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'APPLICABILITY_EDITION_MODE' || activatedTsliMode == 'THEME_EDITION_MODE')
          && (tsli$ | async).chapters.length > 0"
          [checked]="checkAll" (change)="checkAlltems()" [indeterminate]="indeterminate" color="primary" class="mt-3">
        </mat-checkbox>
      </div>
    </div>
    <div class="col-8"></div>
    <div class="col-3 comments-container" *ngIf="(activatedTsliMode == 'SIMPLE_EDITION_MODE')
        || activatedTsliMode == 'REVIEWING_MODE' || activatedTsliMode == 'APPROVING_MODE' || activatedTsliMode == 'THEME_EDITION_MODE'">
      <general-comments></general-comments>
    </div>

  </div>

  <div class="row" *ngFor="let chapter of (tsli$ | async)?.chapters ; let i = index" style="margin-right: 0px;">
    <div class="col-1 pt-2" *ngIf="(tsli$ | async).type === 'product'">
      <div class="row text-center pl-5 ">
        <mat-checkbox
          *ngIf="(tsli$ | async).type === 'product' && (activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'APPLICABILITY_EDITION_MODE' || activatedTsliMode == 'THEME_EDITION_MODE')"
          [checked]="isSelecteditem[i]" (change)="setcheckitem(i)" color="primary" class="mt-3">
        </mat-checkbox>
      </div>
    </div>
    <div class="col-8">
      <chapter #chaptersElements (changedChapter)="changedChapter()" (savedChapter)="savedChapter()"
               (editChapter)="editChapter($event)" (moveChapter)="moveChapter($event)" [tsli]="(tsli$ | async)"
               [colorinput]="listbuttonsColor.length > 0 && listbuttonsColor.indexOf(chapter.id_chapter) >= 0"
               [chapter]="chapter" [chapters]="(tsli$ | async)?.chapters"></chapter>
    </div>
    <div class="col-3 comments-container" *ngIf="(activatedTsliMode == 'SIMPLE_EDITION_MODE')
        || activatedTsliMode == 'REVIEWING_MODE' || activatedTsliMode == 'APPROVING_MODE' || activatedTsliMode == 'THEME_EDITION_MODE' ">
      <chapter-comments [chapter]="chapter"></chapter-comments>
    </div>


  </div>

  <div class="chapter-list"></div>
</div>
<footer class="d-flex justify-content-between text-center mt-3 footer">
  <div></div>
  <div>
    <button mat-flat-button color="primary"
            *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'ADMIN_EDITION_MODE'"
            (click)="addChapter()">{{ "Add new chapter" }}</button>
    <button mat-flat-button color="primary" class="ml-2" *ngIf="activatedTsliMode === 'ADMIN_EDITION_MODE' "
            (click)="validateAdminChanges()">{{ "Validate" }}</button>
    <button mat-raised-button color="primary" class="ml-2" *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE'"
            (click)="sendToReview()">{{ "Send to review" }}</button>
    <button mat-raised-button color="primary" class="ml-2"
            *ngIf="activatedTsliMode == 'REVIEWING_MODE' || activatedTsliMode == 'APPROVING_MODE'"
            (click)="refuse()">{{ "Refuse" }}</button>
    <button mat-raised-button color="primary" class="ml-2" *ngIf="activatedTsliMode == 'REVIEWING_MODE'"
            (click)="sendToApprove()">{{ "Send to Approve" }}</button>
    <button mat-raised-button color="primary" class="ml-2" *ngIf="activatedTsliMode == 'APPROVING_MODE'"
            (click)="publish()">{{ "Publish" }}</button>

  </div>
  <div>
    <span *ngIf="activatedTsliMode == 'SIMPLE_EDITION_MODE' || activatedTsliMode == 'ADMIN_EDITION_MODE'"
          class="mat-caption mr-3 save-message">{{saveMessage}}</span>
    <button mat-flat-button (click)="collapseAll()">{{ "Expand all" }}</button>
    <button mat-flat-button (click)="closeAll()">{{ "Collapse all" }}</button>
  </div>
</footer>
