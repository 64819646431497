<div class="container-fluid " style="min-width: 1500px; overflow-x: auto;
white-space: nowrap;">
  <!-- common header with title, Units and pagination controls -->
  <div class="row">
    <div class="col">
      <bpd-header></bpd-header>
      <br>
    </div>
  </div>

  <br>
  <br>
  <br>

  <!-- split in two cols -->
  <!-- product picker on left-hand side -->
  <!-- surface treatment header on right-hand side -->
  <div class="row" [style.padding-right.px]="paddingRight">


    <div class="col" style="padding-right: 0px;">
      <product-picker></product-picker>
    </div>
    <div class="col" style="max-width: 35%;  min-width: 40%;">
      <surface-treatment-header></surface-treatment-header>
    </div>
  </div>

  <!-- rows of products -->
  <div id="productsRows" class="row scrollable-content">
    <div class="col" style="padding-right: 0px;">
      <product-list></product-list>
    </div>
    <div class="col" style="max-width: 35%;  min-width: 40%;">
      <surface-treatment-content></surface-treatment-content>
    </div>
  </div>
</div>

<div class="footer-space">
  <!-- footer, floating above -->
  <footer class=" mt-3 footer">
    <div class="row  d-flex justify-content-between text-center ">
      <div class="col" style="font-size: 0.9em;">
        <mat-icon style="font-size: 1.3em; color: green;">check_circle</mat-icon>
        Surface Treatment is acceptable for this connection
      </div>
      <div class="col" style="font-size: 0.9em;">
        <mat-icon color="warn" style="font-size: 1.3em;">cancel</mat-icon>
        Surface Treatment is <b>Not</b> acceptable for this connection
      </div>
      <div class="col" style="font-size: 0.9em;">
        <mat-icon style="font-size: 1.3em; color: orange">announcement</mat-icon>
        Surface Treatment is acceptable <b>providing</b> the associated comment is executed (click on the yellow icon to
        see the associated comment)
      </div>
    </div>
  </footer>
</div>

