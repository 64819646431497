import { Component, OnInit, Input } from '@angular/core';
import { CommentsStoreService } from 'src/app/core/comments-store.service';
import { Comment } from 'src/app/core/data/comment';
import { User } from 'src/app/core/data/User';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  constructor(public commentStore: CommentsStoreService) { }
  @Input()
  comment: Comment


  ngOnInit() {
    if(this.comment == undefined){
      this.comment = new Comment();
      this.comment.comment = "auto generated comment auto generated comment auto generated comment auto generated comment auto generated comment auto generated comment auto generated comment auto generated comment";
      this.comment.commentDate = new Date();
      this.comment.commentator = new User();
      this.comment.commentator.first_name = "genarated ";
      this.comment.commentator.name = "user";
    }
  }

  checkComment(){
    this.comment.checked = !this.comment.checked
    this.commentStore.updateComment(this.comment.idComment, this.comment.checked);
  }
}
