<ng-container *ngIf='byItem'>
  <div class="row row-mt">
    <div class="col-md-2"><img src="assets/img/pdf/vam.svg" /></div>
    <div class="col-md-7">
      <div class="title">Technical Specification for Licensees by Item</div>
      <div class="info-1">
        <span class="item-fields">{{tsliItem.connection}}</span>
        <span class="item-fields" *ngIf="tsliItem.connection_specific">{{' - ' + tsliItem.connection_specific}}</span>
        <span class="item-fields">{{ tsliItem.od|fraction }}</span>
        <span class="item-fields">{{ tsliItem.wt }}</span>
        <span class="item-fields">{{ tsliItem.grade }}</span>
        <span class="item-fields" *ngIf="tsliItem.customer_information">{{ tsliItem.customer_information}}</span>
      </div>
    </div>
    <div class="bordered">
      <div class="title">PAGE</div>
    </div>
    <div class="col-md-2">
      <div class="title">PUBLICATION DATE</div>
      <div class="info-1">{{currentDate |date:'dd MMM. yyyy'}}</div>
    </div>
  </div>
  <div class="row row-mt row-b-border">
    <div class="col-md-2">
      <div class="title">POWERED BY</div>
      <div><img src="assets/img/pdf/vallourec.svg" /></div>
    </div>
    <div class="col-md-2">
      <div class="title">PRODUCT TYPE</div>
      <div class="info-2">{{tsliItem.productType}}</div>
    </div>
    <div class="bordered">
      <div class="title">END USER</div>
      <div class="info-2">{{tsliItem.customer}}</div>
    </div>
    <div class="col-md-3">
      <div class="title">LUBRICANT</div>
      <div class="info-2">{{tsliItem.lubricant}}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf='!byItem'>
  
    <div id="info" class="row row-mt">
      <div class="col-md-2"><img src="assets/img/pdf/vam.svg" /></div>
      <div class="col-md-7">
        <div class="title">Technical Specification for Licensees</div>
        <div class="info-1" *ngIf='!showList'>{{tsli.title}}</div>
        <div class="info-1" style="color: red;" *ngIf='showList'>{{listTitle}}</div>

      </div>
      <div class="col-md-1">
          <div class="title">PAGE</div>
        </div>
      <div class="bordered col-md-1" *ngIf='!showList'>
          <div class="title">{{tsli.docType | uppercase}}</div>
          <div class="info-1">{{tsli.reference}}</div>
        </div>
      <div class="col-md-1" *ngIf='!showList'>
          <div class="title">REVISION</div>
          <div class="info-1">{{tsli.revision}}</div>
        </div>
        
    </div>

    <div class="row row-mt" *ngIf='(!showAll && showList)'>
      <div class="col-md-8">
        <div class="title">POWERED BY</div>
        <div><img src="assets/img/pdf/vallourec.svg" /></div>
      </div>
      <div class="col-md-2">
        <div class="title">PUBLICATION DATE</div>
        <div class="info-1">{{currentDate |date:'dd MMM. yyyy'}}</div>
      </div>
      </div>
    
    <div class="row row-mt" *ngIf='showAll'>
      <div class="col-md-2">
        <div class="title">POWERED BY</div>
        <div><img src="assets/img/pdf/vallourec.svg" /></div>
      </div>
      <div class="col">
        <div class="title">PREPARED BY</div>
        <div class="info-2">{{author}}</div>
      </div>
      <div class="bordered col">
        <div class="title">REVIEWED BY</div>
        <div class="info-2">{{reviewer}}</div>
      </div>
      <div class="col">
        <div class="title">APPROVED BY</div>
        <div class="info-2">{{approver}} </div>
      </div>
      <div class="col"></div>
      <div class="right-bordered col">
          <div class="title-2">APPLICABLE FROM</div>
          <div class="info-2">{{tsli.applicationDate |date:'dd MMM. yyyy'}}</div>
        </div>
        <div class="col">
          <div class="title-2">APPLICABLE BY</div>
          <div class="info-2">{{tsli.applicableBy}}</div>
        </div>
    </div>
    <div class="row-b-border"></div>
  </ng-container>