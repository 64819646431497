import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Tsli} from 'src/app/core/data/tsli';
import {SelectionModel} from '@angular/cdk/collections';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {DetailedTsliStore} from 'src/app/core/detailed-tsli-store.service';
import {CurrentTsliStoreService} from '../../../core/current-tsli-store.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DownloadRequestDialogComponent} from 'src/app/core/dialog/download-request-dialog/download-request-dialog.component';
import {AuthService} from 'src/app/core/auth.service';
import {DatePipe} from '@angular/common';
import {TsliRestService} from '../../../core/tsli-rest.service';

@Component({
    selector: 'detailed-tsli-list',
    templateUrl: './tsli-list.component.html',
    styleUrls: ['./tsli-list.component.scss']
})
export class TsliListComponent implements OnInit {
    dataSource = new MatTableDataSource<Tsli>([]);
    cachedTslis: Tsli[];
    searchedTslis = [];
    searchedExp = '';
    displayedColumns: string[] = ['select', 'reference', 'revision', 'applicationDate', 'type', 'action'];
    rowsSelection = new SelectionModel<Tsli>(true, []);
    title = undefined;

    constructor(public detailedTslisStore: DetailedTsliStore,
                public currentTsliStore: CurrentTsliStoreService,
                public router: Router,
                public route: ActivatedRoute,
                public modal: NgbModal,
                public authService: AuthService,
                public datePipe: DatePipe,
                public rest: TsliRestService) {
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
          this.title = params.get('title');
          if (this.title !== undefined) {
            this.displayedColumns.splice(1, 0, 'title');
          }
        });

        this.detailedTslisStore.tslis$.subscribe(resp => {
            this.cachedTslis = resp;
            // this.cachedTslis = resp.filter(tsli =>
            //   tsli.docType === DocumentType.get('INOR')
            // );

            //this.cachedTslis.push(...resp.filter(tsli=>tsli.docType === DocumentType.RTLI))
            //this.cachedTslis.push(...resp.filter(tsli=>tsli.docType === DocumentType.TSLI))
            //this.cachedTslis.push(...resp.filter(tsli=>tsli.docType === DocumentType.TSSU))
            if (this.cachedTslis.length >= 15) {
                this.dataSource.data = this.cachedTslis.slice(0, 15);
            } else {
                this.dataSource.data = this.cachedTslis.slice(0, this.cachedTslis.length);
            }

            this.detailedTslisStore.searchParam$.subscribe(response => {
                this.searchedExp = response.toLowerCase();
                this.searchInData();
            });
        });
    }

    viewMore(): void {
        length = (this.cachedTslis.length - this.dataSource.data.length > 15) ? 15 : this.cachedTslis.length - this.dataSource.data.length;
        this.dataSource.data.push(...this.cachedTslis.slice(this.dataSource.data.length, this.dataSource.data.length + length));
        this.dataSource.data = this.dataSource.data.slice();
    }

    /**
     *  Whether the number of selected elements matches the total number of rows.
     *
     */
    isAllSelected(): boolean {
        const numSelected = this.rowsSelection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear rowsSelection.
     *
     */
    masterToggle(): void {
        this.isAllSelected() ?
            this.rowsSelection.clear() :
            this.dataSource.data.forEach(row => this.rowsSelection.select(row));
    }

    /**
     * View a row
     *
     */
    view(docType: string, reference: string): void {
        // this.currentTsliStore.setCurrentTsli(undefined);
        this.router.navigate([`/tsli/by-reference/${docType}/${reference}`]);
    }

    /**
     * Download selected rows
     *
     */
    download(): void {
        console.log(this.rowsSelection);
        // let idTsliList = this.rowsSelection.selected.map(tsli=> tsli.idTsli);
        let refRev = this.rowsSelection.selected
            .map(tsli => {
                return {
                    'id_tsli': tsli['id'],
                    'name': tsli.document_type.toUpperCase() + tsli.reference + ' - ' + tsli.title + ' - rev.' + tsli.revision
                };
            });
        this.currentTsliStore.generateTsliListPdfRequest(refRev).subscribe(resp => {
            if (resp.success != undefined && resp.success.message == 'pdf.generation.initialized') {
                const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {
                    size: 'lg',
                    centered: true,
                    backdrop: 'static'
                });
                modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_1;
                modalRef.componentInstance.msg = modalRef.componentInstance.MSG_1.replace('<email>', this.authService.getCurrentUser()['email'])
                    + modalRef.componentInstance.MSG_3;
            } else {
                const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {
                    size: 'lg',
                    centered: true,
                    backdrop: 'static'
                });
                modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_2;
                modalRef.componentInstance.msg = modalRef.componentInstance.MSG_2;
            }
        });
    }

    downloadDisplayFile(document: Tsli) {
        let s3_filename = document.display_file_s3_url;
        if (s3_filename == '' || s3_filename == null) {
            s3_filename = document.source_file_s3_url;
        }

        this.rest.downloadDisplayAndSourceFile(s3_filename, document.reference, document.document_type).subscribe(
            response => {
                console.log(response);
                window.open(response, '_self');
            });
    }

    searchInData() {
        if (this.searchedExp == '') {
            this.searchedTslis = this.cachedTslis.slice(0, this.cachedTslis.length);
        } else {
            this.searchedTslis = this.cachedTslis.filter(t => {
                let safeSearchedExp = this.searchedExp.toLowerCase();
                let applicationDate = '';
                if (t.applicationDate != undefined) {
                    applicationDate = this.datePipe.transform(t.applicationDate, 'dd/MM/yyyy');
                }
                return t.title.toLowerCase().includes(safeSearchedExp) ||
                    t.reference.toLowerCase().includes(safeSearchedExp) ||
                    (t.document_type + ' - ' + t.reference).toLowerCase().includes(safeSearchedExp) ||
                    t.type.toLowerCase().includes(safeSearchedExp) ||
                    applicationDate.includes(safeSearchedExp);
            });
        }
        this.dataSource.data = this.searchedTslis.slice(0, 15);
    }

}
