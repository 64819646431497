import {Chapter} from './chapter';
import {Status} from '../enum/status.enum';
import {User} from './User';

export class Tsli {
  docType: string;
  reference: string;
  revision: string;
  title: string;
  type: string;
  status: Status;
  applicationDate: Date;
  applicability: boolean;
  applicabilityEntry: string;
  newApplicabilityEntry: string;
  reviewer: User;
  approver: User;
  chapters: Chapter[];
  theme: string;
  idTsli: string;
  draftInProgress: boolean = false;
  draftStatus: string;
  draftAuthor: User;
  author: User;
  comment: boolean;
  applicableBy: string;
  publicationDate: Date;
  is_vam_family: boolean;
  source_file_s3_url: string;
  display_file_s3_url: string;
  isLegacy: boolean;
  isArchivedDocument: boolean;
  is_restricted_document: boolean;
  document_type: string;
}

