import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';

@Component({
  selector: 'detailed-tsli-by-connection-list',
  templateUrl: './tsli-by-connection-list.component.html',
  styleUrls: ['./tsli-by-connection-list.component.scss']
})
export class TsliByConnectionListComponent implements OnInit {

  constructor(public detailedTslisStore: DetailedTsliStore,
    public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) =>
    this.detailedTslisStore.loadTslisByConnection(params.get('connection')));
  }

}
