import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Tsli} from 'src/app/core/data/tsli';
import {TslisStoreService} from 'src/app/core/tslis-store.service';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialogComponent} from 'src/app/core/dialog/confirm-dialog/confirm-dialog.component';
import {Router} from '@angular/router';
import {Status} from 'src/app/core/enum/status.enum';
import {TsliRestService} from 'src/app/core/tsli-rest.service';
import {
  ConfirmDialogArchiveComponent
} from 'src/app/core/dialog/confirm-dialog-archive/confirm-dialog-archive.component';
import {TsliMode} from 'src/app/core/enum/tsli-mode.enum';
import {User} from 'src/app/core/data/User';
import {AuthService} from 'src/app/core/auth.service';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';
import {UserStoreService} from 'src/app/core/user-store.service';
import {WarningPopupComponent} from '../../warning-popup/warning-popup.component';
import {Comment} from '../../../core/data/comment';
import {
  DownloadSourceOrDisplayFilePopupComponent
} from '../../download-source-or-display-file-popup/download-source-or-display-file-popup/download-source-or-display-file-popup.component';
import {SpinnerDialogComponent} from '../../../core/dialog/spinner-dialog/spinner-dialog.component';
import {ModifyDocumentComponent} from '../../manage-document/modify-document/modify-document.component';
import {CreateDocumentComponent} from '../../manage-document/create-document/create-document.component';
import {NavigateDialogComponent} from '../../../core/dialog/navigate-dialog/navigate-dialog.component';
import {ConfigurationService} from '../../../core/configuration.service';

@Component({
  selector: 'tslis',
  templateUrl: './tslis.component.html',
  styleUrls: ['./tslis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TslisComponent implements OnInit {
  dataSource = new MatTableDataSource<Tsli>([]);
  displayedColumns: string[];
  CurrentUser: User;
  searchedTslis = [];
  searchedExp = '';
  cachedTslis = [];

  @Input() tab: string;
  usersSkillsMatrix: User[];
  public tsliComments: Comment[] = [];
  modalRef: NgbModalRef;
  timeLeft: number = 125;
  cancel_vam_family_property_for_document: string;

  constructor(public tslisStore: TslisStoreService,
              public userStore: UserStoreService,
              public modal: NgbModal,
              public currentTsliStore: CurrentTsliStoreService,
              public router: Router,
              public rest: TsliRestService,
              public currentuser: AuthService,
              public datePipe: DatePipe,
              public authService: AuthService,
              public configService: ConfigurationService) {
  }


  ngOnInit() {
    this.CurrentUser = this.currentuser.getCurrentUser();
    if (this.tab === 'published') {
      this.tslisStore.published$.subscribe(t => {
        if (!this.currentuser.isAdmin()) {
          if (!this.currentuser.isReviewerOrApprover()) {
            this.dataSource.data = t.filter(document => document.type != 'ugia' && document.type != 'ugira');
            this.cachedTslis = t.filter(document => document.type != 'ugia' && document.type != 'ugira');
          } else {
            this.dataSource.data = t.filter(document => document.type != 'ugia');
            this.cachedTslis = t.filter(document => document.type != 'ugia');
          }
        } else {
          this.dataSource.data = t;
          this.cachedTslis = t;
        }
      });
      this.userStore.usersSkillsMatrix$.subscribe(u => {
        this.usersSkillsMatrix = u;
      });
      //this.data = this.tslisStore.published$;
      this.displayedColumns = ['title', 'reference', 'revision', 'applicationDate', 'type', 'draftAuthor', 'skillsMatrix', 'AccessVAMfamlyMembers', 'PublishedLegacy', 'actions'];
    } else if (this.tab === 'drafts') {
      this.tslisStore.drafts$.subscribe(t => {
        this.dataSource.data = t;
        this.cachedTslis = t;
      });
      // this.data = this.tslisStore.drafts$;
      this.displayedColumns = ['title', 'reference', 'revision', 'applicationDate', 'status', 'type', 'AccessVAMfamlyMembers', 'DraftsLegacy', 'actions'];
    } else if (this.tab === 'archived') {
      this.tslisStore.archived$.subscribe(t => {
        this.dataSource.data = t;
        this.cachedTslis = t;
      });
      this.displayedColumns = ['title', 'reference', 'revision', 'applicationDate', 'type', 'actions'];
      // this.data = this.tslisStore.archived$;
    }
  }

  edit(document: Tsli): void {
    if (document.source_file_s3_url != '' && document.source_file_s3_url != null) {
      const editDocument = this.modal.open(ModifyDocumentComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });

      editDocument.componentInstance.documentToModify = document;
      editDocument.componentInstance.activatedTsliMode = TsliMode.simpleEditionMode;
      this.currentTsliStore.setActivatedMode(TsliMode.simpleEditionMode);
    } else {
      this.currentTsliStore.setActivatedMode(TsliMode.simpleEditionMode);
      this.currentTsliStore.clearlistCurrentChapters();
      this.currentTsliStore.openTsli(document.idTsli);
    }
  }

  editAdmin(idTsli: string): void {
    this.currentTsliStore.setActivatedMode(TsliMode.adminEditoionMode);
    this.currentTsliStore.openTsli(idTsli);
  }

  review(document: Tsli): void {
    if (this.tslisStore.isHTMLDocument(document)) {
      const warningModal = this.modal.open(WarningPopupComponent, {size: 'lg', centered: true, backdrop: 'static'});
      warningModal.componentInstance.documentToReviewOrApprove = document;
      warningModal.componentInstance.isToPublish = false;
      warningModal.componentInstance.isSendToApprove = true;

      warningModal.result.then((userResponse) => {
        if (userResponse === 'confirmed') {
          this.updateStatus(Status.APPROBATING, document);
        }
      }, () => {
      });

    } else {
      this.currentTsliStore.setActivatedMode(TsliMode.reviewingMode);
      this.currentTsliStore.openTsli(document.idTsli);
    }
  }

  approve(document: Tsli): void {
    if (this.rest.isHTMLDocument(document)) {
      const warningModal = this.modal.open(WarningPopupComponent, {size: 'lg', centered: true, backdrop: 'static'});
      warningModal.componentInstance.documentToReviewOrApprove = document;
      warningModal.componentInstance.isToPublish = true;
      warningModal.componentInstance.isSendToApprove = false;

      warningModal.result.then((userResponse) => {
        if (userResponse === 'confirmed') {
          this.updateStatus(Status.PUBLISHED, document);
        }
      }, () => {
      });
    } else {
      this.currentTsliStore.setActivatedMode(TsliMode.approvingMode);
      this.currentTsliStore.openTsli(document.idTsli);
    }
  }

  // revise(document: Tsli): void {
  //   const reviseDocument = this.modal.open(CreateRevisionPopupComponent, {
  //     size: 'lg',
  //     centered: true,
  //     backdrop: 'static'
  //   });
  //   this.currentTsliStore.setActivatedMode(TsliMode.revisingMode);
  //   reviseDocument.componentInstance.documentToRevise = document;
  // }

  revise(document): void {
    const reviseDocument = this.modal.open(CreateDocumentComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    this.currentTsliStore.setActivatedMode(TsliMode.revisingMode);
    reviseDocument.componentInstance.documentToRevise = document;
  }

  archive(tsli: Tsli): void {

    const modalRef = this.modal.open(ConfirmDialogArchiveComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.reference = tsli.reference;
    modalRef.componentInstance.revision = tsli.revision;
    modalRef.componentInstance.docType = tsli.docType;


    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        this.rest.updateStatus(tsli, Status.ARCHIVED).subscribe(resp => {
          this.tslisStore.getAllDocuments().subscribe();
          return resp;
        });
      }
    }, () => {
    });

  }

  view(idTsli: string): void {
    this.currentTsliStore.setCurrentTsli(undefined);
    this.router.navigate([`/tsli/by-id-reference/${idTsli}`]);
  }

  download(document: Tsli): void {
    //Pop-up to choose which file to download source or display file
    const modalRef = this.modal.open(DownloadSourceOrDisplayFilePopupComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.document = document;
  }

  editApplicability(idTsli: string) {
    this.currentTsliStore.clearlistCurrentChapters();
    this.currentTsliStore.setActivatedMode(TsliMode.applicabilityEditionMode);
    this.currentTsliStore.openTsli(idTsli);
  }

  editTheme(idTsli: string) {
    this.currentTsliStore.clearlistCurrentChapters();
    this.currentTsliStore.setActivatedMode(TsliMode.themeEditionMode);
    this.currentTsliStore.openTsli(idTsli);
  }

  deleteRevision(document: Tsli): void {
    const modalRef = this.modal.open(ConfirmDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.componentName = document.docType.toUpperCase() + '-' + document.reference + ' rev.' + document.revision;
    modalRef.componentInstance.componentType = document.docType.toUpperCase() + ' Revision';

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        //call delete service
        this.tslisStore.deleteRevision(document.idTsli, document.type);
      }
    }, () => {
    });
  }

  deleteTsli(document: Tsli): void {
    const modalRef = this.modal.open(ConfirmDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.componentName = document.docType.toUpperCase() + '-' + document.reference;
    modalRef.componentInstance.componentType = document.docType.toUpperCase();

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        //call delete service
        this.tslisStore.deleteTsli(document.reference, document.type, document.docType);
      }
    }, () => {
    });
  }

  searchInData() {
    // if (this.tab ==  'published')
    if (this.searchedExp == '') {
      this.searchedTslis = this.cachedTslis;
    } else {

      this.searchedTslis = this.cachedTslis.filter(t => {
        let safeSearchedExp = this.searchedExp.toLowerCase();
        let applicationDate = '';
        if (t.applicationDate != undefined) {
          applicationDate = this.datePipe.transform(t.applicationDate, 'dd/MM/yyyy');
        }
        return t.title.toLowerCase().includes(safeSearchedExp) ||
          t.reference.toLowerCase().includes(safeSearchedExp) ||
          (t.docType + ' - ' + t.reference).toLowerCase().includes(safeSearchedExp) ||
          t.type.toLowerCase().includes(safeSearchedExp) ||
          applicationDate.includes(safeSearchedExp) ||
          (this.tab == 'published' && (t.draftAuthor.firstName + ' ' + t.draftAuthor.name).includes(safeSearchedExp)) ||
          (this.tab == 'drafts' && t.status.includes(safeSearchedExp));

      });
    }
    this.dataSource.data = this.searchedTslis;
  }

  updateSkillsMatrixUser(idTsli, idUser): void {
    this.rest.updateTsliSkillsMatrixUser(idTsli, idUser).subscribe(resp => {
      console.log(resp);
    });
  }

  updateIsVamFamily(tsli: Tsli, value) {
    this.configService.getConfigurationByName('cancel_restricted_access_property_for_document').subscribe(cf => {

      if (tsli.is_restricted_document) {
        //confirmation pop-up
        const modalRef = this.modal.open(NavigateDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
        modalRef.componentInstance.title = 'Unsaved changes';
        modalRef.componentInstance.message_1 = cf['configurations'][0].value;
        modalRef.componentInstance.message_2 = '';

        return modalRef.result.then((userResponse) => {
          console.log('userResponse:', userResponse);
          if (userResponse === 'confirmed') {
            this.rest.updateIsVamFamily(tsli.idTsli, value).subscribe(resp => {
              console.log(resp);
              this.router.navigate(['/tsli/edit/drafts']);
              this.tslisStore.getAllDocuments().subscribe();
            });
            return true;
          }
        }, (reason) => {
          this.router.navigate(['/tsli/edit/drafts']);
          this.tslisStore.getAllDocuments().subscribe();
          return false;
        });
      } else {
        this.rest.updateIsVamFamily(tsli.idTsli, value).subscribe(resp => {
          console.log(resp);
        });
      }
    });
  }

  updateIsLegacy(idTsli, value) {
    this.rest.updateIsLegacy(idTsli, value).subscribe(resp => {
      console.log(resp);
    });
  }

  updateStatus(status: Status, document: Tsli) {

    this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
    this.timeLeft = 125;

    this.rest.updateStatus(document, status).subscribe(resp => {
      if (resp.msg != undefined && resp.msg == 'tsli status updated') {
        this.modalRef.componentInstance.message = 'Status Changed successfuly';
        this.modalRef.componentInstance.done = true;
        this.tslisStore.getAllDocuments().subscribe();

        setTimeout(() => {
          this.modal.dismissAll();
        }, 5000);
      } else {
        this.modalRef.componentInstance.message = 'Failed to change Status';
        this.modalRef.componentInstance.done = false;
        this.tslisStore.getAllDocuments().subscribe();

        setTimeout(() => {
          this.modal.dismissAll();
        }, 5000);
      }
    });
  }

  restrictedAccess(document: Tsli) {

    console.log('document to restricte:', document);
    this.currentTsliStore.setCurrentTsli(document);
    this.router.navigate([`/tsli/admin/users/${document.idTsli}`]);
  }
}
