import { Component, OnInit } from '@angular/core';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { Chapter } from 'src/app/core/data/chapter';
import { Theme, DetailedTsliConstants } from '../../detailed-tsli/detailed-tsli.constants';

@Component({
  selector: 'pdf-summary',
  templateUrl: './pdf-summary.component.html',
  styleUrls: ['./pdf-summary.component.scss']
})
export class PdfSummaryComponent implements OnInit {
  tsli;
  extension;
  chaptersSummary = new Map();
  byItem;
  themes: Theme[] = [];
  summary_entries=[]
  constructor() {
    // this.themes.push({key: 'gnrl_info', name: 'General Information', process: 'General Information'})
    // this.themes.push(...DetailedTsliConstants.THEMES);
  }

  ngOnInit() {
    this.summary_entries = JSON.parse(sessionStorage.getItem('summary'));
    // this.summary_entries = [{number: '1', title: 'test tsli title', page: 3, depth:0},
    // {number: '1', title: 'test tsli titleest tsli titleest tsli', page: 3, depth: 1},
    // {number: '1', title: 'test tsli title', page: 3, depth:2}]
    // this.currentTsli.tsli$.subscribe(value => this.tsli = value);
    // this.currentTsli.chaptesSummary$.subscribe(value => {this.chaptersSummary = value
    // });
    // this.currentTsli.isByItem$.subscribe(value => this.byItem = value);

    //used for tests
    // for (let index = 0; index < 220; index++) {
    //  this.extendChapters.push("chapter " + index);

    // }
  }

  // orderBy(chapters: Chapter[], propName: string): Chapter[] {
  //   if (chapters === undefined) {
  //     return chapters;
  //   } else {
  //     return chapters.sort((a: Chapter, b: Chapter) => a[propName] > b[propName] ? 1 : a[propName] === b[propName] ? 0 : -1);
  //   }
  // }

  // filterBy(chapters: Chapter[], propName: string, proValue: any) {
  //   if(chapters == undefined) return [];
  //   return chapters.filter(chapter => chapter[propName] == proValue);
  // }

  // ngAfterViewChecked() {
  //   // let initialHeight = document.getElementById('summary').offsetHeight;
  //   // //1179 = 1684 - header 280 - footer 100 - summary header 77 - summary footer 48
  //   // let pagesCount = Math.trunc(initialHeight / 1179);
  //   // if (pagesCount < initialHeight / 1179) pagesCount++;
  //   // // let finaleHeight = initialHeight + (pagesCount - 1) * 380;
  //   // // pagesCount = Math.trunc(finaleHeight / 1684);
  //   // // if (pagesCount < finaleHeight / 1684) pagesCount++;
  //   // this.currentTsli.setSummaryPageCount(pagesCount);
  //   // let initialPageCount = pagesCount + 1;
  //   // if (this.byItem) initialPageCount = pagesCount + 4
  
  //   // this.chaptersSummary.forEach((value, key) => {
  //   //   const element = document.getElementById(key);
  //   //   element.innerText = '' + (initialPageCount + value + 1);
  //   // });
  // }
}
