<div class="row" style="margin-left: 10px; margin-top: 10px;">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput type="text" [(ngModel)]="searchedExp" (ngModelChange)="searchInData()">
    <button mat-button *ngIf="searchedExp" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchedExp=''; searchInData()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>

<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="title" style="width: 50px;">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element" [matTooltip]="element.title" #tooltip="matTooltip">{{element.title}}</td>
  </ng-container>

  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef>Reference</th>
    <td mat-cell *matCellDef="let element">{{element.docType | uppercase}}{{ " - " }}{{element.reference}}</td>
  </ng-container>

  <ng-container matColumnDef="revision">
    <th mat-header-cell *matHeaderCellDef>Revision</th>
    <td mat-cell *matCellDef="let element">{{element.revision}}</td>
  </ng-container>

  <ng-container matColumnDef="applicationDate">
    <th mat-header-cell *matHeaderCellDef>Application Date</th>
    <td mat-cell *matCellDef="let element">{{ element.applicationDate | date :  "dd/MM/yyyy" }}</td>
  </ng-container>

  <ng-container *ngIf="tab == 'drafts'" matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{element!.status}}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">{{element!.type}}</td>
  </ng-container>

  <ng-container *ngIf="tab == 'published'" matColumnDef="draftAuthor">
    <th mat-header-cell *matHeaderCellDef>Draft Author</th>
    <td mat-cell *matCellDef="let element">{{element!.draftAuthor?.firstName}} {{element!.draftAuthor?.name}}</td>
  </ng-container>

  <ng-container *ngIf="tab == 'published'" matColumnDef="skillsMatrix">
    <th mat-header-cell *matHeaderCellDef>Skills matrix</th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field class="mr-3">
        <mat-select placeholder="Select" [(ngModel)]="element!.skillsMatrix.id"
                    (selectionChange)="updateSkillsMatrixUser(element.idTsli, $event.value)">
          <mat-option *ngFor="let sm of usersSkillsMatrix"
                      [value]="sm.id">
            {{ sm.first_name}} {{sm.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container *ngIf="tab == 'published' || tab =='drafts'" matColumnDef="AccessVAMfamlyMembers">
    <th mat-header-cell *matHeaderCellDef>Only access for<br/> VAM® family members</th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle *ngIf="element.type != 'general_information'" [(ngModel)]="element.is_vam_family"
                        [disabled]="!CurrentUser.isAdmin && !CurrentUser.isApprover && tab == 'published'"
                        (change)="updateIsVamFamily(element, element.is_vam_family)"></mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container *ngIf="tab == 'published'" matColumnDef="PublishedLegacy">
    <th mat-header-cell *matHeaderCellDef>Legacy</th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isLegacy"
                        [disabled]="element.type.toUpperCase()=== 'LEGACY' || (!CurrentUser.isAdmin && !CurrentUser.isApprover && tab == 'published')"
                        (change)="updateIsLegacy(element.idTsli, element.isLegacy)"></mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container *ngIf="tab == 'drafts'" matColumnDef="DraftsLegacy">
    <th mat-header-cell *matHeaderCellDef>Legacy</th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isLegacy"
                        [disabled]="element.type.toUpperCase() === 'LEGACY' || (!CurrentUser.isAdmin && !CurrentUser.isApprover && !CurrentUser.isReviewer && !CurrentUser.isAuthor && tab == 'drafts')"
                        (change)="updateIsLegacy(element.idTsli, element.isLegacy)"></mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container *ngIf="tab == 'published'" matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="mat-column-right">
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 90px; " (click)="archive(element)">
        Archive
      </button>
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 90px; "
              (click)="revise(element)"
              [disabled]="element.draftInProgress">Revise
      </button>
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 130px; "
              [disabled]="element.isLegacy || element.docType == 'inor' || element.docType == 'form'
              || (element.docType == 'ug' && element.source_file_s3_url != undefined) "
              (click)="editApplicability(element.idTsli)">Edit applicability
      </button>
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 90px; "
              (click)="view(element.idTsli)"
              [hidden]="element.source_file_s3_url != null
               || element.display_file_s3_url != null">View
      </button>
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 90px; "
              [hidden]="(element.source_file_s3_url === null)
               && (element.display_file_s3_url === null)"
              (click)="download(element)">{{ "Download"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 90px; "
              (click)="editAdmin(element.idTsli)"
              *hasRole="'admin'" [disabled]="element.source_file_s3_url != undefined">Edit
      </button>
      <button mat-raised-button [color]="element.is_restricted_document == 1 ? 'accent':'primary'"
              style="margin-right: 10px; width: 140px; "
              (click)="restrictedAccess(element)"
              [disabled]="(!CurrentUser.isAdmin && !CurrentUser.isApprover) || element.type == 'general_information'">
        Restricted access
      </button>
      <button mat-raised-button color="primary" style="margin-right: 10px; width: 90px; "
              (click)="deleteTsli(element)" *hasRole="'admin'">Delete
      </button>
    </td>
  </ng-container>

  <ng-container *ngIf="tab == 'drafts'" matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button color="primary" style="margin-right: 20px;"
              [disabled]="(element.status != 'writing' && element.status != 'waiting resume' || element.author.id != CurrentUser.id )&& !CurrentUser.isAdmin"
              (click)="edit(element)">{{ "Resume"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              [disabled]="(element.status != 'waiting for review' || CurrentUser.id != element.reviewer.id) && !CurrentUser.isAdmin "
              (click)="review(element)">{{ "Review"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              [disabled]="(element.status != 'waiting for approval' || CurrentUser.id != element.approver.id ) && !CurrentUser.isAdmin"
              (click)="approve(element)">{{ "Approve"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              *ngIf="CurrentUser.isApprover && !CurrentUser.isAdmin"
              [disabled]="CurrentUser.id != element.approver.id || element.source_file_s3_url != undefined
              || element.isLegacy || element.type == 'form' || element.type == 'inor'"
              (click)="editTheme(element.idTsli)">{{ "Edit Theme"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              (click)="view(element.idTsli)"
              [hidden]="element.source_file_s3_url != null || element.display_file_s3_url != null">{{ "View"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              [hidden]="element.source_file_s3_url === null && element.display_file_s3_url === null"
              (click)="download(element)">{{ "Download"}}</button>
      <button mat-raised-button [color]="element.is_restricted_document == 1 ? 'accent':'primary'"
              style="margin-right: 20px; width: 140px; "
              (click)="restrictedAccess(element)"
              [disabled]="(!CurrentUser.isAdmin && !CurrentUser.isApprover) || element.type == 'general_information'">
        Restricted access
      </button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              [disabled]="(CurrentUser.id != element.author.id || (element.status != 'writing' && element.status != 'waiting resume' )) && !CurrentUser.isAdmin"
              (click)="deleteRevision(element)"
              *hasRole="['admin','author']">{{ "Delete"}}</button>
    </td>
  </ng-container>

  <ng-container *ngIf="tab == 'archived'" matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px; "
              (click)="view(element.idTsli)"
              [hidden]="element.source_file_s3_url != null
               || element.display_file_s3_url != null">{{ "View"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px;"
              [hidden]="element.source_file_s3_url === null
               && element.display_file_s3_url === null"
              (click)="download(element)">{{ "Download"}}</button>
      <button mat-raised-button color="primary" style="margin-right: 20px; width: 90px;"
              (click)="deleteRevision(element)"
              [disabled]="!CurrentUser.isAdmin">Delete
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
