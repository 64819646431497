import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {Tsli} from 'src/app/core/data/tsli';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TsliHistoryComponent} from './tsli-hisory/tsli-hisory.component';
import {AuthService} from 'src/app/core/auth.service';
import {DownloadRequestDialogComponent} from 'src/app/core/dialog/download-request-dialog/download-request-dialog.component';
import {Status} from 'src/app/core/enum/status.enum';
import {TsliRestService} from '../../../core/tsli-rest.service';
import {DetailedTsliStore} from '../../../core/detailed-tsli-store.service';
import {SpinnerDialogComponent} from '../../../core/dialog/spinner-dialog/spinner-dialog.component';

@Component({
  selector: 'tsli-by-reference-page',
  templateUrl: './tsli-by-reference-page.component.html',
  styleUrls: ['./tsli-by-reference-page.component.scss']
})
export class TsliByReferencePageComponent implements OnInit, OnDestroy {

  modalRef: NgbModalRef;
  timeLeft: number = 125;

  constructor(public route: ActivatedRoute,
              public store: CurrentTsliStoreService,
              public rest: TsliRestService,
              public modal: NgbModal,
              public router: Router,
              public auth: AuthService,
              public detailedTSLIStore: DetailedTsliStore,
              public authService: AuthService) {
  }


  ngOnDestroy(): void {
    this.store.setCurrentTsli(undefined);
  }

  tsli: Tsli;
  documentsList: Tsli[];

  // isPublishedDocument: boolean;
  // isWaitingApprovalDocument: boolean;
  // isArchivedDocument: boolean;
  ngOnInit(): void {
    this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
    this.timeLeft = 125;

    this.auth.Authentication();

    // Go Back to by item
    const product = sessionStorage.getItem('itemProduct');

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('ref')) {
        const reference = params.get('ref');
        console.log('reference ::', reference);

        const docType = params.get('docType');
        console.log('docType ::', docType);

        // Verify restricted access to this document

        // this.userService.getRestrictedUsersForDocument(this.document.idTsli)
        //
        // this.userService.getRestrictedUsersByDocTypeAndRefence(docType, reference).subscribe(response => {
        //   console.log('tsli restricted:', response);
        // });

        this.rest.getAllTslisByParam(reference, undefined).subscribe(resp => {
          this.documentsList = resp.tslis;
          const logged_user = this.authService.getCurrentUser();
          console.log('logged_user:', logged_user);
          let documentAuthorized = this.documentsList.filter(document => document.document_type == docType
            && document.reference == reference);
          console.log('documentAuthorized:', documentAuthorized);

          // If documentAuthorized don't contain the filtered document => this document is not authorized for this user
          if (documentAuthorized.length == 0 && !logged_user.isAdmin && !logged_user.isApprover) {
            if(!logged_user.isReviewer){
              this.router.navigate(['tsli/Unauthorized/404']);
              return false;
            }else {
              this.store.viewTsli(docType, reference);
            }

            // if (!logged_user.isAdmin && !logged_user.isReviewer && !logged_user.isApprover) {
            //   console.log('Unauthorized');
            //   this.router.navigate(['tsli/Unauthorized/404']);
            // }


          } else {
            this.route.queryParamMap.subscribe((param: ParamMap) => {
              //Request from MyVam
              const originParam = param.get('origin');
              console.log('origin1 ::', originParam);

              if (originParam == 'myvam') {
                // by doctype and reference verify if document has a source or display file
                console.log('origin2 ::', originParam);
                this.rest.getTsliByReference(docType, reference, true).subscribe(
                  response => {
                    console.log('origin3 ::', response.tsli.source_file_s3_url);
                    if (response.tsli.source_file_s3_url != null) {
                      console.log('redirect to by reference:', response);
                      this.navigateToDetailedTsli(docType, reference);
                    } else {
                      this.store.viewTsli(docType, reference);
                    }

                  });
              } else {
                this.store.viewTsli(docType, reference);
              }
            });
          }
        });
      } else if (params.has('idTsli')) {
        console.log('reference ::', params.get('idTsli'));
        this.store.getTsliById(params.get('idTsli'));
      }

      this.modalRef.componentInstance.message = 'Please, wait while we are uploading content ...';
      this.modalRef.componentInstance.done = true;

      setTimeout(() => {
        this.modal.dismissAll();
      }, 5000);

    });
    this.store.tsli$.subscribe(value => {
      this.tsli = value;
    });
  }

  navigateToDetailedTsli(docType: string, reference: string): void {
    this.detailedTSLIStore.setSearchParam(docType, reference);
    this.router.navigate(['tsli/details']);
  }


  // To Update by this code
  // ngOnInit(): void {
  //   this.auth.Authentication();
  //
  //   this.route.paramMap.subscribe((params: ParamMap) => {
  //     if (params.has('ref')) {
  //       console.log('reference ::', params.get('ref'));
  //       this.store.viewTsli(params.get('docType'), params.get('ref'));
  //     } else if (params.has('idTsli')) {
  //       console.log('reference ::', params.get('idTsli'));
  //       this.store.getTsliById(params.get('idTsli'));
  //     }
  //     this.store.tsli$.subscribe(value => {
  //       this.tsli = value;
  //       if (value.status != undefined) {
  //         if (value.status == Status.PUBLISHED) {
  //           this.isPublishedDocument = true;
  //         }
  //         if (value.status == Status.APPROBATING) {
  //           this.isWaitingApprovalDocument = true;
  //         }
  //         if (value.status == Status.ARCHIVED) {
  //           this.isArchivedDocument = true;
  //         }
  //       }
  //       console.log('value ::', value);
  //     });
  //   });
  //
  // }

  navigateToBasicProductData(): void {
    this.router.navigate([`tsli/basic-product-data`]);
  }

  showHistory() {
    const modalRef = this.modal.open(TsliHistoryComponent, {size: 'lg', centered: true, backdrop: 'static'});
    console.log('modalRef.componentInstance', modalRef.componentInstance);
    modalRef.componentInstance.reference = this.tsli.reference;
    modalRef.componentInstance.docType = this.tsli.docType;
    modalRef.componentInstance.revision = this.tsli.revision;
    modalRef.componentInstance.reader = this.auth.isCurrentUserReader();
    modalRef.componentInstance.reviwerOrApprover = this.auth.isReviewerOrApprover();

    // modalRef.result.then((userResponse) => {
    //   if (userResponse === 'confirmed') {
    //   }
    // }, (reason) => {
    // });
  }

  download(): void {

    let draft = (this.tsli.status != Status.PUBLISHED && this.tsli.status != Status.ARCHIVED);
    this.store.generateTsliPdfRequest({
        'id_tsli': this.tsli.idTsli,
        'name': this.tsli.docType.toUpperCase() + this.tsli.reference + ' - ' + this.tsli.title + ' - rev.' + this.tsli.revision
      }, draft,
      this.tsli.status).subscribe(resp => {
      if (resp.success != undefined && resp.success.message == 'pdf.generation.initialized') {
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_1;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_1.replace('<email>', this.auth.getCurrentUser()['email'])
          + modalRef.componentInstance.MSG_3;
      } else {
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, {size: 'lg', centered: true, backdrop: 'static'});
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_2;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_2;
      }
    });
  }
}
