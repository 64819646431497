<ng-container *ngIf="tsli">
  <!-- by item chapters -->
  <div>
    <ng-container *ngIf="byItem">
      <h1 class="mat-h1 gras">Disclaimer</h1>

    </ng-container>
    <ng-container *ngIf="!byItem">
      <h3 class="mat-h3"><strong>Disclaimer</strong></h3>
    </ng-container>

    <div id="disclaimer" class="mat-body mb-3">
    </div>
  </div>

  <ng-container *ngIf="byItem">
    <!-- add General Information -->
    <h1 class="mat-h1 gras" id="gnrl_info" style="text-decoration: underline">1 . General Information</h1>
    <div class="mb-3">
      <div *ngFor="let c of generalInfoChapters">
        <div>
          <div class="mat-body">
            <a routerLink="../../tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{
                c.docType |
                  uppercase
              }}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let t of themes; let i = index;">
      <div>
        <h1 class="mat-h1 gras" id="{{t.name}}" style="text-decoration: underline">{{ i + 2 }} . {{ t.name }}</h1>
        <!-- add Process chapters -->
        <div class="mb-3">
          <ng-container *ngFor="let c of processThemesChapters">
            <div *ngIf="c.theme === t.key">
              <div class="mat-body"><a
                routerLink="../../tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{
                  c.docType |
                    uppercase
                }}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a></div>
            </div>
          </ng-container>
          <ng-container *ngFor="let c of ugiraThemesChapters">
            <div *ngIf="c.theme === t.key">
              <div class="mat-body"><a
                routerLink="../../tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{
                  c.docType |
                    uppercase
                }}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a></div>
            </div>
          </ng-container>
          <ng-container *ngFor="let c of ugiaThemesChapters">
            <div *ngIf="c.theme === t.key">
              <div class="mat-body"><a
                routerLink="../../tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{
                  c.docType |
                    uppercase
                }}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a></div>
            </div>
          </ng-container>
          <ng-container *ngFor="let c of ugflThemesChapters">
            <div *ngIf="c.theme === t.key">
              <div class="mat-body"><a
                routerLink="../../tsli/by-reference/{{ c.docType }}/{{ c.reference }}">{{
                  c.docType |
                    uppercase
                }}{{ " " + c.reference + " rev." + c.revision + " " + c.title }}</a></div>
            </div>
          </ng-container>
        </div>

        <!-- add Product chapters -->
        <div *ngIf="tsli.chapters">
          <ng-container *ngFor="let c of filterAndOrder(tsli.chapters, t.key)">
            <div>
              <mat-accordion #accordion="matAccordion" multi="true">

                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <h3 class="mat-h3" id="{{t.key}}-{{c.index}}"><strong>{{ i + 2 }} . {{ c.number }} <span
                      class="separator">.</span>
                      <span [style.background-color]="c.titleStyle">{{ c.title }}</span></strong></h3>
                  </mat-expansion-panel-header>


                  <div class="mat-body mb-3" [innerHTML]="c.body | safeHtml"></div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <router-outlet></router-outlet>
  </ng-container>

  <!--     by reference chapters -->
  <ng-container *ngIf="!byItem">
    <ng-container *ngFor="let c of orderBy(tsli.chapters, 'index')">
      <mat-accordion #accordion="matAccordion" multi="true">

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <h3 class="mat-h3" id="{{'chapter'}}-{{c.index}}"><strong>{{ c.number }} <span
              class="separator">.</span> <span [style.background-color]="c.titleStyle">{{
                c.title
              }}</span></strong></h3>
          </mat-expansion-panel-header>
          <div class="mat-body mb-3" [innerHTML]="c.body | safeHtml" style="overflow: auto;"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </ng-container>
  <footer class="justify-content-between text-center mt-3 footer">
    <button mat-flat-button class="float-right" (click)="openAll()">Expand all</button>
    <button mat-flat-button class="float-right" (click)="closeAll()">Collapse all</button>
  </footer>
</ng-container>
