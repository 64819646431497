import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';
import { Product } from 'src/app/core/data/product';

@Component({
  selector: 'pdf-content',
  templateUrl: './pdf-content.component.html',
  styleUrls: ['./pdf-content.component.scss']
})
export class PdfContentComponent implements OnInit {
  byItem = false;
  withSummary = false
  showPages = false
  pages = 0;
  str_pages = []
  type=false;
  constructor(public route: ActivatedRoute,
    public currentTsli: CurrentTsliStoreService,
    public detailedTsli: DetailedTsliStore,
    public bpdStore: BasicProductDataStoreService,
    public cdRef: ChangeDetectorRef) { }


  ngOnInit() {
    this.type = false;
    this.byItem = false;
    /* show content of TSLi with id or product (tsli by item) or type (TSLI List) */
    this.route.paramMap.subscribe((paramsMap) => {
      if (paramsMap.has("id_tsli")) {
        /* load tsli from backend */
        this.currentTsli.openTsliForPdf(paramsMap.get("id_tsli"));
      } else if (paramsMap.has("type")) {
        this.type = true
      }
       else {
        this.byItem = true;
         /* for tsli by item we need to show BPD */
        
        /* load thread compounds names */
        this.bpdStore.fetchThreadCompoundHeaders();
        
        const product = sessionStorage.getItem('itemProduct');
        const siOrImp = sessionStorage.getItem('siOrImp');
        const isOCTG = sessionStorage.getItem('isOCTG');
        /* switch(-1) to show all tds (pin fil and mill for tds) for example: switchSurfaceTreatment(0) will show only pin field end data */
        this.bpdStore. switchSurfaceTreatment(-1);

        /* switch(-1) to show all assembly (fil and mill for tds) for example: switchAssembyData(0) will show only field end data */
        this.bpdStore. switchAssembyData(-1);

        /* switch(-1) to show all thread compounds for example: switchThreadCompound(0) will show only the first thread compound data*/
        this.bpdStore. switchThreadCompound(-1);

        if(siOrImp != undefined){
          this.bpdStore.setImperialOrSI(siOrImp.toLowerCase() == 'true');
        }
        if(isOCTG != undefined){
          this.bpdStore.setOctgOrNonbOctg(isOCTG.toLowerCase() == 'true');
        }
        
        if(product != undefined){
          /* get TSLI applicable on the product (general information, process and products) */
          this.detailedTsli.setCurrentItem(JSON.parse(product));

          /* fetch basic product data for the product */
          this.bpdStore.addProductOfTsliItem(Object.assign(new Product(), JSON.parse(product))).subscribe(console.log);
        }

      }
    });
    this.withSummary = JSON.parse(sessionStorage.getItem('summary')) != undefined;

  }

}
