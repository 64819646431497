<div class="row" *ngIf="!hasIdTsli">
  <h1><span class="mat-h1">Configure Users Permissions</span></h1>
</div>
<div class="row" *ngIf="hasIdTsli">
  <h1><span class="mat-h1">You are about to modify : {{document.docType.toUpperCase() + " - " + document.reference}}</span></h1>
</div>
<div class="row" *ngIf="hasIdTsli">
  <h1><span class="mat-h1">{{restricted_title}}</span></h1>
</div>
<div class="row" style="margin-left: 30px;" *ngIf="hasIdTsli">
  Deactivated
  <mat-slide-toggle style="margin-left: 10px;" (change)="updateRestrictedStatus(document)"
                    [(ngModel)]="document.is_restricted_document"
                    [checked]="document.is_restricted_document">Activated
  </mat-slide-toggle>
</div>
<div class="row">
  <mat-form-field class="example-form-field">
    <mat-label>Filter</mat-label>
    <input matInput type="text" [(ngModel)]="searchedExp" (ngModelChange)="searchInData()">
    <button mat-button *ngIf="searchedExp" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchedExp=''; searchInData()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-checkbox color="primary" *ngIf="hasIdTsli" (change)="$event ? filterSelected() : null" class="checkbox-all"
                style="margin-top: 25px; margin-left: 35px;">
    Selected
  </mat-checkbox>
</div>
<div class="row">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

    <!-- Select column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" class="checkbox-all"
                      [checked]="rowsSelection.hasValue() && isAllSelected()"
                      [indeterminate]="rowsSelection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                      (change)="$event ? rowsSelection.toggle(row) : null"
                      [checked]="rowsSelection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name-->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{element.name + ' ' + element.first_name}}</td>
    </ng-container>

    <!-- Licensee No -->
    <ng-container matColumnDef="numLicensee">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Licensee No.</th>
      <td mat-cell *matCellDef="let element">{{hasIdTsli ? element.licensee_number : element.licenseeNumber}}</td>
    </ng-container>

    <!-- Company Name -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
      <td mat-cell *matCellDef="let element">{{hasIdTsli ? element.company_name : element.companyName}}</td>
    </ng-container>

    <!-- Email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <mat-header-cell *matHeaderCellDef> email</mat-header-cell>
      <td mat-cell *matCellDef="let element">
        <!-- <edit-value [value]="element.email" (validate)="updateUserMail(element, $event)"></edit-value> -->
        <editvalue [value]="element.email" (validate)="updateUserMail(element, $event)" *ngIf="!hasIdTsli"></editvalue>
        <mat-form-field style="width: auto" *ngIf="hasIdTsli">
          <input matInput type="text" [value]="element.email" [disabled]="hasIdTsli">
        </mat-form-field>
      </td>
    </ng-container>
    <!-- <button mat-icon-button (click)="onEdit(element.email)" [ngSwitch]="isEditEnable">
      <img *ngSwitchCase="true" src="assets/img/editing.png" />
      <mat-icon *ngSwitchCase="false">edit</mat-icon>
    </button> -->
    <!-- <button *ngIf="isEditEnable" mat-raised-button color="primary" (click)="onEdit(element.email)">Submit</button>
    <button *ngIf="!isEditEnable" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onEdit(element)" class="p-button-rounded p-button-text"></button> -->
    <!-- <mat-form-field class="example-full-width" *ngIf="isEditEnable">
        <input matInput [(ngModel)]="element.email">
  </mat-form-field>
  <span *ngIf="!isEditEnable">{{element.email}}</span>
  <button mat-raised-button color="primary" *ngIf="!isEditEnable" (click)="onEdit($event.value)">Edit</button>
  <button *ngIf="isEditEnable" mat-raised-button color="primary" (click)="onEdit($event.value)">Submit</button>  -->
    <!-- <input type="email" matInput [(ngModel)]="element.email">
    <img *ngSwitchCase="true" src="assets/img/editing.png" /> -->
    <!-- <button mat-raised-button type="submit" color="primary" (click)="updateUserMail(element, element.email)">save</button> -->
    <!-- <a (click)="openDialog('Update',element)">Edit</a> |
  <a (click)="openDialog('Delete',element)">Delete</a> -->
    <!-- </td> -->
    <!-- </ng-container> -->

    <!-- Profil -->
    <ng-container matColumnDef="profil">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Profil</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field>
          <mat-select (selectionChange)="updateUserRole(element, $event.value)"
                      [value]="!hasIdTsli ? getHigherRole(element): getRole(element)" [disabled]="hasIdTsli">
            <mat-option value="deactivated">Deactivated</mat-option>
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="approver">Approver</mat-option>
            <mat-option value="reviewer">Reviewer</mat-option>
            <mat-option value="reader">Reader</mat-option>
            <mat-option value="partialreviewer">Partial reviewer</mat-option>
            <mat-option value="partialreader">Partial reader</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="profil_rights" *ngIf="!hasIdTsli">

      <th mat-header-cell *matHeaderCellDef mat-sort-header>Profil rights</th>
      <td mat-cell *matCellDef="let row">
        <button mat-flat-button [color]="row.isLicenseLight? 'accent':'primary'"
                [disabled]="getHigherRole(row) !='partialreader'&& getHigherRole(row) !='partialreviewer'"
                (click)="showLicenseLightRights(row)">View
        </button><!--[disabled]="true"-->
      </td>
    </ng-container>


    <ng-container matColumnDef="AccessVAMfamlyMembers" *ngIf="!hasIdTsli">
      <th mat-header-cell *matHeaderCellDef>VAM® family members</th>
      <td mat-cell *matCellDef="let row">
        <mat-slide-toggle *ngIf="row.isReader" [(ngModel)]="row.is_vam_family"
                          (change)="updateIsVamFamily(row.is_vam_family, row.id)"></mat-slide-toggle>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="row mt-2">
  <div class="col text-center">
    <button mat-button (click)="viewMore()" [disabled]="dataSource.data.length == cachedUsers.length  || showSelected">
      VIEW MORE
    </button>
    <button mat-button (click)="deleteSelection()" [disabled]="!rowsSelection.hasValue()" *ngIf="!hasIdTsli">DELETE
      SELECTION
    </button>
  </div>
</div>

<div class="row mt-2">
  <footer class="col text-center" *ngIf="hasIdTsli">
    <div>
      <button mat-flat-button color="primary" class="ml-2"
              (click)="validateRestrictedAccess()">{{ "VALIDATE" }}</button>
      <button mat-flat-button color="warn" class="ml-2" (click)="cancel()">{{ "CANCEL" }}</button>
    </div>
  </footer>
</div>
