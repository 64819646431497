<div class="tbl-row">
  <!-- <div class="tbl-col" style="flex: 1 1">
    <div class="tds-header" style="margin-top: 50px;">
      <p class="smallest ">Options / Exc.</p>
    </div>
  </div> -->
  <div class="tbl-col " style="flex: 8 8">
    <mat-tab-group (selectedTabChange)="switchSelectedTab($event)" class="tdsTab">
      <mat-tab label="Pin Field End" style="min-width: 50%;">
        <div class="tbl-row" style="height: 100%">
          <div class="tbl-col tds-cc" >
            <div >
              <p class="smallest ">Options / Exc.</p>
            </div>
          </div>
          <div class="tbl-col tds-cc cc-title" matTooltip="As machined"><p>As Machined</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Ceramic bead blasting"><p>Ceramic Bead Blasting</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting"><p>Alumina (Al2O3) Sand Blasting</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Zinc Phosphate"><p>Zinc Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Zn Phosphate"><p>Alumina (Al2O3) Sand Blasting + Zn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Mn Phosphate"><p>Mn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Mn Phosphate"><p>Alumina (Al2O3) Sand Blasting + Mn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title tds-last-col" matTooltip="Copper Plating"><p>Copper Plating</p></div>
        </div>
      </mat-tab>
      <mat-tab label="Pin Mill End" style="min-width: 50%;">
        <div class="tbl-row" style="height: 100%">
          <div class="tbl-col tds-cc" >
            <div >
              <p class="smallest ">Options / Exc.</p>
            </div>
          </div>
          <div class="tbl-col tds-cc cc-title" matTooltip="As machined"><p>As Machined</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Ceramic bead blasting"><p>Ceramic Bead Blasting</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting"><p>Alumina (Al2O3) Sand Blasting</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Zinc Phosphate"><p>Zinc Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Zn Phosphate"><p>Alumina (Al2O3) Sand Blasting + Zn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Mn Phosphate"><p>Mn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Mn Phosphate"><p>Alumina (Al2O3) Sand Blasting + Mn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title tds-last-col" matTooltip="Copper Plating"><p>Copper Plating</p></div>
        </div>
      </mat-tab>

      <mat-tab label="Box End" style="min-width: 50%;">
        <div class="tbl-row" style="height: 100%">
          <div class="tbl-col tds-cc" >
            <div >
              <p class="smallest ">Options / Exc.</p>
            </div>
          </div>
          <div class="tbl-col tds-cc cc-title" matTooltip="As machined"><p>As Machined</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Ceramic bead blasting"><p>Ceramic Bead Blasting</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting"><p>Alumina (Al2O3) Sand Blasting</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Zinc Phosphate"><p>Zinc Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Zn Phosphate"><p>Alumina (Al2O3) Sand Blasting + Zn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Mn Phosphate"><p>Mn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Mn Phosphate"><p>Alumina (Al2O3) Sand Blasting + Mn Phosphate</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Copper Plating"><p>Copper Plating</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Alumina (Al2O3) sand blasting + Copper Plating"><p>Alumina (Al2O3) sand blasting + Copper Plating</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="Ceramic bead blasting + Copper Plating"><p>Ceramic bead blasting + Copper Plating</p></div>
          <div class="tbl-col tds-cc cc-title" matTooltip="TERNARY ALLOY"><p>Ternary Alloy</p></div>
          <div class="tbl-col tds-cc cc-title tds-last-col" matTooltip="PIOTEC PLATING"><p>Piotec Plating</p></div>
      </div>
     </mat-tab>
    </mat-tab-group>
  </div>
</div>

