import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog-status',
  templateUrl: './confirm-dialog-status.component.html',
  styleUrls: ['./confirm-dialog-status.component.scss']
})
export class ConfirmDialogStatusComponent implements OnInit {
  componentName: string;
  componentType: string;
  docType: string;
  tsliType: string


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  dismiss() {
    this.activeModal.dismiss('dissmissed');
  }

  confirm() {
    this.activeModal.close('confirmed');
  }
}
