<ng-container [ngSwitch]="isEditEnable">
    <div *ngSwitchCase="false" class="value" (click)="edit()">{{value}}</div>
    <div *ngSwitchCase="true">
        <form [formGroup]="valueFormGroup">
            <mat-form-field style="width: 90%">
                <input matInput formControlName="valueCtrl" [(ngModel)]="value" required>
            </mat-form-field>
        </form>
        <div class="validate">
            <button mat-button (click)="cancel()">Cancel</button>
            <button mat-button color="primary"  (click)="save()">Validate</button>
        </div>
    </div>
</ng-container>