export class Product {
  od: number;
  wt: number;
  weight: number;
  grade: string;
  lubricant: string;
  connection: string;
  productType: string;
  option: string;
  connection_specific:string;
  customer: string;
  drawing: string;
  customer_information: string;
  isolated: any;
  material: any;
  minwallthickness: any;
  product: any;
  real_customer: any;
  type_drift: any;
  ymin_ksi: any;
  specific_requirement: any;
  is_octg: any;

  equals(product: Product): boolean {
    if(product === undefined || product === null) return false;
    let wtValue = this.wt + '';
            if(('' + this.wt).includes('#')){
              wtValue = ('' + this.wt).split("(")[1].replace('")', '');
            }
    return this.connection === product.connection
    && this.od === product.od
    && wtValue === ''+product.wt && this.grade === product.grade
    && this.lubricant === product.lubricant && this.productType === product.productType
    && this.customer === product.customer && this.option === product.option && this.drawing === product.drawing 
    && this.customer_information === product.customer_information
  }
}
