<div class="modal-header">
  <h4 class="modal-title" id="modal-title"><mat-icon color='warn'>warning</mat-icon>  Warning</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body" >
  <p><strong>  {{message}} </strong></p>
</div>


<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="dismiss()" *ngIf="showReconnect" >Continue</button>
  <button  mat-flat-button color='warn' (click)="confirm()" *ngIf="showReconnect">Logout</button>
  <button  mat-flat-button color='warn' (click)="close()" *ngIf="!showReconnect">Logout</button>

</div>
