export class Chapter {
  id_chapter: string;
  title: string;
  body: string;
  depth: number;
  index: number;
  applicability: boolean;
  applicabilityEntry: string;
  newApplicabilityEntry: string;
  theme: string;
  type: string;
  reference: string;
  number: string; // automatic numbering, depending on chapter depth
  title_tsli: string;
  comment: boolean = false;
  tsliDocType: string;
  tsliRevision: string;
  titleStyle: string;

  /*
      X.Y.Z :
        X. :  index, equals to previous sibling index + 1
              or 1 if first chapter of a given depth
        Y. : idem, taking into account siblings of same depth
   */
}
