<div class="tbl-row">
  <mat-tab-group id="threadCompoundTab"
  (selectedTabChange)="switchSelectedTab($event)"
  class="threadCompoundTab">
    <mat-tab *ngFor="let thread of threadCompounds;" (selectedTabChange)="switchSelectedTab($event)"  label="{{thread.name + ' '}} {{ thread.density | number:'1.0-2' }} {{' '+unit}}" >
      <div class="tbl-row" style="height: 60px;">
        <div class="tbl-col br-lt-top-bt" style="flex: 1 1"><p>Thread Compound Validation</p></div>
        <div class="tbl-col br-lt-top-bt" style="flex: 1 1"><p>Min Dope Volume ({{unitVolume}})</p></div>
        <div class="tbl-col br-lt-top-bt" style="flex: 1 1"><p>Max Dope Volume ({{unitVolume}})</p></div>
        <div class="tbl-col br-lt-top-bt" style="flex: 1 1"><p>Min Dope Qty ({{unitQty}})</p></div>
        <div class="tbl-col br-lt-top-bt" style="flex: 1 1"><p>Max Dope Qty ({{unitQty}})</p></div>
        <div class="tbl-col br-lt-top-bt" style="flex: 1 1"><p>Ratio Pin Box (%/%)</p></div>
        <div class="tbl-col br-lt-top-bt br-rt" style="flex: 1 1"><p>Comment</p></div>
      </div>
    </mat-tab>
    
  </mat-tab-group>

</div>
