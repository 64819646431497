<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{componentType}} deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Are you sure you want to delete <span class="text-primary">{{componentName}}</span> </strong></p>
    <p>All information associated to {{componentName}} will be permanently deleted.
    <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button mat-stroked-button color='basic' (click)="dismiss()">Cancel</button>
    <button  mat-flat-button color='warn' (click)="confirm()">Ok</button>
  </div>