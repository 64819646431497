import { Component, OnInit, Input } from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'assembly-data-header-grid',
  templateUrl: './assembly-data-header-grid.component.html',
  styleUrls: ['./assembly-data-header-grid.component.scss']
})
export class AssemblyDataHeaderGridComponent implements OnInit {

  unit = "(N.m)";

  @Input()
  forPdf: boolean = false;
  
  selectedtab:number = 0;

  constructor(public basicProductDataStore: BasicProductDataStoreService) { 
    this.basicProductDataStore.imperialOrSI$.subscribe(value => value? this.unit = "(Ft.Lb)" : this.unit="(N.m)");
  }

  ngOnInit() {
    // if(this.forPdf) {
    //   this.unit = "(Ft.Lb)";
    // }
    this.basicProductDataStore.selectedIndextab$.subscribe((value) => {this.selectedtab = value; console.log("valeur :: ",this.selectedtab);});
  }



  switchSelectedAssemblyValues(event){
    console.log(event.index);
    this.basicProductDataStore.switchSelectedAssemblyValues(event.index);
   
  }
}
