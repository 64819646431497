import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as CKEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';

import { Chapter } from '../../../core/data/chapter';
import { CurrentTsliStoreService } from '../../../core/current-tsli-store.service';
import { ConfigTSLI } from '../../../core/app.config';
import { TsliLinksListComponent } from '../tsli-links-list/tsli-links-list.component';
import { Tsli } from '../../../core/data/tsli';
import { MathjaxComponent } from './mathjax/mathjax.component';
import { CreateApplicabilityStepperComponent } from './create-applicability-stepper/create-applicability-stepper.component';
import { ConfirmDialogComponent } from 'src/app/core/dialog/confirm-dialog/confirm-dialog.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/auth.service';
import { Status } from 'src/app/core/enum/status.enum';
import { TsliRestService } from 'src/app/core/tsli-rest.service';
import { TsliMode } from 'src/app/core/enum/tsli-mode.enum';

@Component({
  selector: 'chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent implements OnInit, OnDestroy {
  titleFormGroup: FormGroup;

  @Input() chapter: Chapter;
  @Input() chapters: Chapter[];
  @Input() colorinput: boolean;
  @Input() tsli: Tsli;
  @Output('moveChapter') moveChapter = new EventEmitter();
  @Output('editChapter') editChapter = new EventEmitter();
  @Output('changedChapter') changedChapter = new EventEmitter();
  @Output('savedChapter') savedChapter = new EventEmitter();
  @ViewChild('chapterEditor') myEditor: CKEditorComponent;

  public isCollapsed = false;
  public isEditing = false;
  chapterHasChanged = false;
  public savingChapter = false;
  autoSaveChapter = interval(ConfigTSLI.AUTO_SAVE_TIMER);
  subscriptionAutoSave;

  subscriptionActivatedTsliMode;

  // isApplicabilityEditionMode: boolean = true;
  isValidationMode: boolean = false;
  showComment: boolean = false;
  isAdminEditionMode: boolean = false;
  activatedTsliMode: TsliMode;

  public timeLeftToSave = -1;
  public interval;
  public state = '';

  public readonly UPLOAD = "/upload_file";
  uploadImgURL = environment.API_BASE_URL + this.UPLOAD;
  token = '';

  Editor = CKEditor;
  securedBody: any;

  unsecuredFormulas: any[];
  status = Status;
  dropDownID = "dropDown"

  titleStyles = {
    "#ffffff": "styled-input-1",
    "#62f962": "styled-input-2",
    "#72ccfd": "styled-input-3",
    "#fc7899": "styled-input-4",
    "#fdfd77": "styled-input-5",
    "#fabe4e": "styled-input-6",
  }

  selectedTitleStyle = "styled-input-1"
  imagesChecked = false;

  constructor(public currentTsliStore: CurrentTsliStoreService,
    public rest: TsliRestService,
    public router: Router,
    public modal: NgbModal,
    public formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    public authService: AuthService
  ) {

    // this.sanitizer = sanitizer;

    this.titleFormGroup = this.formBuilder.group({
      titleCtrl: ['', Validators.required]
    });
    this.token = this.authService.getToken();
  }

  ngOnDestroy(): void {
    if (this.subscriptionAutoSave != undefined) this.subscriptionAutoSave.unsubscribe();
    this.subscriptionActivatedTsliMode.unsubscribe();
  }

  ngOnInit() {
    this.dropDownID = this.dropDownID + this.chapter.id_chapter;
    if (this.chapter.titleStyle != undefined)
      this.selectedTitleStyle = this.titleStyles[this.chapter.titleStyle]
    this.titleFormGroup.controls.titleCtrl.setValue(this.chapter.title);
    this.subscriptionActivatedTsliMode = this.currentTsliStore.activatedMode$
      .subscribe(value => {
        this.activatedTsliMode = value;
        if (value == TsliMode.simpleEditionMode) {
          this.subscriptionAutoSave = this.autoSaveChapter.subscribe(() => {
            if (this.chapterHasChanged) {
              this.savingChapter = true;
              this.currentTsliStore.saveChapter(this.chapter).then((hasNoError: boolean) => {
                if (hasNoError) {
                  this.savingChapter = false;
                  if (!this.isEditing) {
                    let chapter_body = this.chapter.body + ''
                    this.securedBody = this.sanitizer.bypassSecurityTrustHtml(this.processMedia(chapter_body));
                  }

                  this.savedChapter.emit();
                }
              });
              this.chapterHasChanged = false;
            }
          });
        }
      });

    if (this.chapter.index === 0) {
      this.chapter.number = '1';
    } else {
      if (this.chapter.depth === 0) {
        const result = this.chapters
          .filter(chapter => chapter.depth === 0 && chapter.index < this.chapter.index);
        this.chapter.number = (result.length + 1) + '';
      } else {
        const supposedParents = this.chapters
          .filter(chapter => chapter.depth === this.chapter.depth - 1 && chapter.index < this.chapter.index);
        const realParent = supposedParents[supposedParents.length - 1];
        const result = this.chapters
          .filter(chapter => chapter.depth === this.chapter.depth
            && chapter.index < this.chapter.index
            && chapter.index > realParent.index);

        if (this.chapter != undefined && realParent != undefined) { this.chapter.number = realParent.number.toString() + '.' + (result.length + 1).toString(); }
      }
    }
    let chapter_body = this.chapter.body + ''
    this.securedBody = this.sanitizer.bypassSecurityTrustHtml(this.processMedia(chapter_body));

    this.state = 'All changes saved';
    this.interval = setInterval(() => {
      if (this.timeLeftToSave === 0) {
        this.currentTsliStore.saveChapter(this.chapter).then(() => {
          let chapter_body = this.chapter.body + ''
          this.securedBody = this.sanitizer.bypassSecurityTrustHtml(this.processMedia(chapter_body));
        });

        this.timeLeftToSave--;
      } else if (this.timeLeftToSave > 0) {
        this.timeLeftToSave--;
      }
    }, 500);

  }

  /**
   * Process DataUris from formulas from chapter body
   * - create a map holding all instances,
   * - create a secured URI for each existing formulas
   *
   */
  public processDataUris(): void {
    this.unsecuredFormulas = [];

    // get all unsecured formulas img as strings
    const imgEltRegExp: RegExp = /<img src="(data:image[A-Za-z0-9:;,\/+==]*)">/gm;
    let match;
    while ((match = imgEltRegExp.exec(this.chapter.body)) !== null) {
      this.unsecuredFormulas.push(match);
    }

    // secure all img Uris
    if (this.unsecuredFormulas.length > 0) {
      for (const f of this.unsecuredFormulas) {
        // call once more to get the first matching group (...)
        const securedDataUri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(f[1]);
        f['securedDataUri'] = securedDataUri;
      }
    }

    // replace every img src="..." by its secure counterpart img src="{{ unsecuredFormulas[i].securedDataUri }}"
    let i: number = 0;
    for (const formula of this.unsecuredFormulas) {
      this.chapter.body = this.chapter.body.replace(formula[0], `{{unsecuredFormulas[${i}].securedDataUri}}`);
    }
  }

  onChapterChanged() {
    this.chapterHasChanged = true;
    this.changedChapter.emit(this);
  }

  deleteChapter(event): void {
    const modalRef = this.modal.open(ConfirmDialogComponent, { size: 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.componentName = this.chapter.title;
    modalRef.componentInstance.componentType = 'Chapter';

    modalRef.result.then((userResponse) => {
      if (userResponse === 'confirmed') {
        this.currentTsliStore.removeChapter(this.chapter);
      }
    }, (reason) => {
    });
    event.stopPropagation();
  }

  goRight(event): void {
    this.moveChapter.emit({ type: 'right', data: this.chapter.index });
    event.stopPropagation();
  }

  goLeft(event): void {
    this.moveChapter.emit({ type: 'left', data: this.chapter.index });
    event.stopPropagation();
  }

  goDown(event): void {
    this.moveChapter.emit({ type: 'down', data: this.chapter.index });
    event.stopPropagation();
  }

  goUp(event): void {
    this.moveChapter.emit({ type: 'up', data: this.chapter.index });
    event.stopPropagation();
  }

  canNotBeMovedUp(): boolean {
    return this.chapter.index === 0;
  }

  canNotBeMovedDown(): boolean {
    return this.chapter.index === this.chapters.length - 1;
  }

  canNotBeMovedToLeft(): boolean {
    return this.chapter.depth === 0;
  }

  canNotBeMovedToRight(): boolean {
    if (this.chapter != undefined && this.chapter.index + 1 <= this.chapters.length) {
      return this.chapter.index === 0 || this.chapters[this.chapter.index - 1].depth < this.chapter.depth;
    }
    return false;
  }

  collapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.chapter.title = this.titleFormGroup.controls.titleCtrl.value;

    //added a timeout of 5 seconds to wait for images loading
    setTimeout(() => {
      this.addImageResizedClass();

    }, 5000);
  }

  edit(): void {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editChapter.emit(this);
      this.isCollapsed = true;
    } else {
      let chapter_body = this.chapter.body + ''
      this.securedBody = this.sanitizer.bypassSecurityTrustHtml(this.processMedia(chapter_body));
    }
     //added a timeout of 5 seconds to wait for images loading
     setTimeout(() => {
      this.addImageResizedClass();

    }, 5000);
  }

  refreshBody() {
    let chapter_body = this.chapter.body + ''
    this.securedBody = this.sanitizer.bypassSecurityTrustHtml(this.processMedia(chapter_body));

  }

  saveChapter() {
    this.currentTsliStore.saveChapter(this.chapter).then(value => {
      let chapter_body = this.chapter.body + ''
      this.securedBody = this.sanitizer.bypassSecurityTrustHtml(this.processMedia(chapter_body));
      this.savedChapter.emit()
    });
    event.stopPropagation();
  }

  linkChapter(event) {
    const modalRef = this.modal.open(TsliLinksListComponent, { size: 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.insertLink.subscribe(this.insertLink.bind(this));
    event.stopPropagation();
  }

  insertLink(event) {
    this.myEditor.editorInstance.model.change(writer => {
      const insertPosition = this.myEditor.editorInstance.model.document.selection.getFirstPosition();
      writer.insertText(event.data, { linkHref: event.href }, insertPosition);
    });
  }

  mathEdit(event) {
    const modalRef = this.modal.open(MathjaxComponent, { size: 'lg', centered: true, backdrop: 'static' });
    modalRef.componentInstance.insertMathExpression.subscribe(this.insertMathExpression.bind(this));
    event.stopPropagation();
  }

  insertMathExpression(event) {
    const content = '<img src= \'' + event.src + '\' href=\'' + event.href + '\'></img>';
    const viewFragment = this.myEditor.editorInstance.data.processor.toView(content);
    const modelFragment = this.myEditor.editorInstance.data.toModel(viewFragment);
    const insertPosition = this.myEditor.editorInstance.model.document.selection.getFirstPosition();
    this.myEditor.editorInstance.model.insertContent(modelFragment, insertPosition);
  }

  createModifyApplicability(event): void {
    const modalRef: NgbModalRef = this.modal.open(CreateApplicabilityStepperComponent, { size: 'lg', centered: true });

    modalRef.componentInstance.chapterIndex = this.chapter.id_chapter;
    modalRef.componentInstance.hasApplicability = this.chapter.applicability;
    modalRef.componentInstance.theme = this.chapter.theme;
    modalRef.componentInstance.firstEntryApplicability = this.chapter.applicabilityEntry;
    modalRef.componentInstance.newFirstEntryApplicability = this.chapter.newApplicabilityEntry;
    this.currentTsliStore.addToTmpListCurrentChapters([this.chapter.id_chapter])

    event.stopPropagation();
  }

  onChange({ editor }: ChangeEvent) {
    this.state = 'saving ...';
    this.timeLeftToSave = 1;
  }

  showApplicability() {
    this.currentTsliStore.setFirstEntry(undefined); // clear firstEntry data to force reload from back-end
    // this.currentTsliStore.setActivatedMode(TsliMode.applicabilityReadingMode);
    this.router.navigate([`tsli/chapter/applicability/${this.chapter.id_chapter}`], { skipLocationChange: true });
  }

  processMedia(body: string) {
    let old_outer = [];
    let new_outer = [];

    let figure_div_1 = '<div class="ck-media__wrapper" data-oembed-url="FIGURE_URL">figure_div_2</div>'
    let figure_div_2 = '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">figure_iframe</div>'
    let figure_iframe = '<iframe src="IFRAME_URL" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>'

    let doc = new DOMParser().parseFromString(body, "text/html");
    let figures = doc.getElementsByTagName('figure')
    for (let index = 0; index < figures.length; index++) {
      let element = figures[index];
      if (element.classList.contains('media')) {
        old_outer.push(element.outerHTML)
        element.classList.add('ck-widget')
        element.classList.add('ck-widget_selected')
        element.setAttribute('contenteditable', 'false')
        element.setAttribute('style', 'display:inline !important');
        let media_url = element.firstElementChild.getAttribute('url')
        let figure_iframe_element = figure_iframe.replace('IFRAME_URL', media_url.replace('watch?v=', 'embed/'));
        let figure_div_2_element = figure_div_2.replace('figure_iframe', figure_iframe_element);
        let figure_div_1_element = figure_div_1.replace('FIGURE_URL', media_url).replace('figure_div_2', figure_div_2_element);

        element.innerHTML = figure_div_1_element;
        new_outer.push(element.outerHTML)

      }

    }

    for (let i = 0; i < old_outer.length; i++) {
      body = body.replace(old_outer[i], new_outer[i])
    }
    return body
  }

  switchTitleStyle(color = undefined) {
    if (color != undefined) {
      if (color != this.chapter.titleStyle) {
        this.chapter.titleStyle = color;
        this.selectedTitleStyle = this.titleStyles[color]
        this.onChapterChanged();
      }

    }
    document.getElementById(this.dropDownID).classList.toggle("show");
  }

  onReady() {
    if (this.myEditor) {
      this.myEditor.editorInstance.setData(this.chapter.body);
    }
  }

  addImageResizedClass(){
    console.log('resize_image')
      let imagesFigures = Array.from(document.querySelectorAll('figure.image'));
      imagesFigures.forEach(imgf => {
        if (!imgf.classList.contains('image_resized')) {
          (imgf as HTMLElement).style.width = (imgf.firstElementChild as HTMLElement).offsetWidth + 'px'
          imgf.classList.add('image_resized')
        }

      });
  }

}
