<div class="row">
  <div class="p-col text-center" [style.width.%]="FILTERS_WIDTHS[i]"
    *ngFor="let filter of FILTERS; let i = index">
    <button
      *ngIf="!isEditionMode && filter != ''"
      mat-button 
      style="font-size: .7em; "
      (click)=sort(i) [disabled]="!activateSorting">
      <div style="display: flex;">
        <div class="ellipsis" style=" flex: 0 0 65%; vertical-align: text-top; line-height: 25px;" >
        <span style="margin-right: 5px;">{{filter}}</span>
        </div>
        <div style=" flex: 1 ;"  [ngSwitch]="filtersOrder[i].order">
          <mat-icon *ngSwitchCase="'desc'" style="position: relative; top: 6px;">arrow_upward</mat-icon>
          <mat-icon *ngSwitchCase="'asc'" style="position: relative; top: 6px;">arrow_downward</mat-icon>
        </div>
      </div>
    
    </button>
    <mat-form-field
      *ngIf="isEditionMode && filter != ''"
     
      style="font-size: .7em;"
      [style.min-width.%]="80"
      [style.max-width.%]="80">
      <mat-label>{{filter}}</mat-label>
      <mat-select (selectionChange)="loadNextFilterOptions(filter, $event.value)" [(value)]="selection[filter]">
        <mat-option>None</mat-option>
        <mat-option
          *ngFor="let opt of (basicProductDataStore.currentFilter$ | async)[filter]"
          [value]="opt">{{('' + opt).replace('-SPECIFIC_REQUIREMENT',' SPECIFIC')}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
