<div class="content">
  <p style="margin-bottom: 20px;">Contents</p>
  <ng-container *ngIf="byItem">
    <mat-accordion>
      <mat-expansion-panel class="tsli-sidenav">
        <mat-expansion-panel-header>
          <mat-panel-title class="title" style="cursor: pointer" (click)="navigateToChapter(0, {name:'gnrl_info'})">
            1 . General Information
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel class="tsli-sidenav" *ngFor="let theme of themes; let i = index;">
        <mat-expansion-panel-header>
          <mat-panel-title class="title" style="cursor: pointer" (click)="navigateToChapter(0, theme)">
            {{i+2}} . {{theme.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row chapter-title" *ngFor="let chapter of chaptersByItem[theme.key]; let j = index;"
          [style.padding-left.px]="30 + 10 * chapter.depth">
          <span style="cursor: pointer" (click)="navigateToChapter(chapter.index, theme)">  {{i+2}} . {{chapter.number}}
            {{chapter.title}} </span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container *ngIf="!byItem">
    <div class="row chapter-title " *ngFor="let chapter of tsli?.chapters"
      [style.padding-left.px]="30 + 10 * chapter.depth">
      <div class="col">
          <span style="cursor: pointer" (click)="navigateToChapter(chapter.index)">
              {{chapter.number}} <span class="separator">.</span> {{chapter.title}} </span>
      </div>
    </div>
  </ng-container>
</div>
