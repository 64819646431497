import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

import {map} from 'rxjs/operators';


import { UsersReponse } from './data/user-response';
import { User } from './data/User';
import {UserRestService} from './user-rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {

  public readonly users = new BehaviorSubject<User[]>([]);
  readonly users$ = this.users.asObservable();

  public readonly usersSkillsMatrix = new BehaviorSubject<User[]>([]);
  readonly usersSkillsMatrix$ = this.usersSkillsMatrix.asObservable();

  public subscription: Subscription;

 
  constructor(public rest: UserRestService) {
    this.subscription = this.rest.getSkillsMatrixUsers().subscribe(
      (response: UsersReponse) => this.usersSkillsMatrix.next(response.users)
    );
  }

  getSkillsMatrixUsers(): Observable<User[]> {
    this.rest.getSkillsMatrixUsers().subscribe((response: UsersReponse) => {
        this.users.next(response.users);
        const usersSkillsMatrix = response.users;
        this.usersSkillsMatrix.next(usersSkillsMatrix);
    });

    return this.users$;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
   
}
