export enum TsliMode {

  simpleEditionMode = 'SIMPLE_EDITION_MODE',
  adminEditoionMode = 'ADMIN_EDITION_MODE',
  applicabilityEditionMode = 'APPLICABILITY_EDITION_MODE',
  themeEditionMode = 'THEME_EDITION_MODE',
  // applicabilityReadingMode= "APPLICABILITY_READING_MODE",
  reviewingMode = 'REVIEWING_MODE',
  approvingMode = 'APPROVING_MODE',
  revisingMode = 'REVISION_MODE',
  creatingMode = 'CREATING_MODE'

}
