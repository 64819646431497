<div class="row mt-3">
    <div class="col">
      <h3 class="mat-title">Notitifications</h3>
      <p class="mat-small" style="text-align: right; text-decoration: underline; cursor: pointer;" (click)="viewNotifications()">View all</p>
    </div>
  </div>

  <div class="row flex-fill" style="padding-bottom: 15px;">
    <div class="col d-flex flex-column" style="background-color:#eee">
      <ngb-carousel  #notifCarousel [showNavigationArrows]="true"
      [showNavigationIndicators]="false"
      interval="5000"
      [pauseOnHover]="true"
      [wrap]="true"
      [activeId]="'slide0'">

        <ng-template ngbSlide *ngFor="let notif of notifications;let i = index" [id]="'slide'+i">
          <div class="notif">
            <a href="tsli/notifications/{{notif.id}}">
              {{notif.title}}</a>
            <p class="notif-content" [innerHTML]="notif.content"></p>
          </div>
        </ng-template>
    </ngb-carousel>







      <!-- <div #notifCarousel id="notifCarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner" style="background-color:#eee">

          <div class="carousel-item" *ngFor="let notif of notifications;let i = index" [ngClass]="{'active':  i==0}">
            <div class="notif">
              <p class="mat-body"><a href="/tsli/by-reference/">
                {{notif.title}}</a>
                <span>
                    {{ notif.content }}
                </span>
              </p>
            </div>
          </div>

        </div>
        <a class="carousel-control-prev" href="#notifCarousel" role="button" (click)="getPrev()">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#notifCarousel" role="button" (click)="moveNext()">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div> -->
    </div>
  </div>
