<div class="spinner" *ngIf="!activatedDataReloading">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="activatedDataReloading">
  <div class="tbl-row tbl-root " *ngFor="let tds of surfaceTreatments" style="padding-left: 0;"
    [style.height.px]="tds.opt.length > 1? tds.opt.length * 30 :40">
    <div class="tbl-col tds-cc">
      <ng-container *ngIf="tds.opt.length>1">
        <p class="smallest" *ngFor="let o of tds.opt; let i = index" [ngClass]="{'mb-0': i==tds.opt.length-1, 'mb-20': i!=tds.opt.length-1}">opt {{i + 1}}</p>
        <!-- <p style="margin-bottom: 0;" class="smallest">{{"opt 2"}}</p> -->
      </ng-container>
    </div>
    <div class="tbl-col tds-cc" *ngFor="let field of tds.opt[0].end; let i = index" 
    [ngClass]="{'tds-last-cc': (i+1)==tds.opt[0].end.length, 'pt-2': tds.opt.length == 1}">
      <surface-treatment-icon [value]="tds.opt[j].end[i]"  *ngFor="let opt of tds.opt; let j = index" 
            [comment]="opt.comment"></surface-treatment-icon>
          
    </div>
  </div>
</ng-container>