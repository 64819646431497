<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Download Request</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p><strong>{{ title }} </strong></p>
  <mat-icon *ngIf="title == TITLE_1">mood</mat-icon>
  <mat-icon *ngIf="title == TITLE_2">mood_bad</mat-icon>
  <p>{{ msg }} </p>
</div>
<div class="modal-footer text-center">
  <button  mat-raised-button color="primary" (click)="dismiss()">OK</button>
</div>