<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Modify author</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>modify</ng-template>
      <div class="text-center">
        <h5>You are about to transfer author's right to another person, you will no longer have access to this TSLI
          writing rights ?</h5>
        <div class="modal-header  justify-content-center">
          <div class="row">
            <div class="col"> Actual Author:
              <input matInput id="docAuthor" [value]="document.author.first_name +' '+ document.author.name" readonly
                     style="width: 500px; text-align: center">
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <mat-form-field class="mr-3">
          <mat-select placeholder="Choose an author"
                      [(ngModel)]="selectedReviewer"
                      formControlName="authorSelectCtrl"
                      required>
            <mat-option *ngFor="let author of authors"
                        [value]="author.id" [hidden]="author.id == idUser">
              {{ author.first_name}} {{author.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="text-center footer">
      <button mat-stroked-button class="mr-3" (click)="onCancel()" color="primary">cancel</button>
      <button mat-flat-button matStepperNext class="mr-3" (click)="modifyAuthor()" color="primary">Validate</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirmation</ng-template>
    <ng-container *ngIf="commitSuccessful">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p>your TSLI has been affected to another author </p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">OK !</button>
      </div>
    </ng-container>

    <ng-container *ngIf="commitFailed">
      <h3 class="mat-headline text-center">An error occurred!</h3>
      <div class="text-center">
        <p>your TSLI could not be affected !</p>
        <mat-icon>mood_bad</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">Cancel</button>
      </div>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
