<div class="modal-header">
    <h4 class="mat-subheading-1 mb-0">Add a link to an existing TSLI</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="container">
    <div class="row mt-3">
        <div class="col">
            <h3 class="mat-headline">Choose a Document to link to :</h3>
        </div>
    </div>
    <div class="row scrollable-content">
        <div class="col">

            <mat-selection-list #tslisList class="virtual-scroll" (selectionChange)="handleSelection($event)">
                <mat-list-option>
                    <p class="mat-body"><span class="mat-body-strong">Please contact Mr. Help</span></p>
                </mat-list-option>
                <mat-list-option *ngFor="let tsli of tslis" [value]="tsli">
                    <p class="mat-body"><span class="mat-body-strong">{{tsli.docType | uppercase}} {{" - "}} {{tsli.reference}}</span> - {{tsli.title}}</p>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
</div>

<div class="text-center mb-3">
    <button mat-stroked-button class="mt-3 mr-3" (click)='cancel()'>{{ 'Cancel' }}</button>
    <button mat-flat-button class="mt-3" color="primary" (click)="insertSelectedLink(tslisList.selectedOptions.selected)" [disabled]='tslisList.selectedOptions.selected.length==0'>{{ 'Insert link' }}</button>
</div>