import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { TsliByReferencePageComponent } from './detailed-tsli/tsli-by-reference-page/tsli-by-reference-page.component';
import { TsliByItemPageComponent } from './detailed-tsli/tsli-by-item-page/tsli-by-item-page.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'tsli/by-reference/:docType/:ref', component: TsliByReferencePageComponent},
  { path: 'tsli/by-item', component: TsliByItemPageComponent},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule ]

})
export class FeaturesRoutingModule { }
