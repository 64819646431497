import { Component, OnInit, Input } from '@angular/core';
import { Chapter } from 'src/app/core/data/chapter';
import { Comment } from 'src/app/core/data/comment';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { AuthService } from 'src/app/core/auth.service';
import { CommentsStoreService } from 'src/app/core/comments-store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'chapter-comments',
  templateUrl: './chapter-comments.component.html',
  styleUrls: ['./chapter-comments.component.scss']
})
export class ChapterCommentsComponent implements OnInit {

  constructor(public currentTsli: CurrentTsliStoreService,
     public authService: AuthService,
     public commentsStore: CommentsStoreService) { }

  public comments: Comment[] = [];

  public inputComment: Comment;
  public subscription : Subscription;

  @Input()
  chapter: Chapter;
  ngOnInit() {
    this.inputComment = new Comment();
    this.inputComment.chapter = this.chapter;
    this.inputComment.comment = '';
    this.currentTsli.tsli$.subscribe(value=>{
      this.inputComment.tsli = value;
    });
    this.authService.user$.subscribe(value=>{
      this.inputComment.commentator = value;
    });

    this.subscription = this.commentsStore.comments$.subscribe(value=>{
      let filtered = value.filter(comment=>{ return comment.chapter.id_chapter != undefined && comment.chapter.id_chapter == this.chapter.id_chapter;});
      this.comments = filtered;
      this.chapter.comment = this.comments.length > 0;
    });
  }

  clearComments(){
    this.commentsStore.clearComments(this.currentTsli.getCurrentTsliId(), this.chapter.id_chapter, true);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
