import {Component, OnChanges, OnInit} from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'assembly-data-content',
  templateUrl: './assembly-data-content.component.html',
  styleUrls: ['./assembly-data-content.component.scss']
})
export class AssemblyDataContentComponent {

  assemblyData: any[] = [];
  imperialOrSI;
  activatedDataReloading = true;
  assemblyDataValues ='ASSEMBLYVALUES'
  constructor(public basicProductDataStore: BasicProductDataStoreService) {
    this.basicProductDataStore.imperialOrSI$.subscribe(value=>this.imperialOrSI = value);

    this.basicProductDataStore.assemblyData$.subscribe(data=>{
      this.assemblyData = data;
    });
    this.basicProductDataStore.activatedDataReloading$.subscribe(value=> this.activatedDataReloading = value);
    this.basicProductDataStore.SelectedAssemblyValues$.subscribe(value=> this.assemblyDataValues = value);

  }

}
