export class Notification {
    id: number;
    title: string;
    content: string;
    validity: string;
    role:string;
    licenseeNumber: string;
    permanent: boolean;
}



