import { Component, OnInit } from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'pdf-assembly-data',
  templateUrl: './pdf-assembly-data.component.html',
  styleUrls: ['./pdf-assembly-data.component.scss']
})
export class PdfAssemblyDataComponent implements OnInit {

  constructor(public store: BasicProductDataStoreService) { }
  fieldEnds = [];
  millEnds = [];
  ngOnInit() {
    this.store.switchAssembyData(-1); // (-1 => fetch both sides)
    this.store.assemblyData$.subscribe(value => {
      if (value != undefined && value.length > 0) {
        this.fieldEnds = value.filter(assembly => assembly.side == "Field");
        this.millEnds = value.filter(assembly => assembly.side == "Mill");
      }
      // //only for test
      // else {
      //   this.fieldEnds = [{
      //     minMakeUpTorque: 999.999,
      //     optMakeUpTorque: 999.999,
      //     maxMakeUpTorque: 999.999,
      //     minShoulderingTorque: 999.99,
      //     maxShoulderingTorque: 999.99,
      //     maxTorqueSealability: 999.99,
      //     mtv: 999.999,
      //     maxLinerTorque: 999.999,
      //     maxAllowedTorque: 999.999,
      //     shaleOperatingTorque: 999.999,
      //     minDeltaTurns: 9.99,
      //     maxDeltaTurns: 9.99,
      //     minShoulderSlope: 99,
      //     maxShoulderSlope: 99,
      //     jaws: 'Regular'
      //   },
      //   {
      //     minMakeUpTorque: 999.999,
      //     optMakeUpTorque: 999.999,
      //     maxMakeUpTorque: 999.999,
      //     minShoulderingTorque: 999.99,
      //     maxShoulderingTorque: 999.99,
      //     maxTorqueSealability: 999.99,
      //     mtv: 999.999,
      //     maxLinerTorque: 999.999,
      //     maxAllowedTorque: 999.999,
      //     shaleOperatingTorque: 999.999,
      //     minDeltaTurns: 9.99,
      //     maxDeltaTurns: 9.99,
      //     minShoulderSlope: 99,
      //     maxShoulderSlope: 99,
      //     jaws: 'Regular'
      //   }];

      //   this.millEnds = [{
      //     minMakeUpTorque: 999.999,
      //     optMakeUpTorque: 999.999,
      //     maxMakeUpTorque: 999.999,
      //     minShoulderingTorque: 999.99,
      //     maxShoulderingTorque: 999.99,
      //     maxTorqueSealability: 999.99,
      //     mtv: 999.999,
      //     maxLinerTorque: 999.999,
      //     maxAllowedTorque: 999.999,
      //     shaleOperatingTorque: 999.999,
      //     minDeltaTurns: 9.99,
      //     maxDeltaTurns: 9.99,
      //     minShoulderSlope: 99,
      //     maxShoulderSlope: 99,
      //     jaws: 'Regular'
      //   },
      //   {
      //     minMakeUpTorque: 999.999,
      //     optMakeUpTorque: 999.999,
      //     maxMakeUpTorque: 999.999,
      //     minShoulderingTorque: 999.99,
      //     maxShoulderingTorque: 999.99,
      //     maxTorqueSealability: 999.99,
      //     mtv: 999.999,
      //     maxLinerTorque: 999.999,
      //     maxAllowedTorque: 999.999,
      //     shaleOperatingTorque: 999.999,
      //     minDeltaTurns: 9.99,
      //     maxDeltaTurns: 9.99,
      //     minShoulderSlope: 99,
      //     maxShoulderSlope: 99,
      //     jaws: 'Regular'
      //   }];
      // }
    });
  }

}
