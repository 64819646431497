<div>
  <header>
    <span class="left-text">Basic Product Data | Detailed TSLI</span>
    <span class="right-text"></span>
  </header>
  <main>
    <div class="instructions">
      <div class="text_area_one" [innerHTML]="text_area_one + ' ' + product">
      </div>
      <div class="text_area_two" [innerHTML]="text_area_two"></div>
    </div>
    <div class="centered-spinner" *ngIf="isLoadingExcel">
      <mat-spinner></mat-spinner>
    </div>
    <div class="refining-options">
      <div class="selection-container">
        <div class="selection-area">
          <h2>Refining options for {{product}}:</h2>
        </div>
        <mat-form-field class="lubricant-field">
          <mat-label>Lubricant</mat-label>
          <mat-select [(value)]="lubricant" (selectionChange)="onLubricantSelectionChange($event)">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let lubricant of lubricants" [value]="lubricant">{{lubricant}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button-container">
        <button (click)="downloadCatalog()" mat-stroked-button class="download-button"
                [disabled]="isButtonDisabled"
                [ngStyle]="{'background-color': isButtonDisabled ? '#ccc' : 'blue', 'padding': '2em'}">
          Download Basic Product Data for this family
        </button>
        <div (click)="download_S3_pdf()" class="container-image">
          <p class="text-image">Download full description</p>
          <img alt="pdf" class="pdf-image" src="assets/img/pdf.png" />
        </div>
      </div>
    </div>
  </main>
</div>

