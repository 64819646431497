import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-dialog-admin-license-light',
  templateUrl: './confirm-dialog-admin-license-light.component.html',
  styleUrls: ['./confirm-dialog-admin-license-light.component.scss']
})
export class ConfirmDialogAdminLicenseLightComponent implements OnInit {
  user : string;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  dismiss() {
    this.activeModal.dismiss('dissmissed');
  }

  confirm() {
    this.activeModal.close('confirmed');
  }
}
