<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Browse Basic Product Data</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h3 class="mat-headline mt-3 text-center">Please choose how to browse Basic Product Data</h3>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col text-center" style="width: 50%">
      <button mat-stroked-button (click)="createBpd()" style="min-width: 35%">Create a new list of items</button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col text-center">
      <button mat-stroked-button (click)="showConnections()" style="min-width: 35%">Browse by connection</button>
    </div>
  </div>

</div>
