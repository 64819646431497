<div class="comments-container text-center">
  <div class="row" style=" margin-bottom: 15px;">
    <div class="col"> <button mat-raised-button class="ml-2" 
      (click)="currentTsliStore.setHasComment(true);">{{ "Add General Comment"}}</button></div>
    <div class="col">
        <button mat-raised-button   class="ml-2" [disabled]="activateClear"
        (click)="clearAllComments()" >{{" Clear All Comments"}}</button>
    </div>
  </div>
 
   

  <mat-accordion *ngIf="(currentTsliStore.tsli$ | async)?.comment">
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  General Comments
              </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="comments-content">
              <comment *ngFor="let comment of tsliComments;" [comment]="comment"></comment>
          </div>
          <mat-divider></mat-divider>
          <comment-input [comment]="inputComment"></comment-input>
          <div class="text-center">
            <button mat-raised-button color="primary"  [disabled]="tsliComments.length == 0"
                      (click)="clearComments()" >{{" Clear Comments"}}</button>
                 
          </div>
      </mat-expansion-panel>
  </mat-accordion>


</div>