<table class="pdf-list-table">
    <thead>
        <tr>
            <th class="list-title header">TITLE</th>
            <th class="list-type header">NAME</th>
            <th class="list-revision header">REVISION</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let tsli of tslis">
            <td class="list-title" [ngClass]="{'group-title': tsli.document_type == undefined}">{{tsli.title}}</td>
            <td class="list-type">
                <ng-container *ngIf="tsli.document_type != undefined">{{tsli.document_type | uppercase}} - {{tsli.reference}}</ng-container>
            </td>
            <td class="list-revision">
                <ng-container *ngIf="tsli.document_type != undefined">{{tsli.revision}}</ng-container>

            </td>
        </tr>
    </tbody>
</table>
