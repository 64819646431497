import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionChooserComponent } from '../connection-chooser/connection-chooser.component';
import { Router } from '@angular/router';
import {BasicProductDataStoreService} from '../../../../core/basic-product-data-store.service';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';

@Component({
  selector: 'app-bpd-start-chooser',
  templateUrl: './bpd-start-chooser.component.html',
  styleUrls: ['./bpd-start-chooser.component.scss']
})
export class BpdStartChooserComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              public router: Router,
              public modal: NgbModal,
              public basicProductDataStore: BasicProductDataStoreService,
              public detailedTsliStore: DetailedTsliStore) { }

  ngOnInit() {
  }

  onClose(): void {
    this.activeModal.dismiss('cross clicked');
  }

  showConnections(): void {
    this.activeModal.dismiss('connection choosed');
    this.modal.open(ConnectionChooserComponent, { size: 'lg', centered: true, backdrop:'static' });
  }

  createBpd(): void {
    this.basicProductDataStore.clearProducts();
    this.detailedTsliStore.clearProducts();
    this.activeModal.dismiss('cross clicked');
    this.router.navigate([`tsli/basic-product-data`]);
  }
}
