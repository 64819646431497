import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bpd-thread-compound',
  templateUrl: './thread-compound.component.html',
  styleUrls: ['./thread-compound.component.scss']
})
export class ThreadCompoundComponent implements OnInit {
  

  paddingRight = 0;
  constructor(public cdRef:ChangeDetectorRef) {
    
  }

  activePadding: boolean = false;
 
  ngAfterViewChecked(){
    let element = document.getElementById("productsRows");
    if(element != null) {
      this.paddingRight = element.offsetWidth - element.clientWidth;
    }
    this.cdRef.detectChanges();
  }

  ngOnInit() {

    
  }

}
