import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Notification } from './data/Notification';
import { ServicesNamesConstants } from './services-names.constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public readonly PATH: string = environment.API_BASE_URL + '/notification';
  public readonly notifications = new BehaviorSubject<Notification[]>([]);
  readonly notifications$: Observable<Notification[]> = this.notifications.asObservable();



  constructor(public http: HttpClient,
    public authService: AuthService,
    public router: Router) {
  }

  addNotification(notification:Notification): Observable<any> {
    return this.http.post<any>(this.PATH,
      notification,
      this.authService.getAuthorization(ServicesNamesConstants.NOTIFICATION_SAVE_NOTIFICATION)).pipe(
      map((response: any): any => {
        this.notifications.next(response["notifications"])
        return response;
      })
      , catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return of();
      }));
  }

  updateNotification(notification:Notification): Observable<any> {
    return this.http.put<any>(this.PATH,
      notification,
      this.authService.getAuthorization(ServicesNamesConstants.NOTIFICATION_SAVE_NOTIFICATION)).pipe(
      map((response: any): any => {
        this.notifications.next(response["notifications"])
        return response;
      })
      , catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return of();
      }));
  }

  deleteNotification(idNotification): Observable<any> {
    return this.http.delete<any>(this.PATH + "?id_notification=" + idNotification ,
      this.authService.getAuthorization(ServicesNamesConstants.NOTIFICATION_DELETE_NOTIFICATION)).pipe(
      map((response: any): any => {
        return response;
      })
    , catchError((err, caught) => {
      if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
      return of();
    }));

  }

  getAllNotitifications(): Observable<any> {
    return this.http.get<any>(this.PATH,
      this.authService.getAuthorization(ServicesNamesConstants.NOTIFICATION_GET_NOTIFICATIONS)).pipe(
      map(response => {
        this.notifications.next(response["notifications"])
        return response;
      })
    , catchError((err, caught) => {
      if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
      return of<any>();
    }));
  }
}
