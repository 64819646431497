import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';

import {TsliResponse} from '../../core/data/tsli-response';
import {Tsli} from '../../core/data/tsli';
import {ErrorBarComponent} from '../error-bar/error-bar.component';
import {TslisStoreService} from '../../core/tslis-store.service';
import {CurrentTsliStoreService} from '../../core/current-tsli-store.service';
import {TsliRestService} from '../../core/tsli-rest.service';
import {Status} from 'src/app/core/enum/status.enum';
import {AuthService} from 'src/app/core/auth.service';
import {User} from 'src/app/core/data/User';
import {UserRestService} from 'src/app/core/user-rest.service';
import {Chapter} from '../../core/data/chapter';
import {ConfigurationService} from 'src/app/core/configuration.service';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {Comment} from '../../core/data/comment';
import {CommentsStoreService} from '../../core/comments-store.service';
import {ServicesNamesConstants} from '../../core/services-names.constants';
import {MatStepper} from '@angular/material/stepper';
import {CheckApplicationDateDialogComponent} from '../../core/dialog/check-application-date-dialog/check-application-date-dialog.component';
import {CommentsRestService} from '../../core/comments-rest.service';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {DatePipe} from '@angular/common';
import {TsliMode} from '../../core/enum/tsli-mode.enum';


function validateZeroPadded() {
  const ZERO_PADDED_REGEXP = /^[0-9]+$/;
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isANumber = ZERO_PADDED_REGEXP.test(control.value);
    return isANumber ? null : {notANumber: true};
  };
}

type ValidationErrors = {
  [key: string]: any;
};

@Component({
  selector: 'app-create-tsli-stepper',
  templateUrl: './create-tsli-stepper.component.html',
  styleUrls: ['./create-tsli-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})

export class CreateTsliStepperComponent implements OnInit, OnDestroy {

  myFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  reviewers: User[];
  approvers: User[];
  reviewer: User;
  approver: User;

  docType: string;
  docTitle: string;
  docReference: string;
  docRevision: string;

  typeDescriptionText = '';
  commitSuccessful = false;
  commitFailed = false;
  sendToReviewSuccessful = false;
  selectedReviewer;
  selectedApprover;
  docApplicableBy;
  docApplicationDate;
  tsliProcessDescription = '';
  tsliProductDescription = '';
  tsliGeneralInformationDescription = '';
  tsliLegacyDescription = '';
  docTypeForm = '';
  type;

  docTypeInor = '';

  chapterOneTitle = '';
  chapterOneBody = '';
  chapterOneNumber = '';
  chapterTwoTitle = '';
  chapterTwoBody = '';
  chapterTwoNumber = '';
  subscriptions = [];


  progress: { percentage: number } = {percentage: 0};
  inputComment: Comment;


  docSource_file_s3_url: string;
  reference: string;
  title: string;
  comment: string;
  idTsli: string;
  source_file_s3_url: string;
  display_file_s3_url: string;
  labelCreateOrModifyDocument = 'Create a new document';
  labelCreateOrModifyArchivedDocument = '';
  labelCreateOrModifyOrLoadArchivedDocument = this.labelCreateOrModifyDocument;
  label = '';
  labelModify = 'create';
  documentToCreate: Tsli;
  documentToModify: Tsli;
  documentToRevise: Tsli;
  tsli: Tsli;
  errorMsg = '';
  activatedDataReloading = true;
  isArchivedDocument = false;

  activatedTsliMode: TsliMode;
  subscriptionActivatedTsliMode;


  // Send to review document
  applicationDate: Date;
  modalRef: NgbModalRef;
  tsliType: string;

  public TYPE_DESCRIPTION_TEXT;
  idUser: string;

  isHTMLDoc = false;

  isTypeDisabled = false;
  idCurrentTsliForRevision: string;

  revisionNumberIsRequired = false;

  isExistDocumentType: boolean;
  isExistDocumentReference: boolean;
  isExistDocumentRevision: boolean;

  public readonly activatedMode = new BehaviorSubject<TsliMode>(undefined);
  readonly activatedMode$ = this.activatedMode.asObservable();

  constructor(public formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public currentTsliStoreService: CurrentTsliStoreService,
              public rest: TsliRestService,
              public userRest: UserRestService,
              public snackBar: MatSnackBar,
              public configService: ConfigurationService,
              public authService: AuthService,
              public commentStore: CommentsStoreService,
              public commentsRestService: CommentsRestService,
              public datePipe: DatePipe,
              public modal: NgbModal) {
  }

  ngOnInit(): void {

    if (this.activatedTsliMode == TsliMode.simpleEditionMode) {
      this.isArchivedDocument = false;
    }
    // Title
    if (this.isArchivedDocument) {
      this.labelCreateOrModifyOrLoadArchivedDocument = this.labelCreateOrModifyArchivedDocument;
      this.label = 'a new archived document';
    } else {
      this.label = 'a new document';
    }
    this.userRest.getUsers().subscribe((usersResponse: User[]) => {
      this.reviewers = usersResponse.filter(user => user.isReviewer);
    });
    this.userRest.getUsers().subscribe((usersResponse: User[]) => {
      this.approvers = usersResponse.filter(user => user.isApprover);
    });

    // const mode = this.activatedMode.getValue();
    // console.log(mode);

    this.myFormGroup = this.formBuilder.group({});

    // Step 2: secondFormGroup
    this.secondFormGroup = this.formBuilder.group({
      typeSelectCtrl: ['', Validators.required]
    });

    // Step 3: thirdFormGroup
    this.thirdFormGroup = this.formBuilder.group({
      applicationDateCtrl: ['', Validators.required],
      approverSelectCtrl: ['', Validators.required],
      reviewerSelectCtrl: ['', Validators.required],
      applicableByCtrl: ['', Validators.required]

    });

    // change descriptive text based on select value
    this.secondFormGroup.controls.typeSelectCtrl.valueChanges.subscribe((value: string) => {
      this.typeDescriptionText = this.TYPE_DESCRIPTION_TEXT[value];
    });
    if (this.activatedTsliMode == TsliMode.simpleEditionMode && this.documentToModify != undefined) {

      if (this.documentToModify.docType === ServicesNamesConstants.DOCUMENT_TYPE_FORM) {
        this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_FORM);
      } else if (this.documentToModify.docType === ServicesNamesConstants.DOCUMENT_TYPE_INOR) {
        this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_INOR);
      }

      this.thirdFormGroup.controls.reviewerSelectCtrl.setValue(this.documentToModify.reviewer.id);
      this.thirdFormGroup.controls.approverSelectCtrl.setValue(this.documentToModify.approver.id);
    }

  }

  onNotify(formGroup: FormGroup): void {
    this.myFormGroup = formGroup;
  }


  getItem(value: string): void {
    this.docType = value;
    if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_FORM) {
      this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_FORM);
      this.secondFormGroup.controls.typeSelectCtrl.disable();
    } else if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_INOR) {
      this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_INOR);
      this.secondFormGroup.controls.typeSelectCtrl.disable();
    } else {
      this.secondFormGroup.controls.typeSelectCtrl.setValue('');
      this.secondFormGroup.controls.typeSelectCtrl.enable();
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    // this.subscriptionActivatedTsliMode.unsubscribe();
  }

  goForward(isINORorFORMdocument: boolean, stepper: MatStepper) {
    if (isINORorFORMdocument) {
      this.isHTMLDoc = false;
      if (this.activatedTsliMode === TsliMode.creatingMode
        || this.activatedTsliMode === TsliMode.simpleEditionMode
        || this.activatedTsliMode === TsliMode.revisingMode) {
        if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_FORM) {
          this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_FORM);
        } else if (this.docType === ServicesNamesConstants.DOCUMENT_TYPE_INOR) {
          this.secondFormGroup.controls.typeSelectCtrl.setValue(ServicesNamesConstants.DOCUMENT_TYPE_INOR);
        }
        // if (this.source_files.length === 0) {
        //   this.isHTMLDoc = true;
        // }

        if (this.documentToModify !== undefined) {
          this.thirdFormGroup.controls.reviewerSelectCtrl.setValue(this.documentToModify.reviewer.id);
          this.thirdFormGroup.controls.approverSelectCtrl.setValue(this.documentToModify.approver.id);
        }

      } else {
        this.isHTMLDoc = true;
        if (this.getDocumentType(this.secondFormGroup.controls.typeSelectCtrl.value)) {
          this.secondFormGroup.controls.typeSelectCtrl.enable();
          this.secondFormGroup.controls.typeSelectCtrl.setValue('');
        }
      }
    } else {
      if (this.activatedTsliMode == TsliMode.revisingMode) {
        this.secondFormGroup.controls.typeSelectCtrl.setValue(this.documentToRevise.type);
        this.isTypeDisabled = true;
        this.isHTMLDoc = true;
      } else {
        if (stepper.selectedIndex === 1) {
          this.isHTMLDoc = true;
          this.secondFormGroup.controls.typeSelectCtrl.setValue('');
          this.secondFormGroup.controls.typeSelectCtrl.enable();
        }
      }
    }
  }


  displayError(msg: string,
               statusCode: string,
               statusText: string) {
    this.snackBar.openFromComponent(ErrorBarComponent, {
      duration: 5000,
      data: {
        msg,
        statusCode,
        statusText
      }
    });
  }

  ngAfterViewInit(): void {
    this.getDynamicTSLICreation();
  }

  getDynamicTSLICreation() {

    // using forkJoin to get all values at a time to process them to
    //
    this.subscriptions.push(
      forkJoin(
        [this.configService.getConfigurationByName('creation_process_description'),
          this.configService.getConfigurationByName('creation_general_information_description'),
          this.configService.getConfigurationByName('creation_product_description'),
          this.configService.getConfigurationByName('creation_form_description'),
          this.configService.getConfigurationByName('creation_legacy_description'),
          this.configService.getConfigurationByName('creation_inor_description'),
          this.configService.getConfigurationByName('chapter_one_title'),
          this.configService.getConfigurationByName('chapter_one_body'),
          this.configService.getConfigurationByName('chapter_two_title'),
          this.configService.getConfigurationByName('chapter_two_body'),
          this.configService.getConfigurationByName('chapter_one_number'),
          this.configService.getConfigurationByName('chapter_two_number')]
      ).subscribe(([process, gi, product, form,
                     legacy, inor, chapterOneTitle,
                     chapterOneBody, chapterTwoTitle, chapterTwoBody,
                     chapterOneNumber, chapterTwoNumber]) => {
        this.tsliProductDescription = product['configurations'][0].value;
        this.tsliGeneralInformationDescription = gi['configurations'][0].value;
        this.tsliProcessDescription = process['configurations'][0].value;
        this.tsliLegacyDescription = legacy['configurations'][0].value;
        this.docTypeForm = form['configurations'][0].value;
        this.docTypeInor = inor['configurations'][0].value;
        this.TYPE_DESCRIPTION_TEXT = {
          process: this.tsliProcessDescription,
          product: this.tsliProductDescription,
          general_information: this.tsliGeneralInformationDescription,
          form: this.docTypeForm,
          legacy: this.tsliLegacyDescription,
          FORM: this.docTypeForm,
          INOR: this.docTypeInor
        };
        this.chapterOneTitle = chapterOneTitle['configurations'][0].value;
        this.chapterOneBody = chapterOneBody['configurations'][0].value;
        this.chapterTwoTitle = chapterTwoTitle['configurations'][0].value;
        this.chapterTwoBody = chapterTwoBody['configurations'][0].value;
        this.chapterOneNumber = chapterOneNumber['configurations'][0].value;
        this.chapterTwoNumber = chapterTwoNumber['configurations'][0].value;

      }));
  }

  addRevisionComment(tsli: Tsli) {
    this.inputComment = new Comment();
    this.inputComment.commentDate = new Date();
    this.inputComment.tsli = tsli;
    this.inputComment.is_revision_comment = '1';
    this.authService.user.subscribe(user => {
      this.inputComment.commentator = user;
    });
    // this.inputComment.comment = JSON.parse(JSON.stringify(this.firstFormGroup.controls.commentRevisionCtrl.value));
    this.commentStore.addComment(this.inputComment);

  }

  checkApplicationDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.applicationDate = new Date(this.thirdFormGroup.controls.applicationDateCtrl.value);
    if (this.applicationDate.getTime() < tomorrow.getTime()) {
      const modalRef: NgbModalRef = this.modal.open(CheckApplicationDateDialogComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      });
      modalRef.componentInstance.applicationDate = this.datePipe.transform(this.applicationDate, 'dd/MM/yyyy');
      // modalRef.componentInstance.validationMode = this.activatedTsliMode == 'REVIEWING_MODE' || this.activatedTsliMode == 'APPROVING_MODE'
      return modalRef.result;
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

  checkDate() {
    const checkResult = this.checkApplicationDate();
    if (checkResult === undefined) {
      return this.commitDocument(true);
    }

    checkResult.then((userResponse) => {
      if (userResponse === 'continue') {
        this.commitDocument(true);
      }
    }).catch(err => console.error(err));
  }

  sendToReview() {
    this.checkDate();
  }

  saveDocument() {
    this.commitDocument(false);
  }

  /*
  * Commit document when click on next button in third form group or in second
  * for group for load archived document
  * */
  commitDocument(isSentToReview: boolean): void {
    const document: Tsli = new Tsli();
    // document.docType = this.firstFormGroup.controls.docTypeCtrl.value;
    // document.title = this.firstFormGroup.controls.titleCtrl.value;
    // document.reference = this.firstFormGroup.controls.referenceNumberCtrl.value;
    // document.revision = this.firstFormGroup.controls.revisionNumberCtrl.value;
    document.type = this.secondFormGroup.controls.typeSelectCtrl.value;
    document.applicationDate = this.thirdFormGroup.controls.applicationDateCtrl.value;
    document.reviewer = new User();
    document.reviewer.id = this.thirdFormGroup.controls.reviewerSelectCtrl.value;
    document.approver = new User();
    document.approver.id = this.thirdFormGroup.controls.approverSelectCtrl.value;
    document.applicableBy = this.thirdFormGroup.controls.applicableByCtrl.value;
    document.isLegacy = document.type.toUpperCase() === 'LEGACY';
    document.isArchivedDocument = this.isArchivedDocument;
    document.status = document.isArchivedDocument ? Status.ARCHIVED : Status.WRITING;
    this.authService.user.subscribe(user => {
      document.author = user;
    });

    this.authService.user.subscribe(user => {
      document.author = user;
    });

    if (this.activatedTsliMode === TsliMode.revisingMode && this.getDocumentType(this.docType)) {
      this.CreateNewRevision(document, isSentToReview);
    } else if (this.activatedTsliMode === TsliMode.simpleEditionMode && this.getDocumentType(this.docType)) {
      this.editDocument(document, isSentToReview);
    } else {
      this.createNewDocument(document, isSentToReview);
    }
  }

  isHTMLDocument(document: Tsli) {
    return this.tslisStore.isHTMLDocument(document);
  }

  getDocumentType(docType: string): boolean {
    return this.tslisStore.getDocumentType(docType);
  }

  createNewDocument(document: Tsli, isSentToReview: boolean) {
    this.tslisStore.createTsli(document).subscribe(
      (tsliResponse: TsliResponse) => {

        if (isSentToReview) {
          this.sendToReviewSuccessful = true;
          this.commitSuccessful = false;
        } else {
          this.sendToReviewSuccessful = false;
          this.commitSuccessful = true;
        }
        document.idTsli = tsliResponse.tsli.idTsli;

        // upload source and display file and add no chapter to add
        if ((this.isHTMLDocument(document) && !this.isHTMLDoc) || document.isArchivedDocument) {
          // this.uploadSourceFile(document);
          // this.uploadDisplayFile(document);
          this.addRevisionComment(document);

          if (isSentToReview) {
            // this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
            //   console.log(resp);
            // });
          }
        } else {
          const chapters: Chapter[] = [];
          const chapterOne: Chapter = new Chapter();
          chapterOne.depth = 0;
          chapterOne.index = 0;
          chapterOne.title = this.chapterOneTitle;
          chapterOne.body = this.chapterOneBody;
          chapterOne.number = this.chapterOneNumber;
          chapterOne.titleStyle = null;
          chapters.push(chapterOne);
          const chapterTwo: Chapter = new Chapter();
          chapterTwo.depth = 0;
          chapterTwo.index = 1;
          chapterTwo.title = this.chapterTwoTitle;
          chapterTwo.body = this.chapterTwoBody;
          chapterTwo.number = this.chapterTwoNumber;
          chapterTwo.titleStyle = null;
          chapters.push(chapterTwo);
          this.currentTsliStoreService.addChaptersWhenTsliCreated(document, chapters);
        }

      },
      (error: any) => {
        this.displayError('An HTTP error occurred! ', error.status, error.statusText);
        this.commitFailed = true;
      });
  }

  editDocument(document: Tsli, isSentToReview: boolean) {
    document.idTsli = this.documentToModify.idTsli;

    this.rest.updateTsli(document, this.isHTMLDoc).subscribe(response => {
      console.log(response);
      if (isSentToReview) {
        this.sendToReviewSuccessful = true;
        this.commitSuccessful = false;
      } else {
        this.sendToReviewSuccessful = false;
        this.commitSuccessful = true;
      }

      if (this.commitSuccessful || this.sendToReviewSuccessful) {
        // Update or delete source file
        // if (this.source_files.length > 0 && this.source_files[0].name !== this.documentToModify.source_file_s3_url) {
        //   document.source_file_s3_url = this.documentToModify.source_file_s3_url;
        //   this.uploadSourceFile(document);
        // } else if (this.source_files.length === 0) {
        //   // Delete source file
        //   this.deleteSourceOrDisplayFile(document, true, false);
        // }
        //
        // // Update or delete display file
        // if (this.display_files.length > 0 && this.display_files[0].name != this.documentToModify.display_file_s3_url) {
        //   document.display_file_s3_url = this.documentToModify.display_file_s3_url;
        //   this.uploadDisplayFile(document);
        // } else if (this.display_files.length == 0) {
        //   // Delete display file
        //   this.deleteSourceOrDisplayFile(document, false, true);
        // }
        //
        // // add comment
        // this.addRevisionComment(document);
        //
        // if (isSentToReview) {
        //   this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
        //     console.log(resp);
        //     this.tslisStore.getAllTslis().subscribe();
        //   });
        // }
      }
    });
  }

  CreateNewRevision(document: Tsli, isSentToReview: boolean) {
    this.tslisStore.createNewRevision(document).subscribe(
      (tsliResponse: TsliResponse) => {
        if (tsliResponse.tsli == null) {
          this.displayError('An HTTP error occurred! ', tsliResponse.error, tsliResponse.error);
          this.errorMsg = tsliResponse.error ? tsliResponse.error : 'TSLI IS NULL';
          this.commitFailed = true;
        } else {
          this.idCurrentTsliForRevision = tsliResponse.tsli.idTsli;
          document.idTsli = tsliResponse.tsli.idTsli;
          if (isSentToReview) {
            this.sendToReviewSuccessful = true;
            this.commitSuccessful = false;
          } else {
            this.sendToReviewSuccessful = false;
            this.commitSuccessful = true;
          }

          // this.uploadSourceFile(document);
          // this.uploadDisplayFile(document);
          this.addRevisionComment(document);

          if (isSentToReview) {
            this.rest.updateStatus(document, Status.REVIEWING).subscribe(resp => {
              console.log(resp);
            });
          }
        }
      }
    );
  }

  deleteSourceOrDisplayFile(document: Tsli, isSourceFile: boolean, isDisplayFile: boolean) {

    if (isSourceFile) {
      this.rest.update_source_file_url(document.idTsli, null).subscribe(response => {
        console.log(response);
        this.tslisStore.getAllDocuments().subscribe();
      });
    }
    if (isDisplayFile) {
      this.rest.update_display_file_url(document.idTsli, null).subscribe(response => {
        console.log(response);
        this.tslisStore.getAllDocuments().subscribe();
      });
    }

  }


  /*
  * Not used code
  * */
  // getReferenceNumberErrMsg(): string {
  //   let errorMsg = '';
  //   if (this.firstFormGroup.controls.referenceNumberCtrl.errors !== undefined) {
  //     if (this.firstFormGroup.controls.referenceNumberCtrl.errors.uniqueReferenceNumber) {
  //       errorMsg = 'this Reference No. already exists';
  //     } else if (this.firstFormGroup.controls.referenceNumberCtrl.errors.required) {
  //
  //     }
  //   }
  //   return errorMsg;
  // }
  //
  // updateStatus(tsli: Tsli, status: Status) {
  //
  //   // this.modalRef = this.modal.open(SpinnerDialogComponent, {size: 'sm', centered: true, backdrop: 'static'});
  //   // this.timeLeft = 125
  //   return this.rest.updateStatus(tsli, status).pipe(map(resp => {
  //       if (resp.msg !== undefined && resp.msg === 'document status updated') {
  //         console.log('document status updated');
  //       }
  //     }),
  //     catchError(err => {
  //       console.log('caught mapping error and rethrowing', err);
  //       return err;
  //     }));
  // }
  // checkIfDocumentExists(referenceNumber: string, docType: string, revisionNumber: string): Observable<boolean> {
  //   return this.rest.isRevisionNumberExist(referenceNumber, docType, revisionNumber).pipe(delay(100));
  // }
  //
  // revisionValidator2() {
  //   if (this.activatedTsliMode == TsliMode.revisingMode && this.isArchivedChecked) {
  //     if (this.firstFormGroup.controls.revisionNumberCtrl.value >= this.documentToRevise.revision) {
  //       this.firstFormGroup.controls.revisionNumberCtrl.setErrors({incorrect: true});
  //       this.firstFormGroup.controls.revisionNumberCtrl.markAsTouched();
  //       this.firstFormGroup.controls.revisionNumberCtrl.markAsDirty();
  //     }
  //   }
  // }
  //
  // revisionValidator(): AsyncValidatorFn {
  //   return (): Observable<ValidationErrors | null> => {
  //     return this.checkIfRevisionNumberExists(this.firstFormGroup.controls.referenceNumberCtrl.value,
  //       this.firstFormGroup.controls.docTypeCtrl.value, this.firstFormGroup.controls.revisionNumberCtrl.value).pipe(
  //       map(res => {
  //         console.log('here2', res);
  //         if (res) {
  //           if ((this.activatedTsliMode == TsliMode.revisingMode || this.activatedTsliMode == TsliMode.creatingMode) && this.isArchivedChecked) {
  //             if (this.firstFormGroup.controls.revisionNumberCtrl.value >= this.documentToRevise.revision) {
  //               return {revisionNumberInvalid: true};
  //             } else {
  //               return null;
  //             }
  //           }
  //         } else {
  //           if ((this.activatedTsliMode == TsliMode.revisingMode || this.activatedTsliMode == TsliMode.creatingMode) && this.isArchivedChecked) {
  //             return {revisionNumberExists: true};
  //           }
  //         }
  //         // // if res is true, revision number not exists, return null
  //         // return res ? null : {revisionNumberExists: true};
  //         // // NB: Return true if there number exist
  //       })
  //     );
  //   };
  // }
  //
  //
  //
  // referenceValidator(): AsyncValidatorFn {
  //   return (): Observable<ValidationErrors | null> => {
  //     return this.checkIfReferenceNumberExists(this.firstFormGroup.controls.referenceNumberCtrl.value,
  //       this.firstFormGroup.controls.docTypeCtrl.value).pipe(
  //       map(res => {
  //         console.log('result', res);
  //         // if res is true, revision number not exists, return null
  //         return res ? null : {referenceNumberExists: true};
  //         // NB: Return true if there number exist
  //       })
  //     );
  //   };
  // }
  //
  //
  // // IsRevisionNumberExist() {
  // //   return true;
  // // if (this.activatedTsliMode == TsliMode.revisingMode && this.isArchivedChecked) {
  // //   console.log('here');
  // //   this.firstFormGroup.controls.revisionNumberCtrl.setErrors({incorrect: true});
  // //   this.firstFormGroup.controls.revisionNumberCtrl.markAsTouched();
  // //   this.firstFormGroup.controls.revisionNumberCtrl.markAsDirty();
  // //   return this.rest.isRevisionNumberExist(this.firstFormGroup.controls.referenceNumberCtrl.value,
  // //     this.firstFormGroup.controls.docTypeCtrl.value, this.firstFormGroup.controls.revisionNumberCtrl.value)
  // //     .pipe(map(value => {
  // //       console.log(value);
  // //       if (value) {
  // //         return {
  // //           incorrect: false,
  // //         };
  // //       }
  // //       return {
  // //         incorrect: false,
  // //       };
  // //     }, () => {
  // //       return {incorrect: false};
  // //     }));
  // //
  // // } else {
  // //   this.firstFormGroup.controls.revisionNumberCtrl.setErrors({'incorrect': true});
  // //   this.firstFormGroup.controls.revisionNumberCtrl.markAsTouched();
  // //   this.firstFormGroup.controls.revisionNumberCtrl.markAsDirty();
  // //
  // //   return this.rest.isRevisionNumberExist(this.firstFormGroup.controls.referenceNumberCtrl.value,
  // //     this.firstFormGroup.controls.docTypeCtrl.value, this.firstFormGroup.controls.revisionNumberCtrl.value)
  // //     // .pipe(map(value => {
  // //     //   console.log('value', value);
  // //     //   // if (value) {
  // //     //   //   console.log('value', value);
  // //     //   //   return null;
  // //     //   // }
  // //     //   // return {incorrect: true};
  // //     // }, () => {
  // //     //   console.log('ici');
  // //     //   //return {incorrect: true};
  // //     // }));
  // //     .pipe(map(value => {
  // //       console.log(value);
  // //       if (value) {
  // //         return {
  // //           incorrect: false,
  // //         };
  // //       }
  // //       return {
  // //         incorrect: true,
  // //       };
  // //     }, () => {
  // //       return {incorrect: true};
  // //     }));
  // // }
  // // }
  //
  // getRevisionNumberErrMsg(): string {
  //   let errorMsg = '';
  //   if (this.firstFormGroup.controls.revisionNumberCtrl.errors !== undefined) {
  //     if (this.firstFormGroup.controls.revisionNumberCtrl.errors.notANumber) {
  //       errorMsg = 'must be a positive number';
  //     } else if (this.firstFormGroup.controls.revisionNumberCtrl.invalid) {
  //       console.log('revisionNumberCtrl', this.firstFormGroup.controls.revisionNumberCtrl.value);
  //       console.log('revisionNumberCtrl exist', this.documentToRevise.revision);
  //       if (this.firstFormGroup.controls.revisionNumberCtrl.value < this.documentToRevise.revision && this.activatedTsliMode == TsliMode.revisingMode) {
  //         console.log('ici');
  //         return errorMsg;
  //       } else {
  //         errorMsg = 'this revision No. already exists';
  //       }
  //     }
  //   }
  //   return errorMsg;
  // }
  //
  // //Verify
  // documentValidator(): AsyncValidatorFn {
  //   if (this.docType != undefined || this.docReference != undefined || this.docRevision != undefined) {
  //     return (): Observable<ValidationErrors | null> => {
  //       return this.checkIfReferenceNumberExists(this.firstFormGroup.controls.referenceNumberCtrl.value,
  //         this.firstFormGroup.controls.docTypeCtrl.value).pipe(
  //         map(res => {
  //           // console.log('result', res);
  //           // // if res is true, revision number not exists, return null
  //           // return res ? null : {referenceNumberExists: true};
  //           // // NB: Return true if there number exist
  //
  //           if (this.docType != undefined) {
  //             return res ? null : {documentExists: true};
  //           }
  //           if (this.docReference != undefined) {
  //             return res ? null : {referenceNumberExists: true};
  //           }
  //           if (this.docRevision != undefined) {
  //             return res ? null : {revisionNumberExists: true};
  //           }
  //         })
  //       );
  //     };
  //     // this.checkIfDocumentExists(this.firstFormGroup.controls.referenceNumberCtrl.value,
  //     //   this.firstFormGroup.controls.docTypeCtrl.value, this.firstFormGroup.controls.revisionNumberCtrl.value).pipe(
  //     //   map(res => {
  //     //
  //     //     console.log('result', res);
  //     //
  //     //     // if (this.activatedTsliMode != TsliMode.revisingMode) {
  //     //     //   if (this.checkIfRevisionNumberExists(this.firstFormGroup.controls.referenceNumberCtrl.value,
  //     //     //     this.firstFormGroup.controls.docTypeCtrl.value, this.firstFormGroup.controls.revisionNumberCtrl.value)) {
  //     //     //     console.log('ici');
  //     //     //     return res ? null : {documentExists: true, revisionNumberExists: true, referenceNumberExists: true};
  //     //     //   }
  //     //     // } else {
  //     //     //   console.log('ici2');
  //     //     // if res is true, revision number not exists, return null
  //     //     if (this.docType != undefined) {
  //     //       return res ? null : {documentExists: true};
  //     //     }
  //     //     if (this.docReference != undefined ) {
  //     //       return res ? null : {referenceNumberExists: true};
  //     //     }
  //     //     if (this.docRevision != undefined ) {
  //     //       return res ? null : {revisionNumberExists: true};
  //     //     }
  //     //
  //     //     // NB: Return true if the number exist
  //     //     // }
  //     //   })
  //     // );
  //   }
  // }
}

