import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';

/* Angular Material imports */
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';


import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {HomeComponent} from './home/home.component';

import {WriterSideBarComponent} from './writer-side-bar/writer-side-bar.component';
import {CreateTsliStepperComponent} from './create-tsli-stepper/create-tsli-stepper.component';
import {ErrorBarComponent} from './error-bar/error-bar.component';
import {TsliComponent} from './tsli/tsli.component';
import {ChaptersListComponent} from './tsli/chapters-list/chapters-list.component';
import {ChapterComponent} from './tsli/chapter/chapter.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {TsliLinksListComponent} from './tsli/tsli-links-list/tsli-links-list.component';
import {MathjaxComponent} from './tsli/chapter/mathjax/mathjax.component';
import {ApplicabilityComponent} from './tsli/chapter/applicability/applicability.component';
import {CreateApplicabilityStepperComponent} from './tsli/chapter/create-applicability-stepper/create-applicability-stepper.component';
import {ApplicabilityItemComponent} from './tsli/chapter/applicability/applicability-item/applicability-item.component';
import {BasicProductDataComponent} from './basic-product-data/basic-product-data.component';
import {BpdStartChooserComponent} from './basic-product-data/modals/bpd-start-chooser/bpd-start-chooser.component';
import {ConnectionChooserComponent} from './basic-product-data/modals/connection-chooser/connection-chooser.component';
import {SurfaceTreatmentComponent} from './basic-product-data/surface-treatment/surface-treatment.component';
import {ProductListComponent} from './basic-product-data/product-list/product-list.component';
import {BpdHeaderComponent} from './basic-product-data/bpd-header/bpd-header.component';
import {AssemblyDataComponent} from './basic-product-data/assembly-data/assembly-data.component';
import {ThreadCompoundComponent} from './basic-product-data/thread-compound/thread-compound.component';
import {FractionPipe} from '../core/pipe/fraction.pipe';
import {ProductPickerComponent} from './basic-product-data/product-picker/product-picker.component';
import {SurfaceTreatmentHeaderComponent} from './basic-product-data/surface-treatment/surface-treatment-header.component';
import {PageUnauthorizedComponent} from './page-unauthorized/page-unauthorized.component';
import {SurfaceTreatementContentComponent} from './basic-product-data/surface-treatment/surface-treatment-content.component';
import {SurfaceTreatmentIconComponent} from './basic-product-data/surface-treatment/surface-treatment-icon.component';
import {AssemblyDataHeaderComponent} from './basic-product-data/assembly-data/assembly-data-header.component';
import {AssemblyDataHeaderGridComponent} from './basic-product-data/assembly-data/assembly-data-header-grid.component';
import {AssemblyDataContentComponent} from './basic-product-data/assembly-data/assembly-data-content.component';
import {UnitNumberFormatPipe} from '../core/pipe/unit-number-format.pipe';
import {ThreadCompoundHeaderComponent} from './basic-product-data/thread-compound/thread-compound-header.component';
import {ThreadCompoundContentComponent} from './basic-product-data/thread-compound/thread-compound-content.component';
import {DetailedTsliComponent} from './detailed-tsli/detailed-tsli.component';
import {TsliByItemComponent} from './detailed-tsli/tsli-by-item/tsli-by-item.component';
import {TsliByItemPageComponent} from './detailed-tsli/tsli-by-item-page/tsli-by-item-page.component';
import {SidebarNavComponent} from './detailed-tsli/sidebar-nav/sidebar-nav.component';
import {TsliContentComponent} from './detailed-tsli/tsli-content/tsli-content.component';
import {TsliItemComponent} from './detailed-tsli/tsli-item/tsli-item.component';
import {TsliByReferenceComponent} from './detailed-tsli/tsli-by-reference/tsli-by-reference.component';
import {TsliByReferencePageComponent} from './detailed-tsli/tsli-by-reference-page/tsli-by-reference-page.component';
import {TsliByProcessComponent} from './detailed-tsli/tsli-by-process/tsli-by-process.component';
import {TsliByProcessListComponent} from './detailed-tsli/tsli-by-process/tsli-by-process-list/tsli-by-process-list.component';
import {TsliListComponent} from './detailed-tsli/tsli-list/tsli-list.component';
import {TsliByConnectionComponent} from './detailed-tsli/tsli-by-connection/tsli-by-connection.component';
import {TsliByConnectionListComponent} from './detailed-tsli/tsli-by-connection/tsli-by-connection-list/tsli-by-connection-list.component';
import {SafeHtmlPipe} from '../core/pipe/safe-html.pipe';
import {TslisComponent} from './tsli/tslis/tslis.component';
import {HasRoleDirective} from '../core/directives/has-role.directive';
import {UsersManagementComponent} from './admin/users-management/users-management.component';
import {ConfirmDialogStatusComponent} from '../core/dialog/confirm-dialog-status/confirm-dialog-status.component';
import {CommentComponent} from './tsli/comments/comment/comment.component';
import {CommentInputComponent} from './tsli/comments/comment-input/comment-input.component';
import {ChapterCommentsComponent} from './tsli/comments/chapter-comments/chapter-comments.component';
import {InformationDialogComponent} from '../core/dialog/information-dialog/information-dialog.component';
import {GeneralCommentsComponent} from './tsli/comments/general-comments/general-comments.component';
import {CreateRevisionPopupComponent} from './manage-document/create-revision-popup/create-revision-popup.component';
import {modifyDateValidationPopupComponent} from './modify-date-validation-popup/modify-date-validation-popup.component';
import {ModifyAuthorStepperComponent} from './modify-author-stepper/modify-author-stepper.component';
import {ConfirmDialogArchiveComponent} from '../core/dialog/confirm-dialog-archive/confirm-dialog-archive.component';
import {TsliHistoryComponent} from './detailed-tsli/tsli-by-reference-page/tsli-hisory/tsli-hisory.component';
import {ConfirmDialogAdminEditComponent} from '../core/dialog/confirm-dialog-admin-edit/confirm-dialog-admin-edit.component';
import {TsliInfosComponent} from './pdf/tsli-infos/tsli-infos.component';
import {TsliDescriptionComponent} from './pdf/tsli-description/tsli-description.component';
import {PdfContentComponent} from './pdf/pdf-content/pdf-content.component';
import {TdsDataComponent} from './pdf/tds-data/tds-data.component';
import {PdfAssemblyDataComponent} from './pdf/pdf-assembly-data/pdf-assembly-data.component';
import {PdfThreadCompoundComponent} from './pdf/pdf-thread-compound/pdf-thread-compound.component';
import {PdfAssemblyContentComponent} from './pdf/pdf-assembly-data/pdf-assembly-content/pdf-assembly-content.component';
import {PdfChaptersComponent} from './pdf/pdf-chapters/pdf-chapters.component';
import {PdfSummaryComponent} from './pdf/pdf-summary/pdf-summary.component';
import {PDFTsliListComponent} from './pdf/tsli-list/tsli-list.component';
import {UserPartialRightsComponent} from './admin/user-partial-rights/user-partial-rights.component';
import {PdfUserComponent} from './pdf/pdf-user/pdf-user.component';
import {VideoComponent} from './home/video/video.component';
import {CheckApplicationDateDialogComponent} from '../core/dialog/check-application-date-dialog/check-application-date-dialog.component';
import {TimeoutDialogComponent} from '../core/dialog/timeout-dialog/timeout-dialog.component';
import {NotificationComponent} from './admin/notification/notification.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NotificationDetailsComponent} from './notifications/notification-details/notification-details.component';
import {ConfigurationComponent} from './admin/configuration/configuration.component';
import {EditvalueComponent} from './admin/editvalue/editvalue.component';
import {UserLicenseLightRightsComponent} from './admin/user-license-light-rights/user-license-light-rights.component';
import {ConfirmDialogAdminLicenseLightComponent} from '../core/dialog/confirm-dialog-admin-license-light/confirm-dialog-admin-license-light.component';
import {FeaturesRoutingModule} from './features-routing.module';
import {ProductListManagementComponent} from './admin/product-list-management/product-list-management.component';
import {WarningPopupComponent} from './warning-popup/warning-popup.component';
import { DownloadSourceOrDisplayFilePopupComponent } from './download-source-or-display-file-popup/download-source-or-display-file-popup/download-source-or-display-file-popup.component';
import { CreateDocumentComponent } from './manage-document/create-document/create-document.component';
import { CreateArchivedDocumentComponent } from './manage-document/create-archived-document/create-archived-document.component';
import { ModifyDocumentComponent } from './manage-document/modify-document/modify-document.component';
import { PocNsComponent } from './poc-ns/poc-ns.component';
import {MatChipsModule} from "@angular/material/chips";

@NgModule({
  declarations: [
    HasRoleDirective,
    PageNotFoundComponent,
    HomeComponent,
    WriterSideBarComponent,
    CreateTsliStepperComponent,
    ErrorBarComponent,
    TsliComponent,
    ChaptersListComponent,
    ChapterComponent,
    TsliLinksListComponent,
    MathjaxComponent,
    ApplicabilityComponent,
    CreateApplicabilityStepperComponent,
    CreateRevisionPopupComponent,
    ConfirmDialogStatusComponent,
    CheckApplicationDateDialogComponent,
    TimeoutDialogComponent,
    ConfirmDialogArchiveComponent,
    ConfirmDialogAdminEditComponent,
    ConfirmDialogAdminLicenseLightComponent,
    CheckApplicationDateDialogComponent,
    modifyDateValidationPopupComponent,
    ModifyAuthorStepperComponent,
    InformationDialogComponent,
    ApplicabilityItemComponent,
    BasicProductDataComponent,
    BpdStartChooserComponent,
    ConnectionChooserComponent,
    UserPartialRightsComponent,
    SurfaceTreatmentComponent,
    ProductListComponent,
    BpdHeaderComponent,
    AssemblyDataComponent,
    ThreadCompoundComponent,
    FractionPipe,
    UnitNumberFormatPipe,
    SafeHtmlPipe,
    ProductPickerComponent,
    SurfaceTreatmentHeaderComponent,
    PageUnauthorizedComponent,
    SurfaceTreatementContentComponent,
    SurfaceTreatmentIconComponent,
    AssemblyDataHeaderComponent,
    AssemblyDataHeaderGridComponent,
    AssemblyDataContentComponent,
    ThreadCompoundHeaderComponent,
    ThreadCompoundContentComponent,
    DetailedTsliComponent,
    TsliByItemComponent,
    TsliByItemPageComponent,
    SidebarNavComponent,
    TsliContentComponent,
    TsliItemComponent,
    TsliByReferenceComponent,
    TsliByReferencePageComponent,
    TsliByProcessComponent,
    TsliByProcessListComponent,
    TsliListComponent,
    TsliByConnectionComponent,
    TsliByConnectionListComponent,
    TslisComponent,
    UsersManagementComponent,
    UserLicenseLightRightsComponent,
    ConfigurationComponent,
    CommentComponent,
    CommentInputComponent,
    ChapterCommentsComponent,
    GeneralCommentsComponent,
    TsliHistoryComponent,
    TsliInfosComponent,
    TsliDescriptionComponent,
    PdfContentComponent,
    TdsDataComponent,
    PdfAssemblyDataComponent,
    PdfThreadCompoundComponent,
    PdfAssemblyContentComponent,
    PdfChaptersComponent,
    PdfSummaryComponent,
    PdfUserComponent,
    PDFTsliListComponent,
    VideoComponent,
    NotificationComponent,
    NotificationsComponent,
    NotificationDetailsComponent,
    EditvalueComponent,
    ProductListManagementComponent,
    WarningPopupComponent,
    DownloadSourceOrDisplayFilePopupComponent,
    CreateDocumentComponent,
    CreateArchivedDocumentComponent,
    ModifyDocumentComponent,
    PocNsComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        FeaturesRoutingModule,

        /* Angular Material imports */
        MatButtonModule,
        MatMenuModule,
        MatCardModule,
        MatToolbarModule,
        MatGridListModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatSelectModule,
        MatListModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatSortModule,
        CKEditorModule,
        NgxDropzoneModule,
        MatChipsModule
    ],
  exports: [
    HasRoleDirective,
    TsliInfosComponent,
    TsliDescriptionComponent

  ],
  providers: [
    DatePipe,
  ]

})
export class FeaturesModule {
}
