<ng-container *ngIf="!forPdf">
    <div class="tbl-row , main-row-default">
        <div class="tbl-col  b-bl b-right " style="flex: 9 9">
            <mat-tab-group (selectedTabChange)="switchSelectedAssemblyValues($event)" [(selectedIndex)]= "selectedtab" >
                <mat-tab label="ASSEMBLY VALUES" style="min-width: 50%;">
                    <div class="tbl-row title-default" style="height: 100%">
                        <div class="tbl-col b-bl">
                            <p class="p-default">Min MUT {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Opti MUT {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Max MUT {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Min SHT {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl b-right">
                            <p class="p-default">Max SHT {{unit}}</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="MAX VALUES" style="min-width: 50%;">
                    <div class="tbl-row title-default" style="height: 100%">
                        <div class="tbl-col b-bl">
                            <p class="p-default">Max Torque with Sealability {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">MTV {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Max liner Torque {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Max allowed Torque {{unit}}</p>
                        </div>
                        <div class="tbl-col b-bl b-right">
                            <p class="p-default">Shale operating Torque {{unit}}</p>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="OTHER ASSEMBLY VALUES" style="min-width: 50%;">
                    <div class="tbl-row title-default" style="height: 100%" >
                        <div class="tbl-col b-bl">
                            <p class="p-default">Min Delta Turns</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Max Delta Turns</p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Min Shoulder slope </p>
                        </div>
                        <div class="tbl-col b-bl">
                            <p class="p-default">Max Shoulder slope</p>
                        </div>
                        <div class="tbl-col b-bl b-right">
                            <p class="p-default">Jaws type</p>
                        </div>
    
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="forPdf">
    <div class="tbl-row main-row-pdf">
        <div class="tbl-col b-bl" style="flex: 9 9">
            <div class="tbl-row b-bottom main-title-pdf">
                <p class= "p-pdf">Make-up Torque</p>
            </div>
            <div class="tbl-row title-pdf">
                <div class="tbl-col b-right">
                    <p class= "p-pdf">Min {{unit}}</p>
                </div>
                <div class="tbl-col b-right">
                    <p class= "p-pdf">Opti {{unit}}</p>
                </div>
                <div class="tbl-col">
                    <p class= "p-pdf">Max {{unit}}</p>
                </div>
            </div>
        </div>
        <div class="tbl-col b-bl" style="flex: 6 6">
            <div class="tbl-row b-bottom main-title-pdf">
                <p class= "p-pdf">Shoulderig Torque</p>
            </div>
            <div class="tbl-row title-pdf" >
                <div class="tbl-col b-right">
                    <p class= "p-pdf">Min {{unit}}</p>
                </div>
                <div class="tbl-col">
                    <p class= "p-pdf">Max {{unit}}</p>
                </div>
            </div>
        </div>
        <div class="tbl-col b-bl" style="flex: 3 3">
            <p class= "p-pdf">Max Torque with Sealability {{unit}}</p>
        </div>
        <div class="tbl-col b-bl" style="flex: 3 3">
            <p class= "p-pdf">MTV {{unit}}</p>
        </div>
        <div class="tbl-col b-bl" style="flex: 3 3">
            <p class= "p-pdf">Max liner Torque {{unit}}</p>
        </div>
        <div class="tbl-col b-bl" style="flex: 3 3">
            <p class= "p-pdf">Max allowed Torque {{unit}}</p>
        </div>
        <div class="tbl-col b-bl" style="flex: 3 3">
            <p class= "p-pdf">Shale operating Torque {{unit}}</p>
        </div>
        <div class="tbl-col b-bl" style="flex: 4 4">
            <div class="tbl-row b-bottom main-title-pdf" >
                <p class= "p-pdf">Delta Turns</p>
            </div>
            <div class="tbl-row title-pdf" >
                <div class="tbl-col b-right">
                    <p class= "p-pdf">Min</p>
                </div>
                <div class="tbl-col">
                    <p class= "p-pdf">Max</p>
                </div>
            </div>
        </div>
        <div class="tbl-col b-bl" style="flex: 4 4">
            <div class="tbl-row b-bottom main-title-pdf" >
                <p class= "p-pdf">Shoulder slope factor</p>
            </div>
            <div class="tbl-row title-pdf" >
                <div class="tbl-col b-right">
                    <p class= "p-pdf">Min</p>
                </div>
                <div class="tbl-col">
                    <p class= "p-pdf">Max</p>
                </div>
            </div>
        </div>
        <div class="tbl-col b-bl b-right jaw-pdf">
            <p class= "p-pdf">Jaws type</p>
        </div>
    </div>
</ng-container>