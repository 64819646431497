<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h1><span class="mat-h1" style="cursor: pointer" (click)="navigateToBasicProductData()">Basic Product Data</span>
        | <span class="mat-h1" style="font-weight: bold">Detailed document</span></h1>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <tsli-item></tsli-item>
      <sidebar-nav></sidebar-nav>
    </div>
    <div class="col-9 scrollable-content">
      <tsli-content></tsli-content>
    </div>
  </div>
</div>
