import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {AuthService} from '../../core/auth.service';
import {TsliRestService} from 'src/app/core/tsli-rest.service';
import {ActivityResponse} from 'src/app/core/data/activity-response';
import {ActivityEvent} from '../../core/enum/activity-event.enum';
import {Status} from 'src/app/core/enum/status.enum';
import {TsliMode} from '../../core/enum/tsli-mode.enum';
import {CurrentTsliStoreService} from '../../core/current-tsli-store.service';
import {CreateDocumentComponent} from '../manage-document/create-document/create-document.component';
import {CreateArchivedDocumentComponent} from '../manage-document/create-archived-document/create-archived-document.component';

@Component({
  selector: 'writer-side-bar',
  templateUrl: './writer-side-bar.component.html',
  styleUrls: ['./writer-side-bar.component.scss']
})
export class WriterSideBarComponent implements OnInit {

  activities: ActivityResponse[] = [];
  activatedTsliMode: TsliMode;

  constructor(public auth: AuthService,
              public tsliRest: TsliRestService,
              public router: Router,
              public modal: NgbModal,
              // public tsliStore: TslisStoreService,
              public currentTsliStore: CurrentTsliStoreService) {
  }

  ngOnInit(): void {
    this.tsliRest.getActivities().subscribe(activities => {
      this.activities = JSON.parse(JSON.stringify(activities));
      this.activities.forEach(
        act => {
          if (this.auth.user.value.isReviewer && act.newStatus == Status.REVIEWING) {
            act.event = ActivityEvent.SENT_TO_REVIEW_REVIEWER;
          } else if (this.auth.user.value.isApprover && act.newStatus == Status.APPROBATING) {
            act.event = ActivityEvent.VALIDATED_REVIEW_APPROVER;
          } else if ((this.auth.user.value.isAuthor
              || this.auth.user.value.isReviewer
              || this.auth.user.value.isApprover)
            && act.oldStatus == Status.APPROBATING && act.newStatus == Status.PUBLISHED) {
            act.event = ActivityEvent.APPROVED;
          }
        });
      this.activities = this.activities.slice(0, 5);
    });
  }

  createDocument(): void {
    const modalRef = this.modal.open(CreateDocumentComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.activatedTsliMode = TsliMode.creatingMode;
    this.currentTsliStore.setActivatedMode(TsliMode.creatingMode);
  }

  createArchivedDocument(): void {
    const modalRef = this.modal.open(CreateArchivedDocumentComponent, {size: 'lg', centered: true, backdrop: 'static'});
    modalRef.componentInstance.activatedTsliMode = TsliMode.creatingMode;
    this.currentTsliStore.setActivatedMode(TsliMode.creatingMode);
  }

  editDocument(): void {
    this.router.navigate(['/tsli/edit/drafts'], { queryParams: { tabId: 0 } });
  }

  validateDocument(): void {
    this.router.navigate(['/tsli/edit/drafts'], { queryParams: { tabId: 1 } });
  }

  download(filename: string, docType: string, reference: string) {
    this.tsliRest.downloadDisplayAndSourceFile(filename, docType, reference).subscribe(
      response => {
        window.open(response, '_self');
      });
  }

}
