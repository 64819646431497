import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Tsli} from '../../core/data/tsli';
import {ErrorBarComponent} from '../error-bar/error-bar.component';
import {TslisStoreService} from '../../core/tslis-store.service';
import {TsliRestService} from '../../core/tsli-rest.service';
import {AuthService} from 'src/app/core/auth.service';
import {User} from 'src/app/core/data/User';
import {UserRestService} from 'src/app/core/user-rest.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modify-author-stepper',
  templateUrl: './modify-author-stepper.component.html',
  styleUrls: ['./modify-author-stepper.component.scss']
})
export class ModifyAuthorStepperComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  idTsli: number;
  authors: User[];

  typeDescriptionText: string;

  commitSuccessful = false;
  commitFailed = false;
  selectedReviewer;
  document: Tsli;

  public readonly TYPE_DESCRIPTION_TEXT: object = {
    process: 'Lorem process ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    product: 'Lorem product ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    general_information: 'Lorem gi ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
  };
  idUser: string;

  constructor(public formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              public tslisStore: TslisStoreService,
              public rest: TsliRestService,
              public userRest: UserRestService,
              public snackBar: MatSnackBar,
              public authService: AuthService,
              public router: Router) {

    this.idUser = authService.getIdUser();


  }

  ngOnInit(): void {
    this.thirdFormGroup = this.formBuilder.group({
      authorSelectCtrl: ['', Validators.required]
    });

    // change descriptive text based based on select value

    // this.userRest.getAllowedUsers(this.documentId).subscribe((usersResponse: User[]) => {
    //   this.authors = usersResponse.filter(user => user.isAuthor);
    // });
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  onClose(): void {
    this.activeModal.close();
  }

  modifyAuthor(): void {
    const tsli: Tsli = new Tsli();
    tsli.reviewer = new User();
    tsli.reviewer.id = this.thirdFormGroup.controls.authorSelectCtrl.value;

    this.authService.user.subscribe(user => {
      tsli.author = user;
    });


    this.tslisStore.modifyAuthor(this.idTsli, tsli.reviewer.id).subscribe(
      (Reponse: string) => {
        this.commitSuccessful = true;
        this.router.navigate(['/tsli/edit/drafts']);


      },
      (error: any) => {
        console.log(error);
        this.displayError('An HTTP error occurred! ', error.status, error.statusText);
        this.commitFailed = true;
      }
    );
  }

  displayError(msg: string,
               statusCode: string,
               statusText: string) {
    this.snackBar.openFromComponent(ErrorBarComponent, {
      duration: 5000,
      data: {
        msg,
        statusCode,
        statusText
      }
    });
  }

}
