<div class='c'>
<!--  <div class='_401'>{{errorCode}}</div>-->
  <div class="title" data-content="404">
    ACCESS DENIED
  </div>
  <hr>
  <div class='_1'>{{page}}</div>
  <div class='_2' [innerHTML]="errorMessage"></div>

  <!--  <div class="row mt-2">-->
  <!--    <div class="col text-center">-->
  <!--      <button mat-button color="primary" (click)="goBack()">{{ "Back" | uppercase }}</button>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
