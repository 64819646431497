import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Notification } from 'src/app/core/data/Notification';
import { NotificationService } from 'src/app/core/notification.service';

@Component({
  selector: 'notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {

  dataSource = new MatTableDataSource<Notification>([]);
  notification = {'title': '', 'content': ''};
  selected;


  //data: Observable<Tsli[]>;
  displayedColumns: string[] = ["title", "content"];

  constructor(public store: NotificationService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if(params.has('notif')){
        this.selected = params.get('notif');
      }
    });
    this.store.getAllNotitifications().subscribe(v=>{
      let notif = v["notifications"]
      this.dataSource.data = notif
      if(this.selected != undefined && this.selected != 0)
        this.notification = notif.filter(n=> n.id == this.selected)[0]
      else if(this.selected == 0 && notif.length > 0)
        this.notification = notif[0]
    });
  }

  selectNotif(idNotif){
    this.selected = idNotif;
    this.notification = this.dataSource.data.filter(n=> n.id == this.selected)[0]
  }

}
