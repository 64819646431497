<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h1><span class="mat-h1" style="cursor: pointer" (click)="navigateToBasicProductData()">Basic Product Data</span>
        | <span class="mat-h1" style="font-weight: bold">Detailed TSLI</span></h1>
    </div>
    <div class="col">
      <button class="float-right" mat-stroked-button *ngIf="tsli.status === 'published'"
              (click)="showHistory()" style="margin-top: 10px;">{{"See History"}}</button>
      <!--<button class="float-right" mat-stroked-button
        style="margin-right: 10px; margin-top: 10px;">{{"Compare Revision"}}</button>
      -->
      <button class="float-right" mat-stroked-button
              *ngIf="tsli?.status != 'published'"
              (click)="download()"
              style="margin-right: 10px; margin-top: 10px;">{{"Download"}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <tsli-item></tsli-item>
      <sidebar-nav></sidebar-nav>
    </div>
    <div id="content" class="col-9 scrollable-content">
      <div *ngIf="tsli?.status != 'published' &&  tsli?.status !='waiting for approval' &&  tsli?.status !='archived'" class=" sticky-top rotated">
        <div class=" banner text-center">
          <h1> TSLI DRAFT</h1>
        </div>
      </div>
      <div *ngIf="tsli?.status == 'waiting for approval'" class=" sticky-top rotated">
        <div class=" banner text-center">
          <h1> TSLI NOT APPROVED</h1>
        </div>
      </div>
      <div *ngIf="tsli?.status == 'archived'" class=" sticky-top rotated">
        <div class=" banner text-center">
          <h1 style="width: 90%;"> THIS IS NOT THE APPLICABLE REVISION</h1>
        </div>
      </div>
      <tsli-content></tsli-content>
    </div>
  </div>
</div>

<!-- to update by this code
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h1><span class="mat-h1" style="cursor: pointer" (click)="navigateToBasicProductData()">Basic Product Data</span>
        | <span class="mat-h1" style="font-weight: bold">Detailed document</span></h1>
    </div>
    <div class="col">
      <button class="float-right" mat-stroked-button *ngIf="isPublishedDocument"
              (click)="showHistory()" style="margin-top: 10px;">{{"See History"}}</button>

      <button class="float-right" mat-stroked-button
              *ngIf="!isPublishedDocument"
              (click)="download()"
              style="margin-right: 10px; margin-top: 10px;">{{"Download"}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <tsli-item></tsli-item>
      <sidebar-nav></sidebar-nav>
    </div>
    <div id="content" class="col-9 scrollable-content">
      <div
        *ngIf="!isPublishedDocument &&  !isWaitingApprovalDocument &&  !isArchivedDocument"
        class=" sticky-top rotated">
        <div class=" banner text-center">
          <h1> TSLI DRAFT</h1>
        </div>
      </div>
      <div *ngIf="isWaitingApprovalDocument" class=" sticky-top rotated">
        <div class=" banner text-center">
          <h1> TSLI NOT APPROVED</h1>
        </div>
      </div>
      <div *ngIf="isArchivedDocument" class=" sticky-top rotated">
        <div class=" banner text-center">
          <h1 style="width: 90%;"> THIS IS NOT THE APPLICABLE REVISION</h1>
        </div>
      </div>
      <tsli-content></tsli-content>
    </div>
  </div>
</div>
-->
