<div class="modal-header">
  <h4 class="modal-title" id="modal-title">License light documentation</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body" >
  <p> Are you sure to restrict access to selected documentation for : <span class="text-primary">{{ user }} </span>  ?</p>
</div>



<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="dismiss()">No</button>
  <button  mat-flat-button color='warn' (click)="confirm()">Yes</button>
</div>