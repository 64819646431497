import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthTokenResponse } from './data/auth-token-response';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from './data/User';
import { Roles } from './enum/role.enum';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutDialogComponent } from './dialog/timeout-dialog/timeout-dialog.component';
import { Location } from '@angular/common';
import {Product} from "./data/product";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  login$: Observable<string>;
  firstName$: Observable<string>;
  name$: Observable<string>;
  token: string;
  email$: Observable<string>;
  user = new BehaviorSubject<User>(new User());
  user$ = this.user.asObservable();
  showModelIdle = true;
  timeLeft: number = 60;
  interval;
  modalRef: NgbModalRef
  timeout;
  windowState = true;
  windowActiveDate = new Date();
  modalTimerGlobal = 780;
  modalTimerLaunch = 180;



  routesRoles = {
    'tsli/edit/drafts': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapters-list/edit/:ref': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapters-list/adminedit/:ref': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapters-list/review/:ref': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapters-list/approve/:ref': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapters-list/applicabilityedit/:ref': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapters-list/themeedit/:ref': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/chapter/applicability/:chapterRef': [Roles.ADMIN, Roles.APPROVER, Roles.AUTHOR, Roles.REVIEWER],
    'tsli/admin/users': [Roles.ADMIN]
  }
  static readonly DEBUG: boolean = false;

  readonly API_BASE_URL: string = environment.API_BASE_URL;

  readonly AUTH_TOKEN: string = '/user';
  constructor(public route: ActivatedRoute, public router: Router, public http: HttpClient, public modal: NgbModal,
              private location: Location) {
  }

  verifyToken(resolve=undefined, reject=undefined) {
    this.getUserByToken().subscribe(result => {
      if(result.login != undefined){
        this.firstName$ = of(result.first_name);
        this.name$ = of(result.name);
        this.login$ = of(result.login);
        let user = new User();
        user.id = result.id;
        user.email = result.email;
        user.name = result.name;
        user.isAdmin = result.is_admin == 1;
        user.isApprover = result.is_approver == 1;
        user.isAuthor = result.is_author == 1;
        user.isReader = result.is_reader == 1;
        user.isReviewer = result.is_reviewer == 1;
        user.isLicenseLight = result.is_license_light == 1;

        user.id = result.id;
        user.first_name = result.first_name;
        user.companyName = result.company_name;
        user.licenseeNumber = result.licensee_number;
        user.login = result.login;
        this.user.next(user);
        this.saveLogin(result.name, result.first_name, result.login, user);
      }

      this.idleLogout();
      if (resolve) resolve()

      }, error => {
        this.router.navigate(['tsli/Unauthorized']);
        if (reject) reject()
    });
  }

  Authentication() {
    const params : Record<string, string> = window.location.search.slice(1).split('&').reduce((acc, arg) => {
      const [k, v] = arg.split('=');
      acc[k] = v;
      return acc;
    }, {});
    const token = params.token;
    return new Promise<void>((resolve, reject) => {
      let currentToken = sessionStorage.getItem('token');
      if (currentToken == null) {
        if (!token) {
          this.router.navigate(['tsli/Unauthorized']);
          this.idleLogout();
          resolve()
        } else {
          let newToken = token;
          this.token = newToken;
          this.verifyToken(resolve, reject);
        }
      } else {
        let newToken = token;
        if (newToken != undefined && newToken != currentToken) {
          this.isLogOut();
          window.history.replaceState({}, '', '');
          this.token = newToken;
          this.verifyToken(resolve, reject);
        } else {
          this.getLogin();
          this.idleLogout();
          resolve()
        }
      }
    });
  }

  getUserByToken(): Observable<any> {
    return this.http.get<any>(this.API_BASE_URL + this.AUTH_TOKEN + '/by-token',
      this.getAuthorization());
  }

  getCurrentUserMail() {
    return this.user.value.email;
  }

  getOptions(serviceName = 'service'): object {
    this.getLogin();
    return {
      headers: new HttpHeaders(
        {
          'tsli-auth-token': this.token,
          'tsli-service-name': serviceName
        })
    };
  }

  getAuthorization(serviceName = 'service', params?: HttpParams): object {
    this.getLogin();
    return {
      headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + this.token
          })
    };
  }

  saveLogin(name, firstname, login, user: User) {
    // localStorage.setItem('token', this.token);
    // localStorage.setItem('name', name);
    // localStorage.setItem('firstName', firstname);
    // localStorage.setItem('login', login);
    // localStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('token', this.token);
    sessionStorage.setItem('name', name);
    sessionStorage.setItem('firstName', firstname);
    sessionStorage.setItem('login', login);
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  getLogin() {
    // if (localStorage.getItem('token') == null) {
    //   this.router.navigate(['tsli/Unauthorized']);
    // }
    // if (localStorage.getItem('token') != null && localStorage.getItem('token') != undefined) {
    // this.token = localStorage.getItem('token');
    // this.name$ = of(localStorage.getItem('name'));
    // this.firstName$ = of(localStorage.getItem('firstName'));
    // this.login$ = of(localStorage.getItem('login'));
    // this.user.next(JSON.parse(localStorage.getItem('user')));
    // }
    if (sessionStorage.getItem('token') != null && sessionStorage.getItem('token') != undefined) {
      this.token = sessionStorage.getItem('token');
      this.name$ = of(sessionStorage.getItem('name'));
      this.firstName$ = of(sessionStorage.getItem('firstName'));
      this.login$ = of(sessionStorage.getItem('login'));
      this.user.next(JSON.parse(sessionStorage.getItem('user')));
    }
  }
  getIdUser(): any {
    // if (localStorage.getItem('token') != null && localStorage.getItem('token') != undefined) {
    // return JSON.parse(localStorage.getItem('user'))['id'];
    // }
    if (sessionStorage.getItem('token') != null && sessionStorage.getItem('token') != undefined) {
      return JSON.parse(sessionStorage.getItem('user'))['id'];
    }
  }

  getToken(): string {
    // return localStorage.getItem('token');
    return sessionStorage.getItem('token');
  }

  isLogIn(): boolean {
    // return localStorage.getItem('token') != null ? true : false;
    return sessionStorage.getItem('token') != null ? true : false;
  }
  getCurrentUser(): User {
    let user = new User();
    // user = JSON.parse(localStorage.getItem('user'));
    user = JSON.parse(sessionStorage.getItem('user'));
    return user;
  }

  checkLogIn(token: string): boolean {

    if (token != undefined) {
      this.verifyToken();
    }
    else {
      // return localStorage.getItem('token') != null ? true : false;
      return sessionStorage.getItem('token') != null ? true : false;
    }
  }


  isLogOut() {
    // localStorage.clear();
    sessionStorage.clear();
    clearTimeout(this.timeout);

  }



  hasRole(roles: Roles | Roles[]): Observable<boolean> {

    let rolesAsArray = roles;
    if (!Array.isArray(roles)) rolesAsArray = Array.of(roles);
    return this.user$.pipe(
      map((user) => {
        if (rolesAsArray.includes(Roles.ADMIN) && user.isAdmin) return true;
        else if (rolesAsArray.includes(Roles.APPROVER) && user.isApprover) return true;
        else if (rolesAsArray.includes(Roles.AUTHOR) && user.isAuthor) return true;
        else if (rolesAsArray.includes(Roles.READER) && user.isReader) return true;
        else if (rolesAsArray.includes(Roles.REVIEWER) && user.isReviewer) return true;
        else return false
      }));
  }


  hasRoleToActivateRoute(path: string): Observable<boolean> {
    const roles = this.routesRoles[path];
    if (!roles) return of(true);
    let state_token = true;
    return this.hasRole(roles).pipe(map(res => {
      if (!res) this.router.navigate(['/']);
      return res;
    }));
    // .subscribe((stats)=>{
    // if(!stats){
    // //state_token = stats;
    // this.router.navigate(['tsli/Unauthorized']);
    // return false;
    // }
    // });
    // return state_token;

  }

  isCurrentUserReader() {
    return this.user.value.isReader;
  }

  isReviewerOrApprover() {
    return this.user.value.isReviewer || this.user.value.isApprover;

  }

  isAdmin() {
    return this.user.value.isAdmin;

  }

  watchUser() {

  }

  idleLogout() {
    // console.log("idle user")
    window.onload = () => { this.resetTimer() };
    window.onmousemove = () => { this.resetTimer() };
    window.onmousedown = () => { this.resetTimer() };
    window.ontouchstart = () => { this.resetTimer() };
    window.onclick = () => { this.resetTimer() };
    window.onkeydown = () => { this.resetTimer() };
    window.addEventListener('scroll', () => { this.resetTimer() }, true); // improved; see comments
    this.resetTimer(); // time is in milliseconds

  }

  resetTimer() {
    // console.log("reset timer")
    if(!this.windowState) this.activateWindow();
    this.windowActiveDate = new Date();

    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // console.log("timeout reseted at " + time);
    if (!this.showModelIdle) return
    let modalTimer = this.modalTimerGlobal;
    clearInterval(this.interval)
    this.interval = setInterval((modal) => {
      // console.log("timer value " + modalTimer);

      if (modalTimer == this.modalTimerLaunch) {
        this.showModelIdle = false;
        this.modalRef = modal.open(TimeoutDialogComponent, { size: 'lg', centered: true, backdrop: 'static' });
        this.modalRef.componentInstance.message = "Your Session Will Expire after ( " + modalTimer + " ) secondes";

        this.modalRef.result.then((resp) => {
          this.showModelIdle = true;

          if (resp == 'logout' || resp == 'disconected') {
            this.isLogOut()
            window.location.href = "https://myvam.vamservices.com"
          }
        }).catch((resp) => {
          this.showModelIdle = true;
        });
      }
      else if (modalTimer < this.modalTimerLaunch && modalTimer > 0) {
        this.modalRef.componentInstance.message = "Your Session Will Expire after ( " + modalTimer + " ) secondes";

      } else if (modalTimer == 0) {
        this.modalRef.componentInstance.message = "Your session has been expired";
        this.modalRef.componentInstance.showReconnect = false;
      }
      modalTimer--;
    }, 1000, this.modal, this.showModelIdle);

  }

  showIdleModal(modal, thenCb, catchCb) {

  }

  startLogoutTimer(modal, interval) {
    let lougoutTimeLeft = this.modalTimerLaunch;
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // console.log("interval started at " + time);

    interval = setInterval(() => {
      // console.log("interval time value " + lougoutTimeLeft)
      if (lougoutTimeLeft > 0) {
        // this.modalRef.componentInstance.startTimer();
        modal.componentInstance.message = "Your Session Will Expire after ( " + lougoutTimeLeft + " ) secondes"
        lougoutTimeLeft--;
      } else {
        modal.componentInstance.message = "Your session has been expired"
        modal.componentInstance.showReconnect = false
        // this.timeLeft = 60;
      }
    }, 1000)
  }

  activateWindow(){
    let diffMs = new Date().getTime() - this.windowActiveDate.getTime();
    let diffMins = Math.trunc(((diffMs % 86400000) % 3600000) / 60000);
    // console.log(diffMins)
    if (diffMins >= (this.modalTimerGlobal / 60) && this.showIdleModal) {
      this.showModelIdle = false;
      this.modalRef = this.modal.open(TimeoutDialogComponent, { size: 'lg', centered: true, backdrop: 'static' });
      this.modalRef.componentInstance.message = "Your session has been expired";
      this.modalRef.componentInstance.showReconnect = false;
      this.modalRef.result.then((resp) => {

        if (resp == 'logout' || resp == 'disconected') {
          this.isLogOut()
          window.location.href = "https://myvam.vamservices.com"
        }
      }).catch((resp) => {
        this.showModelIdle = true;
      });
    } else {
      this.windowState = true;
      this.resetTimer();
    }
  }

  deactivateWindow(){
    this.windowState = false;
    // console.log("deactivate timer")
    // this.windowActiveDate = new Date();
    clearInterval(this.interval)


  }

  setModalTimers(modalTimerGlobal, modalTimerLaunch){
    let intModalTimerGlobal: number = +modalTimerGlobal
    let intModalTimerLaunch: number = +modalTimerLaunch

    this.modalTimerGlobal = intModalTimerGlobal;
    this.modalTimerLaunch = intModalTimerLaunch;
    this.resetTimer();
  }

}
