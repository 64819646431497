<div class="header-space">&nbsp;</div>
<!-- pin end -->
<div class="section">
  <div class="row title">Surface Treatment - PIN END</div>
  <div class="row fields-ml">
    <div class="col option"></div>
    <div class="col field">FIELD END</div>
    <div class="col field">MILL END</div>
    <div class="col-md-3"></div>
  </div>
  <div class="row fields-ml">
    <div class="col option"></div>
    <div class="col">
      <div class="row" style="margin-left: 0;">
        <div class="col end-bar" [style.background-color]="COLORS[i]" *ngFor="let end of PIN_END; let i = index"> <span
          class="rotated" [style.color]='TEXT_COLORS[i]'>{{end.value}}</span></div>
      </div>
    </div>
    <div class="col">
      <div class="row" style="margin-left: 0;">
        <div class="col end-bar" [style.background-color]="COLORS[i]" *ngFor="let end of PIN_END; let i = index"> <span
          class="rotated" [style.color]='TEXT_COLORS[i]'>{{end.value}}</span></div>
      </div>
    </div>
    <div class="col-md-3 comments-title"><span class="comments">comments</span></div>
  </div>
  <div class="row fields-ml opt-border" *ngFor="let option of options; let j = index">
    <div class="col option">Option {{j + 1}}</div>
    <div class="col">
      <div class="row" style="margin-left: 0;">
        <div class="col" style="padding: 0px;" *ngFor="let field of option.fieldEnd; let i = index">
          <surface-treatment-icon [value]="field" [comment]="option.comment"></surface-treatment-icon>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row" style="margin-left: 0;">
        <div class="col" style="padding: 0px;" *ngFor="let mill of option.millEnd; let i = index">
          <surface-treatment-icon [value]="mill" [comment]="option.comment"></surface-treatment-icon>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <span class="comment-break">{{option.comment}}</span>
    </div>
  </div>
</div>

<div class="header-space">&nbsp;</div>
<!-- box end -->
<div class="section">
  <div class="row title  box-title">Surface Treatment - BOX END</div>
  <div class="row fields-ml">
    <div class="col option"></div>
    <div class="col">
      <div class="row" style="margin-left: 0;">
        <div class="col end-bar" [style.background-color]="COLORS[i]" [ngClass]="{'end-bar-border': i == 10 || i == 11}"
             *ngFor="let end of BOX_END; let i = index"> <span class="rotated"
                                                               [style.color]='TEXT_COLORS[i]'>{{end.value}}</span></div>
      </div>
    </div>
    <div class="col-md-3 comments-title"><span class="comments">comments</span></div>
    <div class="col-md-2"></div>
  </div>
  <div class="row fields-ml opt-border" *ngFor="let option of options; let j = index">
    <div class="col option">Option {{j + 1}}</div>
    <div class="col">
      <div class="row" style="margin-left: 0;">
        <div class="col" style="padding: 0px;" *ngFor="let field of option.boxEnd; let i = index">
          <surface-treatment-icon [value]="field" [comment]="option.comment"></surface-treatment-icon>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <span class="comment-break">{{option.comment}}</span>
    </div>
  </div>
</div>

