<div class="modal-header">
  <h4 class="modal-title" id="modal-title"><mat-icon color='warn'>warning</mat-icon>  Warning</h4>
</div>


<div class="modal-body" >
  <p><strong>  Application date  {{applicationDate}} is overdue from publication date </strong></p>
</div>



<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="continue()">Continue</button>
  <button  mat-flat-button color='warn' (click)="reject()"><span *ngIf='validationMode'>Reject</span></button>
  <button  mat-stroked-button color='basic' (click)="dismiss()"><span>Cancel</span></button>
</div>
