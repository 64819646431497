import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, forkJoin, of, empty} from 'rxjs';
import {ApplicabilityResponse} from './data/applicability-response';
import {catchError, first, flatMap, map, tap} from 'rxjs/operators';
import {ApplicabilityItem} from './data/applicability-item';
import {AuthService} from './auth.service';
import {ServicesNamesConstants} from './services-names.constants';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';

// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ApplicabilityService {
  public static readonly DEBUG = true;
  public name: string;

  public readonly APPLICABILITY: string = '/applicability';
  public readonly API_APPLICABILITY_BASE_URL: string = environment.API_BASE_URL;
  public readonly API_LOCAL_BASE_URL: string = environment.API_BASE_LOCAL_URL;

  public productItemsNames = {
    'product type': 'product_type',
    'Product Type': 'product_type',
    'design options': 'option_name',
    'Design Options': 'option_name',
    'Connection': 'connection',
    'OD': 'od',
    'WT': 'wt',
    'Grade': 'grade',
    'Customer': 'customer',
    'Lubricant': 'lubricant'
  };
  newApplicability: boolean = false;

  constructor(public http: HttpClient,
              public authservice: AuthService,
              public router: Router) {
    if (ApplicabilityService.DEBUG) {
      this.name = this.constructor.name;
    }
  }

  /**
   * Return products characteristic values
   * for a characteristic named <item>
   * and parents <parents>
   *
   * @param body
   * @param octgOrNonOctg
   */
  getItemValues(body: { item: string, parents: { name: string, value: string | number }[], fromFilter: boolean }, octgOrNonOctg: boolean = undefined): Observable<any> {
    if (this.productItemsNames[body.item] !== undefined) {
      body.item = this.productItemsNames[body.item];
    }

    body.parents.forEach(parent => {
      if (this.productItemsNames[parent.name] !== undefined) {
        parent.name = this.productItemsNames[parent.name];
      }
    });
    if (octgOrNonOctg != undefined) {
      body['octgOrNonOctg'] = octgOrNonOctg;
    }
    let start: number;

    if (ApplicabilityService.DEBUG) {
      console.log(`-- ${this.name}.getItemValues`);
      console.log(`body:`, body);
      start = new Date().getTime();
    }
    return this.http.post<string[]>(
      this.API_APPLICABILITY_BASE_URL + '/bpd_bulk/load-filter-data',
      body,
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ITEM_VALUE)).pipe(
      map((response: any): any => {
        return response;
      }),
      tap(() => {
        if (ApplicabilityService.DEBUG) {
          console.log(`-- ${this.name}.getItemValues executed in ${new Date().getTime() - start}ms`);

        }
      }),
      catchError((err: any) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
          return empty();
        }
        console.warn('-- some error occurred');
        return of([body.item + 'foo', body.item + 'bar', body.item + 'baz']);
      })
    );
  }

  getProduct(isOctg: boolean = null){
    let body = {"item": "product",
      "parents": [], "fromFilter": true, "isOctg": isOctg
    };
    return this.http.post<string[]>(
      this.API_APPLICABILITY_BASE_URL + '/bpd_bulk/load-filter-data',
      body,
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ITEM_VALUE)
      ).pipe(
      map((response: any): any => {
        return response;
      })
    );
  }

  getVisibleProducts(){
    return this.http.get<string[]>(
      this.API_APPLICABILITY_BASE_URL + '/bpd_bulk/visible-products',
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ITEM_VALUE)
      ).pipe(
      map((response: any): any => {
        return response;
      })
    );
  }

  getLubricant(product: string){
    let body = {"product": product}
    return this.http.get<string[]>(
      this.API_APPLICABILITY_BASE_URL + `/bpd_bulk/load-lubricant`,
      {params: body,
        ...this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ITEM_VALUE)
      }).pipe(
      map((response: any): any => {
        return response;
      })
    );
  }

  downloadPOCNS(body: Object){
    return this.http.post<any>(
      `${this.API_APPLICABILITY_BASE_URL}/download-catalog-data`,
      body,
      {...this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ITEM_VALUE), responseType: "text" as "json"}).pipe(
      ((response: any) => {
        return response;
      }),
      catchError((error) => {
          return of({
            err: "an error occurred",
            response: error,
          });
        })
    );
  }

  /**
   * Fetch saved (existing) applicability from API
   *
   * @param idTsli
   * @param idChapter
   */
  getSavedApplicability(idTsli: string,
                        idChapter: number): Observable<ApplicabilityResponse> {
    if (ApplicabilityService.DEBUG) {
      console.log(`-- getSavedApplicability()`);
    }
    return this.http.get<ApplicabilityResponse>(
      this.API_APPLICABILITY_BASE_URL + this.APPLICABILITY + `/load-applicability?id_tsli=${idTsli}&id_chapter=${idChapter}`,
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_SAVED)).pipe(
      map((response: any): ApplicabilityResponse => {
        if (ApplicabilityService.DEBUG) {
          console.log(`- returned saved applicability:`, response.items[0]);
        }

        return response.items[0];
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  getApplicabilityOrder(idTsli: string,
                        idChapter: number): Observable<any[]> {
    return this.http.get<ApplicabilityResponse>(
      this.API_APPLICABILITY_BASE_URL + this.APPLICABILITY + `/load-applicability?id_tsli=${idTsli}&id_chapter=${idChapter}`,
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ORDER)).pipe(
      map((response: any): any => {
        return response.items[0].order;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }


  getApplicabilityByFilters(parents: ApplicabilityFilter[], nextFilter: string, isOCTG: boolean, fromFilter: boolean): Observable<(string | number)[]> {
    let body = {
      "parents": parents,
      "item": nextFilter,
      "octgOrNonOctg": isOCTG,
      "fromFilter": fromFilter
    }

    return this.http.post<ApplicabilityResponse>(this.API_APPLICABILITY_BASE_URL + "/bpd_bulk/load-filter-data",
      body,
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_BY_FILTER)).pipe(
        map((response: any): any => {
          return response;
        })
        , catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        }));
  }


  /*
  * Get an applicability subtree or complete tree
  * - loads item possible values
  * - loads existing applicability
  * Merge into another and voilà.
  *
  * Called on first level,
  * or on click when collapsing
  *
  * itemParents:
  * path to the subtree
  *
  */


  getApplicability(itemName: string,                                            // current characteristic name: connection | od | wt ...
                   itemParents: { name: string, value: string | number }[],     // parent filters if exists
                   idTsli: string,
                   idChapter: number,
                   allChildrenChecked: boolean = false,
                   fromFilter: boolean = false): Observable<any> {              // fromFilter: used to configure list of products to display by admin
    if (ApplicabilityService.DEBUG) {
      console.log(`-- ${this.name}.getApplicability()`);
    }

    const items /* : ApplicabilityItem[] */ = [];
    return forkJoin(
      this.getItemValues({item: itemName, parents: itemParents, fromFilter: fromFilter}),
      this.getSavedApplicability(idTsli, idChapter)
    ).pipe(map(([availableItems, savedApplicability]: [any, ApplicabilityResponse]) => {
      availableItems.forEach(item => {
        if (typeof (item) === 'object') {
          items.push({                    // ApplicabilityItems[]
            name: item['value'] + '',
            value: item['value'],
            type: itemName,
            checked: false,
            isCollapsed: false,
            children: item.children,
            indeterminate: false,
            allChildrenChecked: false,
            isSpecific: itemParents['connection'],
            customer_information: item['customer_information'],
            drawing: item['drawing']
          });
        } else {
          items.push({                    // ApplicabilityItems[]
            name: item + '',
            value: item,
            type: itemName,
            checked: false,
            isCollapsed: false,
            children: item.children,
            indeterminate: false,
            allChildrenChecked: false
          });
        }
      });

      // if (savedApplicability.theme === undefined) {
      //   savedApplicability.theme = 'endSizing';
      // }

      this.mergeItemsAndSavedApplicability(items, savedApplicability, itemParents, allChildrenChecked);
      return {items: items, theme: savedApplicability.theme};
    }));
  }

  /**
   * Merge a list of empty ApplicabilityItem[]
   * with the saved applicability
   *
   * @param items
   * @param savedApplicability
   * @param itemParents
   * @param allChildrenChecked
   */
  public mergeItemsAndSavedApplicability(items,                  // available values : 0.362, 0.650, ...
                                         savedApplicability: ApplicabilityResponse,     // toute l'applicabilité
                                         itemParents,            // [{New Pipe},{VAM21},{5}]
                                         allChildrenChecked: boolean) {
    if (ApplicabilityService.DEBUG) {
      console.log(`-- ${this.name}.mergeItemsAndSavedApplicability()`);
    }

    let applicability = savedApplicability.applicability; // [...]
    if (applicability !== undefined) {
      let subApplicability: ApplicabilityItem[] = applicability;

      // if a parents filter was passed, filter down the saved applicability to this specific subtree
      if (itemParents.length > 0) {
        for (let i = 0; i < itemParents.length; i++) {
          subApplicability = subApplicability.filter((applicabilityItem: ApplicabilityItem) => applicabilityItem.name == itemParents[i].value.toString());
          if (subApplicability.length > 0) {
            subApplicability = subApplicability[0].children;
          } else {
            console.warn(`no subApplicability for ${itemParents[i].value}`);
            console.log(`itemParents:`, itemParents);
            break;
          }
        }
      } else {
        console.warn(`no itemParents passed`);
      }

      // replace applicability by subApplicability if one exists
      if (subApplicability.length > 0 && subApplicability[0] !== undefined && subApplicability[0].type == items[0].type) {
        applicability = subApplicability;
      } else {
        if (subApplicability.length > 0) {
          console.log(`subApplicability does not fit OK: ${subApplicability[0].type}, ${items[0].type}`);
        } else {
          console.warn(`subApplicability is empty`);
        }
      }

      // assign properties to existing items
      items.forEach(item => {

        let savedItem: ApplicabilityItem = undefined;
        if (!this.newApplicability) {
          savedItem = applicability.find((applicabilityItem: ApplicabilityItem) => {
            console.log('test ::', applicabilityItem.name, ' ==', item.name);
            return applicabilityItem.name == item.name;
          });
        }

        if (savedItem !== undefined) {
          item.isCollapsed = false;
          item.indeterminate = savedItem.indeterminate;
          item.checked = savedItem.checked;
          item.children = savedItem.children;
          if (itemParents['connection'] != undefined && item.customer_information != undefined) {
            item.isSpecific = itemParents['connection'].split('-SPECIFIC').length == 2 ? itemParents['connection'].split('-SPECIFIC')[0] : '';
          }
          item.allChildrenChecked = savedItem.allChildrenChecked;
        } else {
          console.log(`no applicability found for ${item.name}/${item.value}`);
          console.log(`applicability:`, applicability);
          item.checked = item.allChildrenChecked = allChildrenChecked;
        }
      });
    } else if (allChildrenChecked) {
      items.forEach(item /* ApplicabilityItem */ => item.checked = item.allChildrenChecked = true);
    }
  }

  /**
   * Save applicability, calling the API
   *
   */
  saveApplicability(idTsli: string,
                    applicability: ApplicabilityItem[],
                    order: string[],
                    chapters_ids?: string[]): Observable<{ message: string, error: string, requestId: string }> {
    if (ApplicabilityService.DEBUG) {
      console.log(`-- ApplicabilityService.saveApplicability()`);
    }
    let body;


    // TSLI Product
    if (chapters_ids !== undefined) {
      body = {
        id_tsli: idTsli,
        chapters_ids: chapters_ids,
        applicability,
        order
      };
      // TSLI Process
    } else {
      body = {
        id_tsli: idTsli,
        applicability,
        order
      };
    }

    if (ApplicabilityService.DEBUG) {
      console.log(`- body: `, body);
    }

    body['headers'] = {'tsli-service-name': ServicesNamesConstants.APPLICABILITY_SAVE, 'tsli-auth-token': this.authservice.getToken()};
    return this.getPresignedUrl().pipe(flatMap(resp => {
      console.log('presigned URL : ' + resp['url']);
      return this.http.put<{ message: string, error: string, requestId: string }>(resp['url'], JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }, (r1) => r1)).pipe(map((response) => {
        console.log(response);
        return {message: 'string', error: 'string', requestId: response['requestId']};
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));
  }

  getSavingResult(requestId: string) {
    return this.http.get<{ result: string }>(
      this.API_APPLICABILITY_BASE_URL + this.APPLICABILITY + `/saving_result?aws_request_id=${requestId}`,
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_SAVED)).pipe(
      map((response: any): { result: string } => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return empty();
      }));

  }

  keepLambdaWarm() {
    this.http.get<ApplicabilityResponse>(
      this.API_APPLICABILITY_BASE_URL + this.APPLICABILITY + '/keepwarm',
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_ORDER)).subscribe(resp => resp);
  }

  // splitItemsToSave(applicability: ApplicabilityItem[]) {
  //   applicability.forEach(a => {
  //
  //   });
  // }

  getPresignedUrl() {
    return this.http.get<any>(
      this.API_APPLICABILITY_BASE_URL + this.APPLICABILITY + '/presigned_url',
      this.authservice.getAuthorization(ServicesNamesConstants.APPLICABILITY_GET_SAVED)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`])
        }
        return empty();
      }));

  }

}


export class ApplicabilityFilter {
  name: string;
  value: string;
}
