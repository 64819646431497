import { Component, OnInit } from '@angular/core';
import { BasicProductsDataConstants } from '../../basic-product-data/BasicProductsDataConstants';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'pdf-tds-data',
  templateUrl: './tds-data.component.html',
  styleUrls: ['./tds-data.component.scss']
})
export class TdsDataComponent implements OnInit {

  PIN_END = [];
  BOX_END = []
  options = [];

  COLORS = ['#E5E5E5', '#CCCCCC', '#B2B2B2', '#999999', '#7F7F7F', '#666666'
    , '#4C4C4C', '#333333', '#191919', '#000000', '#FFFFFF', '#FFFFFF'];
  TEXT_COLORS = ['#000000', '#000000', '#000000', '#000000', '#FFFFFF',
   '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#000000', '#000000']
  constructor(public store: BasicProductDataStoreService) {
    this.PIN_END = BasicProductsDataConstants.ENDS;
    this.BOX_END = BasicProductsDataConstants.FM_ENDS;
  }

  ngOnInit() {
    /* get TDS from backend */
    this.store.surfaceTreatments$.subscribe(value=>{
      if(value.length > 0)
        this.options = value[0].options;
    });
  }

}
