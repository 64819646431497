import {Component} from '@angular/core';
import {BasicProductDataStoreService} from '../../core/basic-product-data-store.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Product} from '../../core/data/product';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DownloadRequestDialogComponent } from 'src/app/core/dialog/download-request-dialog/download-request-dialog.component';
import { AuthService } from 'src/app/core/auth.service';



/**
 * Page component for detailed TSLIs rowsSelection screens
 * - by item
 * - by reference
 * - by connection
 * - by process
 *
 */
@Component({
  selector: 'detailed-tsli',
  templateUrl: './detailed-tsli.component.html',
  styleUrls: ['./detailed-tsli.component.scss']
})
export class DetailedTsliComponent {

  readonly options = ['connection', 'reference', 'item', 'process']
  selectedOption = this.options[1];
  theme = '';
  connection = '';
  currentUser;

  constructor(public basicProductsDataStore: BasicProductDataStoreService,
              public detailedTSLI : DetailedTsliStore,
              public router: Router,
              public modal: NgbModal,
              public route: ActivatedRoute,
              public auth: AuthService) {

  }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.detailedTSLI.selectedOption$.subscribe(value =>{
      if(value != "") this.selectedOption = value;
    });

    this.route.paramMap.subscribe((params: ParamMap) =>{
      this.theme = params.get('theme');
      this.connection = params.get('connection');
      if(this.theme != undefined) this.selectedOption = this.options[3];
      else if (this.connection != undefined) this.selectedOption = this.options[0];
      this.detailedTSLI.setSelectedOption(this.selectedOption);
    });
  }

  /**
   * Navigate to Basic Product Data,
   * behavior depends on displayed page:
   * - by item: navigate to item BPD page
   * - by connection: navigate to connection BPD page
   * - etc.
   *
   */
  navigateToBasicProductData(): void {
    this.router.navigate([`tsli/basic-product-data`]);
  }

  switchOption(index) {
    this.detailedTSLI.setSelectedOption(this.options[index]);
    this.router.navigate([`/tsli/details`]);
  }

  downloadAll(type, value=undefined){
    this.detailedTSLI.generateTsliListPdf(type, value).subscribe(resp=>{
      if(resp.success != undefined && resp.success.message == 'pdf.generation.initialized'){
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, { size: 'lg', centered: true, backdrop:'static' });
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_1;
        modalRef.componentInstance.msg =  modalRef.componentInstance.MSG_1.replace('<email>', this.auth.getCurrentUser()['email'])
                                          + modalRef.componentInstance.MSG_3;
      }
      else{
        const modalRef: NgbModalRef = this.modal.open(DownloadRequestDialogComponent, { size: 'lg', centered: true, backdrop:'static' });
        modalRef.componentInstance.tittle = modalRef.componentInstance.TITLE_2;
        modalRef.componentInstance.msg = modalRef.componentInstance.MSG_2;
      }
    });
  }
}
