import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Configuration } from 'src/app/core/data/configuration';
import { ConfigurationService} from 'src/app/core/configuration.service';


@Component({
  selector: 'configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  email = '';
  dataSource = new MatTableDataSource<Configuration>([]);
  displayedColumns = ['group', 'property', 'value']
  currentSort: Sort;
  rowsSelection = new SelectionModel<Configuration>(true, []);
  constructor(public configurationService: ConfigurationService,
    public modal: NgbModal
  ) { }

  ngOnInit() {
    this.configurationService.getAllConfigurations().subscribe(config => {
      this.dataSource.data = config["configurations"]
    });
  }

  updateValue(config, newConfig): void {
    if (newConfig.value == null) { return; }
    config.value = newConfig.value;
    console.log(config)
    this.configurationService.updateConfiguration(config).subscribe(resp => {
      console.log('updateValue:', resp);
  })
  }

  stripHtmlTags(html: string): string {
    let div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
}
