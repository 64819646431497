import {Component, OnInit, Input, ViewChild, ChangeDetectorRef} from '@angular/core';

@Component({
  selector: 'bpd-surface-treatment',
  templateUrl: './surface-treatment.component.html',
  styleUrls: ['./surface-treatment.component.scss']
})
export class SurfaceTreatmentComponent {
  paddingRight = 0;
  activePadding: boolean = false;

  constructor(public cdRef: ChangeDetectorRef) {

  }

  ngAfterViewChecked(): void{
    const element = document.getElementById("productsRows");
    if(element != null) {
      this.paddingRight = element.offsetWidth - element.clientWidth;
    }
    this.cdRef.detectChanges();
  }

}
