import {Component} from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'surface-treatment-header',
  templateUrl: './surface-treatment-header.component.html',
  styleUrls: ['./surface-treatment-header.component.scss']
})
export class SurfaceTreatmentHeaderComponent {

  constructor(public basicProductDataStore: BasicProductDataStoreService) {
   }

  ngOnInit() {
   }


  switchSelectedTab(event) {
    this.basicProductDataStore.switchSurfaceTreatment(event.index);
  }

}
