<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Browse Basic Product Data by connection</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h3 class="mat-headline mt-3 text-center">Please pick a Connection</h3>
    </div>
  </div>

  <div class="row">
    <div class="col ">
      <mat-grid-list cols="6">
        <mat-grid-tile *ngFor="let connection of connections">
          <!--<mat-card style="height:100%;width:100%;display:flex;justify-content:center;align-items:center;margin-bottom:16px;">-->
          <mat-card style="display:flex;justify-content:center;align-items:center;">
            <a href="tsli/poc-ns/{{connection}}">
                <span >{{connection}}</span></a>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
