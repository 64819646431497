<div class="row mt-3">
  <div class="col" style="padding-left: 16px; margin-left: 16px">
    Please select the product of your choice below
   </div>
</div>
<div class="row connections">
  <div class="col-md-3 connection-card" *ngFor="let connection of connections">
    <mat-card style="display:flex;justify-content:center;align-items:center;"
     class="connection" (click)="showTSLI(connection)">
      <a>
          <span>{{connection}}</span></a>
    </mat-card>
  </div>



     <!-- <mat-card class="connection" (click)="showTSLI(connection)">
      <img mat-card-image src="assets/img/logo-connection/{{connectionLogo[connection]}}">
    </mat-card> -->
