import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog-archive',
  templateUrl: './confirm-dialog-archive.component.html',
  styleUrls: ['./confirm-dialog-archive.component.scss']
})
export class ConfirmDialogArchiveComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  reference: string;
  revision: number;
  docType: string

  ngOnInit() {
  }

  dismiss() {
    this.activeModal.dismiss('dissmissed');
  }

  confirm() {
    this.activeModal.close('confirmed');
  }

}
