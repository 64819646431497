<div *ngIf="user.isLicenseLight" class="row" style="margin-left: 15px; margin-top: 10px;">Select access to documentation in "License light reader"
  mode for : {{user.first_name}} {{user.name}}</div>
<div *ngIf="!user.isLicenseLight" class="row" style="margin-left: 15px; margin-top: 10px;">Select right for : {{user.first_name}} {{user.name}}</div>
<div class="row" style="margin-left: 30px; margin-top: 15px;" *ngIf="!user.isReviewer">
  Partial reader
  <mat-slide-toggle [checked]="user.isLicenseLight" style="margin-left: 10px;" color="primary"
    (change)="switchPartialLicenseLight()" (click)="$event.stopPropagation()">License light reader</mat-slide-toggle>

</div>
<ng-container *ngIf="user.isLicenseLight">
  <div class="row" style="margin-left: 10px;">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput type="text" [(ngModel)]="searchedExp" (ngModelChange)="searchInData()">
      <button mat-button *ngIf="searchedExp" matSuffix mat-icon-button aria-label="Clear"
        (click)="searchedExp=''; searchInData()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-checkbox color="primary" (change)="$event ? filterSelected() : null" class="checkbox-all"
      style="margin-top: 25px; margin-left: 35px;">
      Selected
    </mat-checkbox>
  </div>

  <table mat-table [dataSource]="dataSource" style="width: 90%;">

    <!-- Select column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" class="checkbox-all"
          [checked]="rowsSelection.hasValue() && isAllSelected()"
          [indeterminate]="rowsSelection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? rowsSelection.toggle(row) : null" [checked]="rowsSelection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- title-->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.title" #tooltip="matTooltip">{{element.title}}</td>
    </ng-container>

    <!-- Reference column -->
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef>Reference</th>
      <td mat-cell *matCellDef="let element">{{element.docType | uppercase}}{{ " - " }}{{element.reference}}</td>
    </ng-container>

    <!-- Revision column -->
    <ng-container matColumnDef="revision">
      <th mat-header-cell *matHeaderCellDef>Revision</th>
      <td mat-cell *matCellDef="let element">{{element.revision}}</td>
    </ng-container>

    <!-- Application Date column -->
    <ng-container matColumnDef="applicationDate">
      <th mat-header-cell *matHeaderCellDef>Application Date</th>
      <td mat-cell *matCellDef="let element">{{ element.applicationDate | date : "dd/MM/y" }}</td>
    </ng-container>

    <!-- Type column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{element.type}}</td>
    </ng-container>

    <!-- View Action column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="view(element)">{{
         element.source_file_s3_url != undefined ? "Download" : "View"}}</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row  *matRowDef="let row; columns: displayedColumns;" (click)="rowsSelection.toggle(row)"></tr>
  </table>

  <div class="row mt-2">
    <div class="col text-center">
      <button mat-button (click)="viewMore()"
        [disabled]="dataSource.data.length == cachedTslis.length || showSelected">VIEW MORE</button>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col text-center">
      <button mat-raised-button (click)='save()'>Save</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!user.isLicenseLight">
  <!-- <div class="row">
    <h3 class="mat-headline text-center" style="margin-left:2% ;">Please pick up connections for this user</h3>
  </div>  -->
  <mat-form-field class="example-full-width" style="margin-left: 10px;">
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="searchText" (input)="filters()">
  </mat-form-field>
  <div class="row" style="margin-left:20px; margin-top: 50px;">
    <ng-container *ngFor="let connection of connectionsfilter">
      <mat-checkbox color="primary" class="col-md-3" style="justify-content:center;align-items:center;"
        (click)="$event.stopPropagation()" (change)="$event ? rowsConnectionSelection.toggle(connection) : null"
        [checked]="rowsConnectionSelection.isSelected(connection)">
        {{connection}}
      </mat-checkbox>
    </ng-container>
  </div>
  <div class="row mt-2" style="margin-top: 50px;">
    <div class="col text-center">
      <button mat-raised-button (click)="commitProductsSelection()">Validate</button>
    </div>
  </div>
</ng-container>
