<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Create a new revision</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Application date & approval</ng-template>
      <div class="modal-header">
        <div class="container">
          <div class="row">
            <div class="col" style="width: 400px">
              <label>Current Application Date:</label>
            </div>
            <div class="col" style="width: 400px">
              {{ document.applicationDate | date: 'dd/MM/y' }}
            </div>
          </div>
          <div class="row">
            <div class="col" style="width: 400px">
              <label>Current Reviewer:</label>
            </div>
            <div class="col" style="width: 400px">
              {{ document.reviewer.first_name + ' ' + document.reviewer.name }}
            </div>
          </div>
          <div class="row">
            <div class="col" style="width: 400px"><label>Current Approver:</label></div>
            <div class="col">
              {{ document.approver.first_name + ' ' + document.approver.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <h3 class="mat-headline text-center">Please enter the Application date</h3>
        <div class="text-center">
          <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Application date"
                   formControlName="applicationDateCtrl"
                   required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field class="mr-3">
          <mat-select placeholder="Choose an reviewer"
                      [(ngModel)]="selectedReviewer"
                      formControlName="reviewerSelectCtrl"
                      required>
            <mat-option *ngFor="let reviewer of reviewers"
                        [value]="reviewer.id" [hidden]="reviewer.id == selectedApprover || reviewer.id == idUser  ">
              {{ reviewer.first_name}} {{reviewer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Choose an approver"
                      [(ngModel)]="selectedApprover"
                      formControlName="approverSelectCtrl"
                      required>
            <mat-option *ngFor="let approver of approvers"
                        [value]="approver.id" [hidden]="approver.id == selectedReviewer || approver.id == idUser">
              {{approver.first_name}} {{approver.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>

      </div>
      <h5 class="mat-headline text-center">You are about change application date and/or validation process, Y/N ? </h5>
    </form>
    <div class="text-center footer">
      <button mat-flat-button matStepperNext color="primary" class="mr-3" (click)="onClose()">Cancel</button>
      <button mat-flat-button matStepperNext (click)="commitTsli()" class="mr-3" color="primary">Validate</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirmation</ng-template>
    <ng-container *ngIf="!activatedDataReloading">
      <h3 class="mat-headline text-center">You are about change application date and/or validation process </h3>
      <div class="spinner text-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="activatedDataReloading && !commitFailed">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p> The operation was successful</p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onValid()">OK !</button>
      </div>
    </ng-container>

    <ng-container *ngIf="commitFailed">
      <h3 class="mat-headline text-center">An error occurred!</h3>
      <div class="text-center">
        <p>the operation was not be created!</p>
        <p>{{errorMsg}}</p>
        <mat-icon>mood_bad</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">Cancel</button>
      </div>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
