<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Create New Revision</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header  justify-content-center">
  <div class="row">
    <div class="col">
      <label>Title:</label>
    </div>
    <div class="col">
      <input matInput id="docTitle" [value]="documentToRevise.title" readonly
             style="width: 400px; text-align: left">
    </div>

    <div class="col">
      <label>Reference:</label>
    </div>
    <div class="col">
      <input matInput id="docReference" [value]="documentToRevise.reference" readonly style="text-align: left">
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button mat-flat-button color="primary" style="margin-right: 10px" (click)="createRevisionToArchive()">Create Previous Revision</button>
  <button mat-flat-button color="primary" style="margin-right: 10px" (click)="CreateRevision()">Create Next Revision</button>
</div>
