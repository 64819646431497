import { Component, OnInit, Input } from '@angular/core';
import { Tds } from 'src/app/core/data/tds';

@Component({
  selector: 'surface-treatment-icon',
  templateUrl: './surface-treatment-icon.component.html',
  styleUrls: ['./surface-treatment-icon.component.scss']
})
export class SurfaceTreatmentIconComponent implements OnInit {

  @Input()
  value:number;
  @Input()
  comment: string;

  constructor() { }

  ngOnInit() {
  }

}
