export class ServicesNamesConstants {
  //applicability services
  static readonly APPLICABILITY_GET_ITEM_VALUE = "APPLICABILITY_GET_ITEM_VALUE"
  static readonly APPLICABILITY_GET_SAVED = "APPLICABILITY_GET_SAVED"
  static readonly APPLICABILITY_GET_ORDER = "APPLICABILITY_GET_ORDER"
  static readonly APPLICABILITY_GET_BY_FILTER = "APPLICABILITY_GET_BY_FILTER"
  static readonly APPLICABILITY_SAVE = "APPLICABILITY_SAVE"

  //product services
  static readonly PRODUCT_GET_PRODUCTS = "PRODUCT_GET_PRODUCTS"
  static readonly PRODUCT_GET_DRAWING_CUSTOMER_INFORMATION = "PRODUCT_GET_DRAWING_CUSTOMER_INFORMATION"
  static readonly PRODUCT_UPDATE_PRODUCTS_VISIBILITY = "PRODUCT_UPDATE_PRODUCTS_VISIBILITY"

  //TSLI services
  static readonly TSLI_CREATE_NEW_REVISION = "TSLI_CREATE_NEW_REVISION"
  static readonly TSLI_VALIDATE_REF = "TSLI_VALIDATE_REF"
  static readonly TSLI_CREATE = "TSLI_CREATE"
  static readonly TSLI_GET_ALL = "TSLI_GET_ALL"
  static readonly TSLI_GET_BY_PARAM = "TSLI_GET_BY_PARAM"
  static readonly TSLI_GET_BY_REF = "TSLI_GET_BY_REF"
  static readonly TSLI_GET_BY_PRODUCT = "TSLI_GET_BY_PRODUCT"
  static readonly TSLI_GET_BY_CONNECTION = "TSLI_GET_BY_CONNECTION"
  static readonly TSLI_UPDATE = "TSLI_UPDATE"
  static readonly TSLI_UPDATE_THEME = "TSLI_UPDATE_THEME"
  static readonly TSLI_UPDATE_STATUS = "TSLI_UPDATE_STATUS"
  static readonly TSLI_ACTIVITIES = "TSLI_ACTIVITIES"
  static readonly TSLI_CREATE_COMMENT = "TSLI_CREATE_COMMENT"
  static readonly TSLI_UPDATE_COMMENT = "TSLI_UPDATE_COMMENT"
  static readonly TSLI_GET_ALL_COMMENTS = "TSLI_GET_ALL_COMMENTS"
  static readonly TSLI_DELETE_COMMENTS = "TSLI_DELETE_COMMENTS"
  static readonly TSLI_DELETE_REVISION = "TSLI_DELETE_REVISION"
  static readonly TSLI_UPDATE_SKILLS_MATRIX_USER = "TSLI_UPDATE_SKILLS_MATRIX_USER"
  static readonly TSLI_UPDATE_FIRST_ENTRY = "TSLI_UPDATE_FIRST_ENTRY"
  static readonly TSLI_UPDATE_IS_LEGACY = "TSLI_UPDATE_IS_LEGACY"
  static readonly TSLI_UPDATE_NO_HTML_DOCUMENT = "TSLI_UPDATE_NO_HTML_DOCUMENT"

  //upload services
  static readonly UPLOAD_UPLOAD = "UPLOAD_UPLOAD"

  //user services
  static readonly USERS_GET_USERS = "GET_USERS"
  static readonly USERS_GET_SKILLS_MATRIX_USERS = "GET_SKILLS_MATRIX_USERS"
  static readonly USERS_UPDATE_USER = "UPDATE_USER"
  static readonly USERS_DELETE_USERS = "DELETE_USERS"
  static readonly USERS_UPDATE_USER_MAIL = "UPDATE_USER_MAIL"

  //notification services
  static readonly NOTIFICATION_GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
  static readonly NOTIFICATION_GET_NOTIFICATION = "GET_NOTIFICATION"
  static readonly NOTIFICATION_SAVE_NOTIFICATION = "SAVE_NOTIFICATION"
  static readonly NOTIFICATION_UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
  static readonly NOTIFICATION_DELETE_NOTIFICATION = "DELETE_NOTIFICATION"

  //configuration services
  static readonly CONFIGURATION_GET_CONFIGURATIONS = "GET_CONFIGURATIONS"
  static readonly CONFIGURATION_GET_CONFIGURATION = "GET_CONFIGURATION"
  static readonly CONFIGURATION_SAVE_CONFIGURATION = "SAVE_CONFIGURATION"
  static readonly CONFIGURATION_UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION"
  static readonly CONFIGURATION_DELETE_CONFIGURATION = "DELETE_CONFIGURATION"

  //Document type
  static readonly DOCUMENT_TYPE_FORM="form"
  static readonly DOCUMENT_TYPE_INOR="inor"
  static readonly DOCUMENT_TYPE_UG="ug"

  // S3 URL for documents to upload and download
  static readonly SOURCE_AND_DISPLAY_FILE_GET_SAVED = "SOURCE_AND_DISPLAY_FILE_GET_SAVED"
  static readonly DOC_UPDATE_SOURCE_FILE = "DOC_UPDATE_SOURCE_FILE"
  static readonly DOC_UPDATE_DISPLAY_FILE = "DOC_UPDATE_DISPLAY_FILE"

  //Restricted Access
  static readonly USERS_ADD_RESTRICTED_ACCESS = "USERS_ADD_RESTRICTED_ACCESS"
  static readonly USERS_GET_RESTRICTED_USERS = "USERS_GET_RESTRICTED_USERS"
  static readonly USERS_GET_USERS_TO_RESTRICT = "USERS_GET_USERS_TO_RESTRICT"

}
