<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Modify document</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-horizontal-stepper linear #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup" enctype="multipart/form-data">
      <!--Title-->
      <ng-template matStepLabel>Doc informations</ng-template>
      <h3 class="mat-headline text-center">Please fill in the following information to modify this document</h3>
      <div class="container">
        <div class="row">
          <div class="col">
            <mat-form-field style="width: 100%">
              <mat-select placeholder="Choose document type"
                          formControlName="docTypeCtrl">
                <mat-option *ngFor="let docType of documentTypeValues" [value]="docType">{{documentType.get(docType)}}</mat-option>
                <mat-option></mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Title" formControlName="titleCtrl" required>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Reference No." formControlName="referenceNumberCtrl" required>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Revision No." formControlName="revisionNumberCtrl" required>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col" #dropZoneSourceFileId>
            <mat-label class="input-element">Source file (for internal use):</mat-label>
            <ngx-dropzone [disabled]="isDisabledSourceFileZone" [multiple]="false" (change)="onSelect($event)"
                          formControlName="dropZoneSourceFileCtrl"
                          ngDefaultControl>
              <ngx-dropzone-label>Drop files to attach, or browse</ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of source_files" [file]="f" [removable]="true"
                                    (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
          <div class="col">
            <mat-label class="input-element">File to display (downloadable by licensees):</mat-label>
            <ngx-dropzone [disabled]="isDisabledDisplayFileZone" [multiple]="false"
                          (change)="onSelectDisplayFile($event)"
                          formControlName="dropZoneDisplayFileCtrl" ngDefaultControl>
              <ngx-dropzone-label>Drop files to attach, or browse</ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of display_files" [removable]="true"
                                    (removed)="onRemoveDisplayFile(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" style="margin-left:20% ; margin-top: 10px; width: 500px">
              <mat-label>Revision comments</mat-label>
              <textarea matInput formControlName="commentRevisionCtrl"></textarea>
              <mat-error
                *ngIf="firstFormGroup.controls.referenceNumberCtrl.hasError('required')">{{ " Comment is required " }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6 style="color: #dc3545; font-family:inherit; text-align: left">Note*: if no
              source file, document is to
              be updated as usual TSLI in HTML</h6>
            <h6 style="color: #dc3545; font-family: inherit; text-align: left">Note**: if no File to display and
              embeded
              Source file --> "Source file" would be available for download to every user</h6>
          </div>
        </div>
      </div>
      <div class="text-center footer">
        <button mat-stroked-button class="mr-3" (click)="onCancel()" >Cancel</button>
        <button mat-flat-button matStepperNext color="primary" (click)="goForward()" >Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Doc type</ng-template>
      <h3 class="mat-headline text-center">Please select the type of the new document</h3>
      <div class="text-center">
        <mat-form-field>
          <mat-select placeholder="Type" formControlName="typeSelectCtrl" required [disabled]="isTypeDisabled">
            <mat-option value="form">FORM</mat-option>
            <mat-option value="inor">INOR</mat-option>
            <mat-option value="ugfl">UG for Licensee</mat-option>
            <mat-option value="ugira">UGI for Reviewer-Approver</mat-option>
            <mat-option value="ugia">UGI for Admin</mat-option>
            <mat-option value="process">Process</mat-option>
            <mat-option value="product">Product</mat-option>
            <mat-option value="general_information">General information</mat-option>
            <mat-option value="legacy">Legacy</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 100%">
        <div style="max-width: 450px; margin: 0 auto 2rem auto">
          <p>{{ typeDescriptionText }}</p>
        </div>
      </div>
      <div class="text-center footer">
        <button mat-stroked-button matStepperPrevious class="mr-3">Previous</button>
        <button mat-flat-button matStepperNext color="primary">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup" label="step3">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Application date & approval</ng-template>
      <h3 class="mat-headline text-center">Please enter the Application date</h3>
      <div class="text-center">
        <mat-form-field class="mr-3">
          <input matInput placeholder="Applicable by" formControlName="applicableByCtrl" required
                 [value]="docApplicableBy">
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Application date" formControlName="applicationDateCtrl"
                 required [value]="docApplicationDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="text-center">
        <mat-form-field class="mr-3">
          <mat-select placeholder="Choose a reviewer"
                      [value]="selectedReviewer"
                      formControlName="reviewerSelectCtrl"
                      required
          >
            <mat-option *ngFor="let reviewer of reviewers"
                        [value]="reviewer.id" [hidden]="selectedApprover == reviewer.id || idUser == reviewer.id ">
              {{ reviewer.first_name}} {{reviewer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Choose an approver"
                      [value]="selectedApprover"
                      formControlName="approverSelectCtrl"
                      required>

            <mat-option *ngFor="let approver of approvers"
                        [value]="approver.id" [hidden]="selectedReviewer == approver.id || idUser == approver.id ">
              {{approver.first_name}} {{approver.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="text-center footer">
        <button mat-stroked-button matStepperPrevious class="mr-3">Previous</button>
        <button mat-flat-button matStepperNext (click)="saveDocument()" color="primary">Next</button>
        <button mat-flat-button matStepperNext (click)="sendToReview()" [disabled]="isHTMLDoc"  style="margin-left:2%" color="primary"
        >Send to review
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirmation</ng-template>
    <ng-container *ngIf="commitSuccessful">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p>your new document has been created</p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">OK !</button>
      </div>
    </ng-container>
    <ng-container *ngIf="sendToReviewSuccessful">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p>your new document has been created and sent to reviwer</p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">OK !</button>
      </div>
    </ng-container>

    <ng-container *ngIf="commitFailed">
      <h3 class="mat-headline text-center">An error occurred!</h3>
      <div class="text-center">
        <p>your new document could not be created!</p>
        <mat-icon>mood_bad</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">Cancel</button>
      </div>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>

