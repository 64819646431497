import { Component, OnInit, Input } from '@angular/core';
import { BasicProductDataStoreService } from 'src/app/core/basic-product-data-store.service';

@Component({
  selector: 'pdf-assembly-content',
  templateUrl: './pdf-assembly-content.component.html',
  styleUrls: ['./pdf-assembly-content.component.scss']
})
export class PdfAssemblyContentComponent implements OnInit {
  @Input()
  assemblyData;
  imperialOrSI = true;

  constructor(public basicProductDataStore: BasicProductDataStoreService) { }
  
  ngOnInit() {
    this.basicProductDataStore.imperialOrSI$.subscribe(value=>this.imperialOrSI = value);

  }

}
