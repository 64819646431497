import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Tsli} from 'src/app/core/data/tsli';
import {DetailedTsliStore} from 'src/app/core/detailed-tsli-store.service';
import {DetailedTsliConstants} from '../../detailed-tsli/detailed-tsli.constants';

@Component({
  selector: 'pdf-tsli-list',
  templateUrl: './tsli-list.component.html',
  styleUrls: ['./tsli-list.component.scss']
})
export class PDFTsliListComponent implements OnInit {

  constructor(public detailedTsliStore: DetailedTsliStore, public route: ActivatedRoute) {
  }

  tslis = [];
  value;


  ngOnInit() {
    let themes = DetailedTsliConstants.THEMES.map(t => t.key);
    let types = {'ugfl': 7, 'ugira': 6, 'ugia': 5, 'inor': 4, 'form': 3, 'general_information': 2, 'process': 1, 'product': 0};
    let typesNames = [{key: 'ugfl', name: 'User Guide For Licensee'}, {key: 'ugira', name: 'User Guide Internal (Reviewer-Approver)'},
      {key: 'ugia', name: 'User Guide Internal (Admin)'}, {key: 'inor', name: ''}, {key: 'form', name: ''},
      {key: 'legacy', name: 'Legacy'}, {key: 'general_information', name: 'General Information'},
      {key: 'process', name: 'Process'}, {key: 'product', name: 'Product'}];


    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('value')) {
        this.value = params.get('value');
        if (themes.includes(this.value)) {
          this.detailedTsliStore.loadTslisByParam('process', this.value);
        } else {
          this.detailedTsliStore.loadTslisByConnection(this.value);
        }

      } else {
        this.detailedTsliStore.loadTslisByParam('reference');
      }

    });
    this.detailedTsliStore.tslis$.subscribe(tslis => {
      let respTslis = tslis.sort((a, b) => {
        return a.document_type === b.document_type ? types[b.type] - types[a.type] : a.document_type.localeCompare(b.document_type);
      });
      let uniqueDoctypes = respTslis.map(t => t.document_type).filter((v, i, a) => a.indexOf(v) === i);
      uniqueDoctypes.forEach(document_type => {
        typesNames.forEach(type => {
          let typeTslis = respTslis.filter(t => t.document_type == document_type && t.type == type['key']);
          if (typeTslis.length > 0) {
            let groupeTitle: Tsli = new Tsli();
            groupeTitle.title = document_type.toUpperCase() + ' ' + type['name'];
            this.tslis.push(groupeTitle);
            this.tslis.push(...typeTslis);
          }
        });

      });

    });

  }

}
