export class User {
    id: number;
    login: string;
    first_name: string;
    name: string;
    email:string;
    isAdmin: boolean;
    isApprover: boolean;
    isAuthor: boolean;
    isReader: boolean;
    isReviewer: boolean;
    isPartial:boolean;
    isLicenseLight:boolean;
    activated: boolean;
    companyName: string;
    licenseeNumber: string;
    product: string[];
    isVamFamily: boolean;
}



