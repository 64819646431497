<div class="comment-1" [ngClass]="{'comment-blue': !comment.checked, 'comment-green': comment.checked }">
  <div class="row">
    <div class="col">
      <span class="float-left" style="font-weight: bold"><mat-checkbox (change)="checkComment()" color="primary" [checked]="comment.checked" ></mat-checkbox> {{comment.commentator.name}} {{comment.commentator.first_name}}
      </span>
      <span class="float-right">{{comment.commentDate |date: 'dd MMM, yyyy HH:mm:ss'}}</span>
    </div>
  </div>
  <div class="row" style="margin-top: 5px; margin-bottom: 5px;">
    <div class="col">
      {{comment.comment}}
    </div>
  </div>
</div>