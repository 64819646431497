import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {TslisStoreService} from 'src/app/core/tslis-store.service';
import {UserStoreService} from 'src/app/core/user-store.service';
import {UserRestService} from 'src/app/core/user-rest.service';
import {AuthService} from 'src/app/core/auth.service';

@Component({
  selector: 'tsli',
  templateUrl: './tsli.component.html',
  styleUrls: ['./tsli.component.scss']
})
export class TsliComponent implements OnInit {
  selectedTabId: number = 0;

  constructor(public route: ActivatedRoute,
              public tslisStore: TslisStoreService,
              public userStore: UserStoreService,
              public authService: AuthService,
              public userRest: UserRestService) {

  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.selectedTabId = params['tabId'] || 0; // Default to the first tab if no tabId is provided
    });

    this.tslisStore.getAllDocuments().subscribe();
    // this.userStore.getSkillsMatrixUsers();
  }

  switchSelectedTab(event) {
    this.tslisStore.setSelectedTab(event.index);
  }
}
