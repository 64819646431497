<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cross clicked')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-horizontal-stepper linear #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup" enctype="multipart/form-data">
      <!--Title-->
      <ng-template matStepLabel>Doc informations</ng-template>
      <h3 class="mat-headline text-center">Please fill in the following information to create archived document</h3>
      <div class="container">
        <div class="row">
          <div class="col">
            <mat-form-field style="width: 100%">
              <mat-select placeholder="Choose document type"
                          formControlName="docTypeCtrl"
                          [disabled]="isDocTypeDisabled"
                          (selectionChange)="onDocumentTypeChange()">
                <mat-option *ngFor="let docType of documentTypeValues" [value]="docType">{{documentType.get(docType)}}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="firstFormGroup.controls.docTypeCtrl.hasError('required')">Type is required
              </mat-error>
              <mat-error
                *ngIf="firstFormGroup.controls.docTypeCtrl.errors?.revisionNumberInvalidReviseMode">
                Revision No. must be less than {{this.revision}}
              </mat-error>
              <mat-error
                *ngIf="firstFormGroup.controls.docTypeCtrl.errors?.documentExists">
                this Document already exists
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Title" formControlName="titleCtrl" required>
              <mat-error
                *ngIf="firstFormGroup.controls.titleCtrl.hasError('required')">Title is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Reference No." formControlName="referenceNumberCtrl" required>
              <mat-error *ngIf="firstFormGroup.controls.referenceNumberCtrl.hasError('required')">
                Reference No. is required
              </mat-error>
              <mat-error
                *ngIf="firstFormGroup.controls.referenceNumberCtrl.errors?.revisionNumberInvalidReviseMode">
                Revision No. must be less than {{this.revision}}
              </mat-error>
              <mat-error
                *ngIf="firstFormGroup.controls.referenceNumberCtrl.errors?.documentExists">
                this Document already exists
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Revision No." formControlName="revisionNumberCtrl" required>
              <mat-error *ngIf="firstFormGroup.controls.revisionNumberCtrl.hasError('required')">
                Revision is required
              </mat-error>
              <mat-error *ngIf="firstFormGroup.controls.revisionNumberCtrl.hasError('pattern')">
                Must be a positive number
              </mat-error>
              <mat-error
                *ngIf="firstFormGroup.controls.revisionNumberCtrl.errors?.revisionNumberInvalidReviseMode">
                Revision No. must be less than {{this.revision}}
              </mat-error>
              <mat-error
                *ngIf="firstFormGroup.controls.revisionNumberCtrl.errors?.documentExists">
                this Document already exists
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <input matInput [matDatepicker]="picker" placeholder="Application date" formControlName="applicationDateCtrl">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col" #dropZoneSourceFileId>
            <mat-label class="input-element">Source file (for internal use):</mat-label>
            <ngx-dropzone [multiple]="false" (change)="onSelectSourceFile($event)"
                          formControlName="dropZoneSourceFileCtrl"
                          ngDefaultControl [required]="isSourceFileRequired">
              <ngx-dropzone-label>Drop files to attach, or browse</ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of source_files" [file]="f" [removable]="true"
                                    (removed)="onRemoveSourceFile(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
            <mat-error
              *ngIf="firstFormGroup.controls.dropZoneSourceFileCtrl.hasError('required')">Source file is required
            </mat-error>
          </div>
          <div class="col">
            <mat-label class="input-element">File to display (downloadable by licensees):</mat-label>
            <ngx-dropzone [multiple]="false" [disabled]="isDisabledDisplayFileZone"
                          (change)="onSelectDisplayFile($event)"
                          formControlName="dropZoneDisplayFileCtrl" ngDefaultControl>
              <ngx-dropzone-label>Drop files to attach, or browse</ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of display_files" [removable]="true"
                                    (removed)="onRemoveDisplayFile(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" style="margin-left:20% ; margin-top: 10px; width: 500px">
              <mat-label>Revision comments</mat-label>
              <textarea matInput formControlName="commentRevisionCtrl"></textarea>
              <mat-error
                *ngIf="firstFormGroup.controls.referenceNumberCtrl.hasError('required')">
                {{ " Comment is required " }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6 style="color: #dc3545; font-family: inherit; text-align: left">Note*: if no File to display and
              embedded Source file --> "Source file" would be available for download to every user</h6>
          </div>
        </div>
      </div>
      <div class="text-center footer">
        <button mat-stroked-button class="mr-3" (click)="onCancel()">Cancel</button>
        <button mat-flat-button matStepperNext color="primary" (click)="goForward(stepper)">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Doc type</ng-template>
      <h3 class="mat-headline text-center">Please select the type of the new document</h3>
      <div class="text-center">
        <mat-form-field>
          <mat-select placeholder="Type" [disabled]="isTypeDisabled" formControlName="typeSelectCtrl" required>
            <mat-option value="form" [hidden]="this.docType != 'form'">FORM</mat-option>
            <mat-option value="inor" [hidden]="this.docType != 'inor'">INOR</mat-option>
            <mat-option value="ugfl" [hidden]="this.docType != 'ug'">UG for Licensee</mat-option>
            <mat-option value="ugira" [hidden]="this.docType != 'ug'">UGI for Reviewer-Approver</mat-option>
            <mat-option value="ugia" [hidden]="this.docType != 'ug'">UGI for Admin</mat-option>
            <mat-option value="process" [hidden]="this.docType == 'ug'">Process</mat-option>
            <mat-option value="product" [hidden]="this.docType == 'ug'">Product</mat-option>
            <mat-option value="general_information" [hidden]="this.docType == 'ug'">General information</mat-option>
            <mat-option value="legacy">Legacy</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 100%">
        <div style="max-width: 450px; margin: 0 auto 2rem auto">
          <p>{{ typeDescriptionText }}</p>
        </div>
      </div>

      <div class="text-center footer">
        <button mat-stroked-button matStepperPrevious class="mr-3">Previous</button>
        <button mat-flat-button matStepperNext color="primary" (click)="saveDocument()">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Confirmation</ng-template>
    <ng-container *ngIf="commitSuccessful">
      <h3 class="mat-headline text-center">Congratulations !</h3>
      <div class="text-center">
        <p>your new document has been archived</p>
        <mat-icon>mood</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">OK !</button>
      </div>
    </ng-container>
    <ng-container *ngIf="commitFailed">
      <h3 class="mat-headline text-center">An error occurred!</h3>
      <div class="text-center">
        <p>your new document could not be created!</p>
        <mat-icon>mood_bad</mat-icon>
      </div>
      <div class="text-center footer">
        <button mat-flat-button matStepperNext color="primary" (click)="onClose()">Cancel</button>
      </div>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
