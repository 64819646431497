<div class="modal-header">
  <h4 class="modal-title" id="modal-title"><mat-icon color='warn'>warning</mat-icon>  Save {{docType | uppercase}} content</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body" >
  <p> Are you sure to change the content of the <span class="text-primary">{{docType |uppercase }} {{reference }} {{'rev.' + revision}}</span> </p>
  <p><span class="text-danger">This operation can not be undone.</span></p>
</div>



<div class="modal-footer">
  <button mat-stroked-button color='basic' (click)="dismiss()">No</button>
  <button  mat-flat-button color='warn' (click)="confirm()">Yes</button>
</div>