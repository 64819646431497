<ng-container *ngIf="!showPDF && !showWatermark && !showSummary && !showTslisList">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <a routerLink=""><span><img alt="vam" src="assets/img/vam.svg"/></span></a>
      <span class="fill-remaining-space"></span>
      <span>
        <button mat-icon-button (click)="openVideo()">
          <img alt="learning" src="assets/img/icons/learning-large.ico" width="34"/>
        </button>
        <button mat-icon-button *ngIf="currentUser?.isAdmin" [hidden]="!currentUser?.isAdmin" (click)="manageUsers()">
          <mat-icon> supervisor_account</mat-icon>
        </button>
        <button mat-icon-button *ngIf="currentUser?.isAdmin" [hidden]="!currentUser?.isAdmin"
                (click)="manageProductList()">
            <mat-icon>visibility</mat-icon>
          </button>
        <button mat-icon-button *ngIf="currentUser?.isAdmin" [hidden]="!currentUser?.isAdmin"
                (click)="manageConfigurations()">
            <mat-icon> settings</mat-icon>
          </button>
        <button mat-icon-button *ngIf="currentUser?.isAdmin" [hidden]="!currentUser?.isAdmin"
                (click)="createNotifications()">
          <mat-icon> edit_notifications</mat-icon>
        </button>
        <button mat-icon-button
                onClick="window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=1yC3iKFAPUGpZROo72ysqJvic-1qTo5Ei2_9pUmW-utUMFIxNkpaNFNIOTc1V1k1OFRSQVBWRE1RWCQlQCN0PWcu')">
          <mat-icon>feedback</mat-icon>
        </button>
        <!-- <button mat-icon-button>
          <mat-icon>star_border</mat-icon>
        </button> -->
        <!-- <button mat-icon-button matBadge="15" matBadgeColor="accent" matBadgeSize="medium">
          <mat-icon>notifications_none</mat-icon>
        </button> -->
        <mat-form-field *ngIf="environment.demo || environment.dev || switchForInt || switchForQual"
                        style="font-size: 15px;margin-left: 20px;">
          <mat-label>Select user</mat-label>
          <mat-select [(ngModel)]="selectedUser" (ngModelChange)="changeUser($event)">
            <mat-option *ngFor="let user of users" [value]="user.login">{{ user.login }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf=" environment.int || environment.qual || environment.production"
              style="margin-left: 10px; margin-right: 20px;">{{ auth.login$ | async }}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>account_circle</mat-icon>
        </button>
            <mat-menu #menu="matMenu">
                <a mat-flat-button href="https://myvam.vamservices.com">Log out</a>
            </mat-menu>
            </span>
    </mat-toolbar-row>
  </mat-toolbar>
  <router-outlet></router-outlet>
  <div class="app-footer-space"></div>
  <div class="app-footer d-flex justify-content-between">
    <span></span>
    <div id="copyright" [innerHTML]="copyright"></div>
    <span><a href="https://myvam.vamservices.com/appli/Files/LegalNotice.pdf" target="_blank">Legal Notice</a> </span>
    <span><a href="https://myvam.vamservices.com/appli/Files/DataPolicy.pdf" target="_blank">Terms &amp; Conditions</a></span>
    <div id="trademark" [innerHTML]="trademark"></div>
    <span></span>
  </div>
</ng-container>
<ng-container *ngIf="showPDF || showSummary || showTslisList">

  <div id="page-numbers" class="page-numbers">
    <span></span>
  </div>
  <div id="first-page-numbers" class="page-numbers">
  </div>
  <div class="container" id="container">
    <div class="page" id="page" *ngIf="!showSummary">
      <pdf-tsli-infos [showAll]='!showTslisList' [showList]='showTslisList' [listTitle]='pdfListTitle'
                      class="custom-width"></pdf-tsli-infos>

      <pdf-tsli-description></pdf-tsli-description>
    </div>
    <table>
      <thead class="main-head">
      <tr>
        <td>
          <div
            [ngClass]="{'item-header-space' :(byItem || showTslisList) , 'header-space': (!byItem && !showTslisList)}">
            <pdf-tsli-infos [showAll]='false' [showList]='showTslisList' [listTitle]='pdfListTitle'></pdf-tsli-infos>
          </div>
        </td>
      </tr>
      <tr *ngIf="!byItem && maxTitleLength" style="height: 50px;"></tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td>
          <div class="footer-space">&nbsp;</div>
        </td>
      </tr>
      </tfoot>
    </table>

    <div class="header">
      <div class="draft d-flex justify-content-around" *ngIf="(draft || archived) && !byItem && !showTslisList">
        <span>{{watermark}}</span>
        <span>{{watermark}}</span>
        <span>{{watermark}}</span>
        <span>{{watermark}}</span>
        <span>{{watermark}}</span>
        <span>{{watermark}}</span>
      </div>
    </div>
    <div class="footer" *ngIf='!showPages' [innerHTML]="pdfFooter">
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showWatermark">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</ng-container>
