import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'bpd-assembly-data',
  templateUrl: './assembly-data.component.html',
  styleUrls: ['./assembly-data.component.scss']
})
export class AssemblyDataComponent {

  paddingRight = 0;
  constructor(public cdRef:ChangeDetectorRef) {
    
  }

  activePadding: boolean = false;
 
  ngAfterViewChecked(){
    let element = document.getElementById("productsRows");
    if(element != null) {
      this.paddingRight = element.offsetWidth - element.clientWidth;
    }
    this.cdRef.detectChanges();
  }

}
