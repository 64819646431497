<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Add a new expression</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="container">
  <div class="row mt-3">
    <div class="col">
      <h3 class="mat-headline">Expression</h3>
      <div class="d-flex justify-content-between">
        <div><p>Enter an expression in <span class="latex">T<sub>E</sub>X</span> format
          <button class="help-icon" mat-icon-button aria-label="Help about TeX syntax" (click)="openTexSyntax()">
            <mat-icon>help</mat-icon>
          </button>
        </p></div>
        <div>
          <mat-form-field>
            <mat-label>Pick an example...</mat-label>
            <mat-select ngModel (ngModelChange)="examplePicked($event)">
              <mat-option *ngFor="let example of examples" [value]="example.value">
                {{example.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <textarea id="expression" [(ngModel)]="expression" (ngModelChange)="expressionChanged()"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 class="mat-headline">Preview</h3>
      <div id="mathContent" #mathContent [innerHtml]="expressionToParse"></div>
    </div>
  </div>
</div>
<div class="text-center footer mb-3">
  <button mat-stroked-button class="mt-3 mr-3" (click)='cancel()'>{{ 'Cancel' }}</button>
  <button mat-flat-button class="mt-3" color="primary" (click)='emitInsertMathExpression()' [disabled]='expression?.length == 0'>{{ 'Insert' }}</button>
</div>
