<ng-container [ngSwitch]="value">
    <div *ngSwitchCase="2" class="bt text-center">
        <mat-icon style="font-size: 1.2em; color: green; height: unset;">check_circle</mat-icon>
    </div>
    <div *ngSwitchCase="0" class="bt text-center">
        <mat-icon color="warn" style="font-size: 1.2em; height: unset;">cancel</mat-icon>
    </div>
    <div *ngSwitchCase="1" [matTooltip]="comment" class="bt text-center" #tooltip="matTooltip">
        <mat-icon style="font-size: 1.2em; color: orange; height: unset;" (mouseleave)="tooltip.hide()" (mouseenter)="tooltip.hide()" (click)="tooltip.toggle()">announcement</mat-icon>
    </div>
    <div *ngSwitchCase="-2"  class="bt text-center" style="font-size: 60%">
        <a href="https://expertise.vallourec.com/contact-assistance" target="_blank">Contact M. Help</a>
    </div>
</ng-container>
