<div class="row">
    <h1><span class="mat-h1">My Notifications</span></h1>
</div>
<div class="row">
    <div class="col notification">
            <div class="row"><h2>{{notification.title}}</h2></div>
            <div class="row"><div [innerHTML]="notification.content"></div></div>
    </div>
    <div class="col">
        <div class="row">
            <table mat-table [dataSource]="dataSource" style="width: 100%;">

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> Title </th>
                    <td mat-cell *matCellDef="let element" (click)="selectNotif(element.id)" class="table-title" > {{element.title}} </td>
                </ng-container>

                <ng-container matColumnDef="content">
                    <th mat-header-cell *matHeaderCellDef> Content </th>
                    <td mat-cell *matCellDef="let element"> <div class="content" [innerHTML]="element.content">{{element.content}}</div> </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
