<div class="container-fluid">
  <div class="row mt-3">

    <div class="col">
      <div class="row">
        Please create an item below and add to the list to display the associated TSLI
      </div>

      <div class="row">
        <div class="col">
          <div>
            Select your grade type:
          </div>
          OCTG
          <mat-slide-toggle [checked]="!isOCTG" color="primary" (change)="switchOctgNonOctg()"
                            (click)="$event.stopPropagation()">Mechanical
          </mat-slide-toggle>

        </div>
        <div class="col-7" *ngIf="warningDisplay != undefined" [innerHTML]="warningDisplay"></div>
        <div class="col-3">
          <button mat-stroked-button class="float-right ml-3" style="margin-right: 15px;"
                  [disabled]="dataSource.data.length == 0" (click)="clearList()">Clear list
          </button>
          <button mat-stroked-button class="float-right" [disabled]="isFilterSelectionIncomplete()"
                  (click)="addToList()">Add to list
          </button>
          <div class="float-right ml-3" style="margin-right: 15px;">
            SI
            <mat-slide-toggle [checked]="checked" color="primary" (change)="switchImperialOrSI()"
                              (click)="$event.stopPropagation()">Imperial
            </mat-slide-toggle>
          </div>
        </div>
      </div>


    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <table class="products-list" mat-table [dataSource]="dataSource">

        <!-- Select column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" class="checkbox-all" color="primary"
                          [checked]="rowsSelection.hasValue() && isAllSelected()"
                          [indeterminate]="rowsSelection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowsSelection.toggle(row) : null"
                          [checked]="rowsSelection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Connection column -->
        <ng-container matColumnDef="connection">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="connectionMenu">
              {{ getFilterLabel('connection').toUpperCase().includes('SPECIFIC_REQUIREMENT') ? getFilterLabel('connection').toUpperCase().replace('-SPECIFIC_REQUIREMENT', '') : getFilterLabel('connection') }}
              <strong [hidden]="!getFilterLabel('connection').toUpperCase().includes('SPECIFIC_REQUIREMENT')"
                      style="color:crimson"> Specific</strong>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #connectionMenu="matMenu">
              <button mat-menu-item (click)="setFilter('connection', v)"
                      *ngFor="let v of (store.filterValues$ | async).connection">
                {{ v.toUpperCase().includes('SPECIFIC_REQUIREMENT') ? v.toUpperCase().replace('-SPECIFIC_REQUIREMENT', '') : v }}
                <strong [hidden]="!v.toUpperCase().includes('SPECIFIC_REQUIREMENT')" style="color:crimson">
                  Specific</strong>
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.connection.toUpperCase().includes('SPECIFIC_REQUIREMENT') ? element.connection.toUpperCase().replace('-SPECIFIC_REQUIREMENT', '') : element.connection }}
            <strong [hidden]="!element.connection.toUpperCase().includes('SPECIFIC_REQUIREMENT')" style="color:crimson">
              Specific</strong>
          </td>
        </ng-container>

        <!-- OD column -->
        <ng-container matColumnDef="od">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="odMenu">
              {{ getFilterLabel('od') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #odMenu="matMenu">
              <button mat-menu-item (click)="setFilter('od', v)"
                      *ngFor="let v of (store.filterValues$ | async).od">{{ v | fraction }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.od | fraction }}</td>
        </ng-container>

        <!-- WT column -->
        <ng-container matColumnDef="wt">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="wtMenu">
              {{ getFilterLabel('wt') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #wtMenu="matMenu">
              <button mat-menu-item (click)="setFilter('wt', v)"
                      *ngFor="let v of (store.filterValues$ | async).wt">{{ v }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.wt }}</td>
        </ng-container>

        <!-- Grade column -->
        <ng-container matColumnDef="grade">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="gradeMenu">
              {{ getFilterLabel('grade') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #gradeMenu="matMenu">
              <button mat-menu-item (click)="setFilter('grade', v)"
                      *ngFor="let v of (store.filterValues$ | async).grade">{{ v }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.grade }}</td>
        </ng-container>

        <!-- Lubricant column -->
        <ng-container matColumnDef="lubricant">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="lubricantMenu">
              {{ getFilterLabel('lubricant') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #lubricantMenu="matMenu">
              <button mat-menu-item (click)="setFilter('lubricant', v)"
                      *ngFor="let v of (store.filterValues$ | async).lubricant">{{ v }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.lubricant }}</td>
        </ng-container>

        <!-- Product type column -->
        <ng-container matColumnDef="productType">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="productTypeMenu">
              {{ getFilterLabel('productType') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #productTypeMenu="matMenu">
              <button mat-menu-item (click)="setFilter('productType', v)"
                      *ngFor="let v of (store.filterValues$ | async).productType">{{ v }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.productType }}</td>
        </ng-container>

        <!-- Option column -->
        <ng-container matColumnDef="option">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="optionMenu">
              {{ getFilterLabel('option') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #optionMenu="matMenu">
              <button mat-menu-item (click)="setFilter('option', v)"
                      *ngFor="let v of (store.filterValues$ | async).option">{{ v }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.option }}</td>
        </ng-container>

        <!-- Customer column -->
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button [matMenuTriggerFor]="customerMenu">
              {{ getFilterLabel('customer') }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #customerMenu="matMenu">
              <button mat-menu-item (click)="setFilter('customer', v)"
                      *ngFor="let v of (store.filterValues$ | async).customer">{{ v }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.customer }}</td>
        </ng-container>

        <!-- Customer column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="removeFromList(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- View column -->
        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-flat-button color="primary" (click)="view(element)">View</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col text-center">
      <button mat-stroked-button (click)="download()" [disabled]="rowsSelection.isEmpty()">Download</button>
    </div>
  </div>
</div>
