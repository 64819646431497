export class DetailedTsliConstants {
  static readonly PROPERTIES: DetailedTsliProperty[] = [
    {key: 'connection', name: 'Connection', values: ['VAM® 21', 'VAM TOP']},
    {key: 'od', name: 'OD', values: [3.5, 8, 9, 10]},
    {key: 'wt', name: 'WT', values: [0.254, 5, 6, 7]},
    {key: 'grade', name: 'Grade', values: ['C90', 'CM110', 'CM110Y', 'CS130']},
    {key: 'lubricant', name: 'Lubricant', values: ['standard thread compound']},
    {key: 'productType', name: 'Product type', values: ['Pipe', 'Accessories']},
    {key: 'option', name: 'Options', values: ['Regular']},
    {key: 'customer', name: 'Customer', values: ['Total', 'Aramco']}
  ];

  static readonly THEMES: Theme[] = [
    {key: 'designAndEngineering', name: 'Design & engineering', process: 'Design & engineering'},
    {key: 'endSizing', name: 'End sizing', process: 'Stress relieve & end sizing'},
    {key: 'threading', name: 'Threading', process: 'Threading'},
    {key: 'gaugingAndInspection', name: 'Gauging & inspection', process: 'Gauging & inspection'},
    {key: 'surfaceTreatment', name: 'Surface treatment', process: 'Surface treatment'},
    {key: 'lubricant', name: 'Lubricant', process: 'Lubricant'},
    {key: 'makeUp', name: 'Make up', process: 'Make up'},
    {key: 'markingProtectionAndPackaging', name: 'Marking protection and packaging', process: 'Marking protection and packaging'}
  ];
}

export class DetailedTsliProperty {
  key: string;
  name: string;
  values: (string|number)[];
}

export class Theme {
  key: string;
  name: string;
  process: string;
}
