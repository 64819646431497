import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'download-request-dialog',
  templateUrl: './download-request-dialog.component.html',
  styleUrls: ['./download-request-dialog.component.scss']
})
export class DownloadRequestDialogComponent implements OnInit {

  title: string = '';
    TITLE_1: string = 'Congratulations!';
    TITLE_2: string = 'An error occurred!';
  msg: string;
    MSG_1: string = 'you will soon receive an email at <email> with a download link for your request. \n'
    MSG_2: string = 'an error is occured on your personalized download request'
    MSG_3: string = 'Please note, it can take a few  minutes before delivering this email'
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  dismiss() {
    this.activeModal.dismiss('dissmissed');
  }

}
