<div class="tbl-row" style="height: 40px;" *ngFor="let row of assemblyData;">
    <div class="tbl-col b-bl" style="flex: 9 9">
        <div class="tbl-row" style="height: 45px;">
            <div class="tbl-col b-right" [ngSwitch]="imperialOrSI">
                <p *ngSwitchCase="false" class="p-center">{{row.minMakeUpTorque|unitNumberFormat: imperialOrSI}}</p>
                <p *ngSwitchCase="true" class="p-center">{{row.minMakeUpTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
            </div>
            <div class="tbl-col b-right" [ngSwitch]="imperialOrSI">
                <p *ngSwitchCase="false" class="p-center">{{row.optMakeUpTorque|unitNumberFormat: imperialOrSI}}</p>
                <p *ngSwitchCase="true" class="p-center">{{row.optMakeUpTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
            </div>
            <div class="tbl-col" [ngSwitch]="imperialOrSI">
                <p *ngSwitchCase="false" class="p-center">{{row.maxMakeUpTorque|unitNumberFormat: imperialOrSI}}</p>
                <p *ngSwitchCase="true" class="p-center">{{row.maxMakeUpTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
            </div>
        </div>
    </div>
    <div class="tbl-col b-bl" style="flex: 6 6">
        <div class="tbl-row" style="height: 45px;">
            <div class="tbl-col b-right" [ngSwitch]="imperialOrSI">
                <p *ngSwitchCase="false" class="p-center">{{row.minShoulderingTorque|unitNumberFormat: imperialOrSI}}
                </p>
                <p *ngSwitchCase="true" class="p-center">
                    {{row.minShoulderingTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
            </div>
            <div class="tbl-col" [ngSwitch]="imperialOrSI">
                <p *ngSwitchCase="false" class="p-center">{{row.maxShoulderingTorque|unitNumberFormat: imperialOrSI}}
                </p>
                <p *ngSwitchCase="true" class="p-center">
                    {{row.maxShoulderingTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
            </div>
        </div>
    </div>
    <div class="tbl-col b-bl" style="flex: 3 3" [ngSwitch]="imperialOrSI">
        <p *ngSwitchCase="false" class="p-center">{{row.maxTorqueSealability|unitNumberFormat: imperialOrSI}}</p>
        <p *ngSwitchCase="true" class="p-center">{{row.maxTorqueSealability_lbft|unitNumberFormat: imperialOrSI}}</p>
    </div>
    <div class="tbl-col b-bl" style="flex: 3 3" [ngSwitch]="imperialOrSI">
        <p *ngSwitchCase="false" class="p-center">{{row.mtv|unitNumberFormat: imperialOrSI}}</p>
        <p *ngSwitchCase="true" class="p-center">{{row.mtv_lbft|unitNumberFormat: imperialOrSI}}</p>
    </div>
    <div class="tbl-col b-bl" style="flex: 3 3" [ngSwitch]="imperialOrSI">
        <p *ngSwitchCase="false" class="p-center">{{row.maxLinerTorque|unitNumberFormat: imperialOrSI}}</p>
        <p *ngSwitchCase="true" class="p-center">{{row.maxLinerTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
    </div>
    <div class="tbl-col b-bl" style="flex: 3 3" [ngSwitch]="imperialOrSI">
        <p *ngSwitchCase="false" class="p-center">{{row.maxAllowedTorque|unitNumberFormat: imperialOrSI}}</p>
        <p *ngSwitchCase="true" class="p-center">{{row.maxAllowedTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
    </div>
    <div class="tbl-col b-bl" style="flex: 3 3" [ngSwitch]="imperialOrSI">
        <p *ngSwitchCase="false" class="p-center">{{row.shaleOperatingTorque|unitNumberFormat: imperialOrSI}}</p>
        <p *ngSwitchCase="true" class="p-center">{{row.shaleOperatingTorque_lbft|unitNumberFormat: imperialOrSI}}</p>
    </div>
    <div class="tbl-col b-bl" style="flex: 4 4">
        <div class="tbl-row" style="height: 45px;">
            <div class="tbl-col b-right">
                <p class="p-center">{{row.minDeltaTurns|unitNumberFormat: imperialOrSI}}</p>
            </div>
            <div class="tbl-col">
                <p class="p-center">{{row.maxDeltaTurns|unitNumberFormat: imperialOrSI}}</p>
            </div>
        </div>
    </div>
    <div class="tbl-col b-bl" style="flex: 4 4">
        <div class="tbl-row" style="height: 45px;">
            <div class="tbl-col b-right">
                <p class="p-center">{{row.minShoulderSlope|unitNumberFormat: imperialOrSI}}</p>
            </div>
            <div class="tbl-col">
                <p class="p-center">{{row.maxShoulderSlope|unitNumberFormat: imperialOrSI}}</p>
            </div>
        </div>
    </div>
    <div class="tbl-col b-bl b-right" [matTooltip]="row.jaws" #tooltip="matTooltip" style="flex: 8 8; ">
        <p class="p-jaw">{{row.jaws}}</p>
    </div>
</div>