import {Component, OnDestroy, OnInit} from '@angular/core';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import { Product } from 'src/app/core/data/product';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { Tsli } from 'src/app/core/data/tsli';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tsli-item',
  templateUrl: './tsli-item.component.html',
  styleUrls: ['./tsli-item.component.scss']
})
export class TsliItemComponent implements OnInit, OnDestroy {
  public currentItemSubscription: Subscription;
  public byItemSubscription: Subscription;
  public tsliSubscription: Subscription;

  tsliItem: Product;
  byItem: boolean;
  tsli: Tsli;
  constructor(public detailedTsliStore: DetailedTsliStore,
              public store: CurrentTsliStoreService) {
  }

  ngOnInit(): void {
    this.currentItemSubscription = this.detailedTsliStore.currentItem$.subscribe((product: Product) => this.tsliItem = product);
    this.byItemSubscription = this.store.isByItem$.subscribe((byItem: boolean) => this.byItem = byItem);
    this.tsliSubscription = this.store.tsli$.subscribe((value: Tsli) => {
      this.tsli = value;
    });
  }

  ngOnDestroy(): void {
    if(this.currentItemSubscription !== undefined) {
      this.currentItemSubscription.unsubscribe();
    }

    if(this.byItemSubscription !== undefined) {
      this.byItemSubscription.unsubscribe();
    }

    if(this.tsliSubscription !== undefined) {
      this.tsliSubscription.unsubscribe();
    }
  }
}
