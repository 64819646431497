
<div class="container-fluid applicability">

  <div class="row">
    <div class="col">
      <h3 *ngIf="!isShowApplicabilityMode" class="mat-headline mt-3">Please select the parameters below</h3>
    </div>
  </div>

  <div class="row">
        <div class="col-10"></div>
        <div class="col-2">
          <button mat-button disabled color="primary" class="mt-2">
            {{'Import Applicability from'}}
          </button>
        </div>
  </div>

  <div class="row mt-3 mb-3">
    <div class="col">
      <table style="width: 100%; table-layout: fixed;">
        <tr>
          <td *ngFor='let entry of itemEntries ; let i = index' style="padding: 0px; border: 0px;">
            <button mat-button style="width: 100%" (click)="sort(i)">{{entry.name}}
              <span aria-hidden="true" [ngSwitch]="entry.order">
                <mat-icon *ngSwitchCase="'asc'" style="position: relative; top: 6px;">arrow_drop_up</mat-icon>
                <mat-icon *ngSwitchCase="'desc'" style="position: relative; top: 6px;">arrow_drop_down</mat-icon>
              </span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <applicability-item #applicabilityItems
        *ngFor="let item of items"
        [item]="item"
        [cols]="7"
        [chapterIndex]= "chapterIndex"
        [parents] = "parents"
        [itemsOrder] = "itemEntries"
        (callEnableValidateButton)='callEnableValidateButton()'></applicability-item>
    </div>
  </div>
  
</div>
<footer class="d-flex justify-content-between text-center mt-3 footer">

  <div></div>
  <div>
    <button *ngIf="!isShowApplicabilityMode" mat-flat-button color="primary" (click)= 'validateApplicability()'>{{ 'Validate applicability' | uppercase }}</button>
    <button *ngIf="!isShowApplicabilityMode" mat-flat-button color="primary" class="ml-2" (click)= 'cancelApplicability()'>{{ 'cancel' | uppercase }}</button>
    <button *ngIf="isShowApplicabilityMode" mat-flat-button color="primary" class="ml-2" (click)= 'cancelApplicability()'>{{ 'close' | uppercase }}</button>
  </div>
  <div>
    <button mat-flat-button (click)="closeAll()">{{ "Close all" }}</button>
  </div>
</footer>
