import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as CKEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'editvalue',
  templateUrl: './editvalue.component.html',
  styleUrls: ['./editvalue.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EditvalueComponent implements OnInit {
  isEditEnable = false;
  isHtmlContent = false; // Indique si le contenu est en HTML
  oldValue: string;

  @Input() value: string; // Valeur initiale
  @Output('validate') validate = new EventEmitter<string>(); // Émet la valeur validée

  public Editor = CKEditor;
  public valueFormGroup: FormGroup;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    // Initialisation du formulaire
    this.valueFormGroup = this.formBuilder.group({
      valueCtrl: [this.value || '']
    });
    this.isHtmlContent = this.checkIfHtml(this.value);
  }

  edit(): void {
    this.oldValue = this.value;
    this.isEditEnable = true;
  }

  save(): void {
    const updatedValue = this.valueFormGroup.get('valueCtrl').value;
    this.validate.emit(updatedValue);
    this.value = updatedValue;
    this.isEditEnable = false;
  }

  cancel(): void {
    console.log('cancel', this.oldValue);
    this.valueFormGroup.patchValue({ valueCtrl: this.oldValue });
    this.isEditEnable = false;
  }

  checkIfHtml(content: string): boolean {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlRegex.test(content);
  }
}
