import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CurrentTsliStoreService } from 'src/app/core/current-tsli-store.service';
import { DetailedTsliStore } from 'src/app/core/detailed-tsli-store.service';
import { Product } from 'src/app/core/data/product';
import { DetailedTsliConstants } from '../../detailed-tsli/detailed-tsli.constants';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'pdf-tsli-infos',
  templateUrl: './tsli-infos.component.html',
  styleUrls: ['./tsli-infos.component.scss']
})
export class TsliInfosComponent implements OnInit {
  byItem = false;
  tsli;
  tsliItem;
  currentDate = new Date();
  author: string = '';
  reviewer: string = '';
  approver: string = '';
  // draft: boolean = false;
  // archived: boolean = false;
  // watermark = '';
  
  @Input() showAll: boolean = false;
  @Input() showList: boolean =false;
  @Input() listTitle ='';


   

  constructor(public currentTsli: CurrentTsliStoreService,  public route: ActivatedRoute,
    public detailedTsliStore: DetailedTsliStore) { 


    }

  ngOnInit() {

    this.currentTsli.isByItem$.subscribe(value=> {
      this.byItem = value || JSON.parse(sessionStorage.getItem('summaryByItem'));
     
    });
    this.detailedTsliStore.currentItem$.subscribe((product: Product) => this.tsliItem = product);
    this.currentTsli.tsli$.subscribe(value=> {
      this.tsli = value;
      if(this.tsli.author) this.author = this.tsli.author.name + '.' +this.tsli.author.first_name;
      if(this.tsli.reviewer) this.reviewer = this.tsli.reviewer.name + '.' +this.tsli.reviewer.first_name;
      if(this.tsli.approver) this.approver = this.tsli.approver.name + '.' +this.tsli.approver.first_name;
      // this.archived = this.tsli.status == 'archived';
      // this.draft = this.tsli.status != 'published' && !this.archived;
      // if(this.draft) {
      //   this.watermark = ' DRAFT '
      // }
      // else if(this.archived) {
      //   this.watermark = ' NOT APPLICABLE '
      // }
    });

  }


}
