import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CurrentTsliStoreService} from 'src/app/core/current-tsli-store.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MatStepper} from '@angular/material/stepper';
import {TsliRestService} from 'src/app/core/tsli-rest.service';
import {AuthService} from 'src/app/core/auth.service';
import {ApplicabilityService} from 'src/app/core/applicability.service';
import {User} from 'src/app/core/data/User';
import {ConfigurationService} from 'src/app/core/configuration.service';

@Component({
  selector: 'app-create-applicability-stepper',
  templateUrl: './create-applicability-stepper.component.html',
  styleUrls: ['./create-applicability-stepper.component.scss']
})
export class CreateApplicabilityStepperComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  themeDescriptionText: string;
  entryDescriptionText: string;

  // firstEntry: string;
  alert_theme: string = '';
  alert_applicability: string = '';
  configurations = [];
  alert_new_applicability = 'the approver of TSLI has changed the start by item. In order to keep the current applicability you can ask the approver to change the first item to ';
  readonly MAIN_ALERT = 'be aware if a \'Theme\' or a \'Start Data\' is already selected, by changing them, you will erase all previous applicabilitys for others connections  previously made!';
  readonly CHAPTER_THEME_ALERT = 'this chapter has already a THEME if you want to override it please click the next button again';
  readonly CHAPTER_THEME_ALERT_2 = 'You have already set a THEME for this chapter, please ask the approver of the TSLI for Theme Edition';
  readonly CHAPTER_THEME_APPLICABILITY = 'You have already set a first item for this chapter, please ask the approver of the TSLI for Start By modification';
  readonly TSLI_THEME_ALERT = 'this tsli has already a THEME if you want to override it please click the next button again';
  readonly CHAPTERS_THEME_ALERT = 'some of or all the selected chapters has already a THEME if you want to override it please click the next button again';


  readonly CHAPTER_APPLICABILITY_ALERT = 'this chapter has already an Applicability if you want to override it please click the next button again';
  readonly TSLI_APPLICABILITY_ALERT = 'this tsli has already an Applicability if you want to override it please click the next button again';
  readonly CHAPTERS_APPLICABILITY_ALERT = 'some of or all the selected chapters has already an Applicability if you want to override it please click the next button again';

  invalidApplicability: boolean;

  themeFirstValidation = true;
  applicabilityFirstValidation = true;

  @Input() chapterIndex: number;
  @Input() hasApplicability: boolean;
  @Input() theme: string;
  @Input() firstEntryApplicability: string;
  @Input() newFirstEntryApplicability: string;
  currentFirstEntryApplicability: string;


  multipleChapters: boolean = false;
  @ViewChild('stepper', {static: true}) stepper: MatStepper;

  // we'll need to iterate on .keys()
  Object = Object;

  public readonly THEMES: object = {
    designAndEngineering: 'Design & Engineering',
    endSizing: 'End sizing',
    threading: 'Threading',
    gaugingAndInspection: 'Gauging & Inspection',
    surfaceTreatment: 'Surface treatment',
    makeUp: 'Make up',
    lubricant: 'Lubricant',
    markingProtectionAndPackaging: 'Marking protection and Packaging'
  };

  //maybe deprecated - To be checked
  public readonly THEME_DESCRIPTION_TEXT: object = {
    designAndEngineering: 'Lorem process ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.' +
      ' Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    endSizing: 'Lorem product ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    threading: 'Lorem gi ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    gaugingAndInspection: 'gaugingAndInspection',
    surfaceTreatment: 'surfaceTreatment',
    makeUp: 'Make up',
    lubricant: 'lubricant',
    markingProtectionAndPackaging: 'markingProtectionAndPackaging'
  };
  //maybe deprecated - To be checked
  public readonly ENTRY_DESCRIPTION_TEXT: object = {
    connection: 'Lorem process ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    grade: 'Lorem product ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    lubricant: 'Lorem gi ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    productType: 'Lorem process ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    designOptions: 'Lorem product ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    customer: 'Lorem gi ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.' +
      ' Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
  };


  allChaptersHasThemes: boolean = false;
  allChaptersHasApplicabilities: boolean = false;

  oneChaptersHasThemes: boolean = false;
  oneChaptersHasApplicability: boolean = false;
  //idUser:string;
  isThemeStepNextEnabled = false;
  isApplicabilityStepNextEnabled = false;
  skipFirstStep = false;
  skipSecondStep = false;
  disableSecondStep = false;
  validateSecondStep = false;

  currentUser: User;

  constructor(public formBuilder: FormBuilder,
              public rest: TsliRestService,
              public router: Router,
              public activeModal: NgbActiveModal,
              public currentTsliStore: CurrentTsliStoreService,
              public currentuser: AuthService,
              public configService: ConfigurationService,
              public applicabilityService: ApplicabilityService
  ) {


    this.invalidApplicability = true;
    this.currentUser = currentuser.getCurrentUser();

  }

  ngOnInit(): void {
    this.configurations = this.configService.getConfigurationsValues();
    this.alert_theme = this.configurations.filter(x => x.property == 'applicability_main_alert')[0].value;
    this.currentTsliStore.activatedMode.subscribe(v => {
      // this.activatedMode = v;
      let is_approver = this.currentTsliStore.getCurrentTsliAproverId() == this.currentUser.id;
      this.skipFirstStep = (!this.currentUser.isAdmin && !is_approver && this.theme != undefined && (!this.multipleChapters || this.chapterIndex != undefined));
      this.skipSecondStep = v == 'THEME_EDITION_MODE' && !this.hasApplicability;
      this.validateSecondStep = v == 'THEME_EDITION_MODE';
      this.disableSecondStep = (!this.currentUser.isAdmin && !is_approver && this.hasApplicability) || (is_approver && !this.hasApplicability && this.validateSecondStep);
      if (this.skipFirstStep) {
        this.alert_theme = this.CHAPTER_THEME_ALERT_2;
      }
    });
    if (this.newFirstEntryApplicability != this.firstEntryApplicability && this.newFirstEntryApplicability != null) {
      this.currentFirstEntryApplicability = this.newFirstEntryApplicability;
    } else {
      this.currentFirstEntryApplicability = this.firstEntryApplicability;
    }
    this.alert_new_applicability = this.alert_new_applicability + this.firstEntryApplicability + '. You can review the actual Applicabilty by clicking on "Skip", until you validate it, previous ones are saved and you can come back to it';
    if (this.chapterIndex != undefined || !this.multipleChapters) {
      if (this.theme != undefined) {

        //set selected
        this.allChaptersHasThemes = true;
        this.firstFormGroup = this.formBuilder.group({
          themeSelectCtrl: this.theme
        });
      } else {
        this.firstFormGroup = this.formBuilder.group({
          themeSelectCtrl: ['', Validators.required]
        });
      }
      if (this.hasApplicability) {
        this.allChaptersHasApplicabilities = true;
        this.secondFormGroup = this.formBuilder.group({
          firstEntrySelectCtrl: this.currentFirstEntryApplicability
        });
      } else {
        this.secondFormGroup = this.formBuilder.group({
          firstEntrySelectCtrl: ['', Validators.required]
        });
      }
    } else {
      this.currentTsliStore.allChaptersHasThemes().subscribe(value => {
        this.allChaptersHasThemes = value;
        if (!this.allChaptersHasThemes) {
          this.firstFormGroup = this.formBuilder.group({
            themeSelectCtrl: ['', Validators.required]
          });
        } else {
          this.firstFormGroup = this.formBuilder.group({
            themeSelectCtrl: ['']
          });
        }
      });
      this.currentTsliStore.allChaptersHasApplicability().subscribe(value => {
        this.allChaptersHasApplicabilities = value;
        if (!this.allChaptersHasApplicabilities) {
          this.secondFormGroup = this.formBuilder.group({
            firstEntrySelectCtrl: ['', Validators.required]
          });
        } else {
          this.secondFormGroup = this.formBuilder.group({
            firstEntrySelectCtrl: ['']
          });
        }
      });
    }


    this.firstFormGroup.controls.themeSelectCtrl.valueChanges.subscribe((value: string) => {
      this.themeDescriptionText = this.THEME_DESCRIPTION_TEXT[value];
      this.isThemeStepNextEnabled = true;
    });

    this.secondFormGroup.controls.firstEntrySelectCtrl.valueChanges.subscribe((value: string) => {
      this.entryDescriptionText = this.ENTRY_DESCRIPTION_TEXT[value];
      this.isApplicabilityStepNextEnabled = true;
    });

    this.currentTsliStore.oneChaptersHasThemes().subscribe(value => {
      this.oneChaptersHasThemes = value;
    });

    this.currentTsliStore.oneChaptersHasApplicability().subscribe(value => {
      this.oneChaptersHasApplicability = value;
    });
  }

  onCancel(): void {
    this.activeModal.dismiss('cancel clicked');
    this.currentTsliStore.clearTmpListCurrentChapters();

  }

  onClose(): void {
    this.activeModal.dismiss('cross clicked');
    this.currentTsliStore.clearTmpListCurrentChapters();
  }

  // firstEntryChanged(value) {
  //   this.firstEntry = value;
  // }

  validate(): void {
    if (this.applicabilityFirstValidation && this.hasApplicability) {
      if (this.chapterIndex != undefined) {
        this.alert_applicability = this.configurations.filter(x => x.property == 'applicability_chapters_applicability_alert')[0].value;
      } else {
        this.alert_applicability = this.configurations.filter(x => x.property == 'applicability_tsli_applicability_alert')[0].value;
      }
      this.applicabilityFirstValidation = false;
    } else if (this.applicabilityFirstValidation && this.multipleChapters && this.oneChaptersHasApplicability) {
      this.alert_applicability = this.configurations.filter(x => x.property == 'applicability_chapters_applicability_alert')[0].value;
      this.applicabilityFirstValidation = false;
    } else {
      if (this.validateSecondStep) {
        let chapters = undefined;
        if (!this.multipleChapters) {
          if (this.chapterIndex != undefined) {
            chapters = [];
            chapters.push(this.chapterIndex);
          }
          this.currentTsliStore.saveNewFirstEntry(this.secondFormGroup.controls.firstEntrySelectCtrl.value, chapters);
        } else {
          chapters = this.currentTsliStore.getTmpListCurrentChapters();
          this.currentTsliStore.saveNewFirstEntry(this.secondFormGroup.controls.firstEntrySelectCtrl.value, chapters);
        }
        this.router.navigate([`tsli/edit/drafts`], {skipLocationChange: true});
      } else {

        if (this.firstEntryApplicability && this.firstEntryApplicability != this.secondFormGroup.controls.firstEntrySelectCtrl.value) {
          this.applicabilityService.newApplicability = true;
        }
        this.currentTsliStore.setFirstEntry(this.secondFormGroup.controls.firstEntrySelectCtrl.value);
        this.router.navigate([`tsli/chapter/applicability/${this.chapterIndex}`], {skipLocationChange: true});
      }
      this.activeModal.dismiss('completed');
    }
  }

  skipTheme() {
    this.stepper.selectedIndex = 1;
    if (!this.currentUser.isAdmin && this.firstEntryApplicability != undefined) {
      this.alert_applicability = this.CHAPTER_THEME_APPLICABILITY;
    }
  }

  openApplicability() {
    if (this.firstEntryApplicability && this.firstEntryApplicability != this.secondFormGroup.controls.firstEntrySelectCtrl.value) {
      this.applicabilityService.newApplicability = true;
    }
    this.currentTsliStore.setFirstEntry(this.currentFirstEntryApplicability); // clear firstEntry data to force reload from back-end
    this.activeModal.dismiss('completed');
    this.router.navigate([`tsli/chapter/applicability/${this.chapterIndex}`], {skipLocationChange: true});
  }

  validateTheme() {
    if (this.themeFirstValidation && this.theme != undefined && !this.skipFirstStep) {
      if (this.chapterIndex != undefined) {
        this.alert_theme = this.configurations.filter(x => x.property == 'applicability_chapter_theme_alert')[0].value;
      } else {
        this.alert_theme = this.configurations.filter(x => x.property == 'applicability_tsli_theme_alert')[0].value;
      }
      this.themeFirstValidation = false;
    } else if (this.themeFirstValidation && this.multipleChapters && this.oneChaptersHasThemes && !this.skipFirstStep) {
      this.alert_theme = this.configurations.filter(x => x.property == 'applicability_chapters_theme_alert')[0].value;

      this.themeFirstValidation = false;
    } else {
      let chapters = undefined;
      if (!this.multipleChapters) {
        if (this.chapterIndex != undefined) {
          chapters = [];
          chapters.push(this.chapterIndex);
        }
        this.currentTsliStore.updateTheme(this.firstFormGroup.controls.themeSelectCtrl.value, chapters);
        // if(this.hasApplicability) this.openApplicability();
        // else  this.stepper.selectedIndex = 1;
        if (!this.skipSecondStep) {
          this.stepper.selectedIndex = 1;
        } else {
          this.activeModal.dismiss('completed');
        }
      } else {
        chapters = this.currentTsliStore.getTmpListCurrentChapters();
        this.currentTsliStore.updateTheme(this.firstFormGroup.controls.themeSelectCtrl.value, chapters);
        if (!this.skipFirstStep) {
          this.stepper.selectedIndex = 1;
        } else {
          this.activeModal.dismiss('completed');
        }
      }
    }

  }

  skipApplicability() {
    this.activeModal.dismiss('completed');
    if (this.hasApplicability) {
      this.openApplicability();
    }
  }
}


