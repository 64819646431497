<div class="container-fluid" style="max-height: 100%;">
	<div class="row">
		<div class="col">
			<h1><span class="mat-h1" style="font-weight: bold">Basic Product Data | </span>
				<span class="mat-h1" *ngIf="this.productsStore.isEditionMode" style="cursor: pointer" (click)="navigateToTsliByItemOrByReference()">Detailed document</span>
				<span class="mat-h1" *ngIf="!this.productsStore.isEditionMode" >Detailed document</span></h1>
		</div>
	</div>
	<div class="row">
			<div class="col">
			<mat-tab-group #tabs (selectedTabChange)="switchSelectedTab($event)" >
			  <mat-tab label="SURFACE TREATMENT"><bpd-surface-treatment></bpd-surface-treatment></mat-tab>
				<mat-tab label="ASSEMBLY DATA"><bpd-assembly-data></bpd-assembly-data></mat-tab>
				<mat-tab label="THREAD COMPOUND"><bpd-thread-compound></bpd-thread-compound></mat-tab>
			</mat-tab-group>
			</div>
	</div>
</div>
