<div class="modal-header">
  <h4 class="mat-subheading-1 mb-0">Add Chapter Applicability</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Pick a Theme</ng-template>
      <h3 class="mat-headline text-center" *ngIf="!hasApplicability">Please select a Theme</h3>
      <h3 class="mat-headline text-center" *ngIf="hasApplicability">Do you want modify the Theme</h3>
      <div class="text-center">
        <mat-form-field>
          <mat-select placeholder="Theme" formControlName="themeSelectCtrl" [required]="!allChaptersHasThemes" [disabled]="skipFirstStep">
            <mat-option *ngFor="let theme of Object.keys(THEMES)" value="{{theme}}">{{THEMES[theme]}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 100%">
        <div style="max-width: 450px; margin: 0 auto 2rem auto">
          <p class="alert">{{ alert_theme }}</p>
        </div>
      </div>
    </form>
    <div class="text-center footer">
      <button mat-stroked-button class="mr-3" (click)="onCancel()">Cancel</button>
      <button mat-flat-button class="mr-3" *ngIf="!skipFirstStep" (click)="validateTheme()" color="primary" [disabled]="!isThemeStepNextEnabled">{{skipSecondStep?"Validate":"Next"}}</button>
      <button mat-flat-button class="mr-3" color="primary" *ngIf="allChaptersHasThemes && !skipSecondStep" (click)="skipTheme()">Skip</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" *ngIf="!skipSecondStep">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Pick first data field</ng-template>
      <h3 class="mat-headline text-center" *ngIf="!hasApplicability">Please choose the first data field to add the applicability to the current chapter</h3>
        <h3 class="mat-headline text-center" *ngIf="hasApplicability">Do you want modify the first data to modify the applicability</h3>
      <div class="text-center">
        <mat-form-field>
          <mat-select placeholder="I decide to start by" formControlName="firstEntrySelectCtrl" required value="{{firstEntryApplicability}}" [disabled]="disableSecondStep">
            <mat-option value="connection">Connection</mat-option>
            <mat-option value="grade">Grade</mat-option>
            <mat-option value="lubricant">Lubricant</mat-option>
            <mat-option value="product type">Product Type</mat-option>
            <mat-option value="design options">Design Options</mat-option>
            <mat-option value="customer">Customer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="width: 100%">
        <div style="max-width: 450px; margin: 0 auto 2rem auto">
          <p class="alert">{{ alert_applicability }}</p>
          <!-- <p class="alert" *ngIf='currentFirstEntryApplicability != firstEntryApplicability'>{{ alert_new_applicability }}</p> -->
        </div>
      </div>
    </form>
    <div class="text-center footer">
      <button mat-stroked-button matStepperPrevious class="mr-3">Previous</button>
      <button mat-flat-button matStepperNext class="mr-3" color="primary" (click)="validate()" [disabled]="!isApplicabilityStepNextEnabled">{{validateSecondStep?"Validate":"Next"}}</button>
      <button mat-flat-button class="mr-3" color="primary" *ngIf="allChaptersHasApplicabilities && !validateSecondStep" (click)="skipApplicability()">Skip</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
