<div class="container-fluid">
  <div class="row">

    <div class="col-4">
      <ng-container
        [ngSwitch]="(auth.user$ | async).isAdmin || (auth.user$ | async).isAuthor ||(auth.user$ | async).isApprover
        || (auth.user$ | async).isReviewer">
        <writer-side-bar *ngSwitchCase="true"></writer-side-bar>
        <notifications-panel *ngSwitchCase="false"></notifications-panel>
      </ng-container>
    </div>

    <div *ngIf="auth.login$ | async" class="col-8">
      <div class="container">
        <div class="row mt-3">
          <div class="col">
            <!-- new revision notification -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>A new Revision is available !</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p *ngFor="let activity of notifications;" class="mat-body">
                  <ng-container *ngIf="activity.newStatus == 'published' && activity.filename != undefined ">
                    <a href="#"
                       (click)="download(activity.filename, activity.tsli.docType, activity.tsli.reference)">{{activity.tsli.docType | uppercase}} {{activity.tsli.reference}}</a>
                    was {{activity.event}} at rev. {{ activity.tsli.revision }}
                  </ng-container>
                  <ng-container *ngIf="activity.newStatus == 'published' && activity.filename == undefined ">
                    <a href="/tsli/by-reference/{{activity.tsli.docType}}/{{activity.tsli.reference}}">
                      {{activity.tsli.docType | uppercase}} {{activity.tsli.reference}}</a>
                    was {{activity.event}} at rev. {{ activity.tsli.revision }}
                  </ng-container>
                  <ng-container *ngIf="activity.newStatus == 'archived' && activity.filename != undefined ">
                    <a href="#" (click)="download(activity.filename, activity.tsli.docType, activity.tsli.reference)">
                      {{activity.tsli.docType | uppercase}} {{activity.tsli.reference}}</a>
                    rev. {{ activity.tsli.revision }}
                    <span> {{activity.event}}</span>
                  </ng-container>
                  <ng-container *ngIf="activity.newStatus == 'archived' && activity.filename == undefined ">
                    <a href="/tsli/by-reference/{{activity.tsli.docType}}/{{activity.tsli.reference}}">
                      {{activity.tsli.docType | uppercase}} {{activity.tsli.reference}}</a>
                    rev. {{ activity.tsli.revision }}
                    <span> {{activity.event}}</span>
                  </ng-container>
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button>{{ "Learn more" | uppercase }}</button>
              </mat-card-actions>
            </mat-card>
            <!-- end of new revision notification -->
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-xl">
            <mat-card>
              <mat-card-header style="height: 80px;">
                <mat-card-title>Basic Product Data</mat-card-title>
              </mat-card-header>
              <img mat-card-image src="assets/img/0A - Basic Product Data.jpg" alt="Placeholder image">
              <mat-card-content [innerHtml]="bpdDescription">
              </mat-card-content>
              <mat-card-actions class="text-center">
                <button mat-raised-button color="primary"
                        (click)="navigateToBasicProductData()">{{ "Continue" | uppercase }}</button>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="col-xl">
            <mat-card id="tsliCard">
              <mat-card-header style="height: 80px;">
                <mat-card-title>Technical Specifications
                  <br>
                  (TSLI, RTLI, NT, TSSU, FORM, INOR, UG)
                </mat-card-title>
              </mat-card-header>
              <img mat-card-image src="assets/img/0B-VAM Technical Specification.jpg" alt="Placeholder image">
              <mat-card-content [innerHTML]="techSpecsDescription">
              </mat-card-content>
              <mat-card-actions class="text-center">
                <button mat-raised-button color="primary"
                        (click)="navigateToDetailedTsli()">{{ "Continue" | uppercase }}</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
