import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-bar',
  templateUrl: './error-bar.component.html',
  styleUrls: ['./error-bar.component.scss']
})
export class ErrorBarComponent implements OnInit {

  formattedMsg: string;	

  constructor(@Inject(MAT_SNACK_BAR_DATA) public dataObj: any) {
  	if(this.dataObj !== undefined) {
	  	this.formattedMsg = `${this.dataObj.msg} (${this.dataObj.statusCode}: ${this.dataObj.statusText})`;
  	} else {
  		this.formattedMsg = 'We have an undefined error here, this is not good!';
  	}
  }

  ngOnInit() {
  }

}
