import {Component} from '@angular/core';

import {AuthService} from './core/auth.service';
import {Router, ActivatedRoute, Event, NavigationEnd, ParamMap} from '@angular/router';
import {User} from './core/data/User';
import {CurrentTsliStoreService} from './core/current-tsli-store.service';
import {combineLatest} from 'rxjs';
import {environment} from 'src/environments/environment';
import {DetailedTsliConstants} from './features/detailed-tsli/detailed-tsli.constants';
import {DetailedTsliStore} from './core/detailed-tsli-store.service';
import {ConfigurationService} from './core/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  selectedUser: string;
  currentUser: User;
  showPDF: boolean = false;
  showWatermark: boolean = false;
  showSummary: boolean = false;
  showPages: boolean = false;
  showTslisList: boolean = false;
  pageSize = 10000;
  users = environment.users;
  environment = environment;
  pages = undefined;
  draft: boolean = false;
  archived: boolean = false;
  watermark = '';
  byItem = false;
  extd = false;
  pdfListTitle = '';
  maxTitleLength = false;
  copyright = '';
  trademark = '';
  pdfFooter = '';
  subscriptions = [];
  switchForQual = false;
  switchForInt = false;

  constructor(public auth: AuthService, public router: Router,
              public store: CurrentTsliStoreService,
              public detailedTsliStore: DetailedTsliStore,
              public config: ConfigurationService,
              public route: ActivatedRoute) {
    console.log('0.0.5');
  }

  async ngOnInit() {
    await this.auth.Authentication();

    window.onfocus = () => {
      this.auth.activateWindow();
    };

    window.onblur = () => {
      this.auth.deactivateWindow();
    };

    this.auth.user$.subscribe(v => {
      setTimeout(
        () => {
          this.currentUser = v;
          this.switchForQual = environment.qual && environment.users.filter(u => u.login == this.currentUser.login).length != 0;
          this.switchForInt = environment.int && environment.users.filter(u => u.login == this.currentUser.login).length != 0;
        }
      );
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log(event);
        this.showWatermark = event.url.startsWith('/tsli/pdf-watermark');
        this.showSummary = event.url.startsWith('/tsli/pdf-summary');
        this.showPDF = event.url.startsWith('/tsli/as-pdf') || event.url.startsWith('/tsli/by-item/as-pdf');
        this.byItem = event.url.startsWith('/tsli/by-item/as-pdf') || JSON.parse(sessionStorage.getItem('summaryByItem'));
        this.showTslisList = event.url.startsWith('/tsli/pdf-list');
        if (this.showTslisList) {
          let decodedURL = decodeURI(event.url);
          if (event.url.includes('/connection/')) {
            this.pdfListTitle = 'List of TSLIs of ' + decodedURL.split('/').pop();
          } else if (event.url.includes('/theme/')) {
            this.pdfListTitle = 'List of TSLIs of ' + DetailedTsliConstants.THEMES.filter(t => t.key == decodedURL.split('/').pop())[0].process;

          } else {
            this.pdfListTitle = 'List of TSLIs by Reference';
          }

        }
        if (event.url.startsWith('/tsli/by-item/as-pdf')) {
          this.store.setCurrentTsliByItem(true);
        }
        // this.showPages= event.url.startsWith("/tsli/pdf-pages");
        this.watermark = ' DRAFT ';
      }
    });

    this.store.tsli$.subscribe(tsli => {
      this.maxTitleLength = tsli.title && tsli.title.length > 94;
      this.archived = tsli.status == 'archived';
      this.draft = tsli.status != 'published' && !this.archived;
      if (this.draft) {
        this.watermark = ' DRAFT ';
      } else if (this.archived) {
        this.watermark = ' NOT APPLICABLE ';
      }
    });

    this.extd = JSON.parse(sessionStorage.getItem('extd')) != undefined;

    this.dynamicFooter();
    this.dynamicFooterPDF();
    this.config.loadAll();

  }

  ngAfterViewChecked() {
    let pageNumLeftMargin = '-5px';
    if (this.showTslisList) {
      pageNumLeftMargin = '-30px';
    }
    this.pages = JSON.parse(sessionStorage.getItem('pages'));
    // this.pages = 48
    if (document.getElementById('page-numbers') != undefined && this.pages != undefined) {
      document.getElementById('page-numbers').innerText = '';
      document.getElementById('first-page-numbers').innerText = '';
      if (this.pages != undefined) {
        const element = document.createElement('DIV');
        element.classList.add('d-flex');
        element.classList.add('justify-content-around');
        element.classList.add('page-num');
        element.style.position = 'relative';
        //1684 page height, 32.8 page num div height (we use relative positions)
        element.style.top = '0px';
        element.style.left = '0px';
        element.style.zIndex = '0';
        element.innerHTML = '<span>1</span><span>/</span><span>' + this.pages + '</span>';
        let parent = document.getElementById('first-page-numbers');
        parent.appendChild(element);
      }
      for (let index = 1; index < this.pages; index++) {
        const element = document.createElement('DIV');
        element.classList.add('d-flex');
        element.classList.add('justify-content-around');
        element.classList.add('page-num');
        element.style.position = 'relative';
        //1684 page height, 32.8 page num div height (we use relative positions)
        element.style.top = (index == 0 ? 0 : index * (1684 - 52.8)) + 'px';
        element.style.left = pageNumLeftMargin;
        element.style.zIndex = '' + index;
        element.innerHTML = '<span>' + (1 + index) + '</span><span>/</span><span>' + this.pages + '</span>';
        let parent = document.getElementById('page-numbers');
        parent.appendChild(element);

      }
    }
  }

  // ngAfterViewInit(): void {
  //   this.dynamicFooter();
  //   this.dynamicFooterPDF();
  //   this.config.loadAll();
  // }

  dynamicFooterPDF() {
    this.subscriptions.push(this.config.getConfigurationByName('pdf_footer').subscribe(v => {
      this.copyright = v["configurations"]["value"]
    }));
  }

  dynamicFooter() {
    this.subscriptions.push(this.config.getConfigurationByName('copyright').subscribe(v => {
      this.copyright = v["configurations"]["value"]
    }));
    this.subscriptions.push(this.config.getConfigurationByName('trademark').subscribe(v => {
      this.copyright = v["configurations"]["value"]
    }));
  }

  manageUsers() {
    this.router.navigate([`tsli/admin/users`]);
  }

  manageConfigurations() {
    this.router.navigate([`tsli/admin/config`]);
  }

  manageProductList() {
    this.router.navigate([`tsli/admin/product-list-management`]);
  }

  createNotifications() {
    this.router.navigate([`tsli/admin/notifications`]);
  }

  openVideo() {
    this.router.navigate([`tsli/video`]);

  }

  changeUser(login: string) {
    environment.users.forEach(user => {
      if (login == user.login) {
        localStorage.clear();
        const url = this.router.createUrlTree(['/'], { queryParams: { token: user.token } }).toString();
        this.router.navigateByUrl(url).then(() => {
          // Once the URL is updated, reload the page
          window.location.reload();
        });
        return;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
