import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-spinner-dialog',
  templateUrl: './spinner-dialog.component.html',
  styleUrls: ['./spinner-dialog.component.scss']
})
export class SpinnerDialogComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) { }

  componentName: string;
  componentType: string;
  message = ''
  done = false;
  showButton = false;

  ngOnInit() {
  }

  dismiss() {
    navigator.clipboard.writeText(this.message);
    this.activeModal.dismiss('error');
  }

}
